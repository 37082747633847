<template>
  <div
    class="row"
    style="height:350px;">
    <!-- Image -->
    <div
      class="col-md-6 d-none d-md-block"
      :style="bg"/>
    <div class="col-md-6 col-12 bg-success p-5 position-relative">
      <div class="vertical-center">
        <div v-if="view=='dealer'">
          <h2
            class="text-white"
            style="font-weight:400;">
            WHY WAIT?
            <br>
            SIGN-UP WITH LANCASTER TODAY!
          </h2>
        </div>
        <div v-if="view == 'manufacturer'">
          <h2
            class="text-white"
            style="font-weight:400;">
            INQUIRE ABOUT BECOMING A SUPPLIER
          </h2>
        </div>
        <button
          class="btn btn-outline-light"
          style="border-radius: 10px; width:200px;"
          data-bs-toggle="modal"
          data-bs-target="#ContactModal">
          SIGN-UP
        </button>
      </div>
    </div>
    <ContactModal :activeForm="activeForm"/>
  </div>
</template>

<script>
  import ContactModal from '../../contact/ContactModal.vue'
  
  export default {
    props: ['view'],
    data(){
      return{
        bg: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/dealer/warehouseAction.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        },
        activeForm: this.setModal()
      }
    },
    methods:{
      setModal(){
        if(this.view == 'dealer'){
          return {
            title: 'Become A Lancaster Customer',
            formName: 'customer'
          }
        }
        else{
          return {
            title: 'Become A Supplier',
            formName: 'sell'
          }
        }
      }
    },
    components:{
      ContactModal
    }
  }
</script>

<style scoped>
  .vertical-center{
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
  }
</style>