<template>
  <tr>
    <!-- Blank Handler -->
    <td
      v-if="blankError"
      class="animate__animated animate__bounceIn animate__faster"
      colspan="3">
      <span class="text-danger">
        <i class="fa fa-exclamation-triangle"/>
        Fields Cannot be Blank!
      </span>
    </td>
    <!-- Standard View -->
    
    <!-- Delete Mode - Only when toggled on -->
    <td v-if="deleteMode">
      <input   
        type="checkbox"
        class="form-check-input"
        style="cursor: pointer;"
        @change="toggleDeleteProduct"
        v-model="deleteProduct">
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.product_id">
      </div>
      <div v-else>
        {{ p.product_id }}
      </div>
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.class3_price"
          min="0.01"
          step="0.01">
      </div>
      <div v-else>
        {{ p.class3_price ? numeral(p.class3_price).format('$0,0.00') : null}}
      </div>
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.promo_price"
          min="0.01"
          step="0.01">
      </div>
      <div v-else>
        {{ p.promo_price ? numeral(p.promo_price).format('$0,0.00') : null}}
      </div>
    </td>
    <!-- Qty Breaks -->
    <!-- Qty Break 1 -->
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.qty_break1"
          min="1"
          step="1">
      </div>
      <div v-else>
        {{ p.qty_break1 ? numeral(p.qty_break1).format('0,00') : null }}
      </div>
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <select
          class="form-select"
          v-model="form.qty_break1_uom">
          <option
            v-for="(o,i) in uomOptions"
            :key="i">
            {{ o }}
          </option>
        </select>
      </div>
      <div v-else>
        {{ p.qty_break1_uom ? p.qty_break1_uom : null}}
      </div>
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.qty_break1_price"
          min="0.01"
          step="0.01">
      </div>
      <div v-else>
        {{ p.qty_break1_price ? numeral(p.qty_break1_price).format('$0,0.00') : null}}
      </div>
    </td>
    <!-- Qty Break 2 -->
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.qty_break2"
          min="1"
          step="1">
      </div>
      <div v-else>
        {{ p.qty_break2 ? numeral(p.qty_break2).format('0,00') : null}}
      </div>
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <select
          class="form-select"
          v-model="form.qty_break2_uom">
          <option
            v-for="(o,i) in uomOptions"
            :key="i">
            {{ o }}
          </option>
        </select>
      </div>
      <div v-else>
        {{ p.qty_break2_uom ? p.qty_break2_uom : null}}
      </div>
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.qty_break2_price"
          min="0.01"
          step="0.01">
      </div>
      <div v-else>
        {{ p.qty_break2_price ? numeral(p.qty_break2_price).format('$0,0.00') : null}}
      </div>
    </td>
    <!-- Qty Break 3 -->
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.qty_break3"
          min="1"
          step="1">
      </div>
      <div v-else>
        {{ p.qty_break3 ? numeral(p.qty_break3).format('0,00') : null}}
      </div>
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <select
          class="form-select"
          v-model="form.qty_break3_uom">
          <option
            v-for="(o,i) in uomOptions"
            :key="i">
            {{ o }}
          </option>
        </select>
      </div>
      <div v-else>
        {{ p.qty_break3_uom ? p.qty_break3_uom : null}}
      </div>
    </td>
    <td v-if="!blankError">
      <div v-if="edit">
        <input
          type="number"
          class="form-control"
          v-model="form.qty_break3_price"
          min="0.01"
          step="0.01">
      </div>
      <div v-else>
        {{ p.qty_break3_price ? numeral(p.qty_break3_price).format('$0,0.00') : null}}
      </div>
    </td>
    <!-- Actions -->
    <td style="width: 15vw;">
      <EditDelete
        v-if="!edit"
        :promoProduct="p"
        @toggleEdit="toggleEdit"/>
      <SaveCancel
        v-else
        @toggleEdit="toggleEdit"
        @saveProductRow="save"/>
    </td>
  </tr>
</template>

<script>
  import EditDelete from './EditDelete.vue';
  import SaveCancel from './SaveCancel.vue'
  
  export default{
    props: ['p', 'deleteMode', 'activePriceBranch'],
    data(){
      return{
        edit: false,
        blankError: false,
        deleteProduct: false,
        form: {
          id: null,
          promo_id: null,
          product_id: null,
          price_branch: null,
          class3_price: null,
          promo_price: null,
          qty_break1: null,
          qty_break1_uom: null,
          qty_break1_price: null,
          qty_break2: null,
          qty_break2_uom: null,
          qty_break2_price: null,
          qty_break3: null,
          qty_break3_uom: null,
          qty_break3_price: null,
        },
        uomOptions: [null, 'ea', 'cs']
      }
    },
    mounted(){
      this.mountForm()
    },
    watch:{
      deleteMode(newVal){
        if(!newVal){
          this.deleteProduct = false
        }
      },
      activePriceBranch(newVal){
        if(newVal){
          this.mountForm()
        }
      }
    },
    methods:{
      mountForm(){
        for(const f in this.form){
          this.form[f] = this.p[f]
        }
      },
      toggleEdit(){
        this.edit = !this.edit
      },
      toggleBlankError(){
        this.blankError = !this.blankError
      },
      resetBlank(){
        setTimeout(()=>{
          this.toggleBlankError()
        }, 3000)
      },
      save(){
        let blank = false
        for(const f in this.form){
          if(this.form[f] == ''){
            blank = true
          }
        }
        if(blank){
          this.toggleBlankError()
          this.resetBlank()
        }else{
          this.$store.dispatch('updatePromoProduct', this.form)
          this.toggleEdit()
        }
      },
      toggleDeleteProduct(){
        this.$emit('toggleDeleteProduct', this.p.id)
      }
    },
    components:{
      EditDelete,
      SaveCancel
    }
  }
</script>

<style scoped>
  .form-control, .form-select{
    padding: 3px !important;
    font-size: 12px !important;
    min-width: 50px !important;
  }
</style>