<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <h4>User Stats</h4>
        <!-- Session Details -->
        <table class="table table-sm table-striped">
          <tbody>
            <tr
              v-for="(v,k) in sessionStatus"
              :key="v">
              <td>{{k}}</td>
              <td>
                <div v-if="k == 'settings'">
                  <ul>
                    <li
                      v-for="(s) in v"
                      :key="s">
                      {{s}}
                    </li>
                  </ul>
                </div>
                <div v-if="k == 'user'">
                  <ul>
                    <li
                      v-for="(uv, uk) in v"
                      :key="uk">
                      {{uk}}: {{uv}}
                    </li>
                  </ul>
                </div>
                <div v-if="!['user', 'settings'].includes(k)">
                  {{v}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    computed:{
      sessionStatus(){return this.$store.getters.sessionStatus}
    }
  }
</script>