<template>
  <div
    class="modal fade"
    id="productDetail"
    tabindex="-1"
    role="dialog">
    <div
      class="modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-scrollable"
      role="document">
      <div
        v-if="product"
        class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="btn-close float-end"
            data-bs-dismiss="modal"></button>
          <!-- Product Data -->
          <div class="container-fluid">
              <div
                v-if="showFav"
                class="row">
                <div class="col-md-12 mt-3">
                  <!-- Favorites -->
                  <FavPanel
                    v-if="!virtualShow"
                    @toggleFav="toggleFav"
                    :showFav="showFav"
                    :product="product"/>
                </div>
              </div>
              <div
                v-if="!showFav"
                class="row mt-4">
                <div class="col-md-4">
                  <!-- Image -->
                  <div class="text-center p-2">
                    <!-- Fav Trigger -->
                    <i
                      class="fav text-danger float-end"
                      style="left: 15px; position: relative;"
                      :class="isFav ? 'fa fa-heart' : 'far fa-heart'"
                      @click="toggleFav"/>
                    <img
                      :src="product.image_url"
                      @error="imgError"
                      class="img-fluid"
                      style="height:300px; object-fit:contain;">
                  </div>
                </div>
                <div class="col-md-8">
                  <Indicator :product="product"/>
                  <strong>{{product.description}}</strong>
                  <p
                    v-if="generalInfo"
                    class="text-muted">
                    {{generalInfo}}
                  </p>
                  <!-- General Info -->
                  <div>
                    <ul>
                      <li
                        v-for="b in bullets"
                        :key="b.description">
                        <small>
                          {{b.value}}
                        </small>
                      </li>
                    </ul>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <StockStatus
                            v-if="internalRoles.includes(role)"
                            :product="product"/>
                          <BrandLink
                            v-if="product.vendor_id"
                            :product="product"
                            data-bs-dismiss="modal"/>
                          <div>
                            <Exclusion
                              :product="product"
                              class="mt-2"/>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <ProductPrice
                            class="price my-2"
                            :product="product"
                            :virtualShow="virtualShow"
                            :hidePricing="hidePricing"
                            @activeUom="setActiveUom"
                            :key="product.product_id"/>
                        </div>
                        <div class="col-md-8">
                          <!-- Form -->
                          <QtyForm
                            :product="product"
                            :virtualShow="virtualShow"
                            class="mt-2"
                            :activeUom="activeUom"
                            :key="product.product_id"/>
                          <QtyBreaks
                            :product="product"
                            :virtualShow="virtualShow"/>
                        </div>
                      </div>
                      <div class="part-and-prop my-2">
                        <CustomerPartNumber :product="product"/>
                        <Prop65
                          :product="product"
                          class="float-end"
                          style="font-size: 12px;"/>
                      </div>
                      <div class="mt-2">
                        <!-- Availability & Detail -->
                        <ProductAccordion
                          :product="product"
                          :activeUom="activeUom"
                          :info="info"
                          :minQuantity="minQuantity"
                          :caseQty="caseQty"
                          :palletQty="palletQty"
                          :open="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12">
            <button
              v-if="route != '/virtual_show'"
              type="button"
              class="float-start btn btn-primary btn-sm"
              @click="gotoProduct"
              data-bs-dismiss="modal">
              Go to Product Page
            </button>
            <button
              type="button"
              class="float-end btn btn-primary btn-sm"
              data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import QtyForm from '../QtyForm.vue'
  import FavPanel from './FavPanel.vue'
  import Exclusion from '../Exclusion.vue'
  import Indicator from '../Indicator.vue'
  import CustomerPartNumber from './CustomerPartNumber.vue'
  import ProductPrice from '../price/ProductPrice.vue'
  import QtyBreaks from '../QtyBreaks.vue'
  import {caseQuantity,palletQuantity, minQuantity} from '../../../../../shared/ProductFunctions'
  import {showDetail} from '../../../../../shared/showDetail'
  import comingSoon from '../../../../../shared/comingSoon'
  import BrandLink from './BrandLink.vue'
  import { internalRoles } from '../../../../../shared/roles'
  import StockStatus from '../StockStatus.vue'
  import Prop65 from '../Prop65.vue'
  import ProductAccordion from '../productPage/ProductAccordion.vue'
  
  export default {
    inject:['env'],
    // Close fav panel view/status on modal close/hide
    mounted(){
      const modal = document.getElementById('productDetail')
      const accordions = document.querySelectorAll('[class^=accordion-collapse]')
      const accordionBtns = document.querySelectorAll('[class^=accordion-button]')
      if(modal){
        modal.addEventListener('hidden.bs.modal', function(){
          this.$store.dispatch('setShowFav', false)
        }.bind(this))
      }
      if(this.$router.currentRoute.value.path == '/virtual_show'){
        this.virtualShow = true
      }else{
        this.virtualShow = false
      }
    },
    data(){
      return{
        virtualShow: false,
        internalRoles,
        activeUom: null
      }
    },
    components:{
      QtyForm,
      FavPanel,
      Exclusion,
      Indicator,
      CustomerPartNumber,
      ProductPrice,
      QtyBreaks,
      BrandLink,
      StockStatus,
      Prop65,
      ProductAccordion
    },
    methods:{
      toggleFav(){this.$store.dispatch('setShowFav', !this.showFav)},
      imgError(e){comingSoon(e)},
      setActiveUom(activeUom){this.activeUom = activeUom},
      gotoProduct(){this.$router.push({path: `/product/${this.product.product_id}`})}
    },
    computed:{
      product(){return this.$store.getters.getActiveProduct},
      generalInfo(){
        let record = _.find(this.product.eclipse_data.specs, {description: 'General Information'})
        if(record) return record.value
        return null
      },
      minQuantity(){return minQuantity(this.product, this.activeUom, this.env)},
      showFav(){return this.$store.getters.showFavPanel},
      info(){return _.reject(this.product.eclipse_data.specs, s=> _.some(['Bullet', 'General Information'], v=> _.includes(s.description, v)))},
      bullets(){return _.filter(this.product.eclipse_data.specs, s => _.includes(s.description, 'Bullet'))},
      hidePricing(){return this.$store.getters.hidePricing},
      uomQty(){return _.find(this.product.eclipse_data.uoms, {name: this.product.eclipse_data.uom}).quantity},
      caseQty(){return caseQuantity(this.product)},
      palletQty(){return palletQuantity(this.product)},
      showDetail(){if(this.virtualShow) return showDetail(this.product)},
      role(){return this.$store.getters.user.role},
      favoriteList(){return this.$store.getters.getFavList},
      isFav(){return _.includes(this.favoriteList, this.product.product_id)},
      route(){return this.$router.currentRoute.value.path}
    }
  }
</script>

<style scoped>
  .fav{   
    cursor:pointer;
    background: white;
    border-radius: 50%;
    padding: 3px;
  }
  .fav:hover{
    transform: scale(1.2);
    transition: .1s ease-in-out;
  }
  .modal-footer{
    border: none;
  }
  .part-and-prop{
		display: flex;
    justify-content: space-between;
    align-items: baseline;
	}
  .price{
		position: relative;
    top: 8px;
	}
</style>