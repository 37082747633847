<template>
  <div class="mx-1 dropdown">
    <!-- Drop Down Button -->
    <div class="d-grid">
      <button
        class="btn btn-primary btn-sm dropdown-toggle"
        data-bs-auto-close="outside"
        :class="mobile ? 'w-100' : ''"
        type="button"
        id="categoryFilter"
        data-bs-toggle="dropdown"
        :disabled="loading">
        Categories
      </button>
      <div
        class="dropdown-menu p-2 wrapper"
        :class="{'w-100': fullWidthMenu}"
        id="categoryFilterDropdown">
      <!-- Reset Brand Filters -->
        <div>
          <strong class="float-start pt-2">
            Select Categories
          </strong>
          <button
            class="btn btn-outline-primary btn-sm float-end p-1"
            @click="clear">
            <i class="fa fa-ban"/>
            Clear
          </button>
        </div>
        <div class="clearfix"/>
        <!-- Category List -->
        <ul class="p-1 brand-list">
          <li
            v-for="c, index in categoryList"
            :key="index">
            <!-- CheckBoxes -->
            <!-- Main Category -->
            <div class="pretty p-default w-100">
                <input
                  :id="c.main + 'checkbox'"
                  type="checkbox"
                  v-model="categoryList[index].checked"
                  @click="checkMain(c)"/>
                <div class="state p-primary">
                    <label
                      :for="c.main + 'checkbox'">
                      {{c.main}}
                    </label>
                </div>
            </div>
            <!-- Sub Category -->
            <div
              class="ms-4 pretty p-default"
              v-for="s, index in c.subs"
              :key="index">
                <input
                  :id="s.sub + 'checkbox'"
                  type="checkbox"
                  v-model="c.subs[index].checked"
                  @click="checkSub(c, s)"/>
                <div class="state p-primary">
                    <label :for="s.sub + 'checkbox'">
                      {{s.sub}}
                    </label>
                </div>
            </div>
          </li>
        </ul>
        <!-- Submit -->
        <div class="d-grid">
          <button
            class="btn btn-primary btn-sm"
            @click="submit"
            data-bs-dismiss="offcanvas">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import hideDropdown from '../../../../../../shared/hideDropdown'
  import { buildCategories } from '../../../../../../repositories/filterActions';
  
  export default {
    props:['loading', 'filters', 'products', 'mobile', 'fullWidthMenu'],
    data(){
      return{
        categoryList: _.reject(this.filters.options.categories, {main: 'Uncategorized'})
      }
    },
    methods:{
      // submit category filter
      submit(){
        hideDropdown('categoryFilterDropdown')
        // Set mains
        const mains = _.map(_.filter(this.categoryList, {checked: true}), 'main')
        let subs = []
        // Set subs
        this.categoryList.forEach(m=>{
          m.subs.forEach(s=>{
            if(s.checked) subs.push(s.sub)
          })
        })
        const categories = mains.length || subs.length ? {mains, subs} : null
        const data = {
          path: this.route,
          action: 'categoryFilter',
          categories
        }
        this.$store.dispatch('filterProducts', data)
      },
      // Uncheck all subs under main if main is unchecked
      checkMain(main){
        main.checked = !main.checked
        if(main.checked == false){
          main.subs.forEach(s=>{
            s.checked = false
          })
        }
      },
      // auto check the main if sub is checked
      checkSub(main, sub){
        sub.checked = !sub.checked
        let match = _.find(this.categoryList, {main: main.main})
        match.checked = sub.checked
        let subsChecked = _.map(match.subs, s => s.checked)
        if(_.includes(subsChecked, true) && main.checked == true){
          return
        }else if(_.includes(subsChecked, true) && main.checked == false){
          main.checked = true
        }else{
          main.checked = false
        }
      },
      // Clear all and submit
      clear(){
        this.categoryList.forEach(c =>{
          c.checked = false
          c.subs.forEach(s=>{
            s.checked=false
          })
        })
        this.submit()
      }
    },
    watch:{
      brands(){
        if(this.filters.selected.initiator == 'brandFilter'){
          this.categoryList = buildCategories(this.products)
        }else{
          this.categoryList = this.filters.options.categories
        }
      }
    },
    // get categories from state
    computed:{
      lists(){return this.$store.getters.filterLists},
      brands(){return this.filters.selected.brands},
      restrictedCategoryList(){return this.lists.restrictedCategoryList},
      fullWidth(){return window.innerWidth <= 575},
      route(){return this.$router.currentRoute.value.path}
    }
  }
</script>

<style scoped>
  .wrapper{
    width:350px;
    max-height:355px;
    position:relative;
  }
  .brand-list{
    list-style-type: none;
    overflow: hidden scroll;
    max-height: 245px;
    margin-top: 10px;
    border: 1px solid lightgray;
  }
</style>