<template>
  <div
    :id="`brandCard${brand.id}`"
    class="py-2 h-100"
    :ref="brand.name">
    <div 
      class="card card-hover"
      @click="gotoBrand">
      <div class="card-body p-0">
        <img
          v-if="visible"
          :src="brand.logo_url"
          class="img-fluid">
      </div>
    </div>
  </div>
</template>

<script>
  
  export default{
    props: ['brand'],
    data(){
      return{
        visible: false
      }
    },
    mounted(){
      if(this.$refs[`${this.brand.name}`]){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs[`${this.brand.name}`])
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    methods:{
      gotoBrand(){
        this.$router.push({
          name: 'ProductSearch',
          query: {
            action: 'browseBrand',
            brand: this.brand.mfg
          }
        })
      },
      handleIntersection(entries){
        for (let entry of entries) {
          if (entry.isIntersecting){
            this.visible = true
          }
        }
      },
    }
  }
</script>

<style scoped>
    .card{
      min-height: 100%;
    }
  .card-hover:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: 100ms ease-in-out;
    box-shadow: 1px 1px 10px lightgrey !important;
  }
</style>