<template>
  <div>
    <div class="row">
      <div class="col-md-12 bg-secondary text-center text-white py-2 shadow">
        <h3>Our Locations</h3>
        <small class="d-block my-2">Please call ahead and schedule an appointment before visiting show rooms.</small>
        <div
          v-if="!mobileView"
          class="btn-group">
          <button
            class="btn btn-primary btn-sm"
            :class="{active: view == 'map'}"
            @click="setView('map')">
            Map View
          </button>
          <button
            class="btn btn-primary btn-sm"
            :class="{active: view == 'card'}"
            @click="setView('card')">
            Card View
          </button>
        </div>
      </div>
      <!-- Google Maps -->
      <GMapMap
        v-if="view == 'map' && !mobileView"
        :center="center"
        :zoom="5"
        map-type-id="terrain"
        :disableDefaultUI="true"
        style="width: 100%; height: 691px;">
        <template>
          <GMapMarker
            :key="index"
            v-for="(b, index) in branches"
            :icon="['S68', 'S69'].includes(b.branch_id) ? cheneyIcon : icon"
            :position="b.position"
            :clickable="true"
            :draggable="false"
            @click="openWindow(b)">
            <GMapInfoWindow v-if="b.branch_id == activeWindow">
              <div style="line-height: 20px;">
                <div>
                  <h5>
                    {{ b.branch_id }} {{ b.city }}
                  </h5>
                  <span class="badge bg-success mb-1">
                    <i class="fa fa-check"/>
                    PROSUPPLY
                  </span>
                </div>
                <div>
                  {{ b.address }}
                  {{ b.city }}
                </div>
                <div>
                  {{ b.state }},
                  {{ b.zip }}
                </div>
                <div>
                  Phone:&nbsp;
                  <a :href="'tel:' + b.branch_contact.phone">{{ b.branch_contact.phone }}</a>
                </div>
                <div>
                  Email:&nbsp;
                  <a :href="'mailto:' + b.branch_contact.email">{{ b.branch_contact.email }}</a>
                </div>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
        </template>
      </GMapMap>
      <!-- Card View -->
      <div v-if="view == 'card'">
        <div
          class="row"
          style="border: 1px solid lightgray;"
          :style="mobileView ? '' : 'max-height: 691px; overflow-y: scroll;'">
          <BranchCards :branches="branches"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BranchCards from './BranchCards.vue'

export default {
  name: 'App',
  data() {
    return {
      view: 'map',
      windowWidth: null,
      branches: null,
      activeWindow: null,
      icon: require('../../../../../../assets/images/ejw/ejw_48.png'),
      cheneyIcon: require('../../../../../../assets/images/cheney/CheneyCircleLogo48.png'),
      center: {
        lat: 45.094055176,
        lng: -93.419189453
      }
    }
  },
  components:{
    BranchCards
  },
  created(){
    this.getBranches()
  },
  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods:{
    getBranches(){
      axios.get('/api/v1/public/branches/get_branches').then(res=>{
        let branches = _.filter(res.data.branches, b => b.address != null)
        branches.forEach(b=>{
          b.position = {
            lat: parseFloat(b.lat),
            lng: parseFloat(b.lng)
          }
        })
        this.branches = branches
      })
    },
    openWindow(branch){
      this.center = branch.position
      this.activeWindow = branch.branch_id
    },
    setView(view){
      this.view = view
    }
  },
  computed:{
    mobileView(){
      if(this.windowWidth && this.windowWidth < 576){
        this.view = 'card'
        return true
      }
      this.view = 'map'
      return false
    }
  }
}
</script>