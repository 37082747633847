<template>
  <div>
    <!-- Header -->
    <div class="row border-bottom bg-primary shadow-sm animate__animated animate__slideInDown animate__fast">
      <div class="col-md-12 col-12 p-2">
          <!-- Close -->
          <button
            class="close btn btn-outline-light btn-sm"
            @click="close">
            <i class="fa fa-times"/>
            Close
          </button>
          <!-- TM Details -->
          <div class="row">
            <div class="col-md-12 text-center text-light">
              <div>
                <div class="mt-1">{{tm.first_name}} {{tm.last_name}}</div>
                <small>{{tm.region}}</small>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 my-2">
          <div class="float-start">
            <div class="text-muted">Customer List</div>
          </div>
          <div class="float-end">
            <form
              name="subQuery"
              @submit.prevent="submitQuery"
              class="position-relative">
              <input
                class="form-control"
                style="max-width:200px;"
                type="text"
                placeholder="Search Customers..."
                required
                autocomplete="off"
                autocapitalize="off"
                spellcheck="false"
                v-model="subQuery">
                <i
                  v-if="subQuery"
                  class="fa fa-times text-danger clear"
                  @click="clearSubQuery"/>
            </form>
          </div>
        </div>
      </div>
    </div>
    <CustomerList
      :tm="tm"
      :subQuery="subQuery"/>
  </div>
</template>

<script>
  import CustomerList from './CustomerList.vue'
  
  export default {
    props: ['tm'],
    data(){
      return{
        subQuery: null
      }
    },
    components:{
      CustomerList
    },
    mounted(){
      const content = document.getElementById('content')
      content.scrollTo(0,0)
    },
    methods:{
      close(){
        this.$emit('deleteActive')
      },
      submitQuery(){
        document.activeElement.blur()
      },
      clearSubQuery(){
        this.subQuery = null
      }
    }
  }
</script>

<style scoped>

  .tm-image{
    height: 95px;
    object-fit: cover;
    border-radius: 5px
  }
  .close{
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
  }
  .nav-link{
    padding: 5px !important;
  }
  .clear{
    position: absolute;
    right: 5px;
    top: 1px;
    z-index: 3;
    padding: 10px;
  }
  .clear:hover{
    cursor: pointer;
  }
</style>