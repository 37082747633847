import store from '../../../stores/store'
import router from '../../../router'

export const searchLogic = (result) => {
  console.log(`Scanned: ${result.barcodeText}`)
  const query = {
    query: result.barcodeText,
    barcode: result.barcodeText,
    barcodeFormat: result.barcodeFormat,
    barcodeFormatString: result.barcodeFormatString
  }

  if(store.getters.vShowCameraResults && router.currentRoute.value.path != '/virtual_show'){
    router.push({path: '/virtual_show'})
    store.dispatch('vShowCameraSearch', {query})
  }else if(router.currentRoute.value.path == '/virtual_show'){
    store.dispatch('vShowCameraSearch', {query})
  }else{
    router.push({name: 'ProductSearch', query})
  }
}