<template>
  <div>
    <button 
      class="btn btn-primary btn-sm"
      @click="reset">
      <i class="fa fa-chevron-left"/>
      Back
    </button>
    <div class="col-md-12">
      <div>
        Name: {{fav.name}}
      </div>
      <div>
        Description: {{fav.description || 'N/A'}}
      </div>
      <hr>
      <!-- Content -->
      <div
        v-if="!loading"
        class="row">
        <div
          v-for="p in favProducts"
          :key="p.product_id"
          class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
          <ProductCard :product="p"/>
        </div>
      </div>
      <!-- Spinner -->
      <div
        v-if="loading"
        class="text-center">
        <Spinner/>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import ProductCard from './ProductCard.vue'

  export default {
    props:['fav'],
    data(){
      return{
        loading: true
      }
    },
    components:{
      Spinner,
      ProductCard
    },
    mounted(){
      this.$store.dispatch('favProducts', this.fav)
    },
    watch:{
      favProducts(newProducts){
        if(newProducts){
          this.loading = false
        }
      }
    },
    methods:{
      reset(){
        this.$emit('resetFav')
      }
    },
    computed:{
      favProducts(){
        return this.$store.getters.favProducts
      }
    }
  }
</script>

<style scoped>
  img{
    width:100%;
    height:150px;
    object-fit: contain;
  }
</style>