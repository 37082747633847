<template>
  <div class="main">
    <div class="row text-dark">
      <div class="col-md-12">
        <div class="bg-light white-card shadow">
          <div v-if="!loading">
            <div class="text-center">
              <img
                v-if="env == 'EJW'"
                :src="ejwLogo"
                class="img-fluid logo">
              <img
                v-else-if="env == 'EJW-CHENEY'"
                :src="cheneyLogo"
                class="img-fluid logo">
              <img
                v-else
                :src="logo"
                class="img-fluid logo">
              <h3 class="my-4">Set New Password</h3>
            </div>
            <PasswordForm
              :contact="contact"
              :forgotPassword="true"
              @passwordUpdated="removeForgotPasswordBlock"/>
          </div>
          <div
            v-if="loading"
            class="text-center mt-5">
            <Spinner/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import PasswordForm from '../views/user/components/PasswordForm.vue'
  import Spinner from '../../global/Spinner.vue'

  export default {
    inject: ['env'],
    data(){
      return{
        contact: null,
        loading: true,
        logo: require('../../../../assets/images/lan/LogoColorIcon.png'),
        ejwLogo: require('../../../../assets/images/ejw/home/eStore_Icon_OurHistory.png'),
        cheneyLogo: require('../../../../assets/images/cheney/CheneyLogoLight.png')
      }
    },
    components: {
      PasswordForm,
      Spinner
    },
    mounted(){
      this.dataFetch()
    },
    methods: {
      dataFetch(){
        axios.get('/api/v1/private/user_management/contact_details').then(res => {
          this.contact = res.data.contact
          this.loading = false
        })
      },
      removeForgotPasswordBlock(){
        this.$store.dispatch('removeForgotPasswordBlock')
      }
    },
    computed:{
      sessionCompany(){return this.$store.getters.sessionCompany}
    }
  }
</script>

<style scoped>
  .main{
    background: var(--bs-primary);
    background: -webkit-linear-gradient(to bottom, var(--bs-primary), var(--bs-secondary));
    background: linear-gradient(to bottom, var(--bs-primary), var(--bs-secondary));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .white-card{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 10px;
    min-width: 50%;
    min-height: 300px;
  }
  .logo{
    max-height: 100px;
  }
  @media(max-width: 744px){
    .white-card{
      min-width: 90%;
    }
  }
</style>