<template>
  <div class="forgot-password-main row mt-5 animate__animated animate__fadeIn animate__fast">
    <div class="col-md-12 text-center">
      <h4>ForgotPassword?</h4>
      <div v-if="!loading && !showConfirm && !showError">
        <p>Please enter the email address associated with your account.</p>
        <form
          name="forgotPassEnterEmail"
          class="input-group"
          @submit.prevent="submitForgot">
          <input
            type="email"
            class="form-control"
            required
            v-model="forgotEmail">
          <button
            type="submit"
            class="btn btn-outline-light btn-sm">
            Submit
          </button>
        </form>
        <a
          class="text-light float-start"
          href="javascript: void(0);"
          @click="cancel">
          Cancel
        </a>
      </div>
    </div>
    <!-- Forgot Password Confirmation -->
    <div
      v-if="showConfirm"
      class="col-md-12 text-center mt-2">
      <div class="my-2">
        <strong>Password Reset Request Recevied</strong>
      </div>
      <div class="my-2">
        <strong>You will receive an email shortly.</strong>
      </div>
    </div>
    <!-- Error -->
    <div
      v-if="showError"
      class="col-md-12 text-center mt-2 alert alert-danger">
      <div class="my-2">
        <h4>
          <i class="fa fa-exclamation-triangle"/>
          Error
        </h4>
      </div>
      <div class="my-2">
        <strong>{{error}}</strong>
      </div>
      <div class="my-2">
        <a
          v-if="inv == 'LAN'"
          href="mailto:orders@lancasterco.com">
          orders@lancasterco.com
        </a>
        <a
          v-else
          href="tel:888-396-1280">888-396-1280</a>
      </div>
    </div>
    <!-- Spinner -->
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner style="color: white !important;"/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../global/Spinner.vue'

  export default {
    inject: ['env'],
    data(){
      return{
        forgotEmail: null,
        showConfirm: false,
        loading: false,
        showError: false,
        error: null
      }
    },
    components:{
      Spinner
    },
    methods:{
      cancel(){
        this.$emit('cancel')
      },
      resetMessages(){
        setTimeout(function(){
          this.$emit('cancel')
          this.forgotEmail = null
          this.showConfirm = false
          this.showError = false
          this.error = null
        }.bind(this), 5000)
      },
      submitForgot(){
        this.loading = true
        axios.post('/api/v1/private/session/forgot_password', {email: this.forgotEmail}).then((res) => {
          this.loading = false
          if(!res.data.error){
            this.showConfirm = true
            this.resetMessages()
          }else{
            this.showError = true
            this.error = res.data.error
            this.resetMessages()
          }
        })
      }
    }  
  }
</script>