<template>
  <div>
    <!-- Core Application -->
    <div v-if="!forgotPassword">
      <!-- Non column Based -->
      <TopNav :vShowMode="vShowMode"/>
      <LeftMobileMenu/>
      <RightMobileMenu :vShowMode="vShowMode"/>
      <!-- Modals -->
      <ProductDetail :key="$route.fullPath"/>
      <ShipToSelectorModal v-if="!internalRoles.includes(user.role)"/>
      <PrepaidExclude/>
      <FeedBackModal/>
      <BetaModal/>
      <FavViewModal/>
      <CartCopyMoveModal/>
      <CrossSellModal/>
      <VideoModal/>
      <div class="row">
        <!-- Left Column -->
        <div
          ref="leftSide"
          class="col-lg-2 d-none d-lg-block left-side"
          v-if="!vShowMode"
          :style="[adminMode ? {'height': `100vh`, 'background': 'var(--bs-primary)'} : {'height': `85vh`, 'background' : 'white'}]">
          <LeftSide/>
        </div>
        <!-- Middle Column -->
        <div
          class="col-12"
          :class="vShowMode ? 'col-lg-12' : 'col-lg-10'">
          <Content :adminMode="adminMode"/>
          <BottomNav
            ref="bottomNav"
            class="bottom-nav"/>
        </div>
      </div>
    </div>
    <MobileFilterControls/>
    <!-- Set New Password if gone through forgort password avenue - Cant Continue until completed -->
    <SetNewPassword v-if="forgotPassword"/>
  </div>
</template>

<script>
  import Content from './Content.vue'
  import LeftSide from '../menus/desktop/LeftSide.vue'
  import RightSide from '../menus/desktop/RightSide.vue'
  import TopNav from '../nav/TopNav.vue'
  import BottomNav from '../nav/BottomNav.vue'
  import RightMobileMenu from '../menus/mobile/RightMobileMenu.vue'
  import LeftMobileMenu from '../menus/mobile/LeftMobileMenu.vue'
  import BetaModal from './BetaModal.vue'
  import SetNewPassword from './SetNewPassword.vue'
  import CartCopyMoveModal from './cartCopyMove/CartCopyMoveModal.vue'
  import MobileFilterControls from '../menus/mobile/filters/MobileFilterControls.vue'

  // Modals
  import ProductDetail from '../views/product/detail/ProductDetail.vue'
  import ShipToSelectorModal from './ShipToSelectorModal.vue'
  import PrepaidExclude from '../views/product/PrepaidExclude.vue'
  import FeedBackModal from '../app/FeedBackModal.vue'
  import FavViewModal from '../app/FavViewModal.vue'
  import VideoModal from './VideoModal.vue'
  import CrossSellModal from './CrossSellModal.vue'
  
  import {internalRoles} from '../../../shared/roles'


  export default{
    data(){
      return{
        internalRoles
      }
    },
    components:{
      Content,
      LeftSide,
      RightSide,
      TopNav,
      BottomNav,
      RightMobileMenu,
      LeftMobileMenu,
      ProductDetail,
      ShipToSelectorModal,
      PrepaidExclude,
      FeedBackModal,
      BetaModal,
      FavViewModal,
      SetNewPassword,
      CartCopyMoveModal,
      VideoModal,
      CrossSellModal,
      MobileFilterControls
    },
    computed:{
      showSideBar(){return this.$store.getters.showSideBar},
      showLeftMenu(){return this.$store.getters.showLeftMenu},
      showRightMenu(){return this.$store.getters.showRightMenu},
      adminMode(){return this.$router.currentRoute.value.path == '/admin'},
      vShowMode(){return this.$router.currentRoute.value.path == '/virtual_show'},
      user(){return this.$store.getters.user},
      forgotPassword(){return this.$store.getters.forgotPassword}
    }
  }
</script>

<style scoped>
  @media(min-width: 992px){
    .bottom-nav{
      display:none;
    }
  }
  .left-side{
    overflow-y: scroll;
  }
  .left-side::-webkit-scrollbar {
    display: none;
  }

/* Hide scrollbar for IE, Edge and Firefox */
.left-side{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
