<template :key="product.product_id">
  <div>
    <!-- Display Part Number -->
    <div v-if="partNumber">
      <small v-if="!editing && !confirm && !loading">
        <strong>Part Number:&nbsp;</strong>
        <a
          v-if="allowEdit"
          class="part-link"
          href="javascript: void(0)"
          @click="toggleEdit">
          {{partNumber}}
          <i class="fa fa-edit"/>
        </a>
        <span v-else>{{partNumber}}</span>
      </small>
    </div>
    <!-- Add Customer Part Number (if none)-->
    <div v-if="!partNumber && !editing && !confirm && !loading && allowEdit">
      <small>
        <a
          href="javascript: void(0)"
          @click="toggleEdit">
          <i class="fa fa-plus"/>
          Add Part Number
        </a>
      </small>
    </div>
    <!-- Editing Form -->
    <div v-if="editing && !confirm && !loading">
      <form
        name="custPartNumber"
        @submit.prevent="submit"
        class="input-group">
        <input
          class="form-control"
          v-model="partNumber"
          placeholder="Part Number"
          required
          type="text">
        <button
          class="btn btn-primary btn-sm cust-num-btn"
          @click="toggleEdit"
          type="button">
          <i class="fa fa-ban"/>
          <span class="d-none d-sm-none d-lg-block">Cancel</span>
        </button>
        <button
          class="btn btn-danger btn-sm cust-num-btn"
          @click="deletePart"
          :disabled="!product.customer_part_number"
          type="button">
          <i class="fa fa-trash"/>
          <span class="d-none d-sm-none d-lg-block">Delete</span>
        </button>
        <button
          class="btn btn-primary btn-sm cust-num-btn"
          type="submit">
          <i class="fa fa-paper-plane"/>
          <span class="d-none d-sm-none d-lg-block">Submit</span>
        </button>
      </form>
    </div>
    <!-- Confirmation Message -->
    <div
      v-if="confirm && !loading"
      class="animate__animated animate__bounceIn animate__faster">
      <strong
        :class="error ? 'text-danger' : 'text-success'">
        <i
          v-if="error"
          class="fa fa-exclamation-triangle"/>
        {{message}}
      </strong>
    </div>
    <!-- Spinner -->
    <Spinner v-if="loading"/>
  </div> 
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../../global/Spinner.vue'
  
  export default {
    props: ['product'],
    components:{
      Spinner
    },
    data(){
      return{
        editing: false,
        partNumber: this.product.customer_part_number?.part_number || null,
        previousVal: null,
        confirm: false,
        message: null,
        loading: false,
        error: false
      }
    },
    watch:{
      product(newVal){
        if(newVal){
          this.editing = false
          if(newVal.customer_part_number?.part_number){
            this.partNumber = newVal.customer_part_number.part_number
            this.reset()
          }else{
            this.confirm = false
            this.message = null
            this.loading = false
            this.editing = false
            this.partNumber = null
            this.previousVal = null
          }
        }
      }
    },
    methods:{
      reset(){
        this.confirm = false
        this.message = null
        this.loading = false
        this.editing = false
        this.error = false
      },
      toggleEdit(){
        this.editing = !this.editing
      },
      existing(){
        if(this.previousVal){
          return this.previousVal
        }
        else if(this.product.customer_part_number){
          return this.product.customer_part_number.part_number
        }
      },
      toggleConfirm(message){
        this.message = message
        this.confirm = true
        setTimeout(function(){
          this.reset()
        }.bind(this), 3000)
      },
      submit(){
        this.loading = true
        const params = {
          product_id: this.product.product_id,
          part_number: this.partNumber,
          existing: this.existing()
        }
        axios.post('/api/v1/private/customer_part_number/insert', params).then(res => {
          this.toggleConfirm(res.data.message)
          this.previousVal = res.data.record.part_number
          this.partNumber = res.data.record.part_number
          this.loading = false
          this.$store.dispatch('updateCustomerPartNumber', res.data.record)
        }).catch(error =>{
          this.error = true
          this.partNumber = null
          this.loading = false
          this.toggleConfirm(error.response.data.error)
        })
      },
      deletePart(){
        this.loading = true
        const params = {
          product_id: this.product.product_id,
          existing: this.existing()
        }
        axios.delete('/api/v1/private/customer_part_number/delete', {params}).then(res => {
          this.toggleConfirm(res.data.message)
          this.partNumber = null
          this.loading = false
          this.$store.dispatch('deleteCustomerPartNumber', {product_id: this.product.product_id})
        })
      }
    },
    computed:{
      allowEdit(){
        const mainEntity = this.$store.getters.getEntity.entity
        // Check to see if main entity is a billTo if true allow customer part number edit or submit
        // Only Bill To ID's should be able to edit customer part numbers
        return mainEntity.is_bill_to
      }
    }
  }
</script>

<style scoped>
  .part-link{
    text-decoration: none;
  }
  .cust-num-btn{
    font-size:12px;
    min-width: 40px;
  }
</style>