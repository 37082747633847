<template>
  <div class="my-5">
    <ProductSelection :categories="categories"/>
    <div
      class="row">
      <div class="col-md-12 text-center">
        <Vendors :vendors="vendors"/>
      </div>
    </div>
    <EjwBrand/>
    <hr>
    <PremierFlooring/>
  </div>
</template>

<script>
  import Vendors from './Vendors.vue'
  import EjwBrand from './EjwBrand.vue'
  import PremierFlooring from './PremierFlooring.vue'
  import ProductSelection from './ProductSelection.vue'

  export default {
    props: ['vendors', 'categories'],
    components:{
      Vendors,
      EjwBrand,
      PremierFlooring,
      ProductSelection
    }
  }
</script>