<template>
  <div class="row">
    <div
      v-for="r in records"
      :key="r.id"
      class="col-lg-3 col-md-6 p-2">
      <IndexGridTile
        :headers="headers"
        :record="r"
        :deleteAllowed="deleteAllowed"
        :key="headers.column"/>
    </div>
  </div>
</template>

<script>
  import IndexGridTile from './IndexGridTile.vue';
  
  export default{
    props: ['data', 'deleteAllowed'],
    components:{
      IndexGridTile
    },
    computed: {
      records(){
        if(this.data?.table_data?.map(d => d.column).includes('position')){
          return _.sortBy(this.data.table_values, 'position')
        }
        return this.data.table_values
      },
      headers(){return this.data.table_data}
    }
  }
</script>