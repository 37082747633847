<template>
  <div>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="mobileFilterControls" aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Filters</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <div class="text-muted">
          Filter & Sort Products
        </div>
        <SortBy
          v-if="show('SortBy') && !searching"
          class="my-2"
          :mobile="true"
          :loading="loading"
          :fullWidthMenu="fullWidthMenu"/>
        <BrandFilter
          v-if="show('BrandFilter') && !query.brand && !searching"
          class="my-2"
          :mobile="true"
          :filters="filters"
          :loading="loading"
          :products="products"
          :fullWidthMenu="fullWidthMenu"/>
        <CategoryFilter
          v-if="show('CategoryFilter') && !query.category && !searching"
          class="my-2"
          :mobile="true"
          :filters="filters"
          :loading="loading"
          :products="products"
          :fullWidthMenu="fullWidthMenu"/>
        <BranchFilter
          v-if="show('BranchFilter') && !searching"
          class="my-2"
          :mobile="true"
          :filters="filters"
          :loading="loading"
          :fullWidthMenu="fullWidthMenu"/>
      </div>
    </div>
  </div>
</template>

<script>
  import SortBy from '../../views/FilterControls/components/SortBy.vue'
  import BrandFilter from '../../views/FilterControls/components/BrandFilter.vue'
  import CategoryFilter from '../../views/FilterControls/components/CategoryFilter.vue'
  import BranchFilter from '../../views/FilterControls/components/BranchFilter.vue'

  export default{
    // Exclude prop should be filter component name(s) to be excluded
    // Prop can be singular name (string) or array of component names to exclude
    // Example Names: ViewSwitcher, SortBy, BrandFilter, CategoryFilter, BranchFilter
    // Example Usage: 'ViewSwitcher' OR ['SortBy', 'CategoryFilter']
    props: ['loading', 'exclude', 'products'],
    methods:{
      show(name){
        return this.exclude != name || !this.exclude.includes(name)
      }
    },
    components:{
      SortBy,
      BrandFilter,
      CategoryFilter,
      BranchFilter
    },
    computed:{
      filters(){return this.$store.getters.productFilters},
      query(){return this.$router.currentRoute.value.query},
      fullWidthMenu(){return window.innerWidth <= 575},
      searching(){return this.$store.getters.productLoadState}
    }
  }
</script>