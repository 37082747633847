<template>
  <!-- Cross Sell modal -->
  <div
    class="modal fade"
    id="crossSellModal"
    tabindex="-1"
    data-bs-keyboard="false"
    role="dialog"
    aria-labelledby="modalTitleId"
    aria-hidden="true">
    <div
      class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
      role="document">
      <div class="modal-content">
        <!-- Header -->
        <div class="modal-header">
          <img
            class="img-fluid"
            style="max-height: 70px; margin: 0 auto;"
            :src="headerImg">
        </div>
        <!-- Content -->
        <div class="modal-body">
          <!-- Form Confirmation -->
          <div
            v-if="showConfirm"
            class="text-center my-5 animate__animated animate__fadeIn animate__faster">
            <h3 class="text-success">
              <i class="fa fa-check-circle"/>
              Form Submitted
            </h3>
            <div class="text-muted">
              <div>
                <small>We will be in contact shortly.</small>
              </div>
              <div>
                <small>Thank You!</small>
              </div>
            </div>
          </div>
          <!-- Form & text blurb -->
          <div v-else>
            <!-- General info text and get started OR download button dependent on company -->
            <div
              v-if="!showForm"
              class="text-center">
              <p>{{ text }}</p>
              <p>
                <a
                  :href="link"
                  target="_blank">
                  Learn More About Our Sister Company, {{ company }}
                </a>
              </p>
              <!-- Download PDF - Sign up for EJW from Lancaster site only -->
              <a
                v-if="showDownload"
                class="btn btn-primary"
                :href="pdfForm"
                target="_blank">
                <i class="fa fa-download"/>
                Download Sign Up Form
              </a>
              <!-- Call to action - Sign up for Lancaster from EJW site only -->
              <a
                v-else
                class="btn btn-primary"
                href="javascript: void(0)"
                @click="toggleForm">
                Get Started!
              </a>
            </div>
            <!-- Contact Form for EJW site use only -->
            <div
              v-if="showForm"
              class="animate__animated animate__fadeIn animate__faster">
              <form @submit.prevent="submit">
                <CustomerForm :form="form"/>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-primary">
                    <i class="fa fa-paper-plane"/>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- Actions -->
        <div class="modal-footer">
          <button
            v-if="!showForm || showConfirm"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal">
            Close
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click="toggleForm">
            <i class="fa fa-ban"/>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
  import CustomerForm from '../../public/lan/contact/forms/CustomerForm.vue'
  import axios from 'axios'

  export default{
    inject: ['env'],
    data(){
      return{
        headerImg: this.setHeader(),
        text: this.setText(),
        pdfForm: 'https://merit-production.s3.amazonaws.com/lanEstore/crossSell/Customer_Information_Form_EJW.pdf',
        company: this.env.includes('EJW') ? 'Lancaster' : 'EJ Welch',
        link: this.env.includes('EJW') ? 'https://www.lancasterco.com' : 'https://www.ejwelch.com',
        showDownload: !this.env.includes('EJW'),
        showForm: false,
        form: {},
        showConfirm: false
      }
    },
    mounted(){
      this.setForm()
    },
    watch:{
      showForm(newVal){
        if(newVal && this.shipTo){
          this.setForm()
          this.form.customer_id = this.shipTo.entity_id
          if(this.shipTo.name) this.form.company = this.shipTo.name
          if(this.shipTo.address.street_line_1) this.form.address = this.shipTo.address.street_line_1
          if(this.shipTo.address.city) this.form.city = this.shipTo.address.city
          if(this.shipTo.address.state) this.form.state = this.shipTo.address.state
          if(this.shipTo.address.postal_code) this.form.zip = this.shipTo.address.postal_code
        }
      }
    },
    components:{
      CustomerForm
    },
    methods:{
      toggleForm(){
        this.showForm = !this.showForm
        if(!this.showForm) this.form = {}
      },
      setHeader(){
        let img
        if(this.env.includes('EJW')){
          img = require('../../../../assets/images/cross_sell/Become_a_Lancaster_Customer.png')
        }else{
          img = require('../../../../assets/images/cross_sell/Become_an_EJW_Customer.png')
        }
        return img
      },
      setText(){
        let txt
        if(this.env.includes('EJW')){
          txt = 'Lancaster is the premier paint sundry distributor, supplying products to retailers throughout North and South America. With seven distribution centers located in the US and Canada. Lancaster supplies over 13,000 products, from over 350 manufacturers, to more than 10,000 independent paint stores, national and regional paint chains, hardware stores, home centers and online retailers.'
        }else{
          txt = 'EJ Welch is one of the US’s largest flooring distributors, offering customers a large selection of popular favorites and trending styles. We are committed to your success and helping you win more jobs. Our sales and support staff are here to help keep your project moving, whether that be product knowledge, training, technical solutions or coordinating orders and deliveries.'
        }
        return txt
      },
      setForm(){
        this.form = {
          activeForm: 'customer',
          contactCompany: 'Lancaster'
        }
      },
      confirmFormSubmit(){
        this.showConfirm = true
        setTimeout(function(){
          this.setForm()
          this.showConfirm = false
        }.bind(this),5000)
      },
      submit(){
        axios.post('/api/v1/public/contact/submit_contact_form', this.form).then(res=>{
          if(res.status == 200){
            this.confirmFormSubmit()
          }
        })
      },
    },
    computed:{
      shipTo(){return this.$store.getters.getEntity.activeShipTo}
    }
  }
</script>