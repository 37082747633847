<template>
  <div>
    <div class="row mt-2 px-4">
      <div class="col-md-12">
        <h4>Catalogs</h4>
      </div>
    </div>   
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
    <div class="container-fluid">
      <div
        v-if="!loading"
        class="animate__animated animate__fadeIn animate__faster">
        <!-- Catalogs -->
        <div
          v-if="filteredCatalogs && filteredCatalogs.length > 0"
          class="row mb-5">
          <div
            v-for="c in filteredCatalogs"
            :key="c.id"
            class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            
            <Item :item="c" type="catalog" />

          </div>
        </div>
        <div
          v-else
          class="row">
          <div class="col-md-12 text-center mt-5">
            <h4 class="text-muted">No Catalogs</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../global/Spinner.vue'
  import Item from './Item.vue'

  export default {
    name: 'Catalogs',
    data(){
      return{
        loading: false,
        catalogs: null
      }
    },
    components:{
      Spinner,
      Item
    },
    created(){
      this.loading = true
      axios.get('/api/v1/private/digital_assets/get_assets').then(res =>{
        if(res.status == 200){
          this.catalogs = _.filter(res.data.assets, {asset_type: 'catalog'})
          this.catalogs = _.orderBy(this.catalogs,'created_at','desc')
          this.loading = false
        }
      })
    },
    computed: {
      filteredCatalogs(){
        return _.filter(this.catalogs, (c) => { return c.company == this.$store.getters.sessionCompany || c.company ==  'Both' })
      }
    }
  }
</script>
