<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="61" viewBox="0 0 358 84.687">
      <g transform="translate(0 -0.003)">
        <path :class="fillClass" d="M111.82,17.45H136v8.2H122.22v5.62h11.93v8.19H122.22V46H136.3v8.2H111.82Z"></path> 
        <path :class="fillClass" d="M165.57,43.35c0,6.94-4.47,11.51-12.93,11.51s-12.92-4.57-12.92-11.51V41.73h10.4v1.94c0,1.84.79,3,2.52,3s2.53-1.15,2.53-3V17.45h10.4Z"></path> 
        <path :class="fillClass" d="M212.22,17.45l6.31,22.75,4.15-22.75h10.4l-9.3,36.78H215l-7-25.69-7,25.69h-8.77l-9.3-36.78h10.4l4.15,22.75,6.3-22.75Z"></path> 
        <path :class="fillClass" d="M237.07,17.45h24.22v8.2H247.48v5.62H259.4v8.19H247.48V46h14.08v8.2H237.07Z"></path> 
        <path :class="fillClass" d="M267,17.45h10.41V46H291.5v8.2H267Z"></path> 
        <path :class="fillClass" d="M298.46,21.13c2.63-2.94,6.62-4.31,11.4-4.31,9.25,0,14.66,4.63,15.18,13.72h-10.3c-.15-3.42-1.47-5.52-4.88-5.52a4,4,0,0,0-3.68,1.84c-1.1,1.63-1.42,4-1.42,9s.32,7.35,1.42,9a4,4,0,0,0,3.68,1.84c3.41,0,4.73-2.1,4.88-5.51H325c-.52,9.09-5.93,13.71-15.18,13.71-4.78,0-8.77-1.37-11.4-4.31-3-3.36-4.1-8.25-4.1-14.71S295.41,24.49,298.46,21.13Z"></path> 
        <path :class="fillClass" d="M347.6,39.46h-7.15V54.23h-10.4V17.45h10.4V31.27h7.15V17.45H358V54.23H347.6Z"></path> 
        <path :class="fillClass" d="M111.7,66.87a6.58,6.58,0,0,1,5.75-2.66c4.41,0,7,2.74,7.2,6.61h-3.22a3.66,3.66,0,0,0-4-3.82,3.57,3.57,0,0,0-3.11,1.51c-.73,1.07-1,2.52-1,5.43s.32,4.35,1,5.42a3.57,3.57,0,0,0,3.11,1.51,3.66,3.66,0,0,0,4-3.82h3.22c-.16,3.87-2.79,6.61-7.2,6.61A6.58,6.58,0,0,1,111.7,81c-1.37-2-1.63-4.54-1.63-7.06S110.33,68.83,111.7,66.87Z"></path> 
        <path :class="fillClass" d="M140.88,66.87a7.54,7.54,0,0,1,11.5,0c1.37,2,1.63,4.54,1.63,7.07s-.26,5.1-1.63,7.06a7.54,7.54,0,0,1-11.5,0c-1.37-2-1.64-4.54-1.64-7.06S139.51,68.83,140.88,66.87Zm8.86,1.64a4,4,0,0,0-6.23,0c-.72,1.07-1,2.52-1,5.43s.33,4.35,1,5.42a4,4,0,0,0,6.23,0c.73-1.07,1-2.52,1-5.42S150.47,69.58,149.74,68.51Z"></path> 
        <path :class="fillClass" d="M177.25,83.34l-4.78-12.55V83.34h-3.18V64.53h3.55l5.88,15.32,5.89-15.32h3.54V83.34H185V70.79l-4.8,12.55Z"></path> 
        <path :class="fillClass" d="M207.24,76.41v6.93H204V64.53h7.47c3.7,0,6,2.15,6,5.94s-2.26,5.94-6,5.94Zm3.79-2.8a3.141,3.141,0,1,0,0-6.28h-3.79v6.28Z"></path> 
        <path :class="fillClass" d="M229.55,83.34l6.1-18.81h3.81l6.1,18.81h-3.33l-1.21-4h-7l-1.18,4Zm8-15.72-2.66,8.92h5.3Z"></path> 
        <path :class="fillClass" d="M263.18,70.15V83.34H260V64.53h3.31l7,13.19V64.53h3.17V83.34h-3.31Z"></path> 
        <path :class="fillClass" d="M300.1,64.53h3.39l-6.13,11.39v7.42h-3.22V75.92L288,64.53h3.39l4.35,8.54Z"></path> 
        <path class="primary-app" d="M69.65,25.16C58.78-1.24,44.37-1.36,40.16.89L26.5,14.3c11.33,1.49,13.94,7.61,13.94,7.61A30.94,30.94,0,0,0,23.1,19.06a11.89,11.89,0,0,0-6.76,3.32L0,38.5c16.5,1.83,20.6,13.92,24.76,23.08s12.93,19.84,24.4,23.11l44-41C81.23,43.8,72.25,31.48,69.65,25.16Z"></path> 
        <path class="light" d="M57.31,55.78c-.12,0-.43-.18-.7-1.25a19.15,19.15,0,0,1-.41-4.21v-.09c0-1.17,0-2.7.05-4.24a12.9,12.9,0,0,0-.3-3.51,16.26,16.26,0,0,0-2-5.06l-.3-.46a.17.17,0,0,0-.16-.09.2.2,0,0,0-.17.07L42.44,50h-.06a1.66,1.66,0,0,1-.9-.81,3,3,0,0,1,0-2.85c.66-1.37,1.31-2.79,1.93-4.15l.22-.47c.13-.3.27-.6.41-.9a20.33,20.33,0,0,0,1.39-3.46,19.74,19.74,0,0,0,.5-2.57,12.51,12.51,0,0,0-.1-4,5.61,5.61,0,0,0-.29-1,2.53,2.53,0,0,0-.35-.68c-.16-.21-.29-.35-.42-.35a.16.16,0,0,0-.09,0,.2.2,0,0,0-.11.21c.22,1.69-1.57,5.82-1.78,6.28L31.92,60a.2.2,0,0,0,0,.17l1.8,3.7a.21.21,0,0,0,.19.11c1.83,0,10.07-9.2,17-18.92,1.32-1.94,2.14-2.29,2.6-2.23.91.13.75,1.37.66,2.1v.35a76.17,76.17,0,0,0-.22,8.39c.2,5.29,1.14,8.6,2.72,9.54a.21.21,0,0,0,.24,0c6.65-5.55,11.94-26.67,13.85-35.86a19.755,19.755,0,0,1-1-2.13c-.39-.94-.78-1.85-1.18-2.73A96.93,96.93,0,0,1,57.93,55.34C57.84,55.48,57.56,55.82,57.31,55.78Z"></path>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: ['type'],
  computed: {
    fillClass(){
      return this.type ? this.type : 'light'
    }
  }
}
</script>

<style>
  .light{ fill:#fff}
  .dark{ fill: var(--bs-primary)}
  .primary-app{ fill:#D73E18; }
</style>