<template>
  <div class="animate__animated animate__fadeIn animate__faster">
    <!-- Added Animation -->
    <div
      v-if="added"
      class="text-success animate__animated animate__bounceIn animate__faster"
      style="font-size:12px;">
      <i class="fa fa-check-circle"/>
      <strong>&nbsp;Added to cart</strong>
    </div>
    <!-- Standard add btn -->
    <div :class="{condensed: mobile}">
      <form
        name="qtyForm"
        @submit.prevent="addToCart">
        <div
          v-if="product"
          class="input-group"> 
          <input
            :name="'qtyForm' + this.product.product_id"
            class="form-control"
            :disabled="disableState"
            type="number"
            :title="user.estore_noorder ? 'Your account is unable to add to cart or submit orders.' : ''"
            :min="minQty.quantity"
            :step="minQty.quantity"
            :placeholder="nonStockOutofStock ? 'Out of Stock' : ''"
            v-model="qty"/>
          <button
            :disabled="disableState"
            :title="user.estore_noorder ? 'Your account is unable to add to cart or submit orders.' : ''"
            class="btn btn-primary btn-sm py-1"
            :type="btnType">
            <i class="fa fa-cart-plus"/>
          </button>
        </div>
      </form>
      <div
        v-if="product.eclipse_data"
        class="text-muted"
        style="font-size:12px; min-width: 110px;">
        Min Order Qty:{{minQty.quantity + displayUom(minQty.name) }}
      </div>
    </div>
  </div>
</template>

<script>
  import {minQuantity, nonStockOutOfStock, displayUom } from '../../../../shared/ProductFunctions'
  import {showDetail} from '../../../../shared/showDetail'
  
  export default {
    inject: ['env'],
    props: ['product', 'virtualShow', 'activeUom', 'mobile'],
    data(){
      return{
        qty: null,
        added: false
      }
    },
    methods:{
      addToCart(){
        if(this.disableState) return
        this.product.cartData = {
          qty: this.qty == null ? this.minQty.quantity : this.qty,
          virtualShow: this.virtualShow ? true : false,
          dropship: this.virtualShow && this.detail?.dropship ? true : false,
          activeUom: this.activeUom
        }
        this.$store.dispatch('addToCart', this.product)
        this.added = true
        this.qty = null
        document.activeElement.blur()
        this.$emit('added')
      },
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      minQty(){
        if(this.product.eclipse_data){
          return minQuantity(this.product, this.activeUom, this.env)
        }
        return {name: null, quantity: null}
      },
      detail(){if(this.virtualShow) return showDetail(this.product)},
      user(){return this.$store.getters.user},
      cart(){return this.$store.getters.cart},
      cartLoading(){return this.$store.getters.cartLoading},
      nonStockOutofStock(){
        return nonStockOutOfStock(this.env, this.product)
      },
      disableState(){
        if(this.product.eclipse_data && this.cart){
          return this.product.eclipse_data.unit_price == 0 || this.user.estore_noorder || this.cartLoading || this.nonStockOutofStock
        }
        return true
      },
      btnType(){
        if(this.product.eclipse_data){
          this.product.eclipse_data.unit_price == 0 ? 'button' : 'submit'
        }
      }
    }
  }
</script>

<style scoped>
  .condensed{
    position: absolute;
    top: -43px;
    right: 0px;
    max-width: 120px;
  }

  .condensed-add-btn button{
    padding: 2px 10px;
  }
</style>