<template>
  <div class="container">
    <div class="row animate__animated animate__fadeIn">
      <div
        class="col-md-12 text-center"
        style="position: relative; top: 30vh;">
        <img
          :src="logo"
          class="img-fluid p-5"
          style="max-width: 300px;">
        <div class="progress">
          <div
            v-if="loadStep == 0"
            class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            style="width: 15%;">
            Initializing - 15%
          </div>
          <div
            v-if="loadStep == 1"
            class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            style="width: 25%;">
            Loading Show Dates - 25%
          </div>
          <div
            v-if="loadStep == 2"
            class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            style="width: 40%;">
            Loading Categories - 40%
          </div>
          <div
            v-if="loadStep == 3"
            class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            style="width: 60%;">
            Loading ReorderPad - 60%
          </div>
          <div
            v-if="loadStep == 4"
            class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            style="width: 75%;">
            Loading Favorites - 75%
          </div>
          <div
            v-if="loadStep == 5"
            class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            style="width: 90%;">
            Finalizing - 90%
          </div>
          <div
            v-if="loadStep == 6"
            class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            style="width: 100%;">
            Complete! - 100%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['loadStep'],
    data(){
      return{
        logo: require('../../../../../../assets/images/lan/virtual_show/vShowLogoDark.png')
      }
    }
  }
</script>

<style scoped>
  .progress{
    height: 2rem !important;
    font-size: 1rem !important;
  }
</style>