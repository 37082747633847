<template>
  <div
    v-if="recentlyViewed.length > 0"
    class="my-4">
    <div class="row">
      <div class="col-md-12 text-center">
        <h4 style="font-weight: 300">RECENTLY VIEWED</h4>
      </div>
    </div>
    <div id="recentSlider" class="carousel slide">
      <!-- <ol class="carousel-indicators">
        <li
          v-for="(row, index) in recentlyViewed"
          :key="index"
          data-bs-target="#recentSlider"
          :data-bs-slide-to="index"
          :class="{active: index == 0}"/>
      </ol> -->
      <div class="carousel-inner" role="listbox">
        <div
          class="carousel-item"
          :class="{active: index == 0}"
          v-for="(row, index) in recentlyViewed"
          :key="index">
          <div class="row">
            <div
              v-for="p in row"
              :key="p.product_id"
              class="col-lg-3 col-12">
              <ProductCard :product="p"/>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#recentSlider" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#recentSlider" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
  import ProductCard from '../product/ProductCard.vue'

  export default {
    data(){
      return{
        width: window.innerWidth
      }
    },
    components:{
      ProductCard
    },
    created() {
      window.addEventListener("resize", this.windowResize);
    },
    methods:{
      windowResize(){
        this.width = window.innerWidth
      }
    },
    computed:{
      recentlyViewed(){
        const base = this.$store.getters.recentlyViewed
        return _.chunk(base, this.width < 992 ? 1 : 4)
      }
    } 
  }
</script>

<style scoped>
  .carousel-indicators > li{
    height:10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--bs-primary);
  }
  .carousel{
    height: 550px;
  }
  .carousel-control-next, .carousel-control-prev{
    filter: invert(1);
  }
  .carousel-control-next{
    right: -1em;
  }
  .carousel-control-prev{
    left: -1em;
  }
  .carousel-inner{
    max-width: 80%;
    margin: 0 auto;
  }
</style>