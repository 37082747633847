<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h5>Search</h5>
      </div>
      <div class="col-md-12">
        <form
          name="binLabelSearch"
          @submit.prevent="submitSearch"
          class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="query"
            :disabled="loading"
            required
            placeholder="Search Products...">
          <button
            type="submit"
            class="btn btn-primary">
            <i class="fa fa-search"/>
          </button>
        </form>
      </div>
    </div>
    <div
      v-if="!loading && results && results.products && searchSubmitted"
      class="row animate__animated animate__fadeIn animate__faster">
      <div
        v-for="p in results.products"
        :key="p.product_id"
        class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
        <ProductCard :product="p"/>
      </div>
    </div>
    <div
      v-if="!loading && query && results && results.products.length == 0 && searchSubmitted"
      class="row animate__animated animate__fadeIn animate__faster">
        <div class="col-md-12 text-muted mt-5 text-center">
          <h4>No Results</h4>
        </div>
    </div>
    <div
      v-if="loading"
      class="row">
      <div class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
    </div>
  </div>
</template>

<script>
  import ProductCard from './ProductCard.vue'
  import Spinner from '../../../global/Spinner.vue'
  
  export default {
    data(){
      return{
        query: null,
        searchSubmitted: false
      }
    },
    created(){
      this.$store.dispatch('clearBinLabelSearchResults')
    },
    components:{
      ProductCard,
      Spinner
    },
    methods:{
      submitSearch(){
        this.searchSubmitted = true
        let params = {
          query:{
            query: this.query
          }
        }
        this.$store.dispatch('binLabelSearch', params)
      }
    },
    computed:{
      results(){return this.$store.getters.binLabelSearchResults},
      loading(){return this.$store.getters.binLabelSearchState}
    }
  }
</script>