<template>
  <div class="card my-2">
    <div class="card-body">
      <img
        :src="product.image_url"
        @error="imgError">
      <p
        class="text-center"
        style="overflow:hidden; height:70px;">
        {{product.description}}
      </p>
    </div>
    <div class="card-footer">
      <AddToList :product="product"/>
    </div>
  </div>
</template>

<script>
  import AddToList from './AddToList.vue'
  import comingSoon from '../../../../shared/comingSoon'

  export default {
    props:['product'],
    components:{
      AddToList
    },
    methods:{
      imgError(e){
        comingSoon(e)
      }
    }
  }
</script>

<style scoped>
  img{
    width:100%;
    height:150px;
    object-fit: contain;
  }
</style>