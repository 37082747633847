import axios from 'axios'
import Qs from 'qs'

const initialState = {
  // Holds Favorite Groups
  favorites: [],
  // Holds favorites groups with eclipse data for active Fav
  favoriteProducts: [],
  pagination: null,
  activeFav: null,
  loading: false,
  favShowMoreLoadState: false,
}

export const favoriteStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setFavorites(state, favorites){
      state.favorites = favorites
    },
    addToFavorites(state, favorite){
      state.favorites.push(favorite)
    },
    setActiveFav(state, fav){
      state.activeFav = fav
    },
    resetActiveFav(state){
      state.activeFav = null
    },
    unsetActiveFav(state){
      state.activeFav = null
      state.favoriteProducts = []
    },
    setFavProducts(state, results){
      state.favoriteProducts = results.products
      state.pagination = results.pagination
    },
    updateFavorite(state, favorite){
      let fav = _.find(state.favorites, {id: favorite.id})
      state.favorites = _.reject(state.favorites, {id: favorite.id})
      favorite.favorite_products = fav.favorite_products
      state.favorites.push(favorite)
    },
    removeFavGroup(state, id){
      state.favorites = _.reject(state.favorites, {id})
    },
    removeFavProduct(state, product_id){
      state.favoriteProducts = _.reject(state.favoriteProducts, f => f.product_id == product_id)
      state.activeFav.favorite_products = _.reject(state.activeFav.favorite_products, f => f.product_id == product_id)
    },
    setLoadStatus(state, status){
      state.loading = status
    },
    updateFavList(state, data){
      // Add
      if(data.action == 'add'){
        data.products.forEach(p => {
          const fav = {
            id: p.id,
            product_id: p.product_id,
            favorite_group_id: p.favorite_group_id
          }
          let group = _.find(state.favorites, {id: fav.favorite_group_id})
          group.favorite_products.push(fav)
        })
      }
      // Remove
      if(data.action == 'remove'){
        data.products.forEach(p => {
          let group = _.find(state.favorites, {id: p.favorite_group_id})
          group.favorite_products = _.reject(group.favorite_products, {product_id: p.product_id})
        })
      }
    },
    appendFavProducts(state, results){
      state.favoriteProducts = [...state.favoriteProducts, ...results.products]
      state.pagination = results.pagination
    },
    setFavShowMoreLoadState(state, status){
      state.favShowMoreLoadState = status
    }
  },
  actions:{
    favoriteResetState(context){
      context.commit('resetState')
    },
    getFavorites(context){
      context.commit('resetActiveFav')
      axios.get('/api/v1/private/favorites').then((res) =>{
        context.commit('setFavorites', res.data.favorites)
      })
    },
    createFavGroup(context, fav){
      axios.post('/api/v1/private/favorites', fav).then(res =>{
        context.commit('addToFavorites', res.data.favorite)
      })
    },
    setActiveFav(context, fav){
      context.commit('setActiveFav', fav)
    },
    unsetActiveFav(context){
      context.commit('unsetActiveFav')
    },
    getFavProducts(context, filters){
      if(!filters){
        context.commit('setLoadStatus', true)
      }else{
        context.commit('setFavShowMoreLoadState', true)
      }
      let products = _.map(context.state.activeFav.favorite_products, p => p.product_id)
      let params={products, ...filters}
      axios.get('/api/v1/private/favorites/get_products', {params, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})}).then(res => {
        if(res.data.results.pagination && res.data.results.pagination.currentPage > 1){
          context.commit('appendFavProducts', res.data.results)
          context.commit('setFavShowMoreLoadState', false)
        }else{
          context.commit('setFavProducts', res.data.results)
          context.commit('setLoadStatus', false)
        }
      })
    },
    favShowMore(context, page){
      let params = {
        filters: {
          area: 'favorites',
          path: '/ordering_tools',
          page: page ? page : 1
        }
      }
      this.dispatch('getFavProducts', params)
    },
    favProducts(context, fav){
      let products = _.map(fav.favorite_products, f => f.product_id)
      let params={products}
      axios.get('/api/v1/private/favorites/get_products', {params}).then(res => {
        context.commit('setFavProducts', res.data.results)
      })
    },
    setFromProduct(context, params){
      axios.post('/api/v1/private/favorites/set_from_product', {favorite: params}).then(res =>{
        context.commit('updateFavList', res.data)
      })
    },
    updateFavGroup(context, favGroup){
      axios.post('/api/v1/private/favorites/update_group', {favorite: favGroup}).then( res =>{
        context.commit('updateFavorite', res.data.favorite)
      })
    },
    deleteFavGroup(context, id){
      context.commit('removeFavGroup', id)
      axios.delete(`/api/v1/private/favorites/${id}`)
    },
    deleteFavProduct(context, detail){
      context.commit('removeFavProduct', detail.eclipse_id)
      axios.delete('/api/v1/private/favorites/delete_product_from_group', {params: detail})
    }
  },
  getters:{
    favorites: state => {return state.favorites},
    getFavList: state =>{
      let ids = []
      _.each(state.favorites, f => _.each(f.favorite_products, fp => ids.push(fp.product_id)))
      return _.uniq(ids)
    },
    getActiveFav: state => {return state.activeFav},
    activeFavLoadStatus: state => {return state.activeFavLoading},
    favProducts: state => {return state.favoriteProducts},
    favoritesPagination: state => {return state.pagination},
    favLoadStatus: state => {return state.loading},
    favShowMoreLoadState: state => {return state.favShowMoreLoadState}
  }
}