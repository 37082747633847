<template>
  <div
    v-if="showPrepaid"
    class="mt-3">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th
            class="text-center"
            colspan="2">
            <strong>
              <span v-if="qualify.pass && !qualify.info || !qualify.pass && !qualify.info">Prepaid&nbsp;</span>
              Freight
              <i
                v-if="qualify.pass && !qualify.info"
                class="fa fa-check-circle text-success"/>
              <i
                v-else-if="!qualify.pass && !qualify.info"
                class="fa fa-times-circle text-danger"/>
              <i
                v-else
                class="fa fa-info-circle text-info"/>
            </strong>
          </th>
        </tr>
      </thead>
      <tbody
        class="overflow-hidden"
        style="background: rgb(211,211,211,0.5)">
        <template v-if="loading">
          <tr>
            <td
              colspan="2"
              class="text-center">
              <Spinner/>
            </td>
          </tr>
        </template>
        <template v-else>
          <template v-if="freight.msg">
            <tr colspan="2">
              <td class="text-center">{{ freight.msg }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td>Min Order Value</td>
              <td class="animate__animated animate__fadeIn animate__faster">
                {{numeral(freight.amount).format('$0,0.00')}}
              </td>
            </tr>
            <tr>
              <td>Eligible</td>
              <td>{{numeral(eligible).format('$0,0.00')}}</td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {eligible, freight} from '../../../../shared/freight'
  import Spinner from '../../../global/Spinner.vue'

  export default {
    props: ['cartProducts'],
    data(){
      return{
        loading: false
      }
    },
    components:{
      Spinner
    },
    computed:{
      company(){return this.$store.getters.sessionCompany},
      showPrepaid(){return ['Cheney Vrooman', 'EJ Welch', 'Lancaster US'].includes(this.company)},
      freight(){return freight()},
      qualify(){
        let q = {pass: false, info: false}
        if(this.freight){
          q.pass = (this.freight.amount - this.eligible) <= 0
          q.info = this.freight.msg ? true : false
        }
        return q
      },
      eligible(){return eligible(this.cartProducts)}
    }
  }
</script>