import store from '../stores/store'
import {userRoles} from './roles'

// Check to see if the logged in entity is a bill to account
// and check that the logged in user is not a sales staff user
export const cartCopyAllowed = () => {
  const $store = store
  const entity = $store.getters.getEntity.entity
  const isBillTo = entity.is_bill_to
  const userRole = userRoles.includes($store.getters.user.role)
  const multiShipTo = entity.ship_tos.length > 1
  if(isBillTo && userRole && multiShipTo){
    return true
  }
  return false
}