<template>
  <div
    v-if="categories.length > 0"
    class="row my-5">
    <div class="col-md-12 text-center">
      <h4>PRODUCT CATEGORIES</h4>
    </div>
    <div
      v-for="(c, index) in categories"
      :key="c.main"
      class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
        <CategoryCard 
          class="d-none d-sm-block"
          :category="c"
          :id="index"/>
    </div>
  </div>
</template>

<script>
  import CategoryCard from '../browse/CategoryCard.vue'
  
  export default {
    components:{
      CategoryCard
    },
    computed:{
      categories(){return _.reject(this.$store.getters.getCategories, (c)=>{
        return c.main == 'Not Assigned' || c.main == 'Electrical, Plumbing'
      })},
    },
    created(){
      this.$store.dispatch('getCategories')
    }
  }
</script>