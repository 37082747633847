<template>  
  <div class="modal fade" id="shipToSelector" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content shadow-custom">
        <div class="modal-header">
          <h5 class="modal-title">Select Ship To</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"/>
        </div>
        <div
          v-if="entityData"
          class="modal-body">
          <!-- Table -->
          <table class="table table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th class="text-center">Status</th>
                <th
                  v-if="isBillTo && entityData.entity.ship_tos.length > 1"
                  class="text-center">
                  Default
                </th>
                <th class="text-center">Name</th>
                <th class="text-center">Address</th>
              </tr>
            </thead>
            <tbody v-if="entityData.entity && entityData.entity.ship_tos">
              <tr
                v-for="s in entityData.entity.ship_tos"
                :key="s.id">
                <td class="text-center text-success">
                  <b v-if="entityData.activeShipTo.entity_id == s.id && !shipToLoading">
                    <i class="fa fa-check"></i>
                    &nbsp;Active
                  </b>
                  <button
                    v-if="entityData.activeShipTo.entity_id != s.id && !shipToLoading"
                    class="btn btn-primary btn-sm"
                    @click="selectShipTo(s)">
                    Select
                  </button>
                  <Spinner v-if="shipToLoading == s.id"/>
                </td>
                <!-- Default Ship To -->
                <td
                  v-if="isBillTo && entityData.entity.ship_tos.length > 1"
                  class="text-center">
                  <div v-if="!defaultShipToLoader">
                    <i
                      v-if="defaultShipTo && defaultShipTo == s.id"
                      class="fa fa-check-circle text-success"/>
                    <button
                      v-else
                      class="btn btn-primary btn-sm"
                      @click="setDefault(s.id)">
                      Set Default
                    </button>
                  </div>
                  <div v-else>
                    <Spinner class="text-center"/>
                  </div>
                </td>
                <td class="text-center">({{s.id}}) {{s.name}}</td>
                <td
                  class="text-center">
                  {{s.street1}}
                  {{s.street2}}
                  {{s.city}}, {{s.state_code}}, {{s.zip_code}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../global/Spinner.vue'
  
  export default {
    data(){
      return{
        shipToLoading: null
      }
    },
    components:{
      Spinner
    },
    watch:{
      entityData(newData){
        if(newData){
          this.shipToLoading = null
        }
      }
    },
    methods:{
      selectShipTo(shipTo){
        this.shipToLoading = shipTo.id
        this.$store.dispatch('selectShipTo', shipTo)
      },
      setDefault(id){
        this.$store.dispatch('setDefaultShipTo', id)
      }
    },
   computed:{
      entityData(){return this.$store.getters.getEntity},
      isBillTo(){return this.$store.getters.isBillTo},
      defaultShipTo(){return this.$store.getters.user.default_ship_to},
      defaultShipToLoader(){return this.$store.getters.defaultShipToLoader}
   } 
  }
</script>

<style scoped>
  .shadow-custom{
    box-shadow: 2px 2px 40px black !important;
  }
</style>