<template>
  <div v-show="show">
    <div 
      v-if="pagination && pagination.currentPage < pagination.totalPages"
      ref="showMore">
    </div>
  </div>
</template>

<script>
  export default{
    props: ['index', 'pagination'],
    mounted(){
      if(this.$refs['showMore']){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs['showMore'])
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    methods:{
      handleIntersection(entries){
        for (let entry of entries) {
          if (entry.isIntersecting){
            this.$emit('getProducts')
          }
        }
      },
    },
    computed:{
      // Show more is auto injected every 18 cards, once it's oberserver becomes visible it triggers the next page load
      // the show me div is actually never visible to the user only to the observer
      show(){
        if(this.index && this.pagination){
          const position = this.index + 1
          return position % 18 == 0
        }
      }
    }
  }
</script>