<template>
  <div class="row mt-3 p-3">
    <div class="col-md-12">
        <!-- Create Record -->
        <button
          v-if="createAllowed"
          class="float-end m-2 btn btn-outline-light btn-sm"
          @click="$emit('toggleCreateNew')">
          <i class="fa fa-plus"/>
          Create New
        </button>
    </div>
    <div class="col-md-12">
      <!-- Table View -->
      <IndexTable
        v-if="indexView == 'table'"
        :data="data"
        :deleteAllowed="deleteAllowed"/>
      <!-- Row View -->
      <IndexGrid
        v-if="indexView == 'grid'"
        :data="data"
        :deleteAllowed="deleteAllowed"/>
    </div>
  </div>
</template>

<script>
  import IndexTable from './IndexTable.vue'
  import IndexGrid from './IndexGrid.vue'

  export default {
    props: ['data', 'view'],
    components:{
      IndexTable,
      IndexGrid
    },
    computed:{
      createAllowed(){
        return this.data.options?.config?.create
      },
      deleteAllowed(){
        return this.data.options?.config?.delete
      },
      indexView(){
        // valid options: ['table', 'list']
        const index  = this.data.options?.config?.index
        if(index){
          return index
        }
        return 'table'
      }
    }
  }
</script>