<template>
  <div class="row my-3 animate__animated animate__bounceIn animate__faster">
    <div class="col-md-12">
      <div class="card">
        <div
          class="card-header"
          :class="currentCart ? 'bg-success' : ''">
          <div class="float-start">
            <!-- Cart Name -->
            <span
              v-if="!editing"
              class="ms-2"
              :class="currentCart ? 'text-white' : 'text-muted'">
              <span v-if="cart.cart_name">{{ cart.cart_name }}</span>
              <span v-else>No Name</span>
              <button
                class="btn btn-sm btn-link"
                :class="currentCart ? 'text-white' : ''"
                @click="toggleEdit">
                <i class="fa fa-edit"/>
              </button>
            </span>
            <!-- Cart Name Form -->
            <form
              v-else
              name="cartName"
              @submit.prevent="submitName">
              <div class="input-group">
                <input
                  name="enterName"
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Enter Name"
                  v-model="cart.cart_name">
                <button
                  class="btn btn-sm btn-primary"
                  type="submit">
                  <i class="fa fa-check-circle"/>
                  Submit
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  type="button"
                  @click="toggleEdit">
                  <i class="fa fa-ban"/>
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <div
            v-if="!editing && cart.vshow_cart"
            class="float-end">
            <span class="badge bg-info float-end">
              <i class="fa fa-certificate"/>
              Virtual Show
            </span>
          </div>
        </div>
        <div class="card-body">
          <!-- Cart Summary Table -->
          <table class="table table-sm">
            <tbody>
              <tr>
                <td>Status</td>
                <td>{{cart.status}}</td>
              </tr>
              <tr>
                <td>Products</td>
                <td>{{ cart.cart_products ? cart.cart_products.length : 0 }}</td>
              </tr>
              <tr>
                <td>Cart Total</td>
                <td>{{ numeral(cart.cart_total).format('$0,0.00') }}</td>
              </tr>
              <tr v-if="vShowCart">
                <td>Virtual Show Cart</td>
                <td>Yes</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer p-0">
          <!-- Actions-->
          <div class="text-center">
            <div
              v-if="!deleting"
              class="btn-group w-100 animate__animated animate__fadeIn animate__faster text-center">
              <button
                v-if="currentCart"
                class="btn btn-link text-success">
                <small>
                  <i class="fa fa-check-circle"/>
                  Active
                </small>
              </button>
              <button
                v-if="!currentCart"
                class="btn btn-link"
                :disabled="editing"
                @click="setActive">
                <small>
                  <i class="fa fa-power-off"/>
                  Activate
                </small>
              </button>
              <button
                v-if="!currentCart"
                class="btn btn-link"
                :disabled="editing"
                @click="  toggleDelete">
                <small>
                  <i class="fa fa-trash"/>
                  Delete
                </small>
              </button>
              <button
                v-if="currentCart && cartCopyAllowed"
                class="btn btn-link"
                :disabled="editing || productCount == 0"
                data-bs-toggle="modal"
                data-bs-target="#cartCopyMoveModal">
                <small>
                  <i class="fa fa-copy"/>
                  Copy / Move
                </small>
              </button>
            </div>
            <!-- Delete Confirm -->
            <div
              v-else
              class="animate__animated animate__bounceIn animate__faster p-2">
              <div class="float-start">Are you sure?&nbsp;</div>
              <div class="float-end">
                <div class="btn-group w-100">
                  <button
                    class="btn btn-sm btn-link"
                    @click="deleteCart">
                    <i class="fa fa-check"/>
                    Yes
                  </button>
                  <button
                    class="btn btn-sm btn-link"
                    @click="toggleDelete">
                    <div class="i fa fa-times"/>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from  'axios'

  export default {
    props:['cart', 'activeCart', 'cartCopyAllowed'],
    data(){
      return{
        editing: false,
        deleting: false
      }
    },
    methods:{
      toggleEdit(){
        this.editing = !this.editing
      },
      toggleDelete(){
        this.deleting = !this.deleting
      },
      setActive(){
        axios.post('/api/v1/private/manage_carts/set_active', {id: this.cart.id}).then(res=>{
          this.$emit('setCartActive', res.data.active_cart)
        })
      },
      submitName(){
        let params = {cart: this.cart}
        axios.put('/api/v1/private/manage_carts/update_name', params).then(res=>{
          if(res.status == 200 && res.data.updated == true){
            this.editing = false
          }
        })
      },
      deleteCart(){
        this.$store.dispatch('deleteCart', this.cart.id)
      }
    },
    computed:{
      currentCart(){return this.cart.id == this.activeCart.details.id},
      productCount(){return this.cart.cart_products.length},
      vShowCart(){return this.cart.details?.vshow_cart}
    }
  }
</script>