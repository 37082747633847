<template>
  <div class="mt-5">
    <Header/>
    <LanAdvan/>
    <Footer/>
  </div>
</template>

<script>
  import Header from './Header.vue'
  import LanAdvan from './LanAdvan.vue'
  import Footer from '../app/Footer.vue'

  export default {
    components:{
      Header,
      LanAdvan,
      Footer
    }
  }
</script>