<template>
  <div>
    <div class="text-center my-3">
      <h5 class="fw-normal"><i class="fa fa-user-times"></i> Are you sure you want to delete this user?</h5>
      <p class="m-0">The user account will be immediately disabled. This action cannot be undone.</p>
    </div>
    <div style="width:200px;" class="d-flex mx-auto flex-column align-items-stretch justify-content-center">
      <button class="m-1 btn btn-danger" @click="deleteContact">
        <span v-if="submitting">
          <i class="fa fa-spinner fa-spin me-2"></i> Deleting user...
        </span>
        <span v-else>
          <i class="fa fa-trash-alt"></i> Confirm Delete
        </span> 
      </button>
      <button class="m-1 btn btn-outline-primary" @click="cancel"><i class="fa fa-ban"></i> Cancel</button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  props:['contact'],
  data(){
    return{
      submitting: false
    }
  },
  methods:{
    cancel(){
      this.$emit('cancel')
    },
    deleteContact(){
      this.submitting = true
      axios.post('/api/v1/private/superuser/delete_contact',{contact: this.contact}).then(res => {
        if(res.status == 200){
          this.$emit('confirmDelete')
        }
      })
    }
  }
}
</script>