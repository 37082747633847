<template>
  <div
    @click="browseMfg"
    :ref="`card-${mfg.id}`"
    class="text-center">
    <div
      class="card my-2"
      style="height:200px; cursor: pointer;">
      <div
        class="card-body m-2"
        v-if="visible"
        :style="{
          'backgroundImage': `url(${mfg.logo})`,
          'height': '200px',
          'backgroundSize': 'contain',
          'backgroundPosition': 'center',
          'background-repeat': 'no-repeat'
        }">
      </div>
      <div
        class="card-footer"
        style="height: 70px ; padding: 10px;">
        <small>{{mfg.name}}</small>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props: ['mfg'],
    data(){
      return{
        visible: false,
        observer: null
      }
    },
    mounted(){
      if(this.$refs[`card-${this.mfg.id}`]){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs[`card-${this.mfg.id}`])
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    methods:{
      handleIntersection(entries, observer){
        for (let entry of entries) {
          if (entry.isIntersecting){
            this.visible = true
          }
        }
      },
      browseMfg(){
        this.$store.dispatch('vShowSetView', 'browse')
        this.$router.push({
          path: '/virtual_show',
          query:{
            action: 'virtualShowBrowseBrand',
            brand: this.mfg.name
          }
        })
      }
    }
  }
</script>