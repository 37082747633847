<template>
  <div class="container-fluid">
    <!-- Spinner -->
    <div
      v-if="loading"
      class="row">
      <div class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
    </div>
    <!-- Content -->
    <div
      v-else
      class="row">
      <div class="col-md-12 text-center mt-3">
        <h4>BRANDS</h4>
        <!-- Search -->
        <div class="float-end">
          <div class="position-relative">
            <input
              type="text"
              id="brandSearch"
              class="form-control"
              style="width: 250px;"
              placeholder="Search Brands..."
              v-model="query"
              @change="searchBrands">
              <i
                v-if="query"
                class="fa fa-times text-danger clear-times animate__animated animate__fadeInRight animate__faster"
                @click="clearQuery"/>
          </div>
        </div>
      </div>
      <!-- Brand Listing -->
      <div
        v-for="b in brandData"
        :key="b.id"
        class="col-xl-2 col-md-3 col-sm-4 col-6">
        <BrandCard
          v-if="b"
          :brand="b"/>
      </div>
      <div
        v-if="brandData.length == 0"
        class="col-md-12 text-center mt-5">
        <h4 class="text-muted">No Brands</h4>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import BrandCard from './BrandCard.vue'
  import Spinner from '../../../global/Spinner.vue'
  
  export default{
    data(){
      return{
        loading: true,
        brands: null,
        query: null
      }
    },
    components:{
      BrandCard,
      Spinner
    },
    created(){
      if(!this.settings.shopByBrand){
        this.$router.push({path: '/'})
      }
    },
    mounted(){
      axios.get('/api/v1/private/brands/get_brands').then(res=>{
        this.brands = res.data.brands
      })
    },
    watch:{
      brands(newVal){
        if(newVal){
          this.loading = false
        }
      }
    },
    methods:{
      clearQuery(){
        this.query = null
        const input = document.getElementById('brandSearch')
        input.focus()
      }
    },
    computed:{
      brandData(){
        const base = _.orderBy(this.brands, 'name', 'asc')
        if(this.query){
          return _.filter(base, b => b.name.toLowerCase().includes(this.query.toLowerCase()))
        }
        return base
      },
      settings(){return this.$store.getters.settings}
    }
  }
</script>

<style scoped>
  .clear-times{
    position: absolute;
    z-index: 1;
    top: 11px;
    right: 15px;
    cursor: pointer;
  }
</style>