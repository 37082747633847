<template>
  <div class="row bg-light m-3 p-3 shadow rounded text-dark">
    <!-- Back Button -->
    <div class="col-md-12">
      <button
        class="btn btn-sm btn-primary"
        @click="back">
        <i class="fa fa-chevron-left"/>
        Back
      </button>
    </div>
    <!-- Promo header Details -->
    <div class="mt-2">
      <div class="border rounded p-3">
        <h5 class="text-muted text-center">Promo Details</h5>
        <div class="row">
          <template
            v-for="v,k in promoHeader"
            :key="v">
            <div class="col-md-3">
              <strong class="mx-2">
                {{ k }} :&nbsp;
                <span
                  v-if="['pdf_only', 'hidden'].includes(k)"
                  class="badge"
                  :class="v == true ? 'bg-success' : 'bg-danger'">
                  {{ v }}
                </span>
                <span v-else>
                  {{ v }}
                </span>
              </strong>
            </div>
          </template>
        </div>
      </div>
      <h5 class="text-muted text-center mt-3">Promo Data</h5>
      <!-- Sub Nav -->
      <div class="mt-3">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{active: subView == 'assets'}"
              href="#"
              @click="setSubView('assets')">Promo Assets
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{active: subView == 'branches'}"
              href="#"
              @click="setSubView('branches')">
              Branch Permissions
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{active: subView == 'products'}"
              href="#"
              @click="setSubView('products')">
              Product Offering
            </a>
          </li>
        </ul>
      </div>
      <PromoAssets
        v-if="subView == 'assets'"
        :assets="promoAssets"
        :promo="promoHeader"/>
      <PromoBranch
        v-if="subView == 'branches'"
        :promoHeader="promoHeader"
        :branchData="promoBranch"/>
      <PromoProducts
        v-if="subView == 'products'"
        :branchData="promoBranch"
        :promoProducts="promoProducts"
        :promoHeader="promoHeader"/>
    </div>
  </div>
</template>

<script>
  import PromoAssets from './components/assets/PromoAssets.vue'
  import PromoBranch from './components/branch/PromoBranch.vue'
  import PromoProducts from './components/products/PromoProducts.vue'
  
  export default{
    data(){
      return{
        subView: 'assets'
      }
    },
    mounted(){
      this.$store.dispatch('getPromoDetails')
    },
    components:{
      PromoAssets,
      PromoBranch,
      PromoProducts
    },
    methods:{
      back(){
        this.$store.dispatch('unsetActivePromo')
      },
      setSubView(view){
        this.subView = view
      }
    },
    computed:{
      promo(){return this.$store.getters.adminActivePromo},
      promoHeader(){return this.promo.promo_detail},
      promoAssets(){return this.promo.promo_assets},
      promoBranch(){return this.promo.promo_branch},
      promoProducts(){return this.promo.promo_products}
    }
  }
</script>

<style scoped>
  .active{
    font-weight: 900;
    color: var(--bs-success);
    border-bottom: 5px solid var(--bs-success);
    transition: 100ms ease-in-out;
  }
</style>