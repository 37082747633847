<template>
  <div
    v-if="tm"
    class="col-md-6">
    <div v-if="!loading">
      <h3>Your Sales Rep</h3>
      <!-- No TM Handler -->
      <div
        v-if="!tm"
        class="row">
        <div class="col-md-12 text-muted my-5 text-center">
          <span>No Sales Rep Data</span>
        </div>
      </div>
      <!-- TM Detail -->
      <div
        v-else
        class="card">
        <div class="card-header">
          <strong>{{tm.first_name}} {{tm.last_name}}</strong>
        </div>
        <div class="card-body">
          <div class="row">
            <!-- Image -->
            <div
              v-if="tm.image"
              class="col-md-2 col-12">
              <img
                :src="tm.image"
                class="img-fluid">
            </div>
            <!-- Stats -->
            <div :class="tm.image ? 'col-md-10' : 'col-md-12'">
              <div>
                <div>
                  Title:&nbsp;
                  <span class="text-muted">{{ tm.title }}</span>
                </div>
                <div>
                  Home Branch:&nbsp;
                  <span class="text-muted">{{tm.home_branch}}</span>
                </div>
                <div>
                  Mobile:&nbsp;
                  <a
                    :href="'tel:' + tm.cell">
                    <span class="text-muted">{{tm.cell}}</span>  
                  </a>
                </div>
                <div>
                  Email:&nbsp;
                  <a :href="'mailto:' + tm.email">{{tm.email}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Spinner -->
    <div
      v-else
      class="row">
      <div class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../global/Spinner.vue'

  export default {
    data(){
      return{
        tm: null,
        loading: true
      }
    },
    components:{
      Spinner
    },
    methods:{
      getSalesRep(id){
        const params = {rep_id: id}
        axios.get('/api/v1/private/contact/get_sales_rep', {params}).then(res =>{
          this.tm = res.data.tm
          this.loading = false
        })
      }
    },
    mounted(){
      this.getSalesRep(this.repID)
    },
    computed:{
      repID(){return this.$store.getters.getEntity.activeShipTo.outside_sales_id}
    }
  }
</script>