<template>
  <div class="mt-1">
    <div>
      <label class="text-muted">
        Submit order as credit request
      </label>
      <div class="input-group">
        <button
          type="button"
          class="btn btn-sm w-50"
          :class="credit ? 'btn-primary' : 'btn-outline-primary'"
          @click="setCredit(true)">
          <i
            class="fa"
            :class="credit ? 'fa-check' : ''"/>
          Yes
        </button>
        <button
          type="button"
          class="btn btn-sm w-50"
          :class="credit ? 'btn-outline-primary' : 'btn-primary'"
          @click="setCredit(false)">
          <i
            class="fa"
            :class="credit ? '' : 'fa-check'"/>
          No
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['form', 'shipViaOptions'],
    data(){
      return{
        credit: false
      }
    },
    methods:{
      setCredit(status){
        this.credit = status
        this.form.creditRequest = status
        if(status){
          this.form.shipVia = 'CRD-CREDIT'
          this.form.customerReleaseNumber = 'CREDIT'
        }else{
          this.form.shipVia = this.shipViaOptions[0].text
          this.form.customerReleaseNumber = null
        }
        document.activeElement.blur()
      }
    }
  }
</script>

<style scoped>
  .input-group{
    z-index: 0;
  }
</style>