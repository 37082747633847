<template>
  <div class="float-end">
    <form
      class="input-group"
      @submit.prevent="submit">
      <input
        type="text"
        class="form-control"
        v-model="query"
        placeholder="Search Products...">
      <button class="btn btn-outline-light">
        <i class="fa fa-search"/>
      </button>
    </form>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        query: null
      }
    },
    methods:{
      submit(){
        if(this.query && this.query.length > 0){
          this.$emit('search', {query: this.query})
          this.query = null
        }
      }
    }
  }
</script>