<template>
  <div 
    @click="toggleOpen()"
    :class="[showScrolledHeader ? 'd-flex' : 'd-none', invoice ? 'bg-success' : 'bg-secondary']" 
    class="d-lg-none scrolled-header animate__animated animate__fadeIn animate__animateFaster flex-column py-1 text-white shadow">
    <span class="d-flex justify-content-between align-items-center text-left fw-bold m-0 px-2">
      
      <span v-if="order">
        {{type + ' ' + details.id + (details.order_number ? '.' + details.order_number : '')}}
      </span>
      <span v-if="invoice">
        {{type + ' ' + details.id + (details.invoice_number ? '.' + details.invoice_number : '')}}
      </span>
      <span>
        <span class="inline-block border border-white px-1 rounded-circle">
          <i style="transition:all 0.2s;" :style="open ? '' : 'transform:rotate(45deg);'" class="fa fa-times"></i>
        </span>
      </span>
      
      
    </span>
    <span v-if="open" class="d-block border-top border-white my-1"></span>
    <div v-if="open" class="d-flex w-100 justify-content-between align-items-center px-2 ">
      <span>PO: {{details.customer_po ? details.customer_po : 'None'}}</span>
      <span>Ship Date: {{details.ship_date}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['showScrolledHeader','order','invoice'],
  data(){
    return {
      open: true
    }
  },
  methods: {
    toggleOpen(){
      this.open = !this.open
    }
  },
  computed: {
    details(){
      if(this.order && !this.invoice){
        return this.order.details
      }
      if(this.invoice && !this.order){
        return this.invoice.details
      }
    },
    type(){
      if(this.order && !this.invoice){
        return 'Order'
      }
      if(this.invoice && !this.order){
        return 'Invoice'
      }
    }
  }
}
</script>
<style scoped>
.scrolled-header {
  position: fixed;
  width:100%;
  left:0;
  right:0;
  top: 62px;
  z-index: 99;
}
</style>