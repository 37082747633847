<template>
  <div class="row">
    <div class="col-md-12">
      <h3>My Account</h3>
      <div class="mt-2">
        <!-- Tabs -->
        <ul
          class="nav nav-pills"
          style="margin: 0px -20px;">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{active: activeTab == 'profile', disabled: loading}"
              @click="setTab('profile')"
              href="javascript: void(0)">
              Profile
            </a>
          </li>
          <li
            v-if="!hideAccountInquiry && company != 'Lancaster Canada'"
            class="nav-item">
            <a
              class="nav-link"
              :class="{active: activeTab == 'status', disabled: loading}"
              @click="setTab('status')"
              href="javascript: void(0)">
              Account Inquiry
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{active: activeTab == 'invoices', disabled: loading}"
              @click="setTab('invoices')"
              href="javascript: void(0)">
              Invoices
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{active: activeTab == 'orders', disabled: loading}"
              @click="setTab('orders')"
              href="javascript: void(0)">
              <span>Open Orders</span>
            </a>
          </li>
          <li
            v-if="company != 'Lancaster Canada'"
            class="nav-item">
            <a
              class="nav-link"
              :class="{active: activeTab == 'pay', disabled: loading}"
              @click="setTab('pay')"
              href="javascript: void(0)">
              <span>Pay My Bill</span>
            </a>
          </li>
        </ul>
      </div>
      <!-- Tab Views -->
      <div v-if="activeTab == 'profile'">
        <Profile/>
      </div>
      <div v-if="activeTab == 'status'">
        <Status
          :detail="detail"
          :authorized="authorized"/>
      </div>
      <div v-if="activeTab == 'invoices'">
        <Invoices
          :authorized="authorized"/>
      </div>
      <div v-if="activeTab == 'orders'">
        <Pending/>
      </div>
      <div v-if="activeTab == 'pay'">
        <BillTrust/>
      </div>
    </div>
  </div>  
</template>

<script>
  import Invoices from './Invoices.vue'
  import Status from './Status.vue'
  import Pending from './Pending.vue'
  import Profile from './Profile.vue'
  import BillTrust from './BillTrust.vue'
  import {internalRoles} from '../../../../shared/roles'
  
  export default {
    data(){
      return{
        internalRoles
      }
    },
    components:{
      Invoices,
      Status,
      Pending,
      Profile,
      BillTrust
    },
    methods:{
      setTab(tab){
        this.$store.dispatch('setActiveTab', tab)
        const searchResets = ['invoices', 'orders']
        if(searchResets.includes(tab)){
          this.$store.dispatch('clearAccountProductSearch')
        }
      }
    },
    computed:{
      detail(){return this.$store.getters.getAccountDetails},
      activeTab(){return this.$store.getters.accountActiveTab},
      entityData(){return this.$store.getters.getEntity},
      user(){return this.$store.getters.user},
      authorized(){
        const user = this.$store.getters.user
        if(internalRoles.includes(user.role)){
          return true
        }else{
          return this.$store.getters.getAccountAuthorized
        }
      },
      loading(){return this.$store.getters.getAccountLoadStatus},
      hideAccountInquiry(){return this.$store.getters.hideAccountInquiry},
      company(){return this.$store.getters.sessionCompany}
    }
  }
</script>

<style scoped>
  .active{
    background: var(--bs-primary);
    color: white;
    padding: 8px;
    margin: 0px 10px;
    border-radius: 10px 10px 0px 0px;
    transition: .3s ease-in-out;
  }
  .active:hover{
    color:white;
  }
  .nav-link:focus{color: white;}
</style>