<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster">
    <div v-if="initialized">
      <div class="row sticky-top bg-white full-width">
        <div class="col-md-12 text-center show-nav">
          <ShowNav
            :activeView="activeView"
            :subView="subView"
            :searchSubmitted="searchSubmitted"
            @navEvent="goto"
            @goBack="clearSubView"/>
        </div>
      </div>
      <BrowseSearch
        v-if="['browse', 'drop'].includes(activeView)"
        :categories="categories"
        :activeView="activeView"
        :searchSubmitted="searchSubmitted"
        :subView="subView"
        @searchSubmitted="handleSearchSubmitted"
        @setSubView="setSubView"
        @goto="goto"/>
      <BrowseMfg
        v-if="activeView == 'mfg'"
        :mfgClear="mfgClear"
        @searchSubmitted="handleSearchSubmitted"/>
      <DirectOrderEntry v-if="activeView == 'direct'"/>
      <Downloads v-if="activeView == 'downloads'"/>
      <Terms v-if="activeView == 'terms'"/>
    </div>
    <div v-else>
      <Init :loadStep="loadStep"/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import ShowNav from './components/ShowNav.vue'
  import BrowseSearch from './components/browseAndSearch/BrowseSearch.vue'
  import BrowseMfg from './components/browseByMfg/BrowseMfg.vue'
  import DirectOrderEntry from './components/directOrderEntry/DirectOrderEntry.vue'
  import Downloads from './components/downloads/Downloads.vue'
  import Init from './components/Init.vue'
  import Terms from './components/terms/Terms.vue'

  export default {
    data(){
      return{
        loadStep: 0,
        searchSubmitted: false,
        subView: null,
        previousView: null,
        mfgClear: false
      }
    },
    async mounted(){
      await this.initialize()
      if(!this.$route.query?.action == 'virtualShowDropShips') this.$store.dispatch('vShowSetView', 'browse')
      if(this.$route.query?.action?.includes('virtualShow') || this.$route.query?.product_id) this.searchSubmitted = true
      this.$nextTick(()=>{
        this.setVshowCart()
      })
    },
    components:{
      ShowNav,
      BrowseSearch,
      BrowseMfg,
      DirectOrderEntry,
      Downloads,
      Init,
      Terms
    },
    methods:{
      handleSearchSubmitted(){
        this.searchSubmitted = true
        this.previousView = this.subView
        this.subView = null
      },
      back(){
        this.searchSubmitted = false
      },
      setSubView(view){
        this.subView = view
      },
      clearSubView(){
        this.searchSubmitted = false
        this.clearActiveMfg()
        this.subView = this.previousView == 'categories' ? 'categories' : null
        this.previousView = null
      },
      backToCategories(){
        this.subView == 'categories'
        this.searchSubmitted = false
      },
      // Get show vShow/date details
      getShowDates(){
        return new Promise((resolve, reject)=>{
          axios.get('/api/v1/private/virtual_show/get_show_dates').then(res => {
            if(res.data.show_dates){
              const showDates = res.data.show_dates
              this.$store.dispatch('setShowDates', showDates)
              resolve()
            }else{
              reject(res.data.error)
            }
          })
        })
      },
      // Get categories for browsing
      getShowCategories(){
        return new Promise((resolve) => {
          axios.get('/api/v1/private/virtual_show/get_categories').then(res => {
            let categories = _.reject(res.data.categories, {category: 'Not Assigned'})
            this.$store.dispatch('setVshowCategories', categories)
            resolve()
          })
        })
      },
      // Get ship to's reorder pad products for flagging in vShow
      getReorderPadProducts(){
        return new Promise((resolve => {
          axios.get('/api/v1/private/reorder_pad/get_list').then(res => {
            if(res.data.results.length){
              this.$store.dispatch('setVshowReorderPadProducts', res.data.results)
            }
            resolve()
          })
        }))
      },
      // Get ship tos favorite products for flagging in vShow
      getFavorites(){
        return new Promise((resolve) => {
          axios.get('/api/v1/private/favorites').then(res => {
            if(res.data.favorites.length){
              let list = []
              res.data.favorites.forEach(function(group){
                const groupProducts = _.map(group.favorite_products, fp => fp.product_id)
                groupProducts.forEach(gp => list.push(gp))
              })
              this.$store.dispatch('setVshowFavorites', _.uniq(list))
            }
            resolve()
          })
        })
      },
      setVshowCart(){
        if(!this.cart?.details.vshow_cart){
          axios.post('/api/v1/private/virtual_show/trigger_vshow_cart').then(res=>{
            if(res.data.switched_to_vshow_cart) this.$store.dispatch('getCart')
          })
        }
      },
      clearActiveMfg(){
        this.mfgClear = true
        this.searchSubmitted = null
        setTimeout(function(){
          this.mfgClear = false
        }.bind(this),1000)
      },
      goto(view){
        this.$store.dispatch('vShowSetView', view)
        switch(view){
          case 'browse':
            this.searchSubmitted = null
            this.previousView = null
            this.subView = null
            break
        }
      },
      async initialize(){
        if(this.initialized) return
        this.$store.dispatch('vShowClear')
        console.log('vShow Initializing')
        await new Promise((res) => setTimeout(res, 500))
        this.loadStep += 1
        console.log('Loading Show Dates')
        try{
          await this.getShowDates()
        }catch(e){
          console.log(e)
          this.$router.push({path: '/'})
          return
        }
        this.loadStep += 1
        console.log('Loading Categories')
        await this.getShowCategories()
        this.loadStep += 1
        console.log('Loading ReorderPad Products')
        await this.getReorderPadProducts()
        this.loadStep += 1
        console.log('Loading Favorites')
        await this.getFavorites()
        this.loadStep += 1
        console.log('Finalizing')
        this.loadStep += 1
        console.log('Complete')
        await new Promise((res) => setTimeout(res, 500))
        this.$store.dispatch('vShowInitialized', true)
      }
    },
    computed:{
      categories(){return this.$store.getters.vShowCategories},
      cart(){return this.$store.getters.cart},
      initialized(){return this.$store.getters.vShowInitialized},
      route(){return this.$route},
      activeView(){return this.$store.getters.vShowActiveView}
    }
  }
</script>

<style scoped>
  .full-width{
    margin: 0px -24px;
  }
  .show-nav{
    border-bottom: 1px solid rgb(var(--bs-primary-rgb), 0.3);
  }
</style>