<template>
  <div class="mt-5">
    <Header/>
    <Stats/>
    <!-- <DistStat/> -->
    <Map/>
    <News/>
    <History/>
    <Footer/>
  </div>
</template>

<script>
  import Header from './Header.vue'
  import Stats from './Stats.vue'
  import DistStat from './DistStat.vue'
  import Map from './Map.vue'
  import News from './News.vue'
  import History from './History.vue'
  import Footer from '../app/Footer.vue'

  export default {
    components:{
      Header,
      Stats,
      DistStat,
      Map,
      News,
      History,
      Footer
    }
  }
</script>