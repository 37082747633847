<template>
  <div>
    <div class="row bg-primary p-3 px-5 footer auto-height">
      <div class="col-md-12">
        <!-- Top -->
        <div class="row">
          <div class="col-md-3 col-12 d-none d-md-block">
            <img
              :src="logo"
              class="img-fluid"
              style="max-width:150px;">
          </div>
          <div class="col-md-9 col-12">
            <div class="float-end">
              <a
                href="javascript:void(0)"
                class="btn btn-link footer-link"
                @click="goto('About')">
                About Us
              </a>
              <a
                href="javascript:void(0)"
                class="btn btn-link footer-link"
                @click="goto('Advantage')">
                Advantage
              </a>
              <a
                href="javascript:void(0)"
                class="btn btn-link footer-link"
                @click="goto('Events')">
                Events
              </a>
              <a
                href="javascript:void(0)"
                class="btn btn-link footer-link"
                @click="goto('Brands')">
                Brands
              </a>
              <a
                href="javascript:void(0)"
                class="btn btn-link footer-link"
                @click="goto('Contact')">
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <!-- Middle -->
        <div class="row">
          <div class="col-md-12">
            <hr style="color:white; height:2px; opacity:1;">
            <div class="row">
              <!-- Left Side -->
              <div class="col-md-6 my-3">
                Already a Lancaster Dealer? Log in to access orders, shipping and more.
                <br>  
                <button
                  class="btn btn-outline-light mt-2"
                  style="width:200px;"
                  @click="gotoLogin">
                  <i class="fa fa-sign-in-alt"/>
                  Login
                </button>
              </div>
              <!-- Right Side -->
              <div class="col-md-6">
                <h4>Stay connected, get inspired and learn more.</h4>
                <a
                  href="https://www.linkedin.com/company/lancaster"
                  target="_blank"
                  class="text-white">
                  <i class="fab fa-linkedin fa-2x px-2"/>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCTMaC8Dsj6macmcvGZqOrAQ"
                  target="_blank"
                  class="text-white">
                  <i class="fab fa-youtube fa-2x px-2"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Bottom -->
        <div class="row">
          <div class="col-md-12">
            <hr style="color:white; height:2px; opacity:1;">
            <div class="row">
              <div class="col-md-12">
                Copyright © 2022 Lancaster Company. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        logo: require('../../../../../assets/images/lan/LogoColor.png')
      }
    },
    methods:{
      goto(routeName){
        window.scrollTo(0,0)
        this.$router.push({name: routeName})
      },
      gotoLogin(){
        this.$router.push({name: 'PublicHome'})
        let el = document.getElementById('router-view')
        el.scrollTo(0,0)
      }
    },
    computed:{
      route(){
        return this.$router.currentRoute.value.path
      }
    }
  }
</script>

<style scoped>
  .footer{
    color:white;
  }
  @media(max-width: 590px){
    .auto-height{
      height: 450px;
    }
  }
  @media(min-width: 591px){
    .auto-height{
      height: 300px;
    }
  }
  .footer-link{
    display: inline-block;
    color:white;
    text-decoration: none;
  }
</style>