<template>
  <div style="position: absolute; top: 5px; right: 5px;">
    <button
      class="btn btn-sm btn-danger"
      @click="deleteFavProduct">
      <i class="fa fa-trash"/>
    </button>
  </div>
</template>

<script>
  export default {
   methods: {
     deleteFavProduct(){
       this.$emit('deleteFavProduct')
     }
   } 
  }
</script>