<template>
  <div>
    <div class="container">
      <div class="row text-center my-5">
        <h2
          class="col-md-12 mb-5 text-primary"
          style="font-weight:400;">
          LANCASTER STATS
        </h2>
        <div class="col-md-3 col-sm-4 col-6 my-5">
          <h4
            class="line text-danger stat"
            id="distCenters"/>
          <strong class="text-primary">DISTRIBUTION CENTERS</strong>
        </div>
        <div class="col-md-3 col-sm-4 col-6 my-5">
          <h4
            class="line text-danger stat"
            id="teamMembers"/>
          <strong class="text-primary">SALES TEAM MEMBERS</strong>
        </div>
        <div class="col-md-3 col-sm-4 col-6 my-5">
          <h4
            class="line text-danger stat"
            id="supportStaff"/>
          <strong class="text-primary">SUPPORT STAFF</strong>
        </div>
        <div class="col-md-3 col-sm-4 col-6 my-5">
          <h4
            class="line text-danger stat"
            id="brands"/>
          <strong class="text-primary">NATIONAL BRANDS</strong>
        </div>
        <div class="col-md-4 col-sm-4  col-6 my-5">
          <h4
            class="line text-danger stat"
            id="customers"/>
          <strong class="text-primary">RETAIL CUSTOMERS</strong>
        </div>
        <div class="col-md-4 col-sm-4  col-6 my-5">
          <h4
            class="line text-danger stat"
            id="products"/>
          <strong class="text-primary">PRODUCTS</strong>
        </div>
        <div class="col-md-4 col-sm-12  col-12 my-5">
          <h4
            class="line text-danger stat"
            id="shipped"/>
          <strong class="text-primary">PARCEL & LTL ORDERS SHIPPED PER MONTH</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {CountUp} from 'countup.js/dist/countUp'

  export default {
    mounted(){
      const options = {suffix: '+'}
      const dist = new CountUp('distCenters', 7)
      const team = new CountUp('teamMembers', 50, options)
      const staff = new CountUp('supportStaff', 150, options)
      const brands = new CountUp('brands', 300, options)
      const customers = new CountUp('customers', 10000, options)
      const products = new CountUp('products', 13000, options)
      const shipped = new CountUp('shipped', 28000, options)
      const list = [dist, team, staff, brands, customers, products, shipped]
      list.forEach(l =>{
        l.start()
      })

    }
  }
</script>

<style scoped>
  .line{
    border-bottom: 1px solid var(--bs-primary);
  }
  .text-secondary{
    font-size: 1.2em;
    opacity: 0.6;
  }
  .stat{
    font-size: 50px;
  }
</style>

