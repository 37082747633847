const initialState = {
  leftMenuView: 'sideNav',
  rightMenuView: null,
  showLeftMenu: false,
  showRightMenu: false,
  repDashboardView: false,
  showNotification: false,
  modalVideo: null,
  notificationData: {
    message: null,
    messageType: null
  },
  exclusionMessage: null
}

export const viewStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    toggleLeftMenu(state){
      state.showLeftMenu = !state.showLeftMenu
      if(state.showLeftMenu == true && state.showRightMenu == true){
        state.showRightMenu = false
      }
      if(state.showLeftMenu == false){
        setTimeout(function(){
          state.leftMenuView = 'sideNav'
        },500)
      }
    },
    closeLeftMenu(state){
      state.showLeftMenu = false
    },
    toggleRightMenu(state, view){
      state.showRightMenu = !state.showRightMenu
      if(state.showRightMenu == true){
        state.rightMenuView = view
      }
      if(state.showRightMenu == true && state.showLeftMenu == true){
        state.showLeftMenu = false
      }
    },
    closeRightMenu(state){
      state.showRightMenu = false
    },
    setSideMenus(state){
      state.showLeftMenu = false
      state.showRightMenu = false
    },
    setLeftMenuView(state, view){
      state.leftMenuView = view
    },
    setRepDashboardView(state, view){
      state.repDashboardView = view
    },
    setNotification(state, data){
      state.showNotification = true
      state.notificationData = {...data}
    },
    clearNotification(state){
      state.showNotification = false
      state.notificationData = {
        message: null,
        messageType: null
      }
    },
    setExclusionMessage(state, data){
      state.exclusionMessage = data.exclusion_message
    },
    setModalVideo(state, data){
      state.modalVideo = data
    }
  },
  actions:{
    viewResetState(context){
      context.commit('resetState')
    },
    toggleLeftMenu(context){
      context.commit('toggleLeftMenu')
    },
    toggleRightMenu(context, view){
      context.commit('toggleRightMenu', view)
    },
    setSideMenus(context){
      context.commit('setSideMenus')
    },
    closeLeftMenu(context){
      context.commit('closeLeftMenu')
    },
    closeRightMenu(context){
      context.commit('closeRightMenu')
    },
    setLeftMenuView(context, view){
      context.commit('setLeftMenuView', view)
    },
    setRepDashboardView(context, view){
      context.commit('setRepDashboardView', view)
    },
    setNotification(context, data){
      context.commit('setNotification', data)
    },
    clearNotification(context){
      context.commit('clearNotification')
    },
    setExclusionMessage(context, data){
      context.commit('setExclusionMessage', data)
    },
    setModalVideo(context, data){
      context.commit('setModalVideo', data)
    }
  },
  getters:{
    activeView: state => {return state.activeView},
    showLeftMenu: state => {return state.showLeftMenu},
    showRightMenu: state => {return state.showRightMenu},
    rightMenuView: state =>{return state.rightMenuView},
    leftMenuView: state =>{return state.leftMenuView},
    repDashboardView: state => {return state.repDashboardView},
    showNotification: state => {return state.showNotification},
    notificationData: state => {return state.notificationData},
    exclusionMessage: state => {return state.exclusionMessage},
    modalVideo: state => {return state.modalVideo}
  }
}