<template>
  <div
    v-if="activeCustomer"
    class="bg-secondary text-center text-white py-2 active-customer shadow-sm"
    @click="showDashboard">
    {{activeCustomer.name}} - {{activeCustomer.id}}
  </div>
</template>

<script>
  export default {
    computed:{
      activeCustomer(){
        return this.$store.getters.getEntity.entity
      }
    },
    methods:{
      showDashboard(){
        this.$store.dispatch('setRepDashboardView', !this.$store.getters.repDashboardView)
      }
    }
  }
</script>

<style scoped>
  .active-customer:hover{
    cursor: pointer;
    font-size: 1.02em;
    transition: 100ms ease-in-out;
    font-weight: bolder;
  }
</style>