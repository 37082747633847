<template>
  <div>
    <!-- Standard Buttons -->
    <div
      v-if="!showDelete"
      class="animate__animated animate__fadeInDown animate__faster">
      <button
        v-if="view == 'promos'"
        class="btn btn-sm btn-link"
        @click="setActivePromo">
        <i class="fa fa-database"/>
        Promo Data
      </button>
      <button
        class="btn btn-sm btn-link"
        @click="setActiveRecord">
        <i class="fa fa-pencil-alt"/>
        Edit
      </button>
      <button
        v-if="deleteAllowed"
        class="btn btn-sm btn-link"
        @click="toggleShowDelete">
        <i class="fa fa-trash-alt"/>
        Delete
      </button>
    </div>
    <!-- Confirm Delete -->
    <div
      v-if="showDelete"
      class="animate__animated animate__bounceIn animate__faster">
      <strong class="text-danger">Are you sure?</strong>
      <div
        class="input-group"
        style="display: inherit;">
        <button
          class="btn btn-sm btn-link"
          @click="deleteRecord">
          <i class="fa fa-check"/>
          Yes
        </button>
        <button
          class="btn btn-sm btn-link"
          @click="toggleShowDelete">
          <i class="fa fa-times"/>
          No
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props: ['values', 'deleteAllowed'],
    data(){
      return{
        showDelete: false
      }
    },
    methods:{
      toggleShowDelete(){
        this.showDelete = !this.showDelete
      },
      deleteRecord(){
        this.$store.dispatch('deleteAdminRecord', this.values)
      },
      setActiveRecord(){
        this.$store.dispatch('setActiveRecord', this.values)
      },
      setActivePromo(){
        this.$store.dispatch('setActivePromo', this.values)
      }
    },
    computed:{
      view(){
        return this.$store.getters.adminView
      }
    }
  }
</script>