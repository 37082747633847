<template>
  <div class="animate__animated animate__fadeIn animate__faster">
    <form
      name="newPassword"
      @submit.prevent="submit">
      <div class="row py-3 password-area">
        <div class="col-md-12 text-center">
          <h5 class="fw-normal"><i class="fa fa-key me-2"></i>Change {{ contact.contact_id == user.contact_id ? 'My' : ''}} Password</h5>
          <div
            v-if="forgotPassword"
            class="mb-3">
            <strong>Welcome Back!</strong>
          </div>
          Password must contain at least 8 characters, include one number, one uppercase letter, one lowercase letter and one special character.
        </div>
        <div class="col-md-12">
          <input
            name="password"
            :type="passwordVisible ? 'text' : 'password'"
            placeholder="Enter New Password"
            autocomplete="off"
            autocapitalize="off"
            spellcheck="false"
            required
            v-model="password1"
            @change="clearError"
            :disabled="passwordUpdating"
            class="form-control my-2">
        </div>
        <div class="col-md-12">
          <input
            name="password2"
            :type="passwordVisible ? 'text' : 'password'"
            placeholder="Reenter New Password"
            autocomplete="off"
            autocapitalize="off"
            spellcheck="false"
            required
            v-model="password2"
            @change="clearError"
            :disabled="passwordUpdating"
            class="form-control my-2">
          <button
            type="button"
            class="btn btn-sm btn-link float-end visible-toggle"
            @click="toggleVisible">
            <span v-if="!passwordVisible">
              <i class="fa fa-eye"/>
              Show Password
            </span>
            <span v-if="passwordVisible">
              <i class="fa fa-eye-slash"/>
              Hide Password
            </span>
          </button>
        </div>
        <!-- Error -->
        <div
          v-if="error"
          class="col-md-12 text-center animate__animated animate__bounceIn animate__faster">
          <strong class="text-danger">{{error}}</strong>
        </div>
      </div>
      <!-- Form Controls -->
      <div
        v-if="!passwordUpdating"
        class="row">
        <div class="col-md-12 d-flex flex-nowrap justify-content-end"> 
          <button
            type="button"
            class="btn btn-outline-primary me-2"
            @click="cancel">
            <i class="fa fa-ban"></i>
            Cancel
          </button> 
          <button
            type="submit"
            class="btn btn-primary">
            <i class="fa fa-save"></i>
            Save
          </button> 
        </div>
      </div>
      <div
        v-if="passwordUpdating"
        class="col-md-12 text-center my-2">
        <Spinner/>
      </div>
    </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../../global/Spinner.vue'

  export default {
    props: ['contact', 'forgotPassword'],
    data(){
      return{
        passwordVisible: false,
        password1: null,
        password2: null,
        error: null,
        passwordUpdating: false
      }
    },
    components:{
      Spinner
    },
    methods:{
      toggleVisible(){
        this.passwordVisible = !this.passwordVisible
      },
      cancel(){
        if(this.forgotPassword){
          this.$store.dispatch('eclipseLogOut')
        }else{
          this.$emit('cancelForm')
        }
      },
      clearError(){
        this.error = null
      },
      submit(){
        // check for match
        if(this.password1 != this.password2){
          this.error = 'Passwords do not match. Please try again.'
          return
        }
        // check for requirements
        const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        if(pattern.test(this.password1)){
          const params = {
            contact_id: this.contact.contact_id,
            entity_id: this.contact.entity_id,
            first_name: this.contact.first_name,
            last_name: this.contact.last_name,
            login_id: this.contact.login_id,
            password: this.password1
          }
          this.passwordUpdating = true
          if(this.user.superuser && this.contact.contact_id != this.user.contact_id){
            axios.post('/api/v1/private/superuser/update_contact_password', params).then(res => {
              if(res.status == 200 && res.data.updated){
                this.passwordUpdating = false
                this.$emit('passwordUpdated')
              }
            })
          }else{
            axios.post('/api/v1/private/user_management/update_password', params).then(res => {
              if(res.status == 200 && res.data.updated){
                this.passwordUpdating = false
                this.$emit('passwordUpdated')
              }
            })  
          }

          
        }else{
          this.error = "Oops! You're password doesn't meet the minimum requirements... Please Try Again."
        }
      }
    },
    computed:{
      user(){return this.$store.getters.user}
    }
  }
</script>

<style scoped>
  .password-area{
    max-width: 500px;
    margin: 0 auto;
  }
  .visible-toggle{
    font-size:12px;
  }
</style>