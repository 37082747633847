<template>
  <div class="card h-100">
    <div
      class="card-header">
      <!-- Record ID -->
      <div class="float-start">
        <small class="text-muted">ID: {{ record.id }}</small>
      </div>
      <!-- Actions -->
      <div class="float-end">
        <EditDelete
          :deleteAllowed="deleteAllowed"
          :values="record"/>
      </div>
    </div>
    <div class="card-body overflow-hidden">
      <table
        class="table table-light table-sm table-striped table-bordered"
        style="table-layout: fixed;">
        <tbody>
          <tr
            v-for="header in headerData"
            :key="header.column">
            <!-- Image Handler, exclude PDF files -->
            <td
              v-if="header.column.includes('uid') && !header.column.includes('file_uid')"
              colspan="2">
              <div class="row">
                <div class="col-md-12 text-center">
                  <img
                    :src="getImgUrl(header.column)"
                    class="img-fluid"
                    style="max-height: 150px;">
                </div>
              </div>
            </td>
            <!-- Boolean Handler -->
            <template v-else-if="header.type == 'bit'">
              <td>
                {{ header.column }}
              </td>
              <td>
                {{ recordData[header.column] }}
              </td>
            </template>
            <!-- Position -->
            <template v-else-if="header.column == 'position'">
              <td>
                {{ header.column }}
              </td>
              <td>
                {{ recordData[header.column] }}
                <!-- Controls -->
                <div>
                  <button
                    class="btn btn-sm btn-link"
                    @click="move('up')">
                    <small>Move Up</small>
                  </button>
                  <button
                    class="btn btn-sm btn-link"
                    @click="move('down')">
                    <small>Move Down</small>
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-sm btn-link"
                    @click="move('top')">
                    <small>To Top</small>
                  </button>
                  <button
                    class="btn btn-sm btn-link"
                    @click="move('bottom')">
                    <small>To Bottom</small>
                  </button>
                </div>
              </td>
            </template>
            <!-- All Other -->
            <template v-else>
              <td>
                {{ header.column }}
              </td>
              <td class="text-muted">
                {{ recordData[header.column] }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import EditDelete from './EditDelete.vue';

  export default{
    props:['headers', 'record', 'deleteAllowed'],
    components:{
      EditDelete
    },
    methods:{
      getImgUrl(column){
        const columnName = _.replace(column, '_uid', '_url')
        return this.record[columnName]
      },
      move(direction){
        const data = {
          view: this.$store.getters.adminView,
          id: this.record.id,
          direction
        }
        this.$store.dispatch('changePosition', data)
      }
    },
    computed:{
      recordData(){return _.omit(this.record, 'id')},
      headerData(){return _.reject(this.headers, {column: 'id'})}
    }
  }
</script>