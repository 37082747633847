<template>
  <div v-if="entityNameList">
    <div class="row align-items-center py-3">
      <div class="col-12 text-sm-end">
        <button 
          :disabled="loading"
          style="width:170px;"
          class="btn btn-outline-primary btn-sm"
          @click="toggleAdding">
          <i :class="adding ? 'fa-ban' : 'fa-user-plus'"  class="fa pe-1"></i> 
          <span v-if="adding">Cancel</span>
          <span v-else>Add New User</span>
        </button>
      </div>
    </div>
    <form
      name="addContact"
      ref="addNewForm" 
      :class="error ? 'border-danger' : 'border-success'" 
      class="mb-3 border p-3" 
      v-if="adding" 
      @submit.prevent="submit">
      <div class="w-100">
        <h5 :class="error ? 'text-danger' : 'text-success'">New eStore User Form</h5>
        <p v-if="error" class="text-danger" v-html="error"></p>
        <p v-else class="text-muted" v-html="'Create new eStore users for your account to submit orders and/or view pricing.'"></p>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 pb-3">
          <div class="form-group">
            <label
              for="firstName"
              class="text-muted small">
              First Name*
            </label>
            <input
              id="firstName"
              type="text"
              class="form-control"
              maxlength="100"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              placeholder="Enter first name"
              :disabled="submitting"
              v-model="formData.first_name"
              required />
          </div>
        </div>
        <div class="col-12 col-lg-6 pb-3">
          <div class="form-group">
            <label
              for="lastName"
              class="text-muted small">
              Last Name*
            </label>
            <input
              id="lastName"
              type="text"
              class="form-control"
              maxlength="100"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              placeholder="Enter last name"
              :disabled="submitting"
              v-model="formData.last_name"
              required />
            
          </div>
        </div>
        <div class="col-12 col-lg-6 pb-3">
          <div class="form-group">
            <label
              for="email"
              class="text-muted small">
              Email Address*
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter email address"
              class="form-control"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              :disabled="submitting"
              required
              v-model="formData.email" />
          </div>
        </div>
        <div class="col-12 col-lg-6 pb-3">
          <div class="form-group">
            <label for="accountSelect" class="text-muted small">Account for This User*</label>
            <select 
              disabled
              title="Toggle the location select above to change accounts."
              name="accountSelect"
              id="accountSelect"
              class="form-select"
              v-model="formData.entity_id"
              required >
              <option v-for="e, index in entityNameList" :key="index" :value="e.value">{{ e.name }}</option>
            </select>
          </div>
          <div class="d-flex">
            <i class="fa fa-info-circle text-muted small m-1"></i><p class="small text-muted"><span class="font-weight-700">Bill To</span> Accounts allow the user to order for all account locations. <span class="font-weight-700">Ship To</span> Accounts limit ordering to the assigned location only.</p>
          </div>
        </div>
        
        <div class="col-12 col-lg-6 pb-3"> 
          <div class="form-group">
            <label
              for="password"
              class="text-muted small">
              Password*
            </label>
            <input
              id="password"
              :type="passwordVisible ? 'text' : 'password'"
              placeholder="Enter password"
              class="form-control"
              :class="passwordFieldClass"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              :disabled="submitting"
              required
              v-model="formData.password" />
          </div>
          <button
            type="button"
            class="btn btn-sm btn-link me-2"
            @click="toggleVisible">
            <span v-if="!passwordVisible">
              <i class="fa fa-eye"/>
              Show Password
            </span>
            <span v-if="passwordVisible">
              <i class="fa fa-eye-slash"/>
              Hide Password
            </span>
          </button>
          <br />
          
          <span :class="passwordExist && !passwordSecure ? 'text-danger' : 'text-muted'" class="small d-inline-block pe-3">Password must be at least 8 characters, include at least one number, one uppercase letter, one lowercase letter and one special character.</span>
        </div>
        <div class="col-12 col-lg-6 pb-3">
          <div class="form-group">
            <label
              for="verify"
              class="text-muted small">
              Verify Password*
            </label>
            <input
              id="verify"
              :type="passwordVisible ? 'text' : 'password'"
              placeholder="Re-enter password"
              class="form-control"
              :class="passwordFieldClass"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              :disabled="submitting"
              required
              v-model="formData.passwordVerify" />
          </div>
          <button
            type="button"
            class="btn btn-sm btn-link me-2"
            @click="toggleVisible">
            <span v-if="!passwordVisible">
              <i class="fa fa-eye"/>
              Show Password
            </span>
            <span v-if="passwordVisible">
              <i class="fa fa-eye-slash"/>
              Hide Password
            </span>
          </button>
          <br />
          <span :class="passwordExist && !passwordMatch ? 'text-danger' : 'text-muted'" class="small d-inline-block pe-3">Please re-enter your new password to verify. Password fields will highlight green once secure and verified.</span>
        </div> 
        
        <div class="col-12 col-lg-6 pb-3">
          <div class="form-group">
            <label
              for="phone"
              class="text-muted small">
              Phone Number (Optional)
            </label>
            <input
              id="phone"
              type="tel"
              ref="telle"
              class="form-control"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              maxlength="14"
              pattern="^(1-)?\d{3}-\d{3}-\d{4}$"
              placeholder="555-555-5555"
              :disabled="submitting"
              v-model="formData.phone_number" />
          </div>
        </div>
        <div class="col-12 col-lg-6 pb-3">
          <div class="form-group">
            <div class="text-muted small">eStore Order Entry</div>
            <fieldset class="form-group">                
              <div class="d-flex align-items-center">
                <input v-model="formData.estore_noorder" value="false" type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" />
                <label for="optionsRadios1" class="form-check-label ms-1">
                  <span class="text-success">Allowed.</span> User can submit eStore orders.
                </label>
              </div>
              <div class="d-flex align-items-center">
                <input v-model="formData.estore_noorder" value="true" type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" />
                <label for="optionsRadios2" class="form-check-label ms-1">
                  <span class="text-danger">Disabled.</span> User sees pricing but cannot submit orders.
                </label>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="col-12 pb-3">
          <div class="d-flex w-100 justify-content-end flex-nowrap">
            <button
              type="button"
              :disabled="submitting"
              class="btn btn-outline-primary me-2"
              @click="toggleAdding">
              <i class="fa fa-ban me-2"></i>
              Cancel
            </button>
            <button
              type="submit"
              :disabled="submitting"
              class="btn btn-primary me-2">
              <span v-if="submitting"> <i class="fa fa-spinner fa-spin me-2"></i> Submitting...</span>
              <span v-else><i class="fa fa-paper-plane me-2"></i> Submit</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios'
import {setNotification} from '../../../../../shared/Notification'

export default {
  props: ['entityNameList','activeEntity','loading'],
  data() {
    return {
      adding: false,
      submitting: false,
      error: null,
      passwordVisible: false,
      formData: {
        password: '',
        passwordVerify: '',
        entity_id: null,
        estore_noorder: false
      }
    }
  },
  created(){
    this.formData.entity_id = this.activeEntity
  },
  methods: {
    toggleAdding(){
      this.adding = !this.adding
      if(this.adding){
        setTimeout(()=>{
          this.telActive()
        },100)
      }
    },
    toggleVisible(){
      this.passwordVisible = !this.passwordVisible
    },
    telActive(){
      const tele = this.$refs.telle;
      tele.addEventListener('keyup', function(e){
        if (e.key != 'Backspace' && (tele.value.length === 3 || tele.value.length === 7)){
        tele.value += '-';
        }
      });
    },
    submit(){
      this.submitting = true
      if(this.passwordMatch && this.passwordSecure){
        axios.post('api/v1/private/superuser/create_new_contact', {...this.formData}).then(res => {
          if(res.status == 200){
            if(res.data.error){
              this.error = res.data.error[0]
              this.submitting = false
            }else{
              this.error = null
              this.formData.login_id = this.formData.email
              this.formData.contact_id = 0 //fake contact id, gets updated after submit
              this.$parent.$emit('addNewSuccess',this.formData)
              this.submitting = false
              setNotification('New eStore User Added!', 'success')
              this.adding = false
              this.formData = {password: '',passwordVerify: '',entity_id: this.activeEntity,estore_noorder: false}
            }
          }
        })
      }else{
        if(!this.passwordMatch){
          this.error = 'Passwords do not match.'
          this.submitting = false
        }else{
          this.error = 'Password must meet all security requirements.'
          this.submitting = false
        }
      } 
    },
  },
  computed:{
    passwordExist(){
      return this.formData.password != '' && this.formData.passwordVerify != ''
    },
    passwordMatch(){
      return this.passwordExist && this.formData.password == this.formData.passwordVerify
    },
    passwordSecure(){
      const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      return this.passwordExist && pattern.test(this.formData.password)
    },
    passwordFieldClass(){
       if(this.passwordExist && (!this.passwordMatch || !this.passwordSecure)){
        return 'border-2 border-danger'
       }
       if(this.passwordExist && this.passwordMatch && this.passwordSecure){
        return 'border-2 border-success'
       }

    }
  },
  watch:{
    error(NewVal){
      if(NewVal != null){
        this.submitting = false
      }
    },
    activeEntity(NewVal){
      this.formData.entity_id = NewVal
    },
    loading(NewVal){
      if(NewVal == true){
        this.adding = false
      }
    }
  }
}
</script>