import store from '../stores/store'

const csContactEmail = (env)=>{
  const company = store.getters.sessionCompany
  if(env == 'EJW') return 'customerservice@ejwelch.com'
  if(env == 'EJW-CHENEY') return 'uppermidwestcustserv@ejwelch.com'
  if(env == 'LAN' && company == 'Lancaster Canada') return 'customer.serviceca@lancasterco.com'
  if(env == 'LAN' && company == 'Lancaster US') return 'orders@lancasterco.com'
}

export default csContactEmail