<template>
  <div>
    <div class="row mt-4">
      <div
        class="col-md-6 d-none d-md-block"
        :style="img"/>
      <div
        class="col-md-6 col-12 bg-primary p-5 position-relative"
        style="min-height: 250px;">
        <div class="centered">
          <h2
            style="font-weight: 400;"
            class="text-white pt-5">HOW CAN EJ WELCH SERVE YOU?</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        img: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/contact/hearFromYou.jpg')})`,
          ...this.bgProperties()
        }
      }
    },
    methods:{
      bgProperties(){
        return {
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          height: '350px'
        }
      }
    }
  }
</script>

<style scoped>
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    width: 85%;
    transform: translateY(-50%);
  }
</style>