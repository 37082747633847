<template>
  <div>
    <Stats :view="view"/>
    <Focus :view="view"/>
    <WhyWait :view="view"/>
  </div>
</template>

<script>
  import Stats from './components/Stats.vue'
  import Focus from './components/Focus.vue'
  import MfgTabs from './components/MfgTabs.vue'
  import WhyWait from './components/WhyWait.vue'
  
  export default {
   props:['view'],
   components:{
     Stats,
     Focus,
     MfgTabs,
     WhyWait
   }
  }
</script>