<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster">
    <div class="row">
      <div class="col-md-12 text-muted">
        <div class="float-end">
          <span>Last Updated: </span>
          <span>12/01/2023</span>
        </div>
      </div>
      <div class="col-12">
        <h1 class="text-center mt-2">Lancaster Policies & Procedures</h1>
        <hr>
        <div class="row">
          <div
            class="col-md-12"
            ref="index">
            <div
              class="card"
              style="box-shadow:2px 2px 5px lightgray;">
              <div class="card-body text-center">
                <h3>Index</h3>
                <hr>
                <div class="row">
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('hoursOfOperation')">
                      Hours of Operation
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('orderInformation')">
                      Order Information
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('cutOff')">
                      Order Cut-Off Time Policy
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('locationAndService')">
                      Distribution Center Locations & Service Areas
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('freightPolicy')">
                      Freight Policy
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('parcel')">
                      Parcel Carriers
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('willCall')">
                      Will Call/ Pick Up Orders
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('international')">
                      Delivery to International Customers
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('charges')">
                      Pricing / Additional Charges
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('backorder')">
                      Backorder Policy
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('special')">
                      Special Orders
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('sds')">
                      Safety Data Sheets (SDS)
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('warranty')">
                      Warranty
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('terms')">
                      Payment Terms
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('holiday')">
                      Holiday Schedules
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('delivery')">
                      Delivery of Product
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('damages')">
                      Damages/ Shortages
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('return')">
                      Return Policy
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('contact')">
                      Who to contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-3"
              ref="hoursOfOperation">
              <h3>Hours of Operation</h3>
              Our hours of operation are Monday - Friday 8:00am to 5:00pm EST 
              &nbsp;Contact customer service at (800) 58-ORDER (586-7337) or by email at 
              <a href="mailto:orders@lancasterco.com">orders@lancasterco.com</a>
              <br>
              Fax orders 24 hours a day, 7 days a week to (800) 457-6382 
              <br>
              Hablamos español 
              <br>
              Apreciamos la oportunidad de servirle
            </div>
            <div
              class="mt-3"
              ref="orderInformation">
              <h3>Order Information</h3>
              Lancaster is committed to providing our customers with the best possible service. This includes providing consistent, reliable delivery times that meet the expectations of our customers.  Any order placed prior to the cut off time of your assigned home Lancaster Distribution Center (DC), on any business day, will be carefully prepared and shipped the same day.  Orders placed after your assigned home DC cut off time will ship the following business day.  
              &nbsp;If your order is placed prior to the requested ship date, you may add on to it until the close of business on the day 
              <b>prior to</b>
              &nbsp;shipment.  
              &nbsp;All orders meeting the predetermined minimum will ship prepaid.  
              &nbsp;We will ship directly to your customer upon request; prepaid minimums and/or freight charges apply.
            </div>
            <div
              class="mt-3"
              ref="cutOff">
              <h3>Order Cut-Off Time Policy</h3>
              <div class="row">
                <div class="col-md-8">
                  <table class="table table-bordered table-striped text-center table-condensed">
                    <thead>
                      <tr>
                        <th class="text-center">
                          <b>Branch</b>
                        </th>
                        <th class="text-center">
                          <b>Time</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>BR#1 (PAULINE)</td>
                        <td>10:00am EST</td>
                      </tr>
                      <tr>
                        <td>BR#2 (LARGO)</td>
                        <td>10:00am EST</td>
                      </tr>
                      <tr>
                        <td>BR#7 (TRACY)</td>
                        <td>1:00pm EST</td>
                      </tr>
                      <tr>
                        <td>BR#8 (KENTUCKY)</td>
                        <td>10:00am EST</td>
                      </tr>
                      <tr>
                        <td>BR#9 (MESQUITE)</td>
                        <td>11:00am EST</td>
                      </tr>
                      <tr>
                        <td>BR #14 (NJ)</td>
                        <td>10:00am EST for UPS Parcel, 12:00pm EST for all LTL Shipments & Sunteck Landover, 4:00pm EST for other Sunteck deliveries</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="mt-3"
              ref="locationAndService">
              <div class="row">
                <div class="col-md-8">
                  <h3>Distribution Center Locations & Service Areas (LTL)</h3>
                  <table class="table table-bordered table-striped table-condensed">
                    <thead>
                      <tr>
                        <th class="text-center"> Home Branch</th>
                        <th class="text-center"> States within Home Branch Service Area</th>
                        <th class="text-center" nowrap> Sister Branch</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>California (CA)</td>
                        <td>CA, NV, OR, WA (HI and AK orders ship to your freight forwarder)</td>
                        <td>TX</td>
                      </tr>
                      <tr>
                        <td>Florida (FL)</td>
                        <td>FL (Caribbean, Central & South America orders ship to your freight forwarder)</td>
                        <td>SC</td>
                      </tr>
                      <tr>
                        <td>Kentucky (KY)</td>
                        <td>KY, IL, WI, OH, MI, IN, WV, IA, SD, ND, MN, NE</td>
                        <td>SC & NJ</td>
                      </tr>
                      <tr>
                        <td>New Jersey (NJ)</td>
                        <td>PA, CT, DE, NH, NJ, NY, MA, ME, RI, VT, MD, DC </td>
                        <td>KY</td>
                      </tr>
                      <tr>
                        <td>South Carolina (SC)</td>
                        <td>SC, NC, GA, TN, VA, MS, AL </td>
                        <td>FL & KY</td>
                      </tr>
                      <tr>
                        <td>Texas (TX)</td>
                        <td>TX, NM, OK, AR, LA, KS, MO, WY, MT, ID, UT, AZ, CO</td>
                        <td>KY</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                Choice of carrier on all prepaid orders will be at Lancaster’s discretion.
                &nbsp;Lancaster uses well-known, established LTL carriers.  These carriers consistently accomplish next day delivery more than 95% of the time.
                &nbsp;Although extraordinary circumstances do occur, Lancaster will strive to work with you and our carriers to ensure every delivery meets your satisfaction. 
              </div>
              <div style="margin-top:10px;">
                Special delivery services may be available in your area from our LTL carriers for an additional charge.
                &nbsp;These include, but are not limited to:  Guaranteed AM delivery, inside delivery, liftgate delivery and call-ahead appointment deliveries. 
              </div>
            </div>
            <div
              class="mt-3"
              ref="freightPolicy">
              <h3>Freight Policy</h3>
              Each “individual shipment” stands alone in determining prepaid status, freight charges, etc. When an order is split into multiple shipments the Freight Policy applies to each individual shipment. 
              <h4 class="mt-3">Policy details:</h4>
              <ul>
                <li style="list-style-type: circle;">
                  Out of stocks <u>do not</u> count toward the prepaid minimum.
                </li>
                <li style="list-style-type: circle;">
                  Prepaid freight is $1,000 for any shipment from the customer’s Home Branch only. This excludes shipments outside of the contiguous United States (Hawaii, Alaska, Puerto Rico, etc.). Outlying and hard to service regions may have a higher prepaid minimum (Nantucket, Martha’s Vineyard, etc.).
                </li>
                <li style="list-style-type: circle;">
                  Shipments meeting $1,000 and shipping from a branch <u>other than</u> the customer’s Home Branch will be handled as follows:
                </li>
                <ul>
                  <li style="list-style-type: circle;">
                    Shipments over 2,500 lbs will have actual freight added to the invoice.
                  </li>
                  <li style="list-style-type: circle;">
                    Shipments under 2,500 lbs from a Sister DC will have an adder applied, which is the difference in freight from Sister DC vs Home DC.
                  </li>
                  <li style="list-style-type: circle;">
                    Shipments under 2,500 lbs from a DC other than the Home DC or Sister DC will have a $50 credit applied to actual freight and be billed for the remainder.
                  </li>
                </ul>
                <li style="list-style-type: circle;">
                  Shipments less than $1,000 will have actual freight added to the invoice, regardless of where it ships from.
                </li>
                <li style="list-style-type: circle;">
                  Machines such as Paint Sprayers and Pressure Washers do not count towards the pre-paid minimum.  If a machine is shipped by itself, the actual freight charge will be billed.
                </li>
                <li style="list-style-type: circle;">
                  Open stock orders – shipping to any other address other than what is listed on the account – do not qualify for prepaid freight and may incur additional fees. Direct shipments from vendors can only ship to addresses listed on the account.
                </li>
                <li style="list-style-type: circle;">
                  Any and all freight charges incurred from direct shipments from vendors will be billed to the account. This includes, but is not limited to detention charges, change in address fees, improper lift gate requirements, etc.
                </li>
              </ul>
              Please contact Customer Service for additional information regarding our Freight Policy.
            </div>
            <div
              class="mt-3"
              ref="parcel">
              <h3>Parcel Carriers</h3>
              Orders that do not meet the required minimum, may ship using a parcel carrier. Keep in mind that some products, because of weight and contents, may incur additional shipping and packaging charges. Not all products can ship parcel, these items will be quoted as LTL shipments.
            </div>
            <div
              class="mt-3"
              ref="willCall">
              <h3>Will Call / Pick Up Orders</h3>
              Lancaster is always happy to assist the customer.  Please allow our customer service and distribution centers a minimum of two hours from the time you call, fax or email your order, before arriving to pick up your order at the warehouse.
            </div>
            <div
              class="mt-3"
              ref="international">
              <h3>Delivery to International Customers</h3>
              Lancaster will prepay the shipment to the freight forwarder of your choice on orders. Minimums for prepaid shipments are still applicable. Customs and duties charges will be handled by the customer.
              &nbsp;Upon receipt of your order please inspect and report any issues with your freight forwarder. 
            </div>
            <div
              class="mt-3"
              ref="charges">
              <h3>Pricing / Additional Charges</h3>
              While Lancaster desires to maintain prices for as long as possible, often circumstances arise that are unexpected, yet warranted, where an immediate price adjustment must be made.  You may email&nbsp;
              <a href="mailto:'special.pricing@lancasterco.com">special.pricing@lancasterco.com</a>
              &nbsp;to sign up for price change notifications. As in any industry, we experience increases that are unavoidable.  Fuel surcharges seem to be the most common of these and we will only charge this as we are being charged from our vendors and common carrier partners.
              &nbsp;We appreciate your willingness to work with us during these times and pledge our effort to control these costs.
            </div>
            <div
              class="mt-3"
              ref="backorder">
              <h3>Backorder Policy</h3>
              In the event an item you requested does not ship with your order, please reorder the item with your next shipment.  With the exclusion of promotional and trade show orders, Lancaster does not backorder any items, unless you request. Items left open from promotional and trade show orders, will be cancelled if not shipped by the end of the promotion window.    
            </div>
            <div
              class="mt-3"
              ref="special">
              <h3>Special Orders</h3>
              While Lancaster inventories more than 14,000 items, occasionally our customers will need an item we do not stock.  If this situation arises, we will be happy to place a special order of $50 or more to be shipped directly to your store from the manufacturer.
              &nbsp;Please be aware that freight charges may apply to special orders if manufacturer prepaid minimums are not met. Special order items are non-returnable. Please contact your sales representative or our Customer Service Department if you need to place a special order.
            </div>
            <div
              class="mt-3"
              ref="sds">
              <h3>Safety Data Sheets (SDS)</h3>
              Lancaster has access to material safety data sheets 24 hours a day, 7 days a week.  Contact your sales representative or a customer service associate for additional information.
            </div>
            <div
              class="mt-3"
              ref="warranty">
              <h3>Warranty</h3>
              Lancaster recognizes the manufacturer's warranty as is in lieu of all other warranties expressed or implied, including warranties of merchantability or fitness for a particular purpose, and any other representations or obligations, including liability for indirect, punitive, incidental or consequential damages.
              &nbsp;This limitation of warranty and liability supersedes all prior representations or warranties of any kind.
            </div>
            <div
              class="mt-3"
              ref="terms">
              <h3>Payment Terms</h3>
              <ul>
                <li>Lancaster operates with terms of Net 30 Days.  There will be a 1.5% monthly service charge on past due balances.  Please assist yourself and us by avoiding these additional charges.</li>
                <li>All Lancaster customers will incur a 2% surcharge when making payments using a credit card. This applies to all customers except those operating inside CT, ME, MA, or OK and customers operating outside the United States.</li>
              </ul>
            </div>
            <div
              class="mt-3"
              ref="holiday">
              <h3>Everyone Needs a Day Off (Holiday Schedules)</h3>
              Lancaster observes the following as national holidays and operates under holiday schedules: 
              <ul>
                <li style="list-style-type: circle;">
                  <b>New Year's Day</b>
                </li>
                <li style="list-style-type: circle;">
                  <b>Memorial Day</b>
                </li>
                <li style="list-style-type: circle;">
                  <b>4th of July</b>
                </li>
                <li style="list-style-type: circle;">
                  <b>Labor Day</b>
                </li>
                <li style="list-style-type: circle;">
                  <b>Thanksgiving Day & the following Friday</b>
                </li>
                <li style="list-style-type: circle;">
                  <b>Christmas Eve & Christmas Day. </b>
                </li>
              </ul>
              <br>
              Should any of these days fall on a weekend, we will observe either the Friday before or Monday after. Lancaster appreciates your business and respects your comments.  Please contact your sales representative or a member of the Customer Service Department with any concerns.
            </div>
            <div
              class="mt-3"
              ref="delivery">
              <h3>Delivery of Product</h3>
              Upon deliver please sign your bill of lading “SUBJECT TO INSPECTION”.  Lancaster applies a green pallet cap, clear stretch wraps, and attaches “Lancaster” custom security tape to all common carrier deliveries.
              &nbsp;If your shipment does not arrive with our pallet cap, tape, or other than clear stretch wrap, please mark bill of lading NO PALLET CAP / TAPE.  Note on the bill of lading any damage that is apparent.  For any problems with your order (shortages, damages, wrong items, etc.), please contact Customer Service at 1-800-586-7337 within 48 hours.
              &nbsp;Please note: If bill of lading is not signed SUBJECT TO INSPECTION and reported within 48 hours, customer will be responsible for all shortages and damages. 
            </div>
            <div
              class="mt-3"
              ref="damages">
              <h3>Damages / Shortages</h3>
              Upon inspection of your order and packing slip, if there are any inconsistencies or damage in your order, a freight claim will need to be filed with the carrier. Email&nbsp;
              <a href="mailto:credits@lancasterco.com">credits@lancasterco.com</a>&nbsp;
              or contact Lancaster Customer Service to file claims for orders that meet the prepaid freight minimum. On freight collect orders, you will need to contact the carrier to file a claim. All visible damage must be noted on the delivery receipt at time of delivery and please take pictures of all damages and send this information to&nbsp;
              <a href="mailto:credits@lancasterco.com">credits@lancasterco.com</a>&nbsp;
              immediately. Please remember that we will not be able to file a freight claim if the bill of lading is signed clear by the customer, therefore we will be unable to process a credit.
            </div>
            <div
              class="mt-3"
              ref="return">
              <h3>Return Policy</h3>
              <div>
                If Lancaster made an error, Customer Service will authorize a return to our distribution center.  We will mail or email a call tag, if needed, or arrange to have the item picked up.  Instructions for the return will be e-mailed or faxed.  Items must be returned within 10 business days of the issuance of the call tag to the customer or the return authorization will be cancelled. 
              </div>
              <div style="margin-top:10px;">
                |If a return is due to circumstances beyond Lancaster’s control (i.e. customer ordered the wrong item or quantity), this return will be subject to a restocking fee.  Please contact Customer Service for return authorization and then contact UPS, FedEx, or a common carrier to arrange for the return.  Freight charges will apply. 
              </div>
              <div style="margin-top:10px;">
                Lancaster will accept returns of any resalable products purchased from us within 60 days of purchase and must be accompanied by a Return Goods Authorization.  Special order items, close-out specials, discontinued items, and final sales cannot be returned.  Before the credit is issued an inspection of the returned products will be made and the following conditions must apply:
                <ul> 
                  <li style="list-style-type:circle;">
                    The product(s) must be returned in its original packaging
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) must not have price stickers attached 
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) must not have any prices or codes handwritten on them 
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) packaging must not be damaged
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) must not be damaged
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) must be included on the return goods authorization
                  </li>
                </ul>
                <strong> Lancaster Territory Managers are not authorized to pick up any products.</strong>
              </div>
            </div>
            <div
              class="mt-3"
              ref="contact">
              <div class="row">
                <div class="col-md-6">
                  <h3>Who to contact</h3>
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Issue</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Order Errors</td>
                        <td>
                          Customer Service or&nbsp;
                          <a href="mailto:credits@lancasterco.com">credits@lancasterco.com</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Damages/ Shortages</td>
                        <td>
                          Customer Service or&nbsp;
                          <a href="mailto:credits@lancasterco.com">credits@lancasterco.com</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Prepaid Order</td>
                        <td>Customer Service</td>
                      </tr>
                      <tr>
                        <td>Collect Order</td>
                        <td>Carrier</td>
                      </tr>
                      <tr>
                        <td>Promotional Pricing or Terms</td>
                        <td>Customer Service</td>
                      </tr>
                      <tr>
                        <td>Check the status of a pending order</td>
                        <td>Customer Service</td>
                      </tr>
                      <tr>
                        <td>Regular Pricing Discrepancies</td>
                        <td>Territory Manager</td>
                      </tr>
                      <tr>
                        <td>Defectives</td>
                        <td>Territory Manager</td>
                      </tr>
                      <tr>
                        <td>Samples</td>
                        <td>Territory Manager</td>
                      </tr>
                      <tr>
                        <td>Other Issues</td>
                        <td>Territory Manager</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
   methods:{
     goto(section){
       this.$refs[section].scrollIntoView({behavior: 'smooth'})
     }
   } 
  }
</script>