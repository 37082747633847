<template>
<div class="mt-3">
  <!-- Dealer Focus -->
  <div
    v-if="view == 'dealer'"
    class="row">
    <div
      class="col-md-8"
      :style="background1">
    </div>
    <div class="col-md-4 bg-secondary text-white p-5">
      <div>
        <h2 style="font-weight: 400;">OUR FOCUS IS YOU</h2>
        <p>We bring value to our customers by developing an integral link between you and our suppliers. This gives you more time to focus on growth, profitability, and longevity and less time on the supply chain.</p>
      </div>
    </div>
  </div>
  <!-- Mfg Focus -->
  <div
    v-if="view == 'manufacturer'"
    class="row">
    <!-- Image -->
    <div
      class="col-md-8 position-relative"
      :style="background2"/>
    <div class="col-md-4 bg-secondary text-white p-5">
      <div>
        <h2 style="font-weight: 400;">WE ARE THE LOGISTICS EXPERT YOU'VE BEEN SEARCHING FOR.</h2>
        <p>Service more customers with less transactions! Get back to focusing on manufacturing and let Cheney Vrooman worry about the rest.</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    props:['view'],
    data(){
      return{
        background1: {
          backgroundImage: `url(${require('../../../../../../assets/images/cheney/advantage/PXL_20240411_144743427.jpg')})`,
          ...this.bgProperties()
        },
        background2: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/mfg/truck.jpg')})`,
          ...this.bgProperties()
        }
      }
    },
    methods:{
      bgProperties(){
        return{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          minHeight: '350px'
        }
      }
    }
  }
</script>