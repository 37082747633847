<template>
  <div class="mt-5">
    <Header/>
    <RoadShow/>
    <TradeShows/>
    <ShowContent/>
    <Testimonials/>
    <Footer/>
  </div>
</template>

<script>
  import Header from './components/Header.vue'
  import RoadShow from './components/RoadShow.vue'
  import TradeShows from './components/TradeShows.vue'
  import ShowContent from './components/ShowContent.vue'
  import Testimonials from './components/Testimonials.vue'
  import Footer from '../app/Footer.vue'

  export default {
    components:{
      Header,
      RoadShow,
      TradeShows,
      ShowContent,
      Testimonials,
      Footer
    }
  }
</script>