<template>
  <section
    v-if="focus || display && navData"
    class="type-ahead shadow-lg"
    id="typeAheadDropDown">
    <!-- Spinner -->
    <div
      v-if="loading" 
      class="row">
      <div class="col-md-12 text-center p-2">
        <Spinner class="type-ahead-spinner"/>
      </div>
    </div>
    <!-- Error Handling -->
    <div v-if="!loading && error">
      <div class="col-md-12 text-center p-4">
        <small class="text-danger">
          <i class="fa fa-exclamation-triangle"/>
          {{ error }}
        </small>
      </div>
    </div>
    <ul class="type-ahead-results">
      <!-- Search for all results link -->
      <div
        v-if="typeAheadData && query.length && !loading && textMatches && textMatches.length"
        class="row">
        <div class="col-md-12">
          <div class="p-2">
            <div
              class="text-muted"
              style="font-size:12px;">
              Suggested:&nbsp;
              <a
                v-for="t in textMatches"
                :key="t"
                class="m-1"
                @click="setQuery(t)"
                href="javascript: void(0);">
                '{{ t }}''
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Type ahead product suggestions -->
      <template v-if="typeAheadData && !loading">
        <li
          v-for="(t, i) in typeAheadData"
          :key="t.product_id"
          class="type-ahead-line"
          :class="position == i ? 'type-ahead-line-active' : ''"
          :id="'typeAheadLine-' + t.product_id"
          @click="submitProduct({id: t.product_id})">
          <div class="row">
            <div class="col-3 text-center">
              <img
                :src="t.image_url"
                class="img-fluid line-img"
                @error="imgError">
            </div>
            <div class="col-9">
              {{ t.description }}
              <div class="row">
                <div class="col-md-12">
                  <ul
                    style="list-style-type: none; padding: 0px;"
                    class="text-muted detail">
                    <li>
                      <small>UPC: {{ t.upc }}</small>
                    </li>
                    <li v-if="t.customer_part_number">
                      <small>Part Number: {{ t.customer_part_number }}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <i
            v-if="i == position"
            class="fa fa-keyboard keyboard animate__animated animate__bounceIn animate__faster"/>
        </li>
      </template>
      <!-- Type ahead search history -->
      <template v-if="!loading && !typeAheadData && history">
        <div class="p-1">
          <small
            style="font-size:14px"
            class="text-muted px-2">
            Your Recent Searches
          </small>
        </div>
        <li
          v-for="(h, i) in history"
          :key="h"
          class="type-ahead-line px-3"
          :class="position == i ? 'type-ahead-line-active' : ''"
          :id="'typeAheadLine-' + i"
          @click="submitSearch(h)">
          {{ h }}
          <i
            v-if="i == position"
            class="fa fa-keyboard keyboard animate__animated animate__bounceIn animate__faster"/>
        </li>
      </template>
    </ul>
    <!-- No Results Handler -->
    <div
      v-if="!loading && !error && typeAheadData && !typeAheadData.length"
      class="row">
      <div class="col-md-12 text-center p-4">
        <small class="text-muted">No Results</small>
      </div>
    </div>
    <!-- Search for all results link -->
    <div
      v-if="typeAheadData && query.length && !loading"
      class="row">
      <div class="col-md-12">
        <div class="float-end p-2">
          <button
            @click="submitSearch(query)"
            class="btn btn-sm btn-outline-primary"
            style="font-size:12px;">
            Show All results for: '{{ query }}'
        </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import {typeAheadSuggestions, typeAheadHistory, keyboardControl, closeOnClickOutside, resetPositiononMouseMove} from '../../../../repositories/typeAheadRepository'
  import comingSoon from '../../../../shared/comingSoon'
  
  export default{
    props: ['query', 'focus', 'route'],
    mounted(){
      this.initialize()
    },
    data(){
      return{
        loading: false,
        typeAheadData: null,
        history: null,
        timer: null,
        position: null,
        error: null,
        display: false,
        spellSuggest: null,
        textMatches: null
      }
    },
    components:{
      Spinner
    },
    watch:{
      query(newVal){
        // Reset on no query
        if(!newVal.length){
          this.error = null
          this.typeAheadData = null
        }
        // Get typeahead data when query present
        if(newVal.length >= 3 && !this.loading){
          this.error = null
          this.getTypeAhead()
        }
      },
      // When search box is clicked inside of fetch search history
      focus(newVal){
        if(newVal && this.query == ''){
          const response = typeAheadHistory()
          response
          .then((res) => {
            if(res.data.history?.length) this.history = res.data.history
          })
          .catch((error)=>{
            this.error = error.response.statusText
          })
        } else if(newVal){
          this.getTypeAhead()
        }
      },
      history(newVal){
        if(newVal && this.focus){
          this.display = true
        }
      },
      typeAheadData(newVal){
        if(newVal && this.focus) this.display = true
      },
      productLoading(newVal){
        if(newVal == true) this.display = false
      },
      route(newVal){
        if(newVal) this.initialize()
      },
      loading(newVal){
        this.$emit('loading', newVal)
      }
    },
    methods:{
      submitProduct(data){
        // Goto Product Page
        this.display = false
        if(this.query.length){
          this.$router.push({
            name: 'ProductPage',
            params: {id: data.id},
            query: {query: this.query}
          })
        }else{
          this.$router.push({path: `/product/${data.id}`})
        }
      },
      submitSearch(query){
        this.display = false
        this.$emit('submitSearch', query)
      },
      getTypeAhead(){
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>{
          if(this.query.length == 0) return
          this.loading = true
          const response = typeAheadSuggestions(this.query)
          response
          .then((res)=>{
            this.typeAheadData = res.data.results
            if(res.data.text_matches){
              this.textMatches = res.data.text_matches
            }else{
              this.textMatches = null
            }
            this.loading = false
          })
          .catch((error)=>{
            this.error = error.response.statusText
            this.loading = false
          })
        }, 1000)
      },
      setQuery(text){
        this.$emit('setQuery', text)
      },
      imgError(e){comingSoon(e)},
      initialize(){
        closeOnClickOutside.call(this)
        resetPositiononMouseMove.call(this)
        keyboardControl.call(this)
      }
    },
    computed:{
      navData(){
        if(this.typeAheadData?.length) return {mode: 'suggestions', data: this.typeAheadData}
        if(this.history?.length) return {mode: 'history', data: this.history}
      },
      productLoading(){return this.$store.getters.productLoadState}
    }
  }
</script>

<style scoped>
  .type-ahead{
    background: white;
    border: 1px solid black;
    width: 100%;
    min-height: 65px;
    position: absolute;
    z-index: 1021;
  }
  .type-ahead-results{
    list-style-type: none;
    padding: 0px;
  }
  .type-ahead-line{
    font-size: 14px;
    padding: 0px 5px;
    cursor: pointer;
    margin-top: 5px;
    position: relative;
  }
  .type-ahead-line:hover{
    background: var(--bs-primary);
    color: white;
    .detail li{
      color:white;
    }
  }
  /* Keyboard up down select */
  .type-ahead-line-active{
    box-shadow: 0px 1px 8px darkslategray;
    transition: all 120ms ease-out;
    min-height:20px;
  }
  .line-img{
    padding:5px;
    max-width: 70px;
    max-height: 70px;
  }
  .type-ahead-spinner{
    height: 25px;
    width: 25px;
    font-size: 14px;
  }
  .keyboard{
    position: absolute;
    top: 3px;
    right: 10px;
  }
</style>