<template>
  <div class="container-fluid">
    <div class="mt-3">
      <!-- View Switcher -->
      <div
        v-if="!activeProduct"
        class="row">
        <div class="col-md-12 text-center">
          <a
            class="btn btn-link"
            :class="view == 'pad' ? 'text-success' : 'text-muted'"
            @click="toggleView('pad')"
            href="javascript: void(0);">
            Search & Add
          </a>
          <a
            class="btn btn-link"
            :class="view == 'copy&paste' ? 'text-success' : 'text-muted'"
            @click="toggleView('copy&paste')"
            href="javascript: void(0);">
            Copy & Paste
          </a>
        </div>
      </div>
      <!-- Quick Pad -->
      <div v-if="view == 'pad'">
        <div
          v-if="!activeProduct"
          class="row d-none d-sm-block">
          <div class="col-md-12">
            <div class="text-center">
              <strong>Do you know exactly what you are looking for? </strong>
              <div class="text-muted mt-2">Enter a Product ID, UPC or Generic Search Term. Select a product from the results, then add your quantity and click "Add To Cart".</div>
            </div>
            <hr v-if="activeProduct">
          </div>
        </div>
        <!-- Search Form -->
        <form
          name="quickPadSearch"
          @submit.prevent="submit">
          <div
            v-if="!activeProduct"
            class="col-md-12 mt-2 animate__animated animate__fadeIn animate__faster">
            <div class="btn-group w-100">
              <input
                name="quickPadSearchProduct"
                class="form-control"
                type="text"
                v-model="product.query"
                :disabled="activeProduct"
                autocomplete="off"
                autocapitalize="off"
                spellcheck="false"
                placeholder="Search Products...">
                <i
                  v-if="product.query"
                  class="fa fa-times text-danger clear-times"
                  @click="fastClear"/>
                <button
                  class="btn btn-primary"
                  type="submit">
                  <i class="fa fa-search"/>
                </button>
            </div>
          </div>
        </form>
        <form
          name="quickPadAdd"
          @submit.prevent="addToCart">
          <button
            v-if="activeProduct"
            class="btn btn-sm btn-primary"
            @click="back">
            <i class="fa fa-chevron-left"/>
            Back
          </button>
          <div class="position-relative mt-2">
            <!-- Active Product -->
            <div
              v-if="activeProduct"
              class="animate__animated animate__fadeIn animate__faster">
              <div class="row">
                <div class="col-md-3 col-12 text-center">
                  <Indicator :product="activeProduct"/>
                  <img
                    :src="activeProduct.image_url"
                    class="img-fluid"
                    style="max-height: 200px;"
                    @error="imgError">
                </div>
                <div class="col-md-9 col-12">
                  <ProductPrice
                    :product="activeProduct"
                    @activeUom="setActiveUom"/>
                  <QtyBreaks :product="activeProduct"/>
                  <div>{{activeProduct.description}}</div>
                  <ul
                    class="text-muted"
                    style="list-style-type: none; padding: 0px; font-size:12px;">
                    <li>Product ID: {{activeProduct.product_id}}</li>
                    <li>UPC: {{activeProduct.upc}}</li>
                    <li>Case Qty: {{caseQty}}</li>
                    <li v-if="activeProduct.customer_part_number">Part Number: {{ activeProduct.customer_part_number.part_number }}</li>
                  </ul>
                  <!-- Qty Form -->
                  <QtyForm
                    v-if="activeProduct"
                    class="mt-2"
                    style="max-width:250px;"
                    @added="clear"
                    :product="activeProduct"
                    :activeUom="activeUom"/>
                </div>
              </div>
              <!-- Inventory Table -->
              <div class="row">
                <div class="col-12 mt-2">
                  <ul class="nav nav-tabs mt-2">
                    <li class="nav-item">
                      <a class="nav-link active" aria-current="page" href="#">Availability</a>
                    </li>
                  </ul>
                  <Availability
                    :product="activeProduct"
                    :activeUom="activeUom"/>
                </div>
              </div>
            </div>
            <!-- Results -->
            <div
              v-if="!activeProduct && products"
              class="col-md-12 result-list">
              <div
                v-for="p in products"
                :key="p.product_id"
                class="row my-1 p-2 result"
                @click="setActive(p)">
                <div class="row">
                  <div class="col-2 d-flex justify-content-center">
                    <img
                      class="img-fluid tesxt-center"
                      style="max-height: 75px; max-height: 75px; object-fit: contain;"
                      :src="p.image_url"
                      @error="imgError">
                  </div>
                  <div
                    class="col-7"
                    style="overflow:hidden; max-height: 50px;">
                    {{p.description}}
                  </div>
                  <div
                    v-if="!hidePricing"
                    class="col-3">
                    <ProductPrice
                      :product="p"
                      :hideSelector="true"
                      @activeUom="setActiveUom"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- No Results -->
            <div
              v-if="!activeProduct && products && products.length == 0"
              class="col-md-12 text-center text-muted mt-5">
              <h4>No Results</h4>
            </div>
          </div>
        </form>
        <div
          v-if="loading"
          class="col-md-12 text-center mt-5">
          <Spinner/>
        </div>
        <!-- Confirmation -->
        <div
          v-if="confirm"
          class="row">
          <div class="col-md-12 mt-5 text-center text-success animate__animated animate__bounceIn animate__faster">
            <h3>
              Product Added To Cart
              <i class="fa fa-check-circle"/>
            </h3>
          </div>
        </div>
      </div>
      <CopyAndPaste v-if="view == 'copy&paste'"/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Qs from 'qs'
  import Spinner from '../../../global/Spinner.vue'
  import Indicator from '../product/Indicator.vue'
  import ProductPrice from '../product/price/ProductPrice.vue'
  import QtyBreaks from '../product/QtyBreaks.vue'
  import QtyForm from '../product/QtyForm.vue'
  import CopyAndPaste from './CopyAndPaste.vue'
  import {caseQuantity} from '../../../../shared/ProductFunctions'
  import { stockFlagInBranch, invInBranch } from '../../../../shared/stockHelpers'
  import comingSoon from '../../../../shared/comingSoon'
  import Availability from '../product/detail/Availability.vue'

  export default {
    data(){
      return{
        product: {
          query: '',
          qty: null
        },
        products: null,
        activeProduct: null,
        loading: false,
        pad: [],
        confirm: false,
        view: 'pad',
        activeUom: null
      }
    },
    components:{
      Spinner,
      Indicator,
      ProductPrice,
      QtyBreaks,
      QtyForm,
      CopyAndPaste,
      Availability
    },
    methods:{
      submit(){
        if(this.product.query != ''){
          this.loading = true
          this.activeProduct = null
          this.products = null
          let params = {
            query: {
              query: this.product.query,
              action: 'quickPadSearch'
            }
          }
          document.activeElement.blur()
          axios.get('/api/v1/private/quick_pad/search', {params, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})}).then(res =>{
            this.products = res.data.products
            this.loading = false
          })
        }
      },
      setActive(product){
        this.activeProduct = product
        this.product.query = product.description
      },
      fastClear(){
        this.activeProduct = null
        this.products = null
        this.product = {
          query: '',
          qty: null
        }
      },
      clear(){
        setTimeout(function(){
          this.activeProduct = null
          this.products = null
          this.product = {
            query: '',
            qty: null
          }
        }.bind(this), 1500)
      },
      toggleView(view){this.view = view},
      back(){this.activeProduct = null},
      imgError(e){comingSoon(e)},
      setActiveUom(activeUom){this.activeUom = activeUom}
    },
    computed:{
      hidePricing(){return this.$store.getters.hidePricing},
      caseQty(){return caseQuantity(this.activeProduct)},
      stockFlagInBranch(){return stockFlagInBranch(this.activeProduct)},
      invInBranch(){return invInBranch(this.activeProduct)}
    }
  }
</script>

<style scoped>
  .result{
    cursor:pointer;
  }
  .result:hover{
    background: lightgray;
  }
  .result-list{
    border: 1px solid lightgray;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
  }
  .qty{
    margin: 0 auto;
    width: 300px;
  }
  .inv-table{
    width: 300px;
    margin: 0 auto;
  }
  .clear-times{
    position: absolute;
    right: 60px;
    top: 1px;
    padding: 10px;
    cursor: pointer;
  }
  .avail{
    max-height: 30vh;
    overflow-y: scroll;
    border-left: 1px solid lightgray;
    border-bottom: 1px solid darkgray;
  }
</style>