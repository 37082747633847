<template>
  <div class="d-block d-lg-none mb-3">
    <div class="container">
      <div class="row mt-1">
        <div
          class="col-12"
          v-for="l in links"
          :key="l.title"
          @click="doAction(l.action)">
          <div
            v-if="l.visible"
            class="mobile-nav shadow-sm mb-1 p-2 position-relative"
            :data-bs-toggle="l.action == 'crossSell' ? 'modal' : ''"
            :data-bs-target="l.action == 'crossSell' ? '#crossSellModal' : ''">
            <strong>
              <i :class="l.icon"/>
              {{l.title}}
            </strong>
            <br>
            <small class="text-muted">{{l.text}}</small>
            <i class="fa fa-chevron-right text-muted chevron"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { companyName } from '../../../../shared/env'
  import {internalRoles} from '../../../../shared/roles'
  
  export default {
    inject: ['env'],
    data(){
      return{
        crossSellCompany: this.env.includes('EJW') ? 'a Lanacster' : 'an EJ Welch',
        crossSellSubText: this.env.includes('EJW') ? 'Sign up to access all our paint sundry brands' : 'Sign up to access all our flooring brands'
      }
    },
    methods:{
      doAction(action){
        if(action == 'crossSell') return
        switch(action){
          case 'logOut':
            this.$store.dispatch('eclipseLogOut')
            break
          case 'employeeSite':
            window.open('https://employees.lancasterco.com', '_blank').focus();
            break
          case 'ReorderPad':
            this.$router.push({name: 'ProductSearch', query:{action: 'reorderPad'}})
            break
          case 'NewProducts':
            this.$router.push({name: 'ProductSearch', query:{action: 'new'}})
            break
          case 'Closeouts':
            this.$router.push({name: 'ProductSearch', query:{action: 'clearance'}})
            break
          default:
            this.$router.push({name: action})
        }
      }
    },
    computed:{
      settings(){return this.$store.getters.settings},
      promoReceive(){return this.$store.getters.promoReceive},
      vShowAllowed(){return this.$store.getters.vShowAllowed},
      vShowData(){
        if(this.vShowAllowed){
          return this.$store.getters.vShowDates
        }
        return null
      },
      user(){return this.$store.getters.user},
      company(){return this.$store.getters.sessionCompany},
      links(){
        return [
          {title: this.vShowData ? this.vShowData.title : 'Virtual Show', text: 'Access our online show from anywhere', icon: 'fa fa-laptop', action: 'VirtualShow', visible: this.vShowAllowed},
          {title: 'My Account', text: 'View my account', icon: 'fa fa-user', action: 'MyAccount', visible: this.settings.myAccount},
          {title: 'Browse Products', text: 'Shop by Category', icon: 'fa fa-book-open', action: 'BrowseProducts', visible: this.settings.shopByCategory},
          {title: 'Browse Brands', text: 'Shop by Brand', icon: 'fa fa-book-open', action: 'BrowseBrands', visible: this.settings.shopByBrand},
          {title: 'New Products', text: 'See whats new', icon: 'fa fa-certificate', action: 'NewProducts', visible: this.settings.newProducts},
          {title: 'Clearance', text: 'Take advantage of low cost items', icon: 'fa fa-hand-holding-usd', action: 'Closeouts', visible: this.settings.closeoutDeals},
          {title: 'Quick Pad', text: 'Quickly search and build an order', icon: 'fa fa-file-signature', action: 'QuickPad', visible: this.settings.orderingTools},
          {title: 'Reorder Pad', text: 'Browse items you have ordered in the past', icon: 'fa fa-sync-alt', action: 'ReorderPad', visible: this.settings.orderingTools},
          {title: 'Favorites', text: 'Your favorite items', icon: 'fa fa-heart', action: 'Favorites', visible: this.settings.orderingTools && !internalRoles.includes(this.user.role)},
          {title: 'Promotions', text: 'Browse products on sale', icon: 'fa fa-search-dollar', action: 'Promos', visible: this.settings.promotions && this.promoReceive ? true : false},
          {title: 'Events', text: 'Our special events', icon: 'fa fa-calendar-day', action: 'PrivateEvents', visible: this.settings.events},
          {title: 'Manage Carts', text: 'Manage multiple shopping carts', icon: 'fa fa-shopping-cart', action: 'ManageCarts', visible: this.settings.manageCarts},
          {title: 'Catalogs', text: 'Browse Catalogs', icon: 'fa fa-book', action: 'Catalogs', visible: this.settings.catalogs},
          {title: 'Magazines', text: 'Browse Magazines', icon: 'fa fa-book', action: 'Magazines', visible: this.settings.magazines},
          {title: 'Library', text: 'Browse Library', icon: 'fa fa-book', action: 'Library', visible: this.settings.library},
          {title: 'Price Lists', text: 'Email Price Lists', icon: 'fa fa-file-alt', action: 'PriceLists', visible: this.settings.priceLists},
          {title: `Become ${this.crossSellCompany} Customer`, text: this.crossSellSubText, icon: 'fa fa-user-plus', action: 'crossSell', visible: this.company != 'Lancaster Canada'},
          {title: 'Settings', text: 'General options & settings', icon: 'fa fa-cog', action: 'Settings', visible: true},
          {title: `Contact ${companyName(this.env)}`, text: 'Get in touch with us', icon: 'fa fa-address-book', action: 'PrivateContact', visible: true},
          {title: 'Policies & Procedures', text: 'Access the rule book', icon: 'fa fa-gavel', action: 'Policies', visible: true},
          {title: 'Privacy Policy', text: 'Keeping you data safe', icon: 'fa fa-user-secret', action: 'PrivatePrivacyPolicy', visible: true},
          {title: 'Latest Updates', text: 'Learn about whats changing', icon: 'fa fa-bug', action: 'PatchNotes', visible: true},
          {title: 'Help & FAQ', text: 'Knowledge base for our eStore', icon: 'fa fa-question-circle', action: 'Faq', visible: true},
          {title: 'Employee Site', text: 'Access the employee site now', icon: 'fa fa-user', action: 'employeeSite', visible: internalRoles.includes(this.user.role)},
          {title: 'Log Out', text: 'See you next time', icon: 'fa fa-sign-out-alt', action: 'logOut', visible: true}
        ]
      }
    }
  }
</script>

<style scoped>
  .mobile-nav{
    line-height:20px;
    border: 1px solid lightgray;
    border-radius: 10px;
  }
  .mobile-nav:hover{
    cursor: pointer;
  }
  .chevron{
    position: absolute;
    right: 15px;
    top: 20px;
  }
  strong{
    font-size:16px;
    color: var(--bs-primary);
  }
</style>