import axios from 'axios'
import router from '../../../router'
import {internalRoles} from '../../../shared/roles'
import logout from '../../../shared/logout'

const initialState = {
  customerData: null,
  lastCustomer: null,
  lastRep: null,
  lastRoute: null,
  loading: false,
  error: null
}

export const tmStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setCustomerData(state, data){
      state.customerData = data
    },
    setCustomer(state, data){
      this.state.entityStore.entity = data.entity
      this.state.entityStore.activeShipTo = data.shipTo
      this.state.entityStore.priceTypes = data.priceTypes
      this.state.entityStore.customerData = data.customerData
    },
    clearTmCustomerList(state){
      state.customerList = null
    },
    setLastCustomer(state, customer_id){
      state.lastCustomer = customer_id
      state.lastRep = this.state.sessionStore.username
    },
    setLastRoute(state, from){
      state.lastRoute = from
    },
    setTmLoading(state, status){
      state.loading = status
    },
    setError(state, error){
      state.error = error
    }
  },
  actions:{
    tmResetState(context){
      context.commit('resetState')
    },
    getTmCustomers(context, tm = null){
      context.commit('setError', null)
      context.commit('setTmLoading', true)
      let params = {}
      if(tm){
        params.tm = tm.eclipse_user_id
      }
      axios.get('/api/v1/private/tm/get_tm_customers', {params}).then(res =>{
        if(res.data.error && res.data.error == 'No User'){
          logout()
        }else{
          context.commit('setCustomerData', res.data.customer_data)
        }
        context.commit('setTmLoading', false)
      })
    },
    selectCustomer(context, data){
      context.commit('setError', null)      
      const loginCall = data.loginCall
      axios.post('/api/v1/private/tm/select_customer', {id: data.customer_id}).then(res => {
        if(res.data.status == 'ok'){
          let data = {
            entity: res.data.entity,
            shipTo: res.data.ship_to,
            priceTypes: res.data.price_types,
            customerData: res.data.customer_data
          }
          context.commit('setCustomer', data)
          this.dispatch('setLastCustomer', data.shipTo.entity_id)
          if(loginCall && this.state.tmStore.lastRoute){
            router.push({path: this.state.tmStore.lastRoute})
          }
          const content = document.getElementById('content')
          content.scrollTo(0,0)
          this.dispatch('getCategories')
          this.dispatch('getCart')
          this.dispatch('vShowClear')
          if(this.state.sessionStore.settings.virtualShow){
            this.dispatch('getVirtualShowStatus')
          }
        }else{
          context.commit('setError', res.data.error)
        }
      })
    },
    setLastCustomer(context, customer_id){
      context.commit('setLastCustomer', parseInt(customer_id))
    },
    clearTmCustomerList(context){
      context.commit('clearTmCustomerList')
    },
    lastRoute(context, from){
      if(internalRoles.includes(this.state.sessionStore.role)){
        context.commit('setLastRoute', from)
      }
    },
    managerCustomerSearch(context, query){
      context.commit('setError', null)
      context.commit('setTmLoading', true)
      let params = {query}
      axios.get('/api/v1/private/tm/manager_customer_search', {params}).then(res => {
        context.commit('setCustomerData', res.data.customer_data)
        context.commit('setTmLoading', false)
      })
    }
  },
  getters:{
    customerData: state => {return state.customerData},
    tmLoading: state => {return state.loading},
    tmError: state => {return state.error}
  }
}