<template>
  <div class="row mt-2">
    <!-- Header -->
    <div class="col-md-12">
      <h2>Downloads</h2>
    </div>
    <!-- Loading Spinner -->
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
    <div
      v-if="downloads && downloads.length && !loading"
      class="col-md-12">
      <!-- Downloads -->
      <table class="table table-striped downloads mt-2">
        <tbody>
          <tr
            v-for="d in downloads"
            :key="d.id">
            <td style="text-align:right">
              <a
                :href="d.pdf"
                class="btn btn-outline-primary btn-sm"
                target="_blank">
                <i class="fa fa-download"/>
                Download
              </a>
            </td>
            <td class="text-left">{{d.title}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="downloads && !downloads.length && !loading"
      class="col-md-12 text-center">
      <h3 class="mt-5 text-muted">No Downloads</h3>
    </div>
    <!-- <small class="text-muted text-center mt-3">*The Show Book reflects spiffs which are only available at the in person event.</small> -->
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../../../global/Spinner.vue'

  export default {
    data(){
      return{
        downloads: null,
        loading: false
      }
    },
    components:{
      Spinner
    },
    mounted(){
      this.getDownloads()
    },
    methods:{
      getDownloads(){
        this.loading = true
        axios.get('/api/v1/private/virtual_show/get_downloads').then(res=>{
          this.downloads = res.data.downloads
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped>
  .downloads{
    max-width: 800px;
    margin: 0 auto;
  }
</style>