<template>
  <div class="container-fluid">
    <div class="row">
      <div
        v-if="!showForm"
        class="col-md-12">
        <div class="px-4 py-2">
          <div class="float-start">
            <h3 class="mt-3">Favorites</h3>
            <div class="text-muted">Group and manage your favorite items all in one place!  Press the <i class="fa fa-heart text-danger"/> to add an item to one of your groups. </div>
          </div>
          <button
            class="float-end btn btn-primary btn-sm create-btn animate__animated animate__fadeInRight animate__faster"
            @click="toggleShowForm">
            <i class="fa fa-plus"></i>
            &nbsp;Create Favorite Group
          </button>
          <div class="clearfix"/>
        </div>
        <hr/>
      </div>
    </div>
    <div class="container-fluid">
      <!-- Favorite Groups Listing-->
      <div
        v-if="!showForm"
        class="row">
        <div class="col-lg-3 col-md-6 col-12 animate__animated animate__fadeIn animate__faster"
          v-for="f in favorites"
          :key="f.id">
          <FavCard :fav="f"/>
        </div>
        <div
          v-if="favorites.length == 0"
          class="text-center col-md-12 text-muted mt-3">
          <h3>Currently no favorite groups, click create favorite group button to get started</h3>
        </div>
      </div>
      <!-- Create group form -->
      <FavForm
        :showForm="showForm"
        @toggleFavForm="toggleShowForm"/>
    </div>
  </div>
</template>

<script>
  import FavForm from './FavForm.vue'
  import FavCard from './FavCard.vue'

  export default{
    data(){
      return{
        showForm: false
      }
    },
    components:{
      FavForm,
      FavCard
    },
    methods:{
      toggleShowForm(){
        this.showForm = !this.showForm
      },
      refresh(){
        this.$store.dispatch('getFavorites')
      }
    },
    created(){
      this.refresh()
    },
    computed:{
      favorites(){return _.orderBy(this.$store.getters.favorites, o => o.name.toLowerCase(), 'asc')},
      activeFav(){return this.$store.getters.getActiveFav}
    }
  }
</script>

<style scoped>
  .content{
    min-height:75vh;
  }
  .fav-header{
    height: 85px;
    background: linear-gradient(45deg, #032535 50%, #021e2b 50%, #032535 100%);
    color: white;
  }
  .create-btn{
    position: relative;
    top: 15px;
  }
</style>