import axios from 'axios'
import Qs from 'qs'

const initialState = {
  list:[],
  searchResults: {
    errors: null,
    filter_data: null,
    pagination: null,
    products: null
  },
  searching: false,
  generating: false,
  sessionID: null
}

export const binlabelStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    addToBinLabelList(state, product){
      let base = _.map(state.list, l => l.product_id)
      if(!_.includes(base, product.product_id)){
        state.list.push(product)
      }
    },
    clearBinLabelList(state){
      state.list = []
    },
    removeFromBinLabelList(state, id){
      state.list = _.reject(state.list, l => l.product_id == id)
    },
    setSearchResults(state, data){
      state.searchResults.errors = data.errors
      state.searchResults.filter_data = data.filter_data
      state.searchResults.pagination = data.pagination
      state.searchResults.products = data.products
    },
    clearSearch(state){
      state.searchResults = {
        errors: null,
        filter_data: null,
        pagination: null,
        products: []
      }
    },
    setSearchStatus(state, status){
      state.searching = status
    },
    generate(state){
      state.generating = true
    },
    setBinLabelSession(state, sessionID){
      state.sessionID = sessionID
    },
    masterReset(state){
      state.list = []
      state.searchResults = {
        errors: null,
        filter_data: null,
        pagination: null,
        products: null
      }
      state.searching = false
      state.generating = false
      state.sessionID = null
    }
  },
  actions:{
    binLabelResetState(context){
      context.commit('resetState')
    },
    addToBinLabelList(context, product){
      context.commit('addToBinLabelList', product)
    },
    clearBinLabelList(context){
      context.commit('clearBinLabelList')
    },
    removeFromBinLabelList(context, id){
      context.commit('removeFromBinLabelList', id)
    },
    binLabelSearch(context, params){
      context.commit('setSearchStatus', true)
      axios.get('/api/v1/private/product/search', {params, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})}).then(res => {
        context.commit('setSearchResults', res.data.results)
        context.commit('setSearchStatus', false)
      })
    },
    clearBinLabelSearchResults(context){
      context.commit('clearSearch')
    },
    generateLabels(context){
      context.commit('generate')
    },
    getBinLabels(context){
      let products = _.map(this.state.binlabelStore.list, l => l.product_id)
      let params = {products}
      axios.get('/api/v1/private/bin_labels/get_bin_labels', {params}).then(res =>{
        if(res.data.sessionID != null){
          // open in new window for dev only
          // window.open(encodeURI('http://binlabels.lancasterco.com?SessionId=' + res.data.sessionID), '_blank').focus()
          context.commit('setBinLabelSession', res.data.sessionID)
        }else{
          context.commit('setBinLabelSession', null)
        }
      })
    },
    masterReset(context){
      context.commit('masterReset')
    }
  },
  getters:{
    binLabelList: state => {return state.list},
    binLabelSearchResults: state => {return state.searchResults},
    binLabelSearchState: state => {return state.searching},
    generateStatus: state => {return state.generating},
    binLabelSessionID: state => {return state.sessionID}
  }
}