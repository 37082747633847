<template>
  <div>
    <!-- Indicator for when magazine zip files are uploading -->
    <div
      v-if="uploading"
      class="row p-2">
      <div class="col-md-12 terxt-center">
        Uploading...
        <Spinner
          color="white"
          style="height:20px; width:20px;"/>
      </div>
    </div>
    <Main
      :data="data"
      :view="view"/>
  </div>
</template>

<script>
  import Main  from './components/Main.vue'
  import Spinner from '../.././global/Spinner.vue'
  
  export default {
    props: ['view'],
    created(){
      this.$store.dispatch('removeActiveRecord')
      this.$store.dispatch('setAdminUploadStatus', false)
    },
    components:{
      Main,
      Spinner
    },
    computed:{
      data(){return this.$store.getters.adminViewData},
      uploading(){return this.$store.getters.adminUploadStatus}
    }
  }
</script>