<template>
  <div class="m-3 cat-zoom px-1">
    <!-- Category Card -->
    <div
      class="my-2 p-2 text-center animate__animated animate__fadeIn animate__faster"
      @click="browseMain(category.main)">
      <!-- Category -->
      <div>
        <img  
          :src="category.image_url ? category.image_url : placeholder"
          class="img-fluid"
          style="height:180px;">
      </div>
      <div>
        <strong
          style="font-size:18px;">
          {{category.main}}
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:['category', 'id'],
    data(){
      return{
        placeholder: require('../../../../../assets/images/coming_soon.png')
      }
    },
    methods:{
      browseMain(main){
        this.$router.push({
          name: 'ProductSearch',
          query: {
            action: 'browseMain',
            category: main
          }
        })
      }
    }
  }
</script>

<style scoped>
  .cat-zoom{
    color: var(--bs-link-color);
    font-weight:300;
  }
  .cat-zoom:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: .15s ease-in-out;
    font-weight: 400;
    color:var(--bs-link-hover-color);
  }
</style>