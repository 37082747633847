<template>
  <div>
    <!-- Header -->
    <div class="bg-primary" style="height:4em;">
      <div
        class="p-2"
        style="width:100%;">
        <h5 class="float-start text-light mt-3">
          <i class="fa fa-shopping-cart"/>
          {{cartName}}
        </h5>
      </div>
      <div class="clearfix"/>
    </div>
    <!-- Body -->
    <div class="bg-white cart-body cart-body-height position-relative">
      <div class="row">
        <div class="col-md-12 mt-2">
          <!-- No Products -->
          <div
            v-if="cart && cart.cartProducts.length == 0"
            class="text-center text-muted mt-5">
            <h4>
              No Products
            </h4>
          </div>
          <!-- Products -->
           <template v-if="cart && cart.cartProducts.length">
             <div
               v-for="p in cartProducts"
               style="border-bottom: 1px solid gray;"
               :key="p">
               <CartRow
                :cartProduct="p"
                :loading="loading"/>
             </div>
           </template>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="cartSummary">
      <div class="px-2">
        <div
          class="p-2"
          style="width:100%;">
          <h5>
            <span class="float-start">
              Total:
            </span>
            <span class="float-end">
            {{numeral(cartTotal).format('$0,0.00')}}
            </span>
          </h5>
        </div>
        <div class="clearfix"/>
      </div>
      <div>
        <div class="row p-2">
          <div class="col-12">
            <div class="d-grid">
              <button
                class="btn btn-primary"
                style="font-size:14px;"
                @click="goto('Cart')">
                <i class="fa fa-shopping-cart"></i>
                Edit & Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue';
  import CartRow from './CartRow.vue'

  export default {
    components:{
      CartRow,
      Spinner
    },
    methods:{
      goto(view){
        this.$router.push({name: view})
        this.$store.dispatch('closeRightMenu')
      }
    },
    computed:{
      cart(){return this.$store.getters.cart},
      loading(){return this.$store.getters.cartLoading},
      cartProducts(){
        return _.sortBy(this.$store.getters.cartProducts, cp => cp.id)
      },
      cartName(){
        return this.cart?.details.cart_name
      },
      cartTotal(){return this.$store.getters.cartTotal}
    }
  }
</script>

<style scoped>
 .cart-body{
  overflow-x:hidden;
  overflow-y: scroll;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid lightgray;
  padding: 10px;
  }
  .cart-body-height{
    height:80vh;
  }
  @media(max-width: 575px) {
    .cart-body-height{
      height:75vh;
    }
  }
  .cartSummary{
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
</style>