<template>
  <div>
    <!-- Header -->
    <div class="row">
      <div class="col-md-12">
        <strong>Cart Details</strong>
        <button
          class="ms-1 btn btn-sm btn-link text-muted float-end"
          @click="detailToggle">
          Show Summary
          <i class="fa fa-caret-up"/>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="float-end">
          <strong>
            Cart Toal: {{ numeral(cartTotal).format('$0,0.00') }}
          </strong>
        </div>
      </div>
      <div
        v-if="productCount == 0"
        class="col-md-12 text-center my-5">
        <h4 class="text-muted">No Products</h4>
      </div>
    </div>
    <!-- Products -->
    <table
      v-if="productCount > 0"
      class="table table-sm table-striped">
      <tbody>
        <template
          v-for="cp in cart.cartProducts"
          :key="cp.eclipse_id">
          <tr>
            <td class="row p-2">
              <div class="col-lg-2 col-md-12 col-12 text-center">
                <img
                  :src="cp.product_data.image_url"
                  class="img-fluid"
                  style="max-width: 75px; max-height: 75px;">
              </div>
              <div class="col-lg-10 col-md-12 col-12">
                <div>
                  {{ cp.product_data.description }}
                </div>
                <div class="row mt-2">
                  <div class="col-md-4 col-4">
                    Qty: {{ cp.quantity }} {{ displayUom(cp.uom) }}
                  </div>
                  <div class="col-md-4 col-4">
                    Unit Price: {{ numeral(cp.pricing.unit_price).format('$0,0.00') }}
                  </div>
                  <div class="col-md-4 col-4">
                    Ext Price: {{ numeral(cp.pricing.unit_price).format('$0,0.00') }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { displayUom } from '../../../../shared/ProductFunctions'

  export default{
    props: ['cart'],
    methods:{
      detailToggle(){this.$emit('detailToggle')},
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      cartTotal(){return _.sum(_.map(this.cart.cartProducts, cp => cp.pricing.extended_price))},
      productCount(){return this.cart.cartProducts.length}
    }
  }
</script>