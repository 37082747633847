<template>
  <div>
    <div
      id="login"
      v-if="!showForgotPassword"
      class="animate__animated animate__fadeIn animate__fast">
      <div
        v-if="error"
        class="bg-light text-danger text-center p-2"
        style="font-size:14px;">
        {{error}}
      </div>
      <form
        name="lanLogin"
        @submit.prevent="submit"
        class="position-relative">
        <input
          name="username"
          class="form-control d-inline-block"
          style="width: 100%;"
          placeholder="Username"
          v-model="form.username"
          autocapitalize="off"
          autocomplete="off"
          spellcheck="false"
          type="text"
          required>
        <input
          name="password"
          class="form-control d-inline-block mb-4"
          style="width: 100%;"
          placeholder="Password"
          v-model="form.password"
          :type="showPassword ? 'text' : 'password'"
          required>
        <!-- Forgot Password -->
        <a
          class="text-light forgot-password"
          href="javascript: void(0);"
          @click="toggleForgotPassword">
          Forgot Password?
        </a>
        <!-- Toggle visible password -->
        <a
          class="text-light show-password"
          href="javascript: void(0);"
          @click="togglePassword">
          {{showPassword ? 'Hide' : 'Show'}} Password
        </a>
        <div class="d-block">
          <button
            class="btn btn-outline-light w-100"
            :disabled="submitting">
            <span v-if="!submitting">
              <i class="fa fa-sign-in-alt"/>
              Login
            </span>
            <span v-else>
              <i class="fa fa-spinner fa-spin"/>
            </span>
          </button>
        </div>
      </form>
    </div>
    <ForgotPassword
      v-if="showForgotPassword"
      @cancel="toggleForgotPassword"/>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import ForgotPassword from './ForgotPassword.vue'

  export default {
    data(){
      return{
        form: {
          username: '',
          password: ''
        },
        submitting: false,
        showPassword: false,
        showForgotPassword: false
      }
    },
    components:{
      Spinner,
      ForgotPassword
    },
    watch:{
      attempts(newVal, oldVal){
        if(newVal != oldVal){
          this.submitting = false
        }
      }
    },
    methods:{
      submit(){
        this.submitting = true
        this.$store.dispatch('eclipseLogIn', this.form)
      },
      submitForgot(){
        this.$emit('forgotPassword')
      },
      togglePassword(){
        this.showPassword = !this.showPassword
      },
      toggleForgotPassword(){
        this.showForgotPassword = !this.showForgotPassword
      }
    },
    mounted(){
      this.$store.dispatch('clearLoginErrors')
    },
    computed:{
      error(){return this.$store.getters.loginError},
      attempts(){return this.$store.getters.loginAttempts}
    }
  }
</script>

<style scoped>
  input{
    background: rgb(255,255,255,0.3) !important;
    color: white;
  }
  input::placeholder{
    color: white;
  }
  input:focus{
    color:white;
  }
  .show-password{
    font-size: 14px;
    position: absolute;
    top: 120px;
    right: 3px;
  }
  .forgot-password{
    font-size: 14px;
    position: absolute;
    top: 120px;
    left: 3px;
  }
  .forgot-password-main{
    font-size:14px;
  }
</style>