<template>
  <div v-show="show">
    <div 
      v-if="pagination && pagination.currentPage < pagination.totalPages"
      ref="showMore">
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  
  export default {
    props: ['favorites', 'index', 'reorderPad', 'productLength'],
    data(){
      return{
        observed: false
      }
    },
    components:{
      Spinner
    },
    mounted(){
      if(this.$refs.showMore){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs.showMore)
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    methods:{
      showMore(){
        this.observed = true
        if(this.favorites){
          this.$store.dispatch('favShowMore', this.pagination.nextPage)
        }else if(this.reorderPad){
          let params = {
            area: 'reorderPad',
            page: this.pagination.nextPage
          }
          this.$store.dispatch('getReorderPadProducts', params)
        }else{
          this.$store.dispatch('showMore', this.pagination.nextPage)
        }
      },
      handleIntersection(entries, observer){
        for (let entry of entries) {
          if (entry.isIntersecting && !this.observed){
            this.showMore()
          }
        }
      }
    },
    computed:{
      pagination(){
        if(this.favorites){
          return this.$store.getters.favoritesPagination
        }else if(this.reorderPad){
          return this.$store.getters.reorderPadResults.pagination
        }else{
          return this.$store.getters.productResults.pagination
        }
      },
      loading(){
        if(this.favorites){
          return this.$store.getters.favShowMoreLoadState
        }else if(this.reorderPad){
          return this.$store.getters.reorderShowMoreLoadStatus
        }else{
          return this.$store.getters.showMoreLoadState
        }
      },
      show(){
        // Auto load load next page if results set is not a clean set of 24
        // OR if it is load the next result set when 11 back from the end card is
        // visible which is typically the second last row of the result set on a 
        const position = this.index + 1
        return (this.productLength % 24 != 0) && position == this.productLength || position % (this.productLength - 11) == 0
      }
    }
  }
</script>