<template>
  <div v-if="cart.details.vshow_cart">
    <div
      class="alert alert-success text-center mt-2">
      <i class="fa fa-laptop"/>
      <div>{{vShowData ?  vShowData.title : 'Virtual Show'}} Order - Discounts Applied</div>
      <small>*Dropships are subject to each vendors prepaid freight and minimum order policies.</small>
      <br>
    </div>
    <!-- <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
        v-model="form.show_attend"
        style="cursor: pointer;">
      <label
        class="form-check-label"
        for="flexSwitchCheckDefault">
        I will be attending the FL show:
      </label>
    </div> -->
  </div>
</template>

<script>
  export default {
    props: ['cart', 'form'],
    computed:{
      company(){return this.$store.getters.sessionCompany},
      entity(){return this.$store.getters.getEntity.entity},
      min(){
        if(this.entity){
          return this.entity.branch_id == '14' ? 850 : 1000.00
        }
      },
      vShowAllowed(){return this.$store.getters.vShowAllowed},
      vShowData(){
        if(this.vShowAllowed){
          return this.$store.getters.vShowDates
        }
        return null
      },
    }
  }
</script>

<style scoped>
  .info{
    cursor: pointer;
  }
</style>