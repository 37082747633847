<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Country Select -->
      <div class="col-md-12 text-center">
				<div class="mt-5 pt-5">
					<h4 class="mb-4">Select Country</h4>
					<select
						v-model="country"
						class="form-select">
						<option value="">Select Country</option>
						<option
							v-for="(c,i) in countries"
							:key="i"	
							:value="c.country">
							{{ c.country }}
						</option>
					</select>
				</div>
      </div>
      <!-- State Selector -->
      <div
        v-if="country && country == 'United States of America'"
        class="col-md-12 text-center mt-3 animate__animated animate__fadeIn">
        <h4 class="mb-4">Select State</h4>
        <select
            class="form-select state-select"
						v-model="activeState">
					<option value="">Select State</option>
          <option
            v-for="s in states"
            :key="s.state"
            :value="s">
						{{ s.state }}
					</option>
        </select>
        <div
					v-if="activeState"
					class="btn-group animate__animated animate__fadeIn animate__faster">
					<button
						class="btn btn-primary btn-lg mt-4"
						@click="setState(s)">Confirm</button>
					<button
						class="btn btn-primary btn-lg mt-4"
						@click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default{
    created(){
      this.getCountryData()
    },
    data(){
      return{
        country: '',
        state: null,
				activeState: '',
				countryData: null,
        branch: null
      }
    },
		watch:{
			country(newVal){
				if(newVal && newVal != 'United States of America') this.submit()
			}
		},
    methods:{
      getCountryData(){
        axios.get('/api/v1/public/country/get_countries').then(res=>{
          this.countryData = res.data.countries
        })
      },
			submit(){
				this.$emit('countrySelect', {country: this.country, state: this.state, branch: this.branch})
			},
      setCountry(country){
        this.country = country
				if(['Canada', 'Other'].includes(this.country)) this.submit()
      },
			setState(){
				this.state = this.activeState.state
        this.branch = this.activeState.branch
				this.submit()
			},
			cancel(){
				this.country = null
				this.state = null
			}
    },
    computed:{
      countries(){
        let data = _.map(this.countryData, c =>{
          return {country: c.country, branch: c.country == 'United States of America' ? null : c.branch}
        })
        return _.uniqBy(data, 'country')
      },
      states(){
        const states = _.map(_.filter(this.countryData, s=> s.state.length > 0), st=>{
          return {state: st.state, branch: st.branch}
        })
        return states
      }
    }
  }
</script>

<style scoped>
    select{
        max-width: 25dvw;
        margin: 0 auto;
				min-width: 300px;
				cursor: pointer;
    }
    option{
        cursor: pointer;
    }
</style>