<template>
  <div class="main">
    <div
      v-if="showForm && !loading"
      class="row animate__animated animate__fadeIn">
      <div class="col-md-12">
        <table class="table table-sm login-table">
          <tr>
            <td>
              <div align="center">
                <h3 class="text-light">Order Legacy</h3>
                <form
                  method="post"
                  name="login"
                  action="https://teserv.lancasterco.com/eserv/eclipse.ecl">
                  <input
                    type="HIDDEN"
                    name="PROCID"
                    value="WEBPROC.WOE.AUTH">
                  <input
                    type="HIDDEN"
                    name="TRACKNO"
                    value="0">
                  <input
                    type="HIDDEN"
                    name="SEARCHSTR"
                    value="">
                  <input
                    type="HIDDEN"
                    name="FX"
                    value="">
                  <input
                    type="HIDDEN"
                    name="VER"
                    value="B">
                  <input
                    type="HIDDEN"
                    name="SITE.AUTH"
                    value="1">
                  <table>
                    <tr>
                      <td>  
                        <table>
                          <tr>
                            <td>
                              <input
                                type="text"
                                name="D1"
                                size="14"
                                value=""
                                placeholder="Username"
                                required
                                class="form-control">
                           </td>
                          </tr>
                          <tr>
                              <td>
                                <input
                                  type="Password"
                                  name="D2"
                                  size="14"
                                  value=""
                                  placeholder="Password"
                                  required
                                  class="form-control">
                              </td>
                          </tr>
                          <tr>
                            <td>
                              <!--
                                Forgot password logic
                                <a class="header" href="/eserv/eclipse.ecl?PROCID=WEBDISP.WOE.LOST.PSWD&VER=B&WEB.COMPANY=&WEB.COMPANY&">Forgot your ID or password? Click here.</a><br><br><br><br>
                              -->
                              <button
                                class="btn btn-outline-light my-2 me-1"
                                type="submit" value="SEND">
                                <i class="fa fa-paper-plane"/>
                                Login
                              </button>
                              <button
                                class="btn btn-outline-light my-2 ms-1"
                                type="reset"  value="CLEAR">
                                <i class="fa fa-sync"/>
                                Reset
                              </button>
                            </td>
                          </tr>
                        </table>
                      </td>
                  </tr>
                </table>
              </form>
              </div>
            </td>
          </tr>
        </table>                    
      </div>
    </div>
    <div v-if="!showForm && !loading">
      <div class="row login-table">
        <div class="col-md-12 text-center">
          <h3 class="text-light">Currently Unavailable</h3>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="row login-table">
      <div class="col-md-12 mt-5 text-center">
        <Spinner class="text-light"/>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../global/Spinner.vue'

  export default {
    mounted(){
      this.getSetting()
    },
    data(){
      return{
        showForm: false,
        loading: true
      }
    },
    components:{
      Spinner
    },
    methods:{
      getSetting(){
        axios.get('/api/v1/public/order_legacy/get_setting').then(res => {
          this.showForm = res.data.order_legacy_active
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped>
  .login-table{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
  .main{
    background: #BBD2C5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #243746, #536976, #BBD2C5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #243746, #536976, #BBD2C5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
</style>