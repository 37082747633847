<template>
  <div>
    <!-- Carousel -->
    <div
      id="sliders"
      class="carousel slide"
      data-bs-ride="carousel">
      <ol class="carousel-indicators">
        <li
          v-for="(s, index) in slides"
          :key="s.id"
          data-bs-target="#sliders"
          :data-bs-slide-to="index"
          :class="{active: index == 0}"/>
      </ol>
      <div
        class="carousel-inner"
        role="listbox">
        <div
          v-for="(s, index) in slides"
          :key="s.id"
          class="carousel-item"
          :class="{active: index == 0}">
          <img
            :src="s.image_url"
            class="carousel-img">
          <div
            v-if="s.text"
            class="carousel-caption d-none d-md-block">
            <span style="font-weight:700;">{{s.text}}</span>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#sliders"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#sliders"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data(){
      return{
        slides: null
      }
    },
    methods:{
      getSlides(){
        axios.get('/api/v1/public/slides/get_public_slides').then(res=>{
          this.slides = res.data.slides
        })
      }
    },
    mounted(){
      this.getSlides()
    }
  }
</script>

<style scoped>
  .carousel-img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 500px;
  }
  .carousel-indicators > li{
    height:10px;
    width: 10px;
    border-radius: 50%;
  }
  .carousel-caption{
    bottom: 3em;
    padding: .5em;
    background: rgb(255,255,255, 0.7);
    color: var(--bs-primary);
    border-radius: 3px;
  }
</style>