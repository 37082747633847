import numeral from 'numeral'
import env from '../env'

export const caseQuantity = (product) => {
  if(product.uom2 == 'cs') return `${numeral(product.uom2_value).format('0,0')}${product.uom1}`
}

export const palletQuantity = (product, virtualShow = null) => {
  if(product.uom3 == 'pl') return `${numeral(product.uom3_value).format('0,0')}${product.uom1}`
}

export const minQuantity = (product, activeUom = null, env = null) => {
  let match
  
  if(activeUom) match = _.find(product.eclipse_data.uoms, {name: activeUom.uom})

  let minUom = _.find(product.eclipse_data.uoms, {quantity: env.includes('EJW') ? product.eclipse_data.min_quantity : product.eclipse_data.per_qty})
  
  if(!minUom){
    minUom = {
      name: product.eclipse_data.uom,
      quantity: product.eclipse_data.min_quantity
    }
  }

  // Determine which uom text to display
  const displayName = ()=>{
    if(activeUom && match) return match.name
    if(minUom) return minUom.name
    return 'ea'
  }

  // Calc min qty
  const displayQty = ()=>{
    if(activeUom && match){
      let qty = ['cs', 'pl'].includes(activeUom.uom) ? 1 : (minUom.quantity / match.quantity)
      // Eclipse cannot accept fractional uoms so if the min is not a whole
      // number within the active uom we must default that active uom min value to 1
      if (qty >0 && qty <1) qty = 1
      return qty
    }
    if(minUom) return (product.eclipse_data.min_quantity / minUom.quantity)
    return 1
  }

  // Build Uom Object
  const uom = {
    name:  displayName(),
    quantity: displayQty()
  }
  
  return uom
}

// For Lancaster business detect if the product is nonstock and has no available inventory
export const nonStockOutOfStock = (env, product)=>{
  if(env == 'LAN' && product?.eclipse_data){
    return product.status_code == 2 && _.sum(_.map(product.eclipse_data.branches, b => parseInt(b.avail))) == 0
  }
  return false
}

// EJW & Cheney to display 'cs' as 'ctn' while still operating as 'cs'
export const displayUom = (uom) => {
  if(env.includes('EJW') && uom == 'cs') return 'ctn'
  return uom
}