<template>
  <div class="mx-1 dropdown">
    <!-- Drop Down Button -->
    <div class="d-grid">
      <button
        class="btn btn-primary btn-sm dropdown-toggle"
        type="button"
        id="brandsFilter"
        :class="mobile ? 'w-100' : ''"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        :disabled="loading">
        Brands
      </button>
      <div
        class="dropdown-menu p-2 wrapper"
        :class="{'w-100': fullWidthMenu}"
        id="brandFilterDropdown">
      <!-- Reset Brand Filters -->
        <div>
          <strong class="float-start pt-2">
            Select Brands
          </strong>
          <button
            class="btn btn-outline-primary btn-sm float-end p-1"
            @click="clear">
            <i class="fa fa-ban"/>
            Clear
          </button>
        </div>
        <div class="clearfix"/>
        <!-- Brands List -->
        <ul class="p-1 brand-list">
          <li
            v-for="m, index in mfgList"
            :key="m">
            <!-- CheckBoxes -->
            <div class="pretty p-default">
                <input
                  :id="m.name + 'checkbox'"
                  type="checkbox"
                  v-model="mfgList[index].checked"/>
                <div class="state p-primary">
                  <label
                    :for="m.name + 'checkbox'">
                    {{m.name}}
                  </label>
                </div>
            </div>
          </li>
        </ul>
        <!-- Submit -->
        <div class="d-grid">
          <button
            class="btn btn-primary btn-sm"
            @click="submit"
            data-bs-dismiss="offcanvas">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { buildBrands } from '../../../../../../repositories/filterActions';
  import hideDropdown from '../../../../../../shared/hideDropdown'

  export default {
    props: ['loading', 'filters', 'products', 'mobile', 'fullWidthMenu'],
    data(){
      return{
        mfgList: this.filters.options.brands
      }
    },
    methods:{
      submit(){
        hideDropdown('brandFilterDropdown')
        const brandsList = []
        this.mfgList.forEach(b =>{
          if(b.checked){
            brandsList.push(b)
          }
        })
        const data = {
          path: this.$router.currentRoute.value.path,
          action: 'brandFilter',
          brands: brandsList?.length ? _.map(brandsList, 'name') : null
        }
        this.$store.dispatch('filterProducts', data)
      },
      clear(){
        this.mfgList.forEach(m =>{
          m.checked = false
        })
        this.submit()
      }
    },
    watch:{
      categories(){
        if(this.filters.selected.initiator == 'categoryFilter'){
          this.mfgList = buildBrands(this.products)
        }else{
          this.mfgList = this.filters.options.brands
        }
      }
    },
    computed:{
      categories(){return this.filters.selected.categories}
    }
  }
</script>

<style scoped>
  .wrapper{
    width:250px;
    max-height:355px;
    position:relative;
  }
  .brand-list{
    list-style-type: none;
    overflow: hidden scroll;
    max-height: 245px;
    margin-top: 10px;
    border: 1px solid lightgray;
  }
</style>