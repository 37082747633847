<template>
  <div class="btn-group mx-1">
    <!-- Grid View -->
    <button
      class="btn btn-primary btn-sm"
      :class="{active: productView == 'grid'}"
      @click="setView('grid')"
      :disabled="loading">
      <i class="fa fa-th-large"/>
    </button>
    <!-- List View -->
    <button
      class="btn btn-primary btn-sm"
      :class="{active: productView == 'list'}"
      @click="setView('list')"
      :disabled="loading">
      <i class="fa fa-list"/>
    </button>
  </div>
</template>

<script>
  export default {
    props: ['loading'],
    methods:{
      setView(view){this.$store.dispatch('setProductView', view)},
    },
    computed:{
      productView(){return this.$store.getters.productView}
    }
  }
</script>