<template>
  <div class="my-5">
    <div class="row">
      <div class="col-md-12 text-center mb-4">
        <h3
          class="text-success"
          style="font-weight: 400;">
          THE LANCASTER ADVANTAGE
        </h3>
      </div>
      <div
        class="btn-group"
        style="margin: 0 auto; width:600px;">
        <button
          class="btn btn-link"
          :class="{'btn-active': view == 'dealer'}"
          @click="switchView('dealer')"
          style="width:200px; text-decoration: none;">
          DEALERS
        </button>
        <button
          class="btn btn-link"
          :class="{'btn-active': view == 'manufacturer'}"
          @click="switchView('manufacturer')"
          style="width:200px; text-decoration: none;">
          SUPPLIERS
        </button>
      </div>
      <!-- Dealer Content -->
      <div v-if="view == 'dealer'">
        <Dealer :view="view"/>
      </div>
      <!-- MFG Content -->
      <div v-else>
        <Mfg :view="view"/>
      </div>  
    </div>
  </div>
</template>

<script>
  import Dealer from './Dealer.vue'
  import Mfg from './Mfg.vue'

  export default {
    data(){
      return{
        view: 'dealer'
      }
    },
    components:{
      Dealer,
      Mfg
    },
    methods:{
      switchView(view){
        this.view = view
      }
    }
  }
</script>

<style scoped>
  .btn-active{
    border-bottom: 5px solid var(--bs-success)
  }
  .btn:focus{
    box-shadow: none;
  }
</style>