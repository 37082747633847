<template>
  <div
    class="row"
    style="height: 350px;">
    <div
      class="col-md-6 p-0 d-none d-md-block"
      :style="style">
    </div>
    <div class="col-md-6 bg-secondary text-white position-relative p-5">
      <div class="centered">
        <h2 style="font-weight: 400;">LANCASTER FILLS THE GAPS</h2>
        <p>We pride ourselves on being a single source for all your paint department needs. We stock all the key national and regional products needed to supply your customers and support your paint brand.</p>
        <button
          class="btn btn-large btn-outline-light"
          @click="gotoCatalog">
          View Our Product Catalog
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        style: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/brands/picking.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      }
    },
    methods:{
      gotoCatalog(){
        this.$router.push({path: '/product_catalog'})
      }
    }
  }
</script>

<style scoped>
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    transform: translateY(-50%);
    width: 85%;
  }
</style>