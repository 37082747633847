<template>
  <div class="row my-5">
    <div class="col-md-12">
      <ul
        class="nav nav-pills nav-fill"
        id="myTab"
        role="tablist"
        style="width:60%; margin: 0 auto;">
        <li class="nav-item" role="presentation">
          <button
            class="btn btn-outline-success dealer-btn active my-2"
            id="expertise-tab"
            data-bs-toggle="tab"
            data-bs-target="#expertise"
            type="button"
            role="tab">
            <strong>EXPERTISE & SERVICE</strong>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="btn btn-outline-success dealer-btn my-2"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#oneStop"
            type="button"
            role="tab">
            <strong>ONE-STOP-SHOPPING</strong>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="btn btn-outline-success dealer-btn my-2"
            id="messages-tab"
            data-bs-toggle="tab"
            data-bs-target="#industry"
            type="button"
            role="tab">
            <strong>SUNDRY SCENE</strong>
          </button>
        </li>
      </ul>
      <!-- Tabs -->
      <div
        class="tab-content my-5"
        style="width:60%; margin: 0 auto;">
        <div
          class="tab-pane active"
          id="expertise"
          role="tabpanel">
          <div class="row">
            <!-- Dealer Image 1 -->
            <div
              class="col-md-6 text-center position-relative mb-3"
              :style="bg1"/>
            <div class="col-md-6 px-4">
              <p>Since getting our start in 1953, we've learned what it takes to be successful. Our staff is factory trained and committed to being your one-stop solution source. Whether that be in-person, through our industry magazine or 24hour SDS, look to Lancaster first.</p>
            </div>
          </div>
        </div>
        <div
          class="tab-pane"
          id="oneStop"
          role="tabpanel">
          <div class="row">
            <!-- Dealer Image 2 -->
            <div
              class="col-md-6 text-center position-relative mb-3"
              :style="bg2"/>
            <div class="col-md-6 px-4">
              <p>Year round savings! Shop more than 13,000 products from 350 suppliers all on one order and in one place. Take advantage of numerous promotions available at any given time.</p>
            </div>
          </div>
        </div>
        <div
          class="tab-pane"
          id="industry"
          role="tabpanel">
          <div class="row">
            <!-- Dealer Image 3 -->
            <div
              class="col-md-6 text-center position-relative mb-3"
              :style="bg3"/>
            <div class="col-md-6 px-4">
              <p>The market is constantly changing and keeping up with it is tough when you have a business to run. Keep up-to- date with the market through Lancaster’s <i>Sundry Scene Magazine</i>, partnerships and email communication.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        bg1:{
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/dealer/dealerTab1.jpg')})`,
          ...this.bgProperties()
        },
         bg2:{
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/dealer/dealerTab2.jpg')})`,
          ...this.bgProperties()
        },
         bg3:{
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/dealer/dealerTab3.jpg')})`,
          ...this.bgProperties()
        }
      }
    },
    methods:{
      bgProperties(){
        return {
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '350px'
        }
      }
    }
  }
</script>

<style scoped>
  .nav-pills .nav-link.active{
    color: white;
    background: var(--bs-success);
  }
  .dealer-btn{
    min-width: 225px;
  }
</style>