<template>
  <div class="row">
    <div class="col-md-12">
      <a
        :href="company == 'Lancaster Canada' ? cheatSheetCAD : cheatSheetUS"
        class="btn btn-sm btn-primary float-end m-1"
        target="_blank">
        Download Cheat Sheet
        <i class="fa fa-download"/>
      </a>
      <a
        href="https://lancaster-video.s3.amazonaws.com/eStore_Customer_Video_Tour.mp4"
        class="btn btn-sm btn-primary float-end m-1"
        target="_blank">
        Take Video Tour
        <i class="fa fa-play"/>
      </a>
    </div>
    <!-- Question 1 -->
    <div class="col-md-12 my-3">
      <strong>Q:  Can I order products that are stocked outside of my warehouse?</strong>
      <div>As long as an item is allowed to be sold in your area, yes!  Shipping and handling charges will apply, and it will not count towards your minimum prepaid freight.  Our customer service team can provide you with a free freight estimate and can be reached by calling&nbsp;
        <a :href="'tel:' + contactBranch.phone">{{contactBranch.phone}}</a>
        or emailing&nbsp;
        <a :href="'mail_to:' + contactBranch.email">{{contactBranch.email}}</a>
      </div>
    </div>
    <!-- Question 2 -->
    <div class="col-md-12 my-3">
      <strong>Q:  I can’t find the product I’m searching for.  Is there another way to look up products?</strong>
      <div>Products can be found searching any number of things from the brand, description, size, color, item number, barcode, sheen, material, finish and more!  Look up past purchases in the Buy Again ordering tool and save items for later in the Favorites ordering tool.</div>
    </div>
    <!-- Question 3 -->
    <div class="col-md-12 my-3">
      <strong>Q:  Is there a quicker way to search products without typing in the barcode?</strong>
      <div>Items can be entered with the second half of UPC or product number.  For example, if the barcode is "070798181014” you could shorten it to "181014” or enter the product number of “18152.”  Try using the Quick Order ordering tool to quickly build a cart when you know exactly what you need. On mobile, you can scan barcodes to find items quickly. Click on the camera button to get started.</div>
    </div>
    <!-- Question 4 -->
    <div class="col-md-12 my-3">
      <strong>Q:  Can I special order items that you do not stock at any location?</strong>
      <div>Items can be ordered directly from our suppliers through Lancaster’s dropship program without the need to open yet another account.  Orders are subject to the supplier’s minimum order and freight policies.</div>
    </div>
    <!-- Question 5 -->
    <div class="col-md-12 my-3">
      <strong>Q:  Do you offer free shipping?</strong>
      <div>We do!  All orders above your minimum prepaid freight amount, will not be charged for shipping or handling.  Only items that in stock at your distribution center will count towards your minimum.  Please check the policies and procedures page for the most up-to-date terms.  Note that promotional periods and drop ships may have their own terms outside of the normal freight policy.</div>
    </div>
    <!-- Question 6 -->
    <div class="col-md-12 my-3">
      <strong>Q: What do I do if my shipment was damaged in transit?</strong>
      <div>Please report any shipping related issues within 48 hours of receiving your order.  If you are able to, please take photos of any issues you discover.</div>
    </div>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        cheatSheetUS: 'https://merit-production.s3.amazonaws.com/lanEstore/pdf/eStore_Customer_Cheat_Sheet_USA.pdf',
        cheatSheetCAD: 'https://merit-production.s3.amazonaws.com/lanEstore/pdf/eStore_Customer_Cheat_Sheet_Canadian.pdf'
      }
    },
    computed:{
      company(){return this.$store.getters.sessionCompany},
      contactBranch(){return this.$store.getters.contactBranch}
    }
  }
</script>