<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster ">
    <div class="row">
      <div class="col-md-12 text-muted">
        <div class="float-end">
          <span>Last Updated: </span>
          <span>12/01/2023</span>
        </div>
      </div>
      <div class="col-12">
        <h1 class="text-center mt-2">EJ Welch Policies & Procedures</h1>
        <hr>
        <div class="row">
          <div
            class="col-md-12"
            ref="index">
            <button
              class="btn btn-primary back-to-top"
              @click="backToTop">
              <i class="fa fa-arrow-up"/>
            </button>
            <div
              class="card"
              style="box-shadow:2px 2px 5px lightgray;">
              <div class="card-body text-center">
                <h3>Index</h3>
                <hr>
                <div class="row">
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('hoursOfOperation')">
                      Hours of Operation
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('orderInformation')">
                      Order Information
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('willCall')">
                      Will Call Orders
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('freight')">
                      Freight Policy
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('damages')">
                      Damages / Shortages
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('pricing')">
                      Pricing / Addition Charges
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('cutOff')">
                      Order Cut off Times
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('sds')">
                      Safety Data Sheets (SDS)
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('warranty')">
                      Warranty
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('payment')">
                      Payment Terms
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('holidays')">
                      Holidays
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('delivery')">
                      Delivery of Product
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('shipment')">
                      Shipment Receipt
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a
                      href="javascript:void(0)"
                      @click="goto('returns')">
                      Returns
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Hours of operation -->
            <div
              class="mt-3"
              id="hoursOfOperation">
              <h3>Hours of Operation</h3>
              <span>Our hours of operation are Monday - Friday 7:00am to 4:00pm CST</span>
              <a
                class="mx-1"
                href="mailto:'customerservice@ejwelch.com'">
                Contact Customer Service
              </a>
              <span class="me-1">at</span>
              <a href="tel:888-396-1280">888-396-1280</a>
            </div>
            <!-- Order Information -->
            <div
              class="mt-3"
              id="orderInformation">
              <h3>Order Information</h3>
              E. J. Welch is committed to providing our customers with the best possible service. This includes providing consistent, reliable delivery times that meet the expectations of our customers. Any order placed prior to the cut-off time of your assigned home Distribution Center (DC), on any business day, will be carefully prepared and shipped on your scheduled delivery day. By request, E.J. Welch will ship out via UPS or LTL.  Orders placed after your assigned home DC cut off time will ship on your next scheduled delivery day.  If your order is placed prior to the requested ship date, you may add to it until the cut off time up to the day prior to shipment.  We can also ship directly to your customer upon request freight charges will apply.
            </div>
            <!-- Will Call -->
            <div
              class="mt-3"
              id="willCall">
              <h3>Will Call Orders</h3>
              E. J. Welch is always happy to assist our customers with DC or Branch pickups.  To minimize your wait time, we encourage customers to please call ahead a minimum of at least two hours before you arrive.
              <div class="mt-3">
                <strong>Orders Ready for Pick Up</strong>
                <br>
                EJ Welch will notify customers when their orders have arrived at their DC or branch and ready to be picked up. EJ Welch will hold them for 30 business days unless otherwise agreed upon in writing. Before an order reaches the 30-day point we will send out a friendly call or email reminding the customer of their order and ask for a pickup or preferred ship out date. EJ Welch understands that jobsite delays, natural disasters and other force majeure do occur, and we want to support affected communities when possible. In these events, please contact your Branch Director as soon as possible so that we may work with your unique situation.
              </div>
            </div>
            <!-- Freight Policy -->
            <div
              class="mt-3"
              id="freight">
              <h3>Freight Policy</h3>
              Each “individual shipment” stands alone in determining prepaid status, freight charges, etc. When an order is split into multiple shipments the Freight Policy applies to each individual shipment.
              <div>
                <strong>Policy details:</strong>
                <ul>
                  <li>All in-stock orders received by 11AM Central time will ship on the same day. </li>
                  <li>Out of stocks do not count toward the prepaid minimum.</li>
                  <li>Depending on destination location order transit times may vary between 1-3 days. </li>
                  <li>All orders will have a $75 delivery fee applied. This includes orders shipped from Home DC to a Branch for customer pickup. </li>
                  <li>Special orders or circumstances may increase standard delivery fee amount.  </li>
                  <li>Prepaid freight must meet the minimums assigned to the destination state (listed below) for any shipment from the customer’s Home DC Chicago/Houston only. </li>
                  <li>Chicago - Home DC </li>
                  <ol>
                    <li>$1500- IA, IN, IL, KY, MI, MN. MO, OH, TN and WI</li>
                    <li>$2000- KS and VA</li>
                    <li>$2500- ND</li>
                  </ol>
                  <li>Houston - Home DC</li>
                  <ol>
                    <li>$1500- AL, AR, LA, MS, OK and TX</li>
                    <li>$2000- CO, FL, GA, NC, and SC</li>
                  </ol>
                  <li>Any states outside of EJ Welch service region must have freight quoted prior to shipping. The minimum order and freight will be determined on a per order basis. This includes shipments outside of the contiguous United States (Hawaii, Alaska, Puerto Rico, etc.). Outlying and hard to service regions may have a higher prepaid minimum required due to surcharges applied by carriers. Freight cost responsibility may be applied in full to a customer’s order. </li>
                  <li>Orders that are shipping from a DC other than the customer’s Home DC will be handled as follows: </li>
                  <ul>
                    <li>All prepaid shipments under 3000 lbs. will have an adder applied, which is the difference in freight from the sister DC vs Home DC.</li>
                    <li>Shipments under prepaid minimums and/or over 3001 lbs. will be charged actual freight.</li>
                  </ul>
                  <li>Shipments less than prepaid minimums will have actual freight added to the invoice, regardless of where it ships from.</li>
                  <li>Certain heavy machinery may not count towards the pre-paid minimum for orders and may even incur all freight charges when shipped individually. An exemptions items list will be provided.  </li>
                  <li>Shipments to any other address other than what is listed on the account may incur additional fees.</li>
                  <li>Direct shipments from vendors can only ship to addresses listed on the account.</li>
                  <li>All freight charges incurred from direct shipments from vendors will be billed to the account. This includes, but is not limited to detention charges, change in address fees, improper lift gate requirements, etc.</li>
                  <li>Special delivery services may be available in your area from our LTL carriers for an additional charge.  These include, but are not limited to: Guaranteed AM delivery, inside delivery, liftgate delivery and call-ahead appointment deliveries which may incur additional freight fees which will be charged to the customer.</li>
                  <li>All job site deliveries have a minimum fee of $325 applied to the order.</li>
                </ul>
              </div>
              Please
              <a
                class="mx-1"
                href="mailto:'customerservice@ejwelch.com'">
                Contact Customer Service
              </a>for additional information regarding our Freight Policy.
            </div>
            <!-- Damages / Shortages -->
            <div
              class="mt-3"
              id="damages">
              <h3>Damages / Shortages</h3>
              Upon inspection of your order and packing slip, if there are any inconsistencies or damage in your order, a freight claim will need to be filed with the carrier. Email&nbsp;
              <a href="mailto:freightclaims@ejwelch.com">freightclaims@ejwelch.com</a>
              &nbsp;or contact EJ Welch Customer Service to file claims for orders that meet the prepaid freight minimum. On freight collect orders, you will need to contact the carrier to file a claim. All visible damage must be noted on the delivery receipt at time of delivery and please take pictures of all damages and send this information to  freightclaims@ejwelch.com immediately. Please remember that we will not be able to file a freight claim if the bill of lading is signed clear by the customer, therefore we will be unable to process a credit.
            </div>
            <!-- Pricing -->
            <div
              class="mt-3"
              id="pricing">
              <h3>Pricing / Additional Charges</h3>
              While E. J. Welch desires to maintain prices for as long as possible. Circumstances often arise that are unexpected, yet warranted, where an immediate price adjustment must be made.  Please feel free to reach out to your account manager to discuss any new and upcoming price changes.  If you do not have an assigned account manager, please reach out to your customer service to discuss. As in any industry, we experience increases that are unavoidable. Fuel surcharges seem to be the most common of these and we will only charge this as we are being charged from our vendors and common carrier partners.  We appreciate your willingness to work with us during these times and pledge our effort to control these costs.
            </div>
            <!-- Order Cut Off Times -->
            <div
              class="mt-3"
              id="cutOff">
              <h3>Order Cut-Off Time Policy</h3>
              E. J. Welch 	Times are based on DC locations Central Time Zones
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>DC</th>
                    <th>LTL Cut off</th>
                    <th>UPS Cut off</th>
                    <th>Store Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>S27E - Elk Grove</td>
                    <td>11:00 AM</td>
                    <td>11:00 AM</td>
                    <td>7:00 - 4:00</td>
                  </tr>
                  <tr>
                    <td>S60 - Houston</td>
                    <td>11:00 AM</td>
                    <td>11:00 AM</td>
                    <td>7:30 - 4:30</td>
                  </tr>
                </tbody>
              </table>
              All orders received after the cutoff will be processed the next business day.  
            </div>
            <!-- SDS Sheets -->
            <div
              class="mt-3"
              id="sds">
              <h3>Safety Data Sheets (SDS)</h3>
              E. J. Welch has access to material safety data sheets during normal business hours. Contact your account manager, local branch, or CS department for additional information.
            </div>
            <!-- Warranty -->
            <div
              class="mt-3"
              id="warranty">
              <h3>Warranty</h3>
              E. J. Welch recognizes the manufacturer's warranty as is in lieu of all other warranties expressed or implied, including warranties of merchantability or fitness for a particular purpose, and any other representations or obligations, including liability for indirect, punitive, incidental or consequential damages.  This limitation of warranty and liability supersedes all prior representations or warranties of any kind.
            </div>
            <!-- Payment Terms -->
            <div
              class="mt-3"
              id="payment">
              <h3>Payment Terms</h3>
              E. J. Welch operates with terms of Net 30 Days (Terms are only approved for customers who have established an account with EJW) as well as with standard cash in advance methods.  There will be a 1.5% monthly service charge on past-due balances. Please assist yourself and us by avoiding these additional charges.
            </div>
            <!-- Holidays -->
            <div
              class="mt-3"
              id="holidays">
              <h3>Everyone Needs a Day Off (Holiday Schedules)</h3>
              E. J. Welch observes the following as national holidays and operates under holiday schedules:
              <ul>
                <li>New Year's Day</li>
                <li>Memorial Day</li>
                <li>4th of July</li>
                <li>Labor Day</li>
                <li>Thanksgiving Day & the following Friday</li>
                <li>Christmas Eve & Christmas Day.</li>
              </ul>
              Should any of these days fall on a weekend, we will observe either the Friday before or the Monday after.  E. J. Welch appreciates your business and respects your comments. Please contact your account manager or a member of the Customer Service department with any concerns.
            </div>
            <!-- Delivery -->
            <div
              class="mt-3"
              id="delivery">
              <h3>Delivery of Product</h3>
              Orders delivered via a carrier should be signed for on the packing slip after you have inspected the delivery. Any inconsistencies, damages, shortages or any other problems, please make a note on the carrier’s receiving documents and
              <a
                class="mx-1"
                href="mailto:'customerservice@ejwelch.com'">
                Contact Customer Service
              </a>
              within 24 hours of receiving the goods. E.J. Welch will conduct an investigation and work with you to determine the next steps at the time of the complaint. Please be aware that on freight collect orders (deliveries that are booked by the customer), you are responsible for filing a claim with the carrier. Freight claims that are signed “free and clear” on the bill of lading or are not reported within 24 hours, will not be accepted. Pictures and other documentation will help speed up the claim process and may be required in order for the claim to be accepted.
            </div>
            <!-- Shipment Receipt -->
            <div
              class="mt-3"
              id="shipment">
              <h3>Shipment Receipt-CHECKLIST</h3>
              <p>
                Every pallet that leaves our Distribution Centers will have our&nbsp;
                <strong>
                  <u>green plastic pallet cap</u>
                </strong>
                &nbsp;on the top of pallet&nbsp;
                <strong>
                  <u>AND</u>
                </strong>
                &nbsp;the&nbsp;
                <strong>
                  <u>red and white security tape across the top of the pallet.</u>
                </strong>
              </p>
              <p>
                If either of these are missing, please do a thorough inspection of your shipment.&nbsp;
                <strong>
                  <u>MAKE NOTE OF THAT ON YOUR DELIVERY RECEIPT OR CARRIER TOUCH FREE DEVICE.</u>
                </strong>
              </p>
              <p>
                Example: <strong>Green wrap and/or Security tape missing.</strong>
              </p>
              <p>
                Any&nbsp;
                <strong>
                  <u>VISUAL DAMAGE</u>
                </strong>
                &nbsp;or&nbsp;
                <strong>
                  <u>IRREGULARITIES</u>
                </strong>
                &nbsp;must be noted on delivery receipt or carrier’s electronic device. Please inspect&nbsp;
                <strong>
                  <u>Entire Shipment.</u>
                </strong>
                &nbsp;If damage is found before signing BOL.
              </p>
              <p>
                <strong>
                  <u>EVERY</u> pallet EJ WELCH ships you should look like below when you receive it:
                </strong>
              </p>
              <div class="row">
                <div class="col-md-12 text-center">
                  <img
                    :src="wrappedOrder"
                    class="img-fluid">
                </div>
              </div>
              <p>
                It is the&nbsp;
                <strong>
                  <u>customer’s responsibility</u>
                </strong>
                &nbsp;to verify information supplied on the delivery receipt or carrier device as it relates to pallet count and/or any loose items before releasing the driver.&nbsp;
                <strong>
                  <u>Any damage must be notated, and documentation provided such as pictures.</u>
                </strong>
              </p>
              <p>
                You must&nbsp;
                <strong>CALL</strong>
                &nbsp;Customer Service&nbsp;
                <strong>
                  WITHIN
                  <u>24 HOURS</u>
                </strong>
                &nbsp;if you realize any shortages or damages on your order at (888) 396-1280 (don't wait for your rep to handle).
              </p>
              <p>
                <div class="text-muted">
                  * Customer compliance with the above noted steps will allow us to assist you in the event you experience damage and or shortages.
                </div>
                <div class="mt-3">
                  <strong>
                    <u>If these steps are not followed, we have LITTLE to NO recourse with the Freight Carrier.</u>
                  </strong>
                </div>
              </p>
            </div>
            <!-- Returns -->
            <div
              class="mt-3"
              id="returns">
              <h3>Return Policy and Cancellation Policy</h3>
              Request for credits or returns can be made by one of the following:
              <ul>
                <li>Phone</li>
                <li>Email</li>
                <li>In-person</li>
              </ul>
              The following details must be provided to the Customer or Account Manager when requesting a credit or return:
              <ul>
                <li>Original Invoice or Sales Order#</li>
                <li>Reason for credit request</li>
              </ul>
              The following conditions must be met before a credit or return can be processed:
              <ul>
                <li>The item was ordered from E. J. Welch (EJW)</li>
                <li>Sales must not be older than 30 days, from the date of pickup or ship out.</li>
                <li>The item is in resaleable condition: original packaging, no stickers, no damage, or writing on the item or box.</li>
                <li>If calling in the issue or emailing a description of defective or damaged item, please document with pictures.</li>
                <li>All vendor merchandise considered defective is subject to inspection and approval by the branch.</li>
              </ul>
              <strong>Merchandise Return:</strong>
              <ul>
                <li>For any problems with an order (shortages, damage, wrong items, etc.) the customer must
                  <a
                    class="mx-1"
                    href="mailto:'customerservice@ejwelch.com'">
                    Contact Customer Service
                  </a>
                  or their Account manager (AM) within 24 hours.</li>
                <li>EJW will accept returns of stocked merchandise only, within 30 days of purchase.</li>
                <li>A return authorization must be created prior to the AM or customer returning product to Distribution Centers or branch.</li>
                <li>Special orders, close-out specials, discontinued items, and “All Sales Final” sales are all non-returnable.</li>
                <li>Returns without authorization will not be accepted.</li>
                <li>During the winter months adhesives, primers or other products affected by freezing temperatures are non-returnable. </li>
                <li>All returns are subject to a 25% restocking fee.</li>
              </ul>
              <strong>Scheduling Returns:</strong>
              <ul>
                <li>Once a return is reviewed by customer service or branch manager, we will then discuss whether the material will be returned by the customer or if EJW will make the arrangements to pick it up.</li>
                <li>Once the material has cleared inspection the credit will be issued to the customer account. </li>
              </ul>
              <strong>Order Entry Errors:</strong>
              <ul>
                <li>If the return stems from an EJW error, Customer Service will authorize a return to the branch.  We will then either schedule a pickup or the customer may return the material to the branch.  No restocking fee will be applied.</li>
              </ul>
              <strong>Restocking Fee:</strong>
              <ul>
                <li>Stock items returned may be subject to a 25% restock fee.</li>
                <li>Nonstock items will need to be approved by the vendor before any credit is issued. Any restocking fee and freight to return to the vendor will be added to the customer credit as a reduction.</li>
              </ul>
              <strong>Cancelling an Order:</strong>
              <ul>
                <li>We will accept an order cancellation for stocked product up to 30 days from the order date.</li>
                <li>Cancellation of a special order will be contingent upon supplier policy. Initiate your order cancellation directly with your CS Manager, Account Manager or Branch Team.</li>
              </ul>
            </div>
            <!-- Contact -->
            <div class="mt-3">
              <h3>Who to Contact</h3>
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>Issue</th>
                    <th>Contact</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Order Errors</td>
                    <td>Customer Service</td>
                  </tr>
                  <tr>
                    <td>Damages / Shortages</td>
                    <td>Customer Service</td>
                  </tr>
                  <tr>
                    <td>Check the status of a pending order</td>
                    <td>Customer Service</td>
                  </tr>
                  <tr>
                    <td>Pricing Discrepancies</td>
                    <td>Account Manager</td>
                  </tr>
                  <tr>
                    <td>Defectives</td>
                    <td>Account Manager/Customer Service</td>
                  </tr>
                  <tr>
                    <td>Samples</td>
                    <td>Account Manager/Customer Service</td>
                  </tr>
                  <tr>
                    <td>Other Issues</td>
                    <td>Account Manager</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        wrappedOrder: require('../../../../../assets/images/ejw/WrappedOrder.jpg')
      }
    },
    methods:{
      goto(id){
        const base = document.getElementById('content')
        const el = document.getElementById(id)
        base.scrollTo({left: 0, top: el.getBoundingClientRect().top - 120, behavior: 'smooth'})
      },
      backToTop(){
        const base = document.getElementById('content')
        base.scrollTo({left: 0, top: 0, behavior: 'smooth'})
      }
    } 
  }
</script>

<style scoped>
 .back-to-top{
    position: fixed;
    z-index: 1;
    bottom: .5rem;
    right: 2rem;
    opacity: 0.5;
  }
  @media(max-width: 991px){
    .back-to-top{
      position: fixed;
      z-index: 1;
      bottom: 5rem;
      right: 2rem;
      opacity: 0.5;
    }
  }
</style>