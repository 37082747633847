<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div
            class="text-center"
            style="max-width:800px; margin: 0 auto;">
            <h2
              class="text-theme-red"
              style="font-weight: 400">
              Product Selection
            </h2>
            <p class="text-muted">No matter how long you’ve been in the industry, EJ Welch provides the right product mix to win you more business.</p>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <div
            id="categorySlider"
            class="carousel slide"
            data-bs-ride="carousel"
            style="min-height: 300px;">
            <ol class="carousel-indicators">
              <li
                v-for="(c, index) in categories"
                :key="index"
                data-bs-target="#categorySlider"
                :data-bs-slide-to="index"
                :class="{active: index == 0}"/>
            </ol>
            <div class="carousel-inner" role="listbox">
              <div
                 v-for="(c,index) in categories"
                :key="c.main"
                class="carousel-item"
                :class="{active: index == 0}">
                <div class="row">
                  <div
                    v-for="cat in c"
                    :key="cat.main"
                    class="col-md-4 col-sm-6 col-6 text-center"
                    style="width: 200px; margin: 0 auto;">
                    <div style="height:220px;">
                      <div>
                        <div style="height: 150px;">
                          <img
                            :src="cat.image_url"
                            class="img-fluid"
                            style="height: inherit; object-fit: cover;">
                        </div>
                      </div>
                      <div class="mt-2">
                        <span
                          class="text-red"
                          style="font-size:18px;">
                          {{cat.main}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#categorySlider" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#categorySlider" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['categories']
  }
</script>

<style scoped>
   .carousel-indicators > li{
    height:10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--bs-secondary)
  }

  .carousel-control-prev, .carousel-control-next{
    width: 4%;
    height:12em;
  }

  .carousel-control-next-icon:after{
    content: '>';
    font-size: 35px;
    color: gray;
  }
  .carousel-control-prev-icon:after {
    content: '<';
    font-size: 35px;
    color: gray;
  }
  .text-red{
    color: #D73E18;
  }
</style>

