<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2
          v-if="view == 'dealer'"
          class="text-theme-red"
          style="font-weight: 400;">
          HEAR FROM EJW CUSTOMERS
        </h2>
        <h2
          v-if="view == 'manufacturer'"
          class="text-theme-blue-light"
          style="font-weight: 400;">
          HEAR FROM EJW SUPPLIERS
        </h2>
      </div>
    </div>
    <Comments
      v-if="view == 'dealer'"
      :list="dealerTestimonials"/>
    <Comments
      v-if="view == 'manufacturer'"
      :list="mfgTestimonials"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import Comments from './Comments.vue'
  export default {
    inject: ['env'],
    props:['view'],
    components:{
      Comments
    },
    data(){
      return{
        dealerTestimonials: null,
        mfgTestimonials: null
      }
    },
    created(){
      this.getDealerTestimonials()
      this.getMfgTestimonials()
    },
    methods:{
      getDealerTestimonials(){
        const params = {env: this.env}
        axios.get('/api/v1/public/testimonials/get_dealer_testimonials', {params}).then(res =>{
          this.dealerTestimonials = res.data.testimonials
        })
      },
      getMfgTestimonials(){
        axios.get('/api/v1/public/testimonials/get_mfg_testimonials').then(res =>{
          this.mfgTestimonials = res.data.testimonials
        })
      }
    }
  }
</script>

<style scoped>
  .divider{
    max-width: 80%;
    margin: 0 auto;
    height: 3px;
  }
</style>