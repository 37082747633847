<template>
  <div class="text-light">
    <button
      class="btn btn-outline-light w-100 my-2"
      @click="toggleShow">
      <strong>Brands</strong>
      <i class="fa fa-caret-down ms-2"/>
    </button>
    <button
      v-if="show && brandFilters && brandFilters.length"
      class="badge bg-light text-dark mx-1"
      style="cursor: pointer;"
      @click="clearBrands">
      Clear
      <i class="fa fa-times text-danger"/>
    </button>
    <ul
      v-if="show"
      class="p-0 animate__animated animate__slideInLeft animate__faster">
      <li v-for="b in brands">
        <LineItem
          lineType="brand"
          :brand="b"
          :clear="clear"
          @emitBrand="handleBrand"/>
      </li>
    </ul>
  </div>
</template>

<script>
  import LineItem from './LineItem.vue'
  export default{
    props: ['loading', 'brands', 'brandFilters'],
    data(){
      return{
        selectedBrands:[],
        clear: false,
        show: false
      }
    },
    components:{
      LineItem
    },
    watch:{
      loading(newVal){
        if(!newVal) this.clear = false
      }
    },
    methods:{
      toggleShow(){
        this.show = !this.show
      },
      submit(){
        this.$emit('setBrands', this.selectedBrands)
      },
      handleBrand(brand){
        if(!this.selectedBrands.includes(brand)){
          this.selectedBrands.push(brand)
        }else{
          this.selectedBrands = _.reject(this.selectedBrands, m => m == brand)
        }
        this.submit()
      },
      clearBrands(){
        this.clear = true
        this.selectedBrands = []
        this.submit()
      }
    }
  }
</script>

<style scoped>
  ul{
    list-style-type: none;
    font-size: 10px !important;
  }
  li{
    font-size: 10px;
    white-space: nowrap;
    padding: 0px 10px;
  }
</style>