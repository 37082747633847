<template>
  <div class="row">
    <div class="col-md-12">
      <!-- Open/Close Fav Panel -->
      <div
        v-if="!showFavPanel"
        class="float-end">
        <i
          class="fa fa-heart fav-hover text-danger"
          @click="toggleFav"/>
      </div>
      <div
        v-if="showFavPanel"
        class="float-start">
        <button
          class="btn btn-outline-primary btn-sm mb-1"
          @click="toggleFav">
          <span>
            <i class="fa fa-arrow-left"/>
            Back
          </span>
        </button>
      </div>
      <div class="clearfix"/>
      <!-- Main Content -->
      <div
        class="container-fluid animate__animated animate__fadeIn animate__faster fav-area"
        v-if="showFav">
        <!-- Header -->
        <div>
          <div class="float-start">
            <h5>Add to Favorites</h5>
            <small class="text-muted">
              Select a Favorite group to add this product to OR create a new group
            </small>
          </div>
          <button
            class="btn btn-primary btn-sm float-end"
            @click="toggleCreate">
            <i class="fa fa-plus"/>
            Create New Group
          </button>
          <div class="clearfix"/>
        </div>
        <!-- Fav Groups -->
        <div v-if="!showConfirm && !loading && !creating">
          <div class="row mt-4">
            <div class="col-md-12">
              <strong class="float-start">Your Favorite Groups</strong>
            </div>
            <div
              class="col-md-2"
              v-for="f in favorites"
              :key="f.id">
              <div class="d-grid">
                <button
                  class="btn btn-outline-primary btn-sm my-2"
                  :class="isActive(f.id) ? 'active btn-primary' : 'btn-outline-default'"
                  @click="toggleGroupStatus(f.id)">
                  <i
                    class="fa fa-check-circle"
                    v-if="isActive(f.id)"/>
                  {{f.name}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Create a Group -->
        <div v-if="creating">
          <form
            name="createFav"
            @submit.prevent="createGroup">
            <strong>New Group</strong>
            <input
              type="text"
              class="form-control my-2"
              placeholder="Enter Name"
              v-model="newGroup.name"
              required>
            <input
              type="text"
              class="form-control my-2"
              placeholder="Enter Description"
              v-model="newGroup.description">
            <div class="mt-2">
              <button
                class="btn btn-primary btn-sm float-end mx-1"
                type="submit">
                <i class="fa fa-check-circle"/>
                Submit
              </button>
              <button
                class="btn btn-primary btn-sm float-end mx-1"
                type="button"
                @click="toggleCreate">
                <i class="fa fa-ban"/>
                Cancel
              </button>
              <div class="clearfix"/>
            </div>
          </form>
        </div>
        <!-- Spinner -->
        <div
          class="row"
          v-if="loading">
          <div class="col-md-12 text-center">
            <Spinner/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../../global/Spinner.vue'
  
  export default {
    props:['showFav', 'product'],
    data(){
      return{
        activeGroups: [],
        removeGroups:[],
        loading: false,
        showConfirm: false,
        touched: false,
        creating:false,
        newGroup: {}
      }
    },
    components:{
      Spinner
    },
    watch:{
      // Initalize elements when modal is recycled && Get fav groups once fav panel is displayed
      showFav(newFavStatus){
        if(newFavStatus == true){
          this.loading = true
          this.touched = false
          this.creating = false
          this.newGroup = {}
          this.$store.dispatch('getFavorites')
        }
      },
      // Check to see if the active product is in a group already
      // mark the group as an active group for this product
      favorites(newFavs){
        if(newFavs){
          this.activeGroups = []
          var activeGroups = this.activeGroups
          var product = this.product
          _.forEach(newFavs, function(favGroup){
            let inList = _.some(favGroup.favorite_products, p => p.product_id == product.product_id)
            if(inList == true){
              activeGroups.push(favGroup.id)
            }
          })
          this.loading = false
        }
      }
    },
    methods:{
      // Toggle Fav Panel
      toggleFav(){
        this.$emit('toggleFav')
      },
      // Button click controlling if the fav group id
      // should be added to the array for submission
      toggleGroupStatus(id){
        this.touched = true
        let groupStatus = _.includes(this.activeGroups, id)
        if(groupStatus == true){
          this.removeGroups.push(id)
          this.activeGroups = _.filter(this.activeGroups, a => a != id)
        }else{
          this.removeGroups = _.filter(this.remveGroups, r => r != id)
          this.activeGroups.push(id)
        }
        this.submit()
      },
      // Toggles the create form
      toggleCreate(){
        this.creating = !this.creating
      },
      // Check to see if fav group has the product
      isActive(id){
        let groupStatus = _.includes(this.activeGroups, id)
        if(groupStatus == true){
          return true
        }else{
          return false
        }
      },
      // Submit fav group ids and product id
      submit(){
        let params = {
          favGroups: this.activeGroups,
          removeGroups: this.removeGroups,
          product_id: this.product.product_id
        }
        this.$store.dispatch('setFromProduct', params)
      },
      createGroup(){
        let fav = {
          name: this.newGroup.name,
          description: this.newGroup.description
        }
        this.$store.dispatch('createFavGroup', fav)
        this.creating = false
        this.$store.dispatch('getFavorites')
      }
    },
    computed:{
      favorites(){return this.$store.getters.favorites || null},
      showFavPanel(){return this.$store.getters.showFavPanel}
    }
  }
</script>

<style scoped>
  .fav-hover{
    cursor: pointer;
  }
  .fav-hover:hover{
    transform: scale(1.2);
    transition: ease-in-out .05s;
    text-shadow: 1px 1px 5px lightgray;
  }
  .fav-area{
    border: 1px solid lightgray;
    border-radius:10px;
    padding:10px;
  }
</style>