<template>
  <div class="modal fade" id="sundrySceneModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sundry Scene Magazine</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img
            :src="img"
            class="img-fluid">
          <div class="my-2 p-2">
            The Sundry Scene Magazine is your trusted source for news, trends, better business practices, education and products in the market.  Tailored to the independent retailer, Lancaster combines its insights with industry experts free of charge to customers.
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        img: require('../../../../../assets/images/lan/advantage/dealer/dealerTab3.jpg')
      }
    }
  }
</script>