<template>
  <div style="font-size:1rem;line-height:1.2rem;" class="product row align-items-center">
    <div class="col-lg-1 col-sm-2 col-2 text-center">
      <img v-if="product.product_detail" :src="product.product_detail.image_url" @error="imgError"
        style="max-height: 120px;" class="img-fluid hover mb-2" data-bs-toggle="modal" data-bs-target="#productDetail"
        @click="setActiveProduct(product.product_detail)">
      <img v-else :src="placeholder" style="max-height: 120px;" class="img-fluid hover">
    </div>
    <div class="col-lg-11 col-sm-10 col-10 hover mb-2 fw-bold"
      :data-bs-toggle="product.product_detail ? 'modal' : ''"
      :data-bs-target="product.product_detail ? '#productDetail' : ''"
      @click="setActiveProduct(product.product_detail)">
      <span class="d-inline-block mb-2 mobile-desc">{{product.description}}</span>
      <!-- mobile id and upc -->
      <div :class="type == 'order' ? 'd-lg-none' : ''" class="row small justify-content-between justify-content-sm-start justify-content-lg-start align-items-center">
        <div class="col-auto pr-4">
          ID: {{product.eclipse_id}}
        </div>
        <div class="col-auto">
          UPC: {{product.upc}}
        </div>
      </div>
    </div>
    <div class="col-12">
      <!-- invoice details  -->
      <div v-if="type == 'invoice'" class="row small">
        <div class="col-6 col-md-auto">
          Ordered:&nbsp;{{numeral(product.quantity).format('0,0')}}
          <span>
            {{displayUom(product.quantity_uom)}}
          </span>
        </div>
        <div class="col-6 col-md-auto">
          Shipped:&nbsp;{{numeral(currencyConvert(product.shipped_quantity)).format('0,0')}}
          <span>
            {{displayUom(product.price_uom)}}
          </span>
        </div>
        <div class="col-6 col-md-auto">
          Unit Price:&nbsp;{{numeral(currencyConvert(product.unit_price)).format('$0,0.00')}}
          <span>
            {{displayUom(product.price_uom)}}
          </span>
        </div>
        <div class="col-6 col-md-auto">
          Ext Price:&nbsp;{{numeral(currencyConvert(product.extended_price)).format('$0,0.00')}}
          <span>
            {{displayUom(product.price_uom)}}
          </span>
        </div>
      </div>

      <div class="row small">
        <div class="col-6 col-md-5">
          <!-- order details -->
          <div v-if="type == 'order'" class="row mb-2">
            <!-- desktop id and upc -->
            <div class="col-12 fw-bold col-lg-6 d-none d-lg-block">
              
                <div>ID: {{product.eclipse_id}}</div>
              
                <div>UPC: {{product.upc}}</div>
              
            </div>
            <div class="col-12 col-lg-6">
              <div>
                Qty:&nbsp;{{numeral(product.shipped_quantity).format('0,0')}}
                <small>{{displayUom(product.quantity_uom)}}</small>
              </div>
            
              <div>
                Unit Price:&nbsp;{{numeral(currencyConvert(product.unit_price)).format('$0,0.00')}}
                <small>{{displayUom(product.price_uom)}}</small>
              </div>
            
              <div>
                Ext Price:&nbsp;{{numeral(currencyConvert(product.extended_price)).format('$0,0.00')}}
                <small>{{displayUom(product.price_uom)}}</small>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="type == 'order' && product.product_detail"
          class="col-6 col-md-4">
          <!-- availability  -->
          <div>
            <button
              class="btn btn-sm btn-link p-0"
              @click="toggleShowAvail">
              <i class="fa fa-cubes"/>
              Show Availability
              <i :class="showAvail ? 'fa fa-caret-up' : 'fa fa-caret-down'"/>
            </button>
          </div>
          <div
            v-if="showAvail"
            class="col-12 overflow-hidden">
            <table class="table table-sm text-center small animate__animated animate__fadeInDown animate__faster">
              <thead>
                <tr>
                  <th class="fw-normal text-sm">Branch</th>
                  <th class="fw-normal text-sm">Qty</th>
                  <th class="fw-normal text-sm">Stocked</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="b in product.product_detail.eclipse_data.branches" :key="b.id">
                  <td>
                    <i v-if="b.name == product.product_detail.eclipse_data.ship_br_name" class="fa fa-home text-info" />
                    <span v-else>{{b.name}}</span>
                  </td>
                  <td>
                    {{numeral(b.avail).format(b.avail >= 10000 ? '0a' : '0,0')}}
                    {{product.product_detail.eclipse_data.price_uom == 'sf' ? displayUom('cs') : displayUom(product.product_detail.eclipse_data.uom)}}
                  </td>
                  <td>
                    <div v-if="stockedInBranch.includes(b.id)" class="w-100 text-success">
                      <i class="fa fa-check-circle text-success" />
                    </div>
                    <div v-else class="w-100 text-muted">
                      <i class="fa fa-times-circle text-danger" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr class="my-3">
    </div>
  </div>
</template>

<script>
import {currencyConverter} from '../../../../shared/currencyConverter'
import {stockedInBranch} from '../../../../shared/stockHelpers'
import { displayUom } from '../../../../shared/ProductFunctions'

export default{
  name: 'LineItem',
  props: ['product','invoice', 'order', 'type'],
  data(){
    return{
      showAvail: false,
      placeholder: require('../../../../../assets/images/coming_soon.png')
    }
  },
  methods: {
    currencyConvert(amount){
      if(this.type == 'invoice' && this.invoice?.details.foreign_currency == 'CAD'){
        const rate = this.invoice.details.exchange_rate
        return currencyConverter(rate, amount)
      }else if(this.type == 'order' && this.order?.details.exchange_rate){
        const rate = this.order.details.exchange_rate
        return currencyConverter(rate, amount)
      }else{
        return amount
      }
    },
    setActiveProduct(product){this.$store.dispatch('setActiveProduct', product)},
    toggleShowAvail(){this.showAvail = !this.showAvail},
    displayUom(uom){ return displayUom(uom) }
  },
  computed:{
    stockedInBranch(){return stockedInBranch(this.product.product_detail)}
  }
}
</script>

<style scoped>
  @media(max-width: 575px){
    .mobile-desc{
      overflow: hidden;
      max-height: 40px;
    }
  }
</style>