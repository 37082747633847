<template>
  <div
    class="col-md-6 p-5 mt-5"
    style="max-width:600px; margin: auto;">
    <form
      name="arPassword"
      @submit.prevent="submit">
      <div class="input-group">
        <input
          name="arPasswordInput"
          placeholder="Enter AR Password"
          autocomplete="off"
          class="form-control"
          type="password"
          v-model="authPassword"
          required/>
        <button
          type="submit"
          class="btn btn-primary">
          <i class="fa fa-search"/>
        </button>
      </div>
    </form>
    <h5
      v-if="authError"
      class="text-center text-danger mb-4 animate__animated animate__bounceIn animate__faster mt-5">
      {{authError}}
    </h5>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        authPassword: ''
      }
    },
    methods:{
      submit(){
        this.$store.dispatch('accountAuth', this.authPassword)
        this.authPassword = ''
      }
    },
    computed:{
      authError(){return this.$store.getters.getAuthError}
    }
  }
</script>