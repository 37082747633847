<template>
  <div
    class="modal fade"
    id="feedbackModal"
    tabindex="-1"
    role="dialog">
    <div
      class="modal-dialog modal-lg"
      role="document">
      <form
        name="feedback"
        class="modal-content"
        @submit.prevent="submit">
        <div class="modal-header">
          <h5 class="modal-title">Customer Feedback</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              data-bs-target="#feedbackModal"/>
        </div>
        <div class="modal-body">
          <p>We value your feedback, any comments left here will help drive bug fixes & app improvements. Please consider the following:</p>
          <ol class="text-left">
            <li>
              <strong>UX (User Experience)</strong>
              <ul>
                <li>Did the app feel intuative and smooth in its interactions and was it your liking?</li>
              </ul>
            </li>
            <li>
              <strong>Function</strong>
              <ul>
                <li>Did things work properly? If you have an issue we want to hear about it.</li>
              </ul>
            </li>
            <li>
              <strong>Comparison</strong>
              <ul>
                <li>
                  As you are a user of the current website, how does this new site compare?
                </li>
              </ul>
            </li>
            <li>
              <strong>Platform</strong>
              <ul>
                <li>Did you use the app on a Computer/Tablet/Mobile? Please be aware this single app can work accross all platforms and can even be installed to your device.</li>
              </ul>
            </li>
            <li>
              <strong>Personal Opinion</strong>
              <ul>
                <li>How do you feel about the app and experience overall? Is anything missing? What would you add next?</li>
              </ul>
            </li>
          </ol>
          <strong>Feedback</strong>
          <textarea
            name="feedback"
            class="form-control my-2"
            cols="30"
            rows="5"
            maxlength="500"
            v-model="form.feedback"
            required/>
          <div class="float-start">
            Overall Experience:&nbsp;
            <i
              v-for="s in stars"
              :key="s"
              :class="form.rating >= s ? selectedStar : unselectedStar"
              @click="setRating(s)"/>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal">  
            Close
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            :disabled="form.feedback.length == 0">
            <i class="fa fa-paper-plane"/>
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  
  export default {
    data(){
      return{
        form: {
          feedback: '',
          rating: 0
        },
        stars: [1,2,3,4,5],
        selectedStar: 'fas fa-star rating-star text-gold animate__animated animate__bounceIn',
        unselectedStar: 'far fa-star rating-star' 
      }
    },
    mounted(){
      const feedbackModal = document.getElementById('feedbackModal')
      if(feedbackModal){
        feedbackModal.addEventListener('hidden.bs.modal', function(){
          this.form = {
            feedback: '',
            rating: 0
          }
        }.bind(this))
      }
    },
    methods:{
      submit(){
        let data = this.form
        this.resetForm()
        axios.post('/api/v1/private/customer_feedback/submit', data).then(res => {
          this.$store.dispatch('disableTimedFeedback')
        })
      },
      resetForm(){
        this.form = {
          feedback: '',
          rating: 0
        }
      },
      setRating(rating){
        this.form.rating = rating
      }
    }
  }
</script>

<style scoped>
  .rating-star{
    cursor: pointer;
  }
</style>