<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster">
    <h1>Privacy Policy</h1>
    <p>Effective date: Jan 01, 2023 </p>
    <p>Merit Distribution Group ("us", "we", or "our") operates the https://www.{{url}} website (the "Service").</p>
    <p>
      This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service
      and the choices you have associated with that data.
    </p>
    <p>
      We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information
      in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have
      the same meanings as in our Terms and Conditions, accessible from https://www.{{url}}
    </p>  
    <h2>Information Collection And Use</h2>
    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
    <h3>Types of Data Collected</h3>
    <h4> 
      <b>Personal Data</b>
    </h4>
    <p>
      While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to
      contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Address, State, Province, ZIP/Postal code, City</li>
        <li>Cookies and Usage Data</li>
      </ul>
    </p>
    <p>
      We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that
      may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the
      unsubscribe link or instructions provided in any email we send.
    </p>
    <h4>
      <b>Usage Data</b>
    </h4>
    <p>
      We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information
      such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our
      Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and
      other diagnostic data.
    </p>
    <h4> 
      <b>Tracking &amp; Cookies Data</b>
    </h4>
    <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
    <p>
      Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser
      from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and
      track information and to improve and analyze our Service.
    </p>
    <p>
      You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
      cookies, you may not be able to use some portions of our Service.
    </p>
    <p>
      Examples of Cookies we use:
      <ul>
        <li>
          strong Session Cookies
          <ul>
            <li>We use Session Cookies to operate our Service.</li>
          </ul>
        </li>
        <li>
          strong Preference Cookies
          <ul>
            <li>We use Preference Cookies to remember your preferences and various settings.</li>
          </ul>
        </li>
        <li>
          strong Security Cookies
          <ul>
            <li>We use Security Cookies for security purposes.</li>
          </ul>
        </li>
      </ul>
    </p>
    <h2>Use of Data</h2>
    <p>
      Merit Distribution Group uses the collected data for various purposes:
      <ul>
        <li>To provide and maintain our Service</li>
        <li>To notify you about changes to our Service</li>
        <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
        <li>To provide customer support</li>
        <li>To gather analysis or valuable information so that we can improve our Service</li>
        <li>To monitor the usage of our Service</li>
        <li>To detect, prevent and address technical issues</li>
        <li>
          To provide you with news, special offers and general information about other goods, services and events which we offer
          that are similar to those that you have already purchased or enquired about unless you have opted not to receive
          such information
          </li>
      </ul>
    </p>
    <h2>Transfer Of Data</h2>
    <p>
      Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your
      state, province, country or other governmental jurisdiction where the data protection laws may differ than those from
      your jurisdiction.
    </p>
    <p>
      If you are located outside United States and choose to provide information to us, please note that we transfer the data,
      including Personal Data, to United States and process it there.
    </p>
    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
    <p>
      Merit Distribution Group will take all steps reasonably necessary to ensure that your data is treated securely and in accordance
      with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless
      there are adequate controls in place including the security of your data and other personal information.
    </p>
    <h2>Disclosure Of Data</h2>
    <h3>Legal Requirements</h3>
    <p>
      Merit Distribution Group may disclose your Personal Data in the good faith belief that such action is necessary to:
      <ul>
        <li>To comply with a legal obligation</li>
        <li>To protect and defend the rights or property of Merit Distribution Group</li>
        <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
        <li>To protect the personal safety of users of the Service or the public</li>
        <li>To protect against legal liability</li>
      </ul>
    </p>
    <h2>Security Of Data</h2>
    <p>
      The security of your data is important to us, but remember that no method of transmission over the Internet, or method of
      electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data,
      we cannot guarantee its absolute security.
    </p>
    <h2>"Do Not Track" Signals</h2>
    <p>
      We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that
      you do not want to be tracked.
    </p>
    <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
    <h2>Service Providers</h2>
    <p>
      We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service
      on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.
    </p>
    <p>
      These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to
      disclose or use it for any other purpose.
    </p>
    <h2>Analytics</h2>
    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
    <p>
      <strong>Google Analytics</strong>
    </p>
    <p>
      Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses
      the data collected to track and monitor the use of our Service. This data is shared with other Google services.
      Google may use the collected data to contextualize and personalize the ads of its own advertising network.
    </p>
    <p>
      You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google
      Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and
      dc.js) from sharing information with Google Analytics about visits activity.
    </p>
    <p>
      For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page:
      <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>
    </p>
    <h2>Links To Other Sites</h2>
    <p>
      Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be
      directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
    </p>
    <p>
      We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites
      or services.
    </p>
    <h2>Changes To This Privacy Policy</h2>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on
      this page.
    </p>
    <p>
      We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update
      the "effective date" at the top of this Privacy Policy.
    </p>
    <p>
      You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
      when they are posted on this page.
    </p>
    <h2>Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact us:
      <ul>
        <li>
          By email:
          <a :href="'mailto:it.support@' + url">it.support@ {{url}} </a>
        </li>
        <li>
          By visiting this page on our website:
          <a :href="'https://www.' + url">www.{{url}}</a>
        </li>
      </ul>
    </p>
  </div>
</template>

<script>
  export default {
    inject: ['env'],
    data(){
      return{
        url: this.env.includes('EJW') ? (this.env.includes('CHENEY') ? 'cheneyvroomanflooring.com' : 'ejwelch.com') : 'lancasterco.com'
      }
    }
  }
</script>