<template>
  <div>
    <div class="p-3 pb-0 mb-3">
      <h2 class="mb-3">Price Lists</h2>
      <ul>
        <li>Select a manufacturer from the list below</li>
        <li>Specify a recipient email address</li>
        <li>A spreadsheet of items and prices is sent via email</li>
        <li>The spreadsheet is attached as a .csv file</li>
      </ul>
    </div>
    <PriceListForm />
  </div>
</template>
<script>
import PriceListForm from './components/PriceListForm.vue'

export default {
  name: 'PriceLists',
  components: {
    PriceListForm
  },
  data(){
    return{}
  },
  mounted(){

  },
  methods(){

  },
  computed:{

  }
}
</script>

<style scoped lang="scss">

</style>