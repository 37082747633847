<template>
  <div>
    <!-- Lancasters Brand -->
    <div
      class="row"
      style="min-height: 500px;">
      <div class="col-md-8 p-0 position-relative">
        <video
          class="video-style"  
          src="https://merit-development.s3.amazonaws.com/ejwEstore/brands/HillCountryInnovationsVideo.mp4"
          autoplay
          loop/>
      </div>
      <div class="col-md-4 bg-secondary text-white position-relative p-5">
        <div class="centered">
          <h2 style="font-weight: 400;">EJ WELCH'S PROPRIETARY BRAND</h2>
          <p>Founded on one simple vision: Design high quality, durable products to compliment any style, at an affordable price. Hill Country Innovations is able to offer lasting styles whether you live in the mountains, by the sea or somewhere in between. Premier Flooring Products offer installation products at an exception value.</p>
        </div>
      </div>
    </div>
    <!-- Dynamic Slider -->
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
           <img
            :src="logo"
            style="width: 250px;"
            class="img-fluid mt-5">
        </div>
      </div>
      <div class="row my-5">
        <div class="col-md-12">
          <div id="dynamicCarousel" class="carousel slide" data-bs-ride="carousel">
            <ol class="carousel-indicators">
              <li data-bs-target="#dynamicCarousel" data-bs-slide-to="0" class="active"></li>
              <li data-bs-target="#dynamicCarousel" data-bs-slide-to="1"></li>
              <li data-bs-target="#dynamicCarousel" data-bs-slide-to="2"></li>
            </ol>
            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-md-6">
                    <p>Innovations reside at the crossroads of rustic simplicity and understated elegance. We embrace the subtle imperfections that make hardwood flooring flawless in its own right. Each thoughtfully curated plank is a unique work of art that, together, tells a story of beauty and strength. Hill Country Innovations is rooted in history and heritage and celebrates the perfect imperfections in authentic hardwood flooring. From traditional to trendy and cater to the unique needs of each US region. So, whether you live among fields of gold, in the mountains or by the sea, Hill Country Innovations feels like home. Infuse warmth and character into every space with hardwood flooring that lasts a lifetime.</p>
                  </div>
                  <div
                    class="col-md-6"
                    :style="img1"/>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-6">
                    <p>Active families need floors that can stand up to kids, dogs and whatever else life throws at them. The Hill Country Innovations rigid core vinyl and ProTech SPC collections have durable wear layers and waterproof cores for a floor that truly performs.</p>
                  </div>
                  <div
                    class="col-md-6"
                    :style="img2"/>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-6">
                    <p>The beauty of mosaic in its versatility. Mosaic can set the stage in a room as a dramatic floor, feature wall, or a unique design detail in a shower or backsplash. Hill Country Innovations features the top styles with exception value to meet bring your design to life.</p>
                  </div>
                  <div
                    class="col-md-6"
                    :style="img3"/>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#dynamicCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#dynamicCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>    
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        logo: require('../../../../../../assets/images/ejw/brands/HillcountryInnovationsLogo.png'),
        background1: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/brands/Vendors_Dynamic_Header.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          backgroundAttachment: 'fixed'
        },
        img1: {
          backgroundImage: `url(${require('../../../../../../assets/images/ejw/brands/img1.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px'
        },
        img2: {
          backgroundImage: `url(${require('../../../../../../assets/images/ejw/brands/img2.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px'
        },
        img3: {
          backgroundImage: `url(${require('../../../../../../assets/images/ejw/brands/img3.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px'
        }
      }
    }    
  }
</script>

<style scoped>
  .carousel-indicators > li{
    height:10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--bs-secondary)
  }
  .carousel-inner{
    max-width: 1000px;
    margin: 0 auto;
  }
  .carousel{
    padding-bottom: 50px;
  }
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    transform: translateY(-50%);
  }
  .carousel-control-prev{
    width: 3%;
    left: -50px;
  }
  .carousel-control-prev-icon{
    background-color: lightgray;
  }
  .carousel-control-next{
    width: 3%;
    right: -50px;
  }
  .carousel-control-next-icon{
    background-color: lightgray;
  }
  .video-style{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>