<template>
  <div class="videoWrapper">
    <div
      class="videoInner"
      :style="activeStyle">
      <div class="header">
        <div class="col">
          <div class="float-start">
            Watch Now
          </div>
          <template v-if="multi">
            <i
              class="fa fa-arrow-alt-circle-right float-end p-1"
              @click="shift('up')"/>
            <i
              class="fa fa-arrow-alt-circle-left float-end p-1"
              @click="shift('down')"/>
          </template>
        </div>
      </div>
      <i
        class="fa fa-play-circle fa-3x text-light"
        style="opacity: 0.6;"
        data-bs-toggle="modal"
        data-bs-target="#videoModal"
        @click="setModalVideo"/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return{
        videos: null,
        position: 0,
        active: null
      }
    },
    mounted(){
      axios.get('/api/v1/private/video/get_videos').then(res=>{
        this.videos = _.orderBy(res.data.videos, 'position')
        this.active = this.videos[this.position]
      })
    },
    methods:{
      setActive(){
        const length = this.videos.length
        let abs = Math.abs(this.position)
        if(abs >= length){
          this.position = 0
          abs = this.position
        }
        this.active = this.videos[abs]
      },
      setModalVideo(){
        this.$store.dispatch('setModalVideo', this.active)
      },
      shift(direction){
        switch(direction){
          case 'up':
            this.position += 1
            this.setActive()
            break
          case 'down':
            this.position -= 1
            this.setActive()
            break
        }
      }
    },
    computed:{
      activeStyle(){
        if(this.active){
          return {
            backgroundImage: 'url(' + this.active.poster_url + ')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }
        }
      },
      multi(){return this.videos && this.videos.length > 1}
    }
  }
</script>

<style scoped>
  .videoWrapper{
    padding:25px 0px
  }
  .videoInner{
    position: relative;
    background: lightgray;
    height: 19.75dvw;
    width: 100%;
    max-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 120ms ease-in;
  }
  .videoInner:hover{
    filter: drop-shadow(2px 4px 6px black);
  }
  .header{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 2.5dvw;
    background: var(--bs-primary);
    width: 100%;
    color: white;
    padding: 0px 5px;
    display: flex;
    align-items: center;
  }
  .fa:hover{
    transform: scale(1.2);
    transition: 120ms ease-in;
    cursor: pointer;
  }
</style>