<template>
  <div style="overflow: hidden;">
    <div
      v-if="!loading && vendorList.length > 0"
      class="container animate__animated animate__fadeIn animate__faster">
      <div
        id="vendorCarousel"
        class="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="3000">
        <ol class="carousel-indicators">
          <li
            v-for="(list, index) in vendorList"
            :key="index"
            data-bs-target="#vendorCarousel"
            :data-bs-slide-to="index"
            :class="{active: index == 0}"/>
        </ol>
        <div class="carousel-inner my-5" role="listbox">
          <div
            v-for="(list, index) in vendorList"
            :key="index"
            :class="{active: index == 0}"
            class="carousel-item">
            <div
              v-if="list"
              class="row"
              style="margin-right: 0px; margin-left: 0px;">
              <VendorCard
                v-for="v in list"
                :key="v.name"
                :vendor="v"/>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#vendorCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#vendorCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div v-if="!loading && vendorList.length == 0">
      <div class="row">
        <div class="col-md-12 text-center text-muted">
          <h5>No Vendors</h5>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <Spinner class="my-5"/>
    </div>
  </div>
</template>

<script>
  import VendorCard from './VendorCard.vue'
  import Spinner from '../../../../global/Spinner.vue'

  export default {
    props: ['vendors', 'activeLetter'],
    data(){
      return{
        loading: false
      }
    },
    components:{
      Spinner,
      VendorCard

    },
    computed:{
      vendorList(){
        if(this.activeLetter){
          this.loading = true
          let base = _.filter(this.vendors, v => v.name.charAt(0).toLowerCase() == this.activeLetter.toLowerCase())
          setTimeout(function(){
            this.loading = false
          }.bind(this), 500)
          return _.chunk(base, 8)
        }else if(this.vendors){
          let base = _.sampleSize(this.vendors, 24)
          return _.chunk(base, 8)
        }else{
          return []
        }
      }
    }
  }
</script>

<style scoped>
  .letter-zoom:hover{
    transform: scale(2);
  }
  .carousel-control-prev{
    width: 3%;
    left: -50px;
  }
  .carousel-control-prev-icon{
    background-color: lightgray;
  }
  .carousel-control-next{
    width: 3%;
    right: -50px;
  }
  .carousel-control-next-icon{
    background-color: lightgray;
  }
  .dark-back{
    background-color: var(--bs-secondary);
    border-radius: 50%;
  }
  .carousel-indicators{
    bottom: -3em;
  }
  .carousel-indicators > li{
    height:10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--bs-secondary);
  }
</style>