<template>
  <div>
    <MessageArea
      v-if="!salesUser"
      class="p-1"/>
    <ActiveCustomer
      v-if="salesUser && !repDashboardView"
      class="d-block d-lg-none"/>
    <div v-if="['rep'].includes(user.role) && repDashboardView">
      <TmDashboard/>
    </div>
    <div v-if="managerRoles.includes(user.role) && repDashboardView">
      <ManagerDashboard/>
    </div>
    <div
      v-if="displayHome"
      class="animate__animated animate__fadeIn animate__faster">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-12 col-12">
            <Slider/>
          </div>
          <div class="col-md-3 d-none d-lg-block">
            <Video/>
          </div>
        </div>
      </div>
      <DesktopHome/>
      <MobileHome/>
    </div>
  </div>
</template>

<script>
  import MessageArea from './MessageArea.vue'
  import Slider from './Slider.vue'
  import Video from './Video.vue'
  import TmDashboard from './tm/TmDashboard.vue'
  import DesktopHome from './DesktopHome.vue'
  import MobileHome from './MobileHome.vue'
  import ActiveCustomer from './tm/ActiveCustomer.vue'
  import ManagerDashboard from './manager/ManagerDashboard.vue'
  import {setNotification} from '../../../../shared/Notification'
  import {internalRoles, managerRoles} from '../../../../shared/roles'
  
  export default {
    data(){
      return{
        managerRoles,
        internalRoles
      }
    },
    methods:{
      goto(view){
        this.$router.push({name: view})
        this.$store.dispatch('closeLeftMenu')
      },
      testNotification(){
        setNotification('Success', 'success')
      }
    },
    components:{
      MessageArea,
      Slider,
      Video,
      TmDashboard,
      DesktopHome,
      MobileHome,
      ActiveCustomer,
      ManagerDashboard
    },
    computed:{
      user(){return this.$store.getters.user},
      repDashboardView(){return this.$store.getters.repDashboardView},
      salesUser(){
        return internalRoles.includes(this.user.role)
      },
      displayHome(){
        return !this.salesUser || this.salesUser && !this.repDashboardView
      }
    }
  }
</script>

