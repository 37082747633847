<template>
  <div
    v-if="!virtualShow || virtualShow && showDetail && !showDetail.dropship"
    :class="showHome ? 'home' : ''"
    clas="col-md-12">
    <EjwTable
      v-if="env.includes('EJW')"
      :product="product"
      :branchAvail="branchAvail"
      :stockFlagInBranch="stockFlagInBranch"
      :invInBranch="invInBranch"
      :activeUom="activeUom"
      :home="home"
      :showHome="showHome"/>
    <LanTable
      v-else
      :product="product"
      :branchAvail="branchAvail"
      :stockFlagInBranch="stockFlagInBranch"
      :invInBranch="invInBranch"
      :home="home"
      :showHome="showHome"/>
  </div>
</template>

<script>
  import {branchAvail} from '../../../../../shared/availability'
  import {stockFlagInBranch, invInBranch} from '../../../../../shared/stockHelpers'
  import LanTable from './LanTable.vue'
  import EjwTable from './EjwTable.vue'

  export default{
    inject:['env'],
    props: ['virtualShow', 'showDetail', 'activeUom', 'product', 'showHome'],
    components:{
      EjwTable,
      LanTable
    },
    computed:{
      branchAvail(){if(this.product) return branchAvail(this.product, this.activeUom)},
      stockFlagInBranch(){return stockFlagInBranch(this.product)},
      invInBranch(){return invInBranch(this.product)},
      home(){return this.$store.getters.homeBranch}
    }
  }
</script>

<style scoped>
  .home{
    position: relative;
    top: 8px;
    left: 3%;
  }
</style>