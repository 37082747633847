<template>
  <div>
    <!-- Add Button to start editing -->
    <button
      v-if="!editing && allowed && !cartProduct.comment"
      :disabled="loading"
      class="btn btn-link btn-sm comment-btn"
      @click="toggleEdit">
        <span
          v-if="!cartProduct.comment"
          class="text-muted">
          <i class="fa fa-plus"/>
          Comment
        </span>
    </button>
    <!-- Existing Comment -->
    <div
      v-if="!editing && cartProduct.comment"
      class="col-md-12 p-1">
      <!-- Standard Comment -->
      <div class="row">
        <div class="col-md-12">
          <div
            class="small text-muted"
            style="word-wrap: break-word;">
            <i
              v-if="comment && !comment.includes('Equipment') && !loading"
              class="fa fa-edit text-muted d-inline"
              style="cursor: pointer;"
              @click="toggleEdit"/>
            <span>Comment: {{cartProduct.comment}}</span>
          </div>
        </div>
    </div>
    </div>
    <!-- Editing Form -->
    <div
      v-show="editing"
      class="p-1 position-relative">
      <div class="row">
        <div class="col-md-12">
          <!-- Autosave Notice -->
          <div
            v-if="autosaved && !showWait"
            class="float-end">
            <span class="badge bg-success animate__animated animate__bounceIn animate__faster">
              <i class="fa fa-check-circle"/>
              Comment Saved!
            </span>
          </div>
          <!-- Wait indicator -->
          <span
            v-if="showWait"
            class="float-end">
            <i class="fa fa-cog fa-spin"/>
          </span>
        </div>
      </div>
      <!-- FORM -->
      <form
        name="commentForm"
        @submit.prevent="update"
        class="row">
        <div class="col-md-12 overflow-hidden">
          <div class="position-relative animate__animated animate__fadeInDown animate__faster">
            <textarea
              :id="'commentForm' + cartProduct.eclipse_id"
              class="form-control"
              type="text"
              rows="5"
              placeholder="Add comment"
              :disabled="shippingCommentApplied && !internalRoles.includes(user.role)"
              v-model="comment"
              @keydown="keyStroke"
              required/>
            <!-- Actions -->
            <div
              v-if="!confirm"
              class="btn-group float-end">
              <button
                class="btn btn-sm btn-outline-primary"
                @click="cancel"
                type="button">
                  <i class="fa fa-times"/>
                Close
              </button>
              <button
                class="btn btn-sm btn-outline-primary"
                :disabled="!comment"  
                @click="clear"
                type="button">
                <i class="fa fa-eraser"/>
                Clear
              </button>
              <button
                class="btn btn-sm btn-outline-danger"
                :disabled="!cartProduct.comment"
                @click="toggleConfirm"
                type="button">
                <i class="fa fa-trash"/>
                Delete
              </button>
            </div>
            <!-- Confirm Delete -->
            <div
              v-else
              class="btn-group float-end animate__animated animate__bounceIn animate__faster">
              <strong class="text-danger me-1">Are you sure?</strong>
              <button
                class="btn btn-sm btn-outline-danger"
                @click="removeComment"
                type="button">
                  <i class="fa fa-check"/>
                Yes
              </button>
              <button
                class="btn btn-sm btn-outline-primary"
                @click="toggleConfirm"
                type="button">
                <i class="fa fa-times"/>
                No
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import {internalRoles} from '../../../../shared/roles'
  
  export default {
    props: ['cartProduct', 'allowed', 'shippingCommentApplied', 'loading'],
    data(){
      return{
        internalRoles,
        showComment: false,
        editing: false,
        comment: null,
        input: document.getElementById(`commentForm${this.cartProduct.eclipse_id}`),
        typeTimer: null,
        autosaved: false,
        confirm: false,
        showWait: false
      }
    },
    created(){
      if(this.cartProduct.comment){
        this.comment = this.cartProduct.comment
      }
    },
    watch:{
      productComment(newVal){
        if(newVal){
          this.comment = newVal
        }
      }
    },
    methods:{
      toggleEdit(){
        this.editing = !this.editing
        if(this.editing){
          this.autosaved = false
        }
      },
      cancelEdit(){
        this.editing = false
      },
      removeComment(){
        this.comment = null
        this.cartProduct.comment = null
        this.confirm = false
        this.update()
      },
      update(){
        clearTimeout(this.typeTimer)
        this.editing = false
        this.cartProduct.comment = this.comment
        this.$emit('updateProduct')
      },
      autosave(){
        this.cartProduct.comment = this.comment
        this.$emit('updateProduct')
      },
      clear(){
        clearTimeout(this.typeTimer)
        this.comment = null
        document.getElementById(`commentForm${this.cartProduct.eclipse_id}`).focus()
      },
      cancel(){
        this.editing = false
        this.comment = this.cartProduct.comment
      },
      keyStroke(){
        // reset timer on keystroke
        if(this.typeTimer){
          clearTimeout(this.typeTimer)
          this.showWait = true
        }
        this.typeTimer = setTimeout(function(){
          this.autosave()
          this.showWait = false
          this.autosaved = true
        }.bind(this), 2000)
      },
      toggleConfirm(){
        this.confirm = !this.confirm
      }
    },
    computed:{
      user(){return this.$store.getters.user},
      productComment(){return this.cartProduct.comment}
    }
  }
</script>

<style scoped>
  .comment-btn{
    font-size:12px;
  }
  .clear-comment{
    position: absolute;
    top: 3px;
    right: 5px;
    cursor: pointer;
  }
  .form-control{
    box-shadow: none !important;
  }
</style>