<template>
  <!-- marketing@lancasterco.com -->
  <div>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Company Name"
      v-model="form.company"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="First Name"
      v-model="form.firstName"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Last Name"
      v-model="form.lastName"
      required>
    <input
      type="email"
      class="form-control my-2"
      placeholder="Email Address"
      v-model="form.email"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Phone Number"
      v-model="form.phone"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Product Categories"
      v-model="form.categories"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Estimated Number of Skus"
      v-model="form.skus"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Website"
      v-model="form.website"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Current Distribution Source"
      v-model="form.dist"
      required>
    <textarea
      type="text"
      rows="5"
      cols="5"
      class="form-control my-2"
      v-model="form.comments"
      placeholder="Additional Comments"/>
  </div>
</template>

<script>
  export default {
    props: ['form']
  }
</script>