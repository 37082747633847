<template>
  <div class="dropdown d-inline-block ">
    <button
      class="btn btn-sm btn-link px-1 dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown">
      Manage Carts
    </button>
    <ul
      class="dropdown-menu shadow"
      style="z-index: 1021; padding: .3rem;">
      <li
        v-for="(cart,index) in cartList"
        :key="cart.id"
        class="position-relative"
        :class="(index + 1) != cartList.length ? 'border-line' : ''">
        <div class="p-1 position-relative">
          <i class="fa fa-shopping-cart text-muted"/>
          {{ cart.cart_name || 'No Name' }}
          <div>
            <!-- Details -->
            <small class="text-muted">
              <template v-if="cart.id == activeCart.details.id">
                <span class="text-success">
                  <i class="fa fa-check-circle me-1"/>
                  <strong>Active</strong>
                </span>
                <br>
              </template>
              <!-- Badges -->
              <div
                v-if="cart.vshow_cart"
                class="badge bg-success badge-size">
                <i class="fa fa-laptop"/>
                Virtual Show
              </div>
              <div
                v-else
                class="badge bg-primary badge-size">
                <i class="fa fa-shopping-cart"/>
                Standard
              </div>
              <div>
                Products: {{ cart && cart.cart_products ? cart.cart_products.length : 0}}
              </div>
              <div>
                Total: {{ numeral(cart.cart_total).format('$0,0.00') }}
              </div>
            </small>
            <div class="action-btns">
              <button
                v-if="cart.id != activeCart.details.id"
                class="btn btn-sm btn-primary action-btn"
                @click="setCart(cart.id)">
                <i class="fa fa-power-off"/>
                Activate
              </button>
              <button
                v-if="cart.id == activeCart.details.id && cartCopyAllowed"
                class="btn btn-sm btn-primary action-btn"
                :disabled="productCount == 0"
                data-bs-toggle="modal"
                data-bs-target="#cartCopyMoveModal">
                <i class="fa fa-copy"/>
                Copy / Move
              </button>
            </div>
          </div>
        </div>
      </li>
      <li><hr class="dropdown-divider"></li>
      <li>
        <router-link
          class="dropdown-item"
          to="/manage_carts">
          <i class="fa fa-arrow-alt-circle-right"/>
          Go To Manage Carts
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  import axios from 'axios'
  import { cartCopyAllowed } from '../../../../shared/cartCopy'

  export default{
    data(){
      return{
        cartCopyAllowed: cartCopyAllowed()
      }
    },
    methods:{
      setCart(id){
        if(id == this.activeCart.details.id){
          return
        }
        axios.post('/api/v1/private/manage_carts/set_active', {id}).then(res => {
          if(res.data){
            this.$store.dispatch('getCart')
          }
        })
      }
    },
    watch:{
      activeCart(newVal){
        if(newVal){
          this.$store.dispatch('getCarts')
        }
      }
    },
    computed:{
      cartList(){return _.sortBy(this.$store.getters.cartList, c=> c.id == this.activeCart.details.id ? 0:1)},
      activeCart(){return this.$store.getters.cart},
      productCount(){return this.activeCart.cartProducts.length}
    }
  }
</script>

<style scoped>
  .badge-size{
    font-size: 12px !important;
    width: 100px;
    padding: 3px;
  }
  .dropdown-menu{
    width: 320px;
    max-height: 465px;
    overflow-y: scroll;
  }
  .border-line{
    border-bottom: 1px solid lightgray;
  }
  .action-btns{
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .action-btn{
    width: 120px;
  }
</style>