<template>
  <div class="container">
    <div class="col-12 text-center mt-5">
      <video
        :src="video"
        class="img-fluid mt-5 shadow"
        controls
        loop>
      </video>
    </div>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        video: 'https://lancaster-video.s3.amazonaws.com/CheneyVroomanEstoreCustomerTour.mp4'
      }
    }
  }
</script>