<template>
  <div class="animate__animated animate__fadeIn animate__faster">
    <div
      v-if="!searchSubmitted && !subView"
      class="row mt-5">
      <div class="col-md-12 text-center">
        <h2 mt-5>Welcome</h2>
        <div class="text-muted">
          Search for show products here or use any of the menus above to browse our offerings
        </div>
        <div class="mt-3">
          <button
            class="btn btn-outline-primary btn-sm animate__animated animate__bounceIn"
            @click="this.$emit('goto', 'terms')">
            Show Terms
          </button>
        </div>
        <Search @searchSubmitted="this.$emit('searchSubmitted')"/>
        <div class="my-2">
          <small class="text-muted">Browse by</small>
        </div>
        <button
          v-if="!internalRoles.includes(user.role)"
          class="btn btn-primary btn-sm m-1"
          @click="browseFavorites">
          <i class="fa fa-heart"/>
          Favorites
        </button>
        <button
          class="btn btn-primary btn-sm m-1"
          @click="browseReorderPad">
          <i class="fa fa-sync"/>
          ReorderPad
        </button>
        <button
          class="btn btn-primary btn-sm m-1"
          @click="this.$emit('setSubView', 'categories')">
          <i class="fa fa-sitemap"/>
          Categories
        </button>
        <!-- <button
          v-if="earlyBirdActive"
          class="btn btn-primary btn-sm m-1"
          @click="browseEarlyBirds">
          <i class="fa fa-dove"/>
          Early Birds
        </button> -->
        <button
          class="btn btn-primary btn-sm m-1"
          @click="browseHotBuys">
          <i class="fa fa-fire"/>
          Hot Buys
        </button>
        <button
          class="btn btn-primary btn-sm m-1"
          @click="browseNew">
          <i class="fa fa-certificate"/>
          New Products
        </button>
      </div>
    </div>
    <div v-if="subView == 'categories'">
      <CategoryBrowse
        :categories="categories"
        @goBack="this.$emit('goBack')"
        @searchSubmitted="this.$emit('searchSubmitted')"/>
    </div>
    <div
      v-if="searchSubmitted"
      class="row">
      <Results @goBack="this.$emit('goBack')"/>
    </div>
  </div>
</template>

<script>
  import Search from './Search.vue'
  import CategoryBrowse from './CategoryBrowse.vue'
  import Results from '../Results.vue'
  import {internalRoles} from '../../../../../../shared/roles'
  import moment from 'moment'
  
  export default {
    props: ['categories', 'activeView', 'subView', 'searchSubmitted'],
    data(){
      return{
        logo: require('../../../../../../../assets/images/lan/virtual_show/vShowLogoDark.png'),
        internalRoles
      }
    },
    components:{
      Search,
      CategoryBrowse,
      Results
    },
    methods:{
      closeTermsWarning(){
        this.$store.dispatch('closeTermsWarning')
      },
      browseFavorites(){
        this.$router.push({
          name: 'VirtualShow',
          query: {action: 'virtualShowFavorites'}
        })
      },
      browseReorderPad(){
        this.$router.push({
          name: 'VirtualShow',
          query: {action: 'virtualShowReorderPad'}
        })
      },
      browseEarlyBirds(){
        this.$router.push({
          name: 'VirtualShow',
          query: {action: 'virtualShowEarlyBirds'}
        })
      },
      browseHotBuys(){
        this.$router.push({
          name: 'VirtualShow',
          query: {action: 'virtualShowHot'}
        })
      },
      browseNew(){
        this.$router.push({
          name: 'VirtualShow',
          query: {action: 'virtualShowNew'}
        })
      }
    },
    computed:{
      loading(){return this.$store.getters.productLoadState},
      user(){return this.$store.getters.user},
      earlyBirdActive(){
        const showDates = {
          startDate: moment(this.$store.getters.vShowDates.start_date),
          endDate: moment(this.$store.getters.vShowDates.end_date)
        }
        const today = moment(new Date())
        // 3 day early bird window
        return today.diff(showDates.startDate, 'days') <= 2 && this.$store.getters.sessionCompany == 'Lancaster US'
      }
      
      // termsWarning(){return this.$store.getters.vShowTermsWarning}
    }
  }
</script>

<style scoped>
  .search{
    max-width: 600px;
    margin: 0 auto;
  }
  .icon{
    max-width: 500px;
  }
  .close-times{
    position: absolute;
    top: 3px;
    right: 7px;
    cursor: pointer;
  }
</style>