<template>
  <div>
    <!-- Lancasters Brand -->
    <div
      class="row"
      style="height: 300px;">
      <div class="col-md-8 p-0" :style="background1"/>
      <div class="col-md-4 bg-secondary text-white position-relative p-5">
        <div class="centered">
          <h2 style="font-weight: 400;">LANCASTER'S PROPRIETARY BRAND</h2>
          <p>Founded on one vision: designing and producing quality products at a competitive price.</p>
        </div>
      </div>
    </div>
    <!-- Dynamic Slider -->
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
           <img
            :src="logo"
            style="width: 250px;"
            class="img-fluid mt-5">
        </div>
      </div>
      <div class="row my-5">
        <div class="col-md-12">
          <div id="dynamicCarousel" class="carousel slide" data-bs-ride="carousel">
            <ol class="carousel-indicators">
              <li data-bs-target="#dynamicCarousel" data-bs-slide-to="0" class="active"></li>
              <li data-bs-target="#dynamicCarousel" data-bs-slide-to="1"></li>
              <li data-bs-target="#dynamicCarousel" data-bs-slide-to="2"></li>
            </ol>
            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-md-6">
                    <p>Dynamic has strategically partnered with <span class="fw-bold">OVER 60 MANUFACTURING PARTNERS</span> around the world. Combined with the spirit of innovation, a commitment to impeccable service, the latest logistical and manufacturing technologies, Dynamic now supplies 800 SKU’S. Whether your customer is a seasoned contractor or a first time DIYer, Dynamic has something to delight them.</p>
                  </div>
                  <div
                    class="col-md-6"
                    :style="img1"/>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-6">
                    <p>The breadth of <span class="fw-bold">DYNAMIC PRODUCT OFFERINGS</span> is expansive, including; caulk, spackle, putty knives, tape, sanding sponges, drop cloths, rags, brushes, roller trays, roller handles, roller covers and a wide variety of paint accessories. That breadth offers you the ability to display many products together without the need to spend hours merchandising. This is made even easier due to the multitude of premade assortments or displays available through Lancaster.</p>
                  </div>
                  <div
                    class="col-md-6"
                    :style="img2"/>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-6">
                    <p>The <span class="fw-bold">DYNAMIC PROGRAM</span> from Lancaster provides you with the flexibility to offer a wide variety of products, covering multiple price points that fit your unique local demographics. With that flexibility, you can improve your margins, margin dollars and net profit, without committing to large inventories. You get just what you need, when you need it, at a competitive price.</p>
                  </div>
                  <div
                    class="col-md-6"
                    :style="img3"/>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#dynamicCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#dynamicCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>    
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        logo: require('../../../../../../assets/images/logos/dynamic-logo.png'),
        background1: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/brands/Vendors_Dynamic_Header.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          backgroundAttachment: 'fixed'
        },
        img1: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/brands/Vendors_Dynamic_Products_1.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px'
        },
        img2: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/brands/Vendors_Dynamic_Products_2.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px'
        },
        img3: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/brands/Vendors_Dynamic_Products_3.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px'
        }
      }
    }    
  }
</script>

<style scoped>
  .carousel-indicators > li{
    height:10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--bs-secondary)
  }
  .carousel-inner{
    max-width: 1000px;
    margin: 0 auto;
  }
  .carousel{
    padding-bottom: 50px;
  }
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    transform: translateY(-50%);
  }
  .carousel-control-next-icon:after{
    content: '>';
    font-size: 35px;
    color: gray;
  }
  .carousel-control-prev-icon:after {
    content: '<';
    font-size: 35px;
    color: gray;
  }
  .carousel-control-next{
    right: -50px;
  }
  .carousel-control-prev{
    left: -50px;
  }
</style>