<template>
  <div class="border rounded shadow-sm p-1 my-2">
    <div class="row">
      <div class="col-md-12">
        <div v-if="!showConfirm">
          <button
            class="btn btn-sm btn-link float-end"
            @click="toggleShowConfirm">
            <i class="fa fa-trash-alt"/>
            Delete
          </button>
          <button
            class="btn btn-sm btn-link float-end"
            @click="$emit('edit', asset)">
            <i class="fa fa-pencil-alt"/>
            Edit
          </button>
        </div>
        <div
          v-else  
          class="float-end me-2 animate__animated animate__bounceIn animate__faster text-danger">
          <strong>
            <i class="fa fa-exclamation-triangle"/>
            Are you sure?
          </strong>
          <div class="input-group">
            <button
              @click="deleteAsset"
              class="btn btn-sm btn-link text-danger">
              Yes
            </button>
            <button
              @click="toggleShowConfirm"
              class="btn btn-sm btn-link text-danger">
              No
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3 text-center">
        <img
          :src="asset.thumbnail"
          class="img-fluid pdf">
        <br>
        <a
          :href="asset.pdf"
          target="_blank"
          class="btn btn-link">
          Preview PDF
        </a>
      </div>
      <div class="col-md-9">
        Title: {{ asset.promo_title }}
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props: ['asset'],
    data(){
      return{
        showConfirm: false
      }
    },
    methods:{
      toggleShowConfirm(){
        this.showConfirm = !this.showConfirm
      },
      deleteAsset(){
        this.$store.dispatch('deletePromoAsset', this.asset.id)
      }
    }
  }
</script>