<template>
  <div class="create container my-5">
    <div
      v-if="showForm"
      class="row">
      <div class="col-md-12">
        <form
          name="fav"
          class="animate__animated animate__fadeIn animate__faster"
          @submit.prevent="submit">
          <h2 class="text-center">Create Favorite Group</h2>
          <div class="form-group">
            <label for="favName">Enter Group Name</label>
            <input
              id="favName"
              v-model="fav.name"
              class="form-control"
              autocomplete="off"
              required/>
          </div>
          <div class="form-group">
            <label for="favDesc">Enter Group Description</label>
            <input
              id="favDesc"
              v-model="fav.description"
              class="form-control"
              autocomplete="off"/>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
               <div class="d-grid">
                <button
                  type="button"
                  class="btn btn-primary my-2"
                  @click="toggleFavForm">
                  <i class="fa fa-times-circle"></i>
                  &nbsp;Cancel
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-grid">
                <button
                  class="btn btn-primary my-2"
                  type="submit">
                  <i class="fa fa-check-circle"></i>
                  &nbsp;Submit
                </button>
              </div>
            </div>
          </div>          
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:['showForm'],
    data(){
      return{
        fav:{}
      }
    },
    methods:{
      toggleFavForm(){
        this.$emit('toggleFavForm')
      },
      submit(){
        this.$store.dispatch('createFavGroup', this.fav)
        this.fav = {}
        this.toggleFavForm()
      }
    }
  }
</script>

<style scoped>
  @media(min-width: 992px){
    .create{
      max-width: 60%;
      margin: 0 auto;
    }
  }
</style>