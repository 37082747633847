<template>
  <div class="container mt-2">
    <div class="row">
      <div
        v-if="!loading && !cartLoading"
        class="col-md-12">
        <button
          v-if="!creating"
          class="btn btn-primary btn-sm float-end"
          @click="toggleCreate">
          <i class="fa fa-plus"/>
          Create Cart
        </button>
        <a
          v-else
          href="#"
          class="float-end"
          @click="toggleCreate">
          <i
            class="fa fa-times text-muted m-2"
            style="font-size: 25px;"/>
        </a>
      </div>
      <!-- Create Cart -->
      <CreateCart
        v-if="creating"
        @cartCreated="updateCarts"/>
      <!-- Cart Lisitng -->
      <div class="col-md-12">
        <div
          v-if="!creating && !loading && !cartLoading"
          class="row">
          <h3>Your Carts</h3>
          <CartCard
            v-for="c in carts"
            :key="c.id"
            class="col-lg-4 col-md-12"
            :cart="c"
            :activeCart="activeCart"
            :cartCopyAllowed="cartCopyAllowed"
            @setCartActive="setActiveCart"/>
        </div>
      </div>
      <span
        v-if="loading || cartLoading"
        class="text-center mt-5">
        <Spinner/>
      </span>
    </div>
  </div>
</template>

<script>
  import CartCard from './CartCard.vue'
  import CreateCart from './CreateCart.vue'
  import Spinner from '../../../../global/Spinner.vue'
  import { cartCopyAllowed } from '../../../../../shared/cartCopy'

  export default {
    data(){
      return{
        creating: false,
        loading: true,
        cartCopyAllowed: cartCopyAllowed()
      }
    },
    components:{
      CartCard,
      CreateCart,
      Spinner
    },
    created(){
      if(!this.settings.manageCarts){
        this.$router.push({path: '/'})
      }
    },
    async mounted(){
      await this.$store.dispatch('getCart')
      this.$store.dispatch('getCarts')
    },
    watch:{
      carts(newCart){
        if(newCart){
          this.loading = false
        }
      }
    },
    methods:{
      toggleCreate(){
        this.creating = !this.creating
      },
      updateCarts(cart){
        this.$store.dispatch('getCarts')
        this.creating = false
      },
      setActiveCart(id){
        this.$store.dispatch('getCart')
      }
    },
    computed:{
      activeCart(){return this.$store.getters.cart},
      carts(){return _.sortBy(this.$store.getters.cartList, c=> c.id == this.activeCart.details.id ? 0:1)},
      settings(){return this.$store.getters.settings},
      cartLoading(){return this.$store.getters.cartLoading}
    }
  }
</script>