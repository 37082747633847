<template>
  <div>
    <div
      v-if="!sessionID"
      class="row">
      <div class="col-md-12 text-center mt-5">
        Generating Labels...<Spinner/> 
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="col-md-12">
        <button
          class="btn btn-primary btn-sm float-end"
          @click="exit">
          Exit
        </button>
        <iframe
          v-if="url && sessionID"
          :src="url"
          frameborder="0"
          class="mt-2"
          style="width:100%;height:70vh;border: 1px solid lightgray; border-radius: 10px;"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  export default {
    created(){
      this.$store.dispatch('getBinLabels')
    },
    components:{
      Spinner
    },
    methods:{
      exit(){
        this.$store.dispatch('masterReset')
      }
    },
    computed:{
      sessionID(){return this.$store.getters.binLabelSessionID},
      url(){return encodeURI('https://binlabels.lancasterco.com?SessionId=' + this.sessionID)}
    }
  }
</script>