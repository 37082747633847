<template>
  <div
    v-if="brand"
    class="text-muted">
    <span class="me-1">Brand:</span>
    <router-link :to="`/browse_brands/${brand}`">
      {{ brand }}
    </router-link>
  </div>
</template>

<script>
  export default {
    props: ['product'],
    computed:{
      brand(){
        return this.product.mfg
      }
    }
  }
</script>