import axios from  'axios'

const initialState = {
  branches: []
}

export const branchStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setBranches(state, branchData){
      state.branches = branchData
    }
  },
  actions:{
    branchResetState(context){
      context.commit('resetState')
    },
    getBranchDetails(context){
      axios.get('/api/v1/public/branches/get_branch_details').then(res =>{
        context.commit('setBranches', res.data.branches)
      })
    }
  },
  getters:{
    getBranches: state =>{return state.branches}
  }
}