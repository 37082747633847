<template>
  <div
    v-for="b in branches"
    class="col-md-6">
    <div class="card my-2 p-2">
      <div style="line-height: 20px;">
        <div>
          <h5>
            {{ b.branch_id }} {{ b.city }}
          </h5>
          <span class="badge bg-success mb-1">
            <i class="fa fa-check"/>
            PROSUPPLY
          </span>
        </div>
        <div>
          {{ b.address }}
          {{ b.city }}
        </div>
        <div>
          {{ b.state }},
          {{ b.zip }}
        </div>
        <div>
          Phone:&nbsp;
          <a :href="'tel:' + b.branch_contact.phone">{{ b.branch_contact.phone }}</a>
        </div>
        <div>
          Email:&nbsp;
          <a :href="'mailto:' + b.branch_contact.email">{{ b.branch_contact.email }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props: ['branches']
  }
</script>