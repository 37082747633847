<template>
  <div class="d-none d-lg-block">
    <!-- <div class="container-fluid">
      <RecentlyViewed/>
    </div> -->
    <div class="container">
      <div class="row mt-1">
        <!-- Desktop Browse Products-->
        <div
          v-if="settings.shopByCategory"
          class="col p-1">
          <div
            class="wrapper shadow"
            :style="env.includes('EJW') ? background1Ejw : background1Lan"
            @click="goto('BrowseProducts')">
            <div class="inner text-center">
              <div class="text-wrapper">
                <span>BROWSE PRODUCTS</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Desktop Closeouts-->
        <div
          v-if="settings.closeoutDeals"
          class="col p-1">
          <div
            class="wrapper shadow"
            :style="background2"
            @click="goto('Closeouts')">
            <div class="inner text-center">
              <div class="text-wrapper">
                <span>CLEARANCE</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Desktop New Products-->
        <div
          v-if="settings.newProducts"
          class="col p-1">
          <div
            class="wrapper shadow"
            :style="background4"
            @click="goto('NewProducts')">
            <div class="inner text-center">
              <div class="text-wrapper">
                <span>NEW PRODUCTS</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Desktop Promos-->
        <div
          v-if="settings.promotions && promoReceive"
          class="col p-1">
          <div
            class="wrapper shadow"
            :style="background5"
            @click="goto('Promos')">
            <div class="inner text-center">
              <div class="text-wrapper">
                <span>PROMOTIONS</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="settings.shopByCategory"
      class="container">
      <Categories/>
    </div>
  </div>
</template>

<script>
  import RecentlyViewed from './RecentlyViewed.vue'
  import Categories from './Categories.vue'
  
  export default {
    inject: ['env'],
    data(){
      return{
        background1Lan: {
          background: `url(${require('../../../../../assets/images/lan/private_home/BROWSE_PRODUCTS.jpg')})`,
          ...this.buildStyle()
        },
        background1Ejw: {
          background: `url(${require('../../../../../assets/images/ejw/home/BrowseProducts.jpg')})`,
          ...this.buildStyle()
        },
        background2: {
          background: `url(${require('../../../../../assets/images/lan/private_home/CLOSEOUT_DEALS.jpg')})`,
          ...this.buildStyle()
        },
        background3Lan: {
          background: `url(${require('../../../../../assets/images/lan/private_home/REORDER_PAD.jpg')})`,
          ...this.buildStyle()
        },
        background3Ejw: {
          background: `url(${require('../../../../../assets/images/ejw/home/OrderingTools.jpg')})`,
          ...this.buildStyle()
        },
        background4: {
          background: `url(${require('../../../../../assets/images/lan/private_home/NEW_PRODUCTS.jpg')})`,
          ...this.buildStyle()
        },
        background5: {
          background: `url(${require('../../../../../assets/images/lan/private_home/PROMOTIONS.jpg')})`,
          ...this.buildStyle()
        },
        background6: {
          background: `url(${require('../../../../../assets/images/lan/private_home/DROP_SHIPS.jpg')})`,
          ...this.buildStyle()
        },
      }
    },
    methods:{
      buildStyle(){
        const style = {
          'backgroundSize': 'cover',
          'backgroundRepeat': 'no-repeat',
          'backgroundPosition': 'top'
        }
        return style
      },
      goto(view){
        switch(view){
          case 'Closeouts':
            this.$router.push({name: 'ProductSearch', query: {action: 'clearance'}})
            break
          case 'NewProducts':
            this.$router.push({name: 'ProductSearch', query: {action: 'new'}})
            break
        }
        this.$router.push({name: view})
      }
    },
    components:{
      RecentlyViewed,
      Categories
    },
    computed:{
      settings(){return this.$store.getters.settings},
      promoReceive(){return this.$store.getters.promoReceive},
      settings(){return this.$store.getters.settings}
    }
  }
</script>

<style scoped>
.wrapper{
    height: 220px;
    overflow: hidden;
  }
  .wrapper:hover{
    transform: scale(1.02);
    transition: .2s ease-in-out;
  }
  .inner{
    color:white;
    height: inherit;
    font-weight: 600;
    font-size: 25px;
    background: rgb(var(--bs-dark-rgb), 0.5);
    text-shadow: 1px 1px 5px black;
  }
  .inner:hover{
    background: rgb(var(--bs-dark-rgb), 0.3);
    transition: .2s ease-in-out;
    cursor: pointer;
  }
  .text-wrapper{
    transform: translateY(3em);
  }
  .text-wrapper2{
    transform: translateY(2em);
  }
</style>