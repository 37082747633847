<template>
  <tr>
    <td>{{ b }}</td>
    <td>
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          style="cursor: pointer; float: none !important;"
          v-model="form.allowedBranches[b]"
          checked="false"
          type="checkbox">
      </div>
    </td>
    <td style="width: 20vw;">
      <select
        class="form-select form-select-sm"
        style="cursor: pointer;"
        v-model="form.priceBranches[b]">
        <option
          v-for="b in branchListing"
          :key="b"
          :value="b">
          {{ b }}
        </option>
      </select>
    </td>
  </tr>
</template>

<script>
  export default{
    props: ['b', 'form', 'sellBranches', 'priceBranches', 'branchListing'],
    mounted(){
      this.sellBranches.forEach(sb => {
        this.form.allowedBranches[sb] = true
      })

      this.priceBranches.forEach(pb => {
        this.form.priceBranches[Object.keys(pb)[0]] = Object.values(pb)[0]
      })
    }
  }
</script>