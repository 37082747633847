<template>
  <div
    v-if="cartProduct && cartProduct.product_data"
    class="row p-2 position-relative">
    <div
      v-if="!loading"
      class="col-md-12">
      <div class="float-end">
        <i
          v-if="!showConfirm"
          class="fa fa-times text-danger"
          id="flyoutRemove"
          style="cursor: pointer;"
          @click="toggleConfirm"/>
        <!-- Confirm Delete -->
        <div
          v-if="showConfirm"
          class="animate__animated animate__fadeInRight animate__faster">
          <div class="d-inline-block input-group text-danger">
            <span>Are you sure?</span>
            <button
              class="btn btn-link btn-sm text-danger"
              id="flyoutConfirmYes"
              @click="removeItem">
              Yes
            </button>
            <button
              class="btn btn-link btn-sm text-danger"
              id="flyoutConfirmNo"
              @click="toggleConfirm">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <Indicator
      :product="cartProduct.product_data"
      :virtualShow="cart.details.vshow_cart"/>
    <div class="col-4 text-center mt-4">
      <img
        class="img-fluid"
        style="max-height:100px;"
        :src="cartProduct.product_data.image_url"
        @error="imgError">
    </div>
    <div class="col-8 text-muted">
      <div class="mt-2">
        {{cartProduct.product_data.description}}
      </div>
      <Exclusion :product="cartProduct.product_data"/>
      <!-- Pricing -->
      <small
        v-if="cartProduct.product_data.eclipse_data"
        class="text-primary">
        <strong :class="promoProduct ? 'text-success' : ''">
          Unit Price: {{numeral(cartProduct.pricing.unit_price).format('$0,0.00')}}
        </strong>
        <small  
          v-if="cartProduct.product_data.eclipse_data.price_uom == 'sf'"
          class="text-muted">
          /sqft
        </small>
        <br>
        Ext Price:
        <span v-if="loading">
          <Spinner style="height: 15px; width: 15px;"/>
        </span>
        <span v-else>{{numeral(cartProduct.pricing.extended_price).format('$0,0.00')}}</span>
        <br>
        Qty
        <span class="text-muted">({{displayUom(cartProduct.uom)}})</span>
        <form
          @submit.prevent="updateQty"
          class="input-group">
          <button
            type="button"
            class="btn btn-sm btn-primary p-1 d-none d-sm-block qty"
            :disabled="loading"
            @click="qtyMod('down')">
            <div class="fa fa-minus"/>
          </button>
          <input
            class="form-control form-control-sm qty-form"
            :disabled="loading"
            style="padding: 0px 10px;"
            :min="step"
            :step="step"
            v-model="cartProduct.quantity"
            type="number">
          <button
            type="button"
            class="btn btn-sm btn-primary p-1 d-none d-sm-block qty"
            :disabled="loading"
            @click="qtyMod('up')">
            <div class="fa fa-plus"/>
          </button>
          <div class="overflow-hidden w-100">
            <button
              v-if="showUpdate"
              class="w-100 mt-1 btn btn-outline-primary qty-btn animate__animated animate__slideInDown animate__faster"
              type="submit"
              id="flyoutUpdate">
              <i class="fa fa-sync"/>
              Update
            </button>
          </div>
        </form>
      </small>
    </div>
  </div>
</template>

<script>
  import Exclusion from '../../views/product/Exclusion.vue'
  import {promoProductV2} from '../../../../shared/promoHelpers'
  import Indicator from '../../views/product/Indicator.vue'
  import comingSoon from '../../../../shared/comingSoon'
  import Spinner from '../../../global/Spinner.vue'
  import { displayUom } from '../../../../shared/ProductFunctions'
  
  export default {
    props: ['cartProduct', 'loading'],
    data(){
      return{
        showUpdate: false,
        showConfirm: false
      }
    },
    components:{
      Exclusion,
      Indicator,
      Spinner
    },
    watch:{
      qty(newVal){
        if(newVal){
          this.showUpdate = true
        }
      }
    },
    methods:{
      imgError(e){
        comingSoon(e)
      },
      qtyMod(direction){
        switch(direction){
          case 'up':
            this.cartProduct.quantity += 1
            break
          case 'down':
            this.cartProduct.quantity -= 1
            break
        }
      },
      removeItem(){
        this.$store.dispatch('removeCartItem', this.cartProduct)
      },
      updateProduct(){
        this.$store.dispatch('updateProduct', this.cartProduct)
      },
      updateQty(){
        this.showUpdate = false
        if(this.cartProduct.quantity && this.cartProduct.quantity > 0){
          this.updateProduct()
        }
      },
      toggleConfirm(){this.showConfirm = !this.showConfirm},
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      promoProduct(){
        return promoProductV2(this.cartProduct.product_data)
      },
      cart(){
        return this.$store.getters.cart
      },
      qty(){return this.cartProduct.quantity},
      step(){return this.cartProduct.product_data.eclipse_data.price_uom == 'sf' ? 1 : this.cartProduct.product_data.eclipse_data.min_quantity}
    }
  }
</script>

<style scoped>
  .qty-form{
    text-align: center;
  }
  .update-btn{
    font-size: 14px;
    max-width: 10rem;
    margin: 0 auto;
  }
  /* remove number input inner up and down btns */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>