<template>
  <div class="alert alert-danger error text-center animate__animated animate__headShake position-relative">
    <div
      class="text-muted close"
      @click="clear">
      close
      <i class="fa fa-times text-muted"/>
    </div>
    <strong>
      <i class="fa fa-exclamation-triangle"/>
      Error
    </strong>
    <div>{{error}}</div>
    <small class="text-muted">
      Open a support ticket:&nbsp;
      <a :href="'mailto:it.support@lancasterco.com?subject=Lancaster eStore - CustomerID:' + shipTo + ', Error_Type: Order Create Failed, Error:' + error">
        it.support@lancasterco.com
      </a>
    </small>
  </div>
</template>

<script>
  export default {
    props: ['error', 'shipTo'],
    methods:{
      clear(){
        this.$store.dispatch('clearError')
      }
    }
  }
</script>

<style scoped>
  .error{
    font-size: 14px;
  }
  .close{
    position: absolute;
    top: 2px;
    right: 10px;
    cursor: pointer;
  }
</style>