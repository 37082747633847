<template>
  <div>
    <div v-if="showHome">
      <span
        v-if="homeDc">
        <i class="fa fa-home text-info"/>
        {{numeral(homeDc.quantity).format(homeDc.quantity >= 10000 ? '0a' : '0,0')}} {{displayUom(homeDc.uom)}}
      </span>
    </div>
    <div v-else>
      <table
        v-if="dcAvail"
        class="table table-sm" style="font-size:14px;"
        :key="product.product_id">
        <thead>
          <tr class="text-muted text-center">
            <th colspan="3">Distribution Centers</th>
          </tr>
          <tr>
            <th class="text-center">Branch</th>
            <th class="text-center">Qty</th>
            <th class="text-center">Stocked</th>
          </tr>
        </thead>
        <!-- DC Table -->
        <tbody>
          <tr
            v-for="b in dcAvail"
            :key="b.id">
            <td class="text-center">
              {{ branchName(b) }}
              <span class="badge bg-theme-red">
                <i
                  v-if="b.branch_id == home"
                  class="fa fa-home"/>
                DC
              </span>
            </td>
            <td class="text-center">
              {{numeral(b.quantity).format(b.quantity >= 10000 ? '0a' : '0,0')}} {{displayUom(b.uom)}}
              <InboundInventory
                :product="product"
                :activeUom="activeUom"
                :branch_id="b.branch_id"/>
            </td>
            <td class="text-center">
              <div
                v-if="stockFlagInBranch && stockFlagInBranch.includes(b.branch_id)"
                class="w-100 text-success"
                title="This product is stocked in this branch">
                <i class="fa fa-check-circle"/>
              </div>
              <div
                v-else-if="invInBranch && invInBranch.includes(b.branch_id)"
                class="w-100 text-success"
                title="This product is not stocked in this branch however we still have inventory available">
                <i class="fa fa-check-circle"/>
              </div>
              <div
                v-else
                class="w-100 text-muted"
                title="This product is not stocked in this branch">
                <i class="fa fa-times-circle"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        class="table table-sm" style="font-size:14px;"
        :key="product.product_id">
        <!-- Branch Table -->
        <thead>
          <tr class="text-muted text-center">
            <th colspan="3">Pickup Locations</th>
          </tr>
          <tr>
            <th class="text-center">Branch</th>
            <th class="text-center">Qty</th>
            <th class="text-center">Stocked</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="b in branches"
            :key="b.id">
            <td class="text-center">
              {{ branchName(b) }}
              <i
                v-if="b.branch_id == home"
                class="fa fa-home text-info"/>
            </td>
            <td class="text-center">
              {{numeral(b.quantity).format(b.quantity >= 10000 ? '0a' : '0,0')}} {{displayUom(b.uom)}}
              <InboundInventory
                :product="product"
                :branch_id="b.branch_id"/>
            </td>
            <td class="text-center">
              <div
                v-if="stockFlagInBranch && stockFlagInBranch.includes(b.branch_id) && b.quantity != 104"
                class="w-100 text-success indicator"
                title="This product is stocked in this branch">
                <i class="fa fa-check-circle"/>
              </div>
              <div
                v-else-if="invInBranch && invInBranch.includes(b.branch_id)"
                class="w-100 text-warning indicator"
                title="This product is not stocked in this branch however we still have inventory available">
                <i class="fa fa-exclamation-circle"/>
              </div>
              <div
                v-else
                class="w-100 text-muted indicator"
                title="This product is not stocked in this branch">
                <i class="fa fa-times-circle"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import InboundInventory from './InboundInventory.vue'
  import { branchName } from '../../../../../shared/branch'
  import { displayUom } from '../../../../../shared/ProductFunctions'

  export default{
    props: ['product', 'branchAvail', 'stockFlagInBranch', 'invInBranch', 'home', 'activeUom', 'showHome'],
    components:{
      InboundInventory
    },
    methods:{
      branchName(branchDetail){
        return branchName(branchDetail)
      },
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      homeDc(){
        return _.find(this.branchAvail, b => b.branch_id == this.home)
      },
      dcAvail(){
        return _.filter(this.branchAvail, b => ['S60', 'S27E', 'S69'].includes(b.branch_id))
      },
      branches(){
        return _.filter(this.branchAvail, b => !['S60', 'S27E', 'S69'].includes(b.branch_id))
      }
    }
  }
</script>

<style scoped>
  .indicator:hover{
    cursor: pointer;
    transform: scale(1.2);
  }
</style>