<template>
  <div
    v-if="product.eclipse_data && activeShipToId == product.eclipse_data.last_ordered_customer_id"
    class="last-ordered">
    <span class="text-muted">
      Last Ordered: {{product.eclipse_data.last_ordered_date}}
    </span>
    <br>
    <span class="text-muted">
      Last Order Qty: {{product.eclipse_data.last_ordered_quantity}} {{displayUom(product.eclipse_data.uom)}} 
    </span>
  </div>
</template>

<script>
  import { displayUom } from '../../../../shared/ProductFunctions'

  export default {
    props: ['product'],
    methods:{
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      activeShipToId(){return this.$store.getters.getEntity.activeShipTo.entity_id}
    }
  }
</script>

<style scoped>
  .last-ordered{
    font-size: 12px;
  }
</style>