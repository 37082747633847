<template>
  <div>
    <!-- Main Category -->
    <template v-if="lineType == 'main'">
      <input
        type="checkbox"
        @click="toggleCheck"
        v-model="checked">
      <button
        class="btn btn-sm btn-link text-light"
        @click="toggleCheck">
        {{ category.main }}
      </button>
    </template>
    <!-- Sub Category -->
    <template v-if="lineType == 'sub'">
      <input
        type="checkbox"
        @click="toggleCheck"
        v-model="checked">
      <button
        class="btn btn-sm btn-link text-light"
        @click="toggleCheck">
        {{ category }}
      </button>
    </template>
    <!-- Brand -->
    <template v-if="lineType == 'brand'">
      <input
        type="checkbox"
        @click="toggleCheck"
        v-model="checked">
      <button
        class="btn btn-sm btn-link text-light"
        @click="toggleCheck">
        {{ brand }}
      </button>
    </template>
  </div>
</template>

<script>
  export default{
    props: ['lineType', 'category', 'brand', 'clear'],
    data(){
      return{
        checked:false
      }
    },
    watch:{
      clear(newVal){
        if(newVal) this.checked = false
      }
    },
    methods:{
      toggleCheck(){
        this.checked = !this.checked
        if(this.lineType == 'main') this.emitMain()
        if(this.lineType == 'sub') this.emitSub()
        if(this.lineType == 'brand') this.emitBrand()
      },
      emitMain(){
        this.$emit('emitMain', this.category)
      },
      emitSub(){
        this.$emit('emitSub', this.category)
      },
      emitBrand(){
        this.$emit('emitBrand', this.brand)
      }
    }
  }
</script>

<style scoped>
  ul{
    padding: 0px 20px;
  }
  input[type="checkbox"]{
    cursor: pointer;
  }
  button{
    font-size: 13px;
  }
</style>
