<template>
  <div class="mt-5">
    <Header/>
    <Content @setForm="setActiveForm"/>
    <ContactModal :activeForm="activeForm"/>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="my-4">
            <h4 class="text-theme-red">CONTACT US</h4>
            <div class="my-4">
              <strong>CUSTOMER SERVICE</strong>
              <div>
                <i class="fa fa-envelope me-1 text-theme-red"/>
                <a href="mailto:uppermidwestcustserv@ejwelch.com">uppermidwestcustserv@ejwelch.com</a>
              </div>
              <div>
                <i class="fa fa-phone me-1 text-theme-red"/>
                <a href="tel:1-800-328-3280">1-800-328-3280</a>
                <br>
                <span>7:00 a.m. – 4:00 p.m. (CST), Monday - Friday</span>
              </div>
            </div>
          </div> 
          <h4 class="text-theme-red">GENERAL INQUIRY</h4>
          <GeneralForm :form="form"/>
        </div>
        <div class="col-lg-6 col-md-12">
          <GoogleMapVue/>
        </div>
      </div>
    </div>
    <ContactModal :activeForm="activeForm"/>
    <Footer/>
  </div>
</template>

<script>
  import ContactModal from './ContactModal.vue'
  import Content from './components/Content.vue'
  import Header from './components/Header.vue'
  import GeneralForm from './forms/GeneralForm.vue'
  import Footer from '../app/Footer.vue'
  import GoogleMapVue from './components/GoogleMap.vue'

  export default {
    inject: ['env'],
    data(){
      return{
        form: {},
        activeForm: {
          formName: null,
          title: null
        }
      }
    },
    components:{
      ContactModal,
      Content,
      Header,
      GeneralForm,
      Footer,
      GoogleMapVue
    },
    methods:{
      setActiveForm(formName){
        this.activeForm.formName = formName
        switch(formName){
          case 'customer':
            this.activeForm.title = 'Become a Customer'
            break;
          case 'join':
            this.activeForm.title = 'Join The Team'
            break;
          case 'sell':
            this.activeForm.title = 'Sell Through EJ Welch'
            break;
          case 'general':
            this.activeForm.title = 'General Inquiry'
            break;
        }
      }
    }
  }
</script>