<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster">
    <div class="row">
      <div class="col-md-12 my-2">
        <h4>Help & FAQ'S</h4>
        <hr>
      </div>
      <EjwFaq v-if="env == 'EJW'"/>
      <CheneyFaq v-else-if="env == 'EJW-CHENEY'"/>
      <LanFaq v-else/>
    </div>
  </div>
</template>

<script>
  import EjwFaq from './EjwFaq.vue'
  import LanFaq from './LanFaq.vue'
  import CheneyFaq from './CheneyFaq.vue'

  export default {
    inject:['env'],
    components:{
      EjwFaq,
      CheneyFaq,
      LanFaq
    }
  }
</script>