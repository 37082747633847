<template>
  <div class="col-md-12">
    <div class="row mt-3">
      <div class="col-md-12">
        <h4
          class="zoom float-start"
          @click="viewOrder">
          {{order.id}}
        </h4>
        <!-- Email Actions -->
        <div class="float-end">
          <div class="input-group">
            <button
              v-if="!inList"
              class="btn btn-primary btn-sm"
              @click="emailToggle('order')">
              <i class="fa fa-envelope"/>
              Order
            </button>
            <button
              v-if="!inList && showQuoteBtn"
              class="btn btn-primary btn-sm"
              @click="emailToggle('quote')">
              <i class="fa fa-envelope"/>
              Quote
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="order.order_status"
        class="col-md-12">
        <span class="badge bg-info">
          <i class="fa fa-clock"/>
          {{ order.order_status }}
        </span>
      </div>
      <div class="col-md-3">
        <small>
          <b>Order Date:</b>
          {{order.order_date}}
        </small>
      </div>
      <div class="col-md-3">
        <small>
          <b>Requested Ship Date:</b>
          {{order.ship_date}}
        </small>
      </div>
      <div class="col-md-3">
        <small>
          <b>Amount:</b>
          {{numeral(order.amount).format('$0,0.00')}}
        </small>
      </div>
      <div class="col-md-3">
        <small>
          <b>PO#</b>
          {{order.customer_po}}
        </small>
      </div>
    </div>        
    <hr/>
  </div>
</template>

<script>
  import { internalRoles } from '../../../../shared/roles'

  export default{
    props: ['order', 'emailData'],
    data(){
      return{
        internalRoles
      }
    },
    methods:{
      viewOrder(){
        this.$emit('viewOrder', this.order)
      },
      emailToggle(type){
        let order = {
          text: this.order.id,
          value: this.order.id + '.' + this.order.gen_id,
          invoice: false,
          quote: type == 'quote'
        }
        this.$emit('emailToggle', order)
      }
    },
    computed:{
      inList(){
        let orderList = _.map(this.emailData.orderList, o => o.text)
        return _.includes(orderList, this.order.id)
      },
      user(){
        return this.$store.getters.user
      },
      showQuoteBtn(){
        if(this.user){
          return this.internalRoles.includes(this.user.role)
        }
      }
    }
  }
</script>

<style scoped>
  .zoom{ transition: all .2s ease-in-out; }
  .zoom:hover{
    transform: scale(1.02);
    cursor:pointer;
  }
</style>