import store from '../stores/store'

// Product stocked in home branch indicator
const stockedAtHomeBranch = (cartProduct) => {
  const branchID = store.getters.homeBranch
  const branchRecord = _.find(cartProduct.product_data.eclipse_data.branches, b => b.id == branchID && parseInt(b.avail) > 0) || null
  return branchRecord ? true : false
}

// Determine freight amounts using matrix data for EJ Welch or simple calc for Lancaster
const determineMatrix = () => {
  const company = store.getters.sessionCompany
  const entity = store.getters.getEntity.activeShipTo.entity

  // LAN
  if(company.includes('Lancaster')){
    return entity.branch_id == '14' ? {amount: 850} : {amount: 1000.00}
  }else{
    // EJW
    const amounts = store.getters.freightAmounts
    const match = _.find(amounts, {state_code: entity.address.state})
    let freight = {amount: null, msg: null}
    
    if(match){
      freight.amount = parseFloat(match.amount)
    }else{
      freight.msg = 'Freight will be quoted prior to shipping'
    }
    return freight
  }
}

// Sum total amounts that qualify for prepaid freight into an eligible value
export const eligible = (cartProducts) => {
  let total = _.map(cartProducts, cp => {
    if(cp.prepaid_freight_exclusion || cp.product_data.eclipse_data.ship_br_available == 0 && !stockedAtHomeBranch(cp) || cp.product_data.eclipse_data.ship_br_available == 0) return
    return cp.pricing.extended_price
  })
  return _.sum(total)
}

// Determine the minimum order valued required to reach prepaid freight
export const freight = () => {
  return determineMatrix()
}
 
// Look for products with prepaid freight exclusions to raise warning if present
export const exclusionWarning = (cartProducts) => {
  const company = store.getters.sessionCompany
  if(company == 'Lancaster Canada') return

  let excludes = []

  cartProducts.forEach(cp => {
    if(cp.prepaid_freight_exclusion){
      excludes.push(cp.prepaid_freight_exclusion.message.exclusion_type)
    }
  })

  const meetsPrepaid = (determineMatrix().amount - eligible(cartProducts)) <= 0
  
  const excludeTypes = [
    'Equipment',
    'Rolled Good',
    'Heavy Good',
    'Long Good'
  ]

  // Exclusions present?
  if(excludes.some(e => excludeTypes.includes(e))) return true 
  if(meetsPrepaid) return false
  if(!meetsPrepaid && excludes.length) return true
  return false
  
}