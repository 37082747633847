<template>
  <div
    v-if="product.eclipse_data && (!virtualShow || virtualShow && showDetail && !showDetail.dropship)"
    class="animate__animated animate__fadeIn animate__faster">
    <!-- Mobile View -->
    <div
      v-if="view == 'mobile'"
      class="mobile text-muted">
      <div>
        <span>Availability: </span>
        <!-- Home Branch -->
        <span class="mx-1">
          <i class="fa fa-home text-info"/>
          {{numeral(shipBranchAvail.quantity).format(shipBranchAvail.quantity >= 10000 ? '0a' : '0,0')}} {{displayUom(shipBranchAvail.uom)}}
        </span>
        <!-- All Branches -->
        <span class="mx-1">
          <span>All:</span>
          {{numeral(totalAvail.quantity).format(totalAvail.quantity >= 10000 ? '0a' : '0,0')}} {{displayUom(totalAvail.uom)}}
        </span>
      </div>
    </div>
    <!-- Standard View -->
    <table
      v-else
      class="table table-sm">
      <thead>
        <tr class="text-center">
          <th class="text-muted">Branch</th>
          <th class="text-muted">Qty</th>
          <th class="text-muted">Stocked</th>
        </tr>
      </thead>
      <tbody>
        <!-- Home Branch -->
        <tr>
          <td class="text-center">
            <i class="fa fa-home text-info"/>
          </td>
          <td>
              {{numeral(shipBranchAvail.quantity).format(shipBranchAvail.quantity >= 10000 ? '0a' : '0,0')}} {{displayUom(shipBranchAvail.uom)}}
          </td>
          <td class="text-center">
            <span v-if="stockedInBranch && stockedInBranch.includes(homeBranch)">
              <i class="fa fa-check-circle text-success"/>
            </span>
            <span v-else>
              <i class="fa fa-times-circle text-muted"/>
            </span>
          </td>
        </tr>
        <!-- Total Branches -->
        <tr v-if="multiBranch">
          <td class="text-center">All</td>
          <td nowrap>
            <span>
              {{numeral(totalAvail.quantity).format(totalAvail.quantity >= 10000 ? '0a' : '0,0')}} {{displayUom(totalAvail.uom)}}
            </span>
          </td>
          <td class="text-center">
            <i
              v-if="product.status_code == 1"
              class="fa fa-check-circle text-success"/>
            <i
              v-else
              class="fa fa-times-circle text-muted"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { shipBranchAvail, totalAvail } from '../../../../shared/availability'
  import { stockedInBranch } from '../../../../shared/stockHelpers'
  import {showDetail} from '../../../../shared/showDetail'
  import { displayUom } from '../../../../shared/ProductFunctions'

  export default {
    inject:['env'],
    props: ['product', 'view', 'virtualShow', 'activeUom'],
    methods:{
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      totalAvail(){if(this.product) return totalAvail(this.product, this.activeUom)},
      homeBranch(){return this.$store.getters.homeBranch},
      shipBranchAvail(){return shipBranchAvail(this.product, this.activeUom, this.env, this.homeBranch)},
      stockedInBranch(){return stockedInBranch(this.product)},
      multiBranch(){return this.product.eclipse_data.branches.length > 1},
      showDetail(){if(this.virtualShow) return showDetail(this.product)}
    }
  }
</script>

<style scoped>
  .mobile{
    font-size:12px;
  }
</style>