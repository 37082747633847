<template>
   <div
      v-if="(index == 0 || (index+1) % (loadInterval - 6) == 0)"
      class="data-loader"
      :ref="'getData-' + (index+1)">
   </div>
</template>

<script>
  export default{
    props: ['index', 'loadInterval'],
    mounted(){
      this.initialize()
    },
    destroyed() {
      this.observer.disconnect()
    },
    watch:{
      products(newVal){
        if(newVal){
          this.initialize()
        }
      }
    },
    methods:{
      initialize(){
        if(this.$refs[`getData-${this.index + 1}`]){
          let observer = new IntersectionObserver(this.handleIntersection)
          observer.observe(this.$refs[`getData-${this.index + 1}`])
          this.observer = observer
        }
      },
      getData(){
        this.$emit('getData', this.index)
      },
      handleIntersection(entries){
        for (let entry of entries) {
          if (entry.isIntersecting){
            this.getData()
            this.observer.disconnect()
          }
        }
      },
    },
    computed:{
      products(){return this.$store.getters.productResults.products}
    }
  }
</script>

<style scoped>
  /* Uncomment class for debugging */
  /* .data-loader{
    height: 100px;
    width: 100px;
    background: blue;
  } */
</style>