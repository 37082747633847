<template>
  <div
    class="row product-list shadow-sm animate__animated animate__fadeIn animate__faster"
    :ref="'list-' + product.product_id">
    <!-- Image -->
    <div
      class="col-md-1 col-6"
      style="cursor:pointer; display: flex; justify-content: center; align-items: center;"
      data-bs-toggle="modal"
      data-bs-target="#productDetail"
      @click="setActiveProduct">
      <img
        v-if="visible"
        class="img-fluid"
        style="max-height:80px; object-fit: contain;"
        :src="product.image_url"
        @error="imgError">
    </div>
    <!-- Description -->
    <div class="col-md-6 col-6">
      <small
        class="text-muted"
        @click="setActiveProduct"
        data-bs-toggle="modal"
        data-bs-target="#productDetail"
        style="cursor:pointer;">
        {{product.description}}
      </small>
      <Indicator
        :product="product"
        :virtualShow="virtualShow"/>
      <StockStatus
        v-if="internalRoles.includes(role)"
        :product="product"/> 
      <!-- Details -->
      <div
        class="row"
        style="font-size: 12px;">
        <div class="col-md-6 mt-2">
          <Prop65 :product="product"/>
          <!-- UPC -->
          <div>
            <span class="text-muted">UPC: {{product.upc}}</span>
          </div>
          <!-- Case Qty -->
          <div v-if="caseQty">
            <span class="text-muted">Case Qty: {{caseQty}}</span>
          </div>
          <!-- Pallet Qty -->
          <div v-if="palletQty">
            <span class="text-muted">Pallet Qty: {{palletQty}}</span>
          </div>
          <!-- Customer Part Number -->
          <div v-if="product.customer_part_number">
            <span class="text-muted">Part Number: {{product.customer_part_number.part_number}}</span>
          </div>
          <LastOrdered :product="product"/>
        </div>
        <div
          class="col-md-6"
          style="min-height:100px;">
          <Availability
            :product="product"
            :virtualShow="virtualShow"
            :activeUom="activeUom"/>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <ProductPrice
        :product="product"
        :key="virtualShow ? `${product.product_id}-v` : product.product_id"
        :virtualShow="virtualShow"
        @activeUom="setActiveUom"/>
      <QtyBreaks
        :product="product"
        :virtualShow="virtualShow"/>
      <Exclusion :product="product"/>
    </div>
    <!-- Qty Form -->
    <div class="col-md-2 col-12 mt-3">
      <Favorite
        v-if="!virtualShow"
        :product="product"/>
      <QtyForm
        :product="product"
        :virtualShow="virtualShow"
        :activeUom="activeUom"/>
    </div>
  </div> 
</template>

<script>
  import QtyForm from './QtyForm.vue'
  import Indicator from './Indicator.vue'
  import QtyBreaks from './QtyBreaks.vue'
  import Exclusion from './Exclusion.vue'
  import Favorite from './Favorite.vue'
  import ProductPrice from './price/ProductPrice.vue'
  import Availability from './Availability.vue'
  import LastOrdered from './LastOrdered.vue'
  import comingSoon from '../../../../shared/comingSoon'
  import {caseQuantity,palletQuantity} from '../../../../shared/ProductFunctions'
  import {internalRoles} from '../../../../shared/roles'
  import StockStatus from './StockStatus.vue'
  import Prop65 from './Prop65.vue'

  export default {
    props:['product', 'view', 'virtualShow', 'loadImages'],
    data(){
      return{
        internalRoles,
        activeUom: null,
        visible: false
      }
    },
    mounted(){
      if(this.$refs[`list-${this.product.product_id}`]){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs[`list-${this.product.product_id}`])
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    components:{
      QtyForm,
      Indicator,
      QtyBreaks,
      Exclusion,
      Favorite,
      ProductPrice,
      Availability,
      LastOrdered,
      StockStatus,
      Prop65
    },
    watch:{
      loadImages(newVal){
        if(newVal) this.visible = this.loadImages.includes(this.product.product_id)
      }
    },
    methods:{
      setActiveProduct(){this.$store.dispatch('setActiveProduct', this.product)},
      imgError(e){comingSoon(e)},
      setActiveUom(activeUom){this.activeUom = activeUom},
      handleIntersection(entries){
        for (let entry of entries) {
          if (entry.isIntersecting){
            this.visible = true
          }
        }
      },
      deleteFavProduct(){this.$emit('deleteFavProduct', this.product.product_id)}
    },
    computed:{
      caseQty(){
        return caseQuantity(this.product)
      },
      palletQty(){
        return palletQuantity(this.product)
      },
      role(){return this.$store.getters.user.role}
    }
  }
</script>

<style scoped>
  .product-list{
    border: 1px solid lightgray;
    border-radius: 10px;
    position:relative;
    background:white;
    padding: 15px;
    margin: 5px 10px;
    min-height: 126px;
  }
</style>