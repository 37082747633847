<template>
  <div class="mt-5">
    <Header/>
    <Content
      :vendors="vendors"
      :categories="categories"/>
    <Footer/>
  </div>
</template>

<script>
  import axios from 'axios'
  import Header from './components/Header.vue'
  import Content from './components/Content.vue'
  import Footer from '../app/Footer.vue'
  
  export default {
    data(){
      return{
        vendors: null,
        categories: null
      }
    },
    components:{
      Header,
      Content,
      Footer
    },
    created(){
      this.getVendors()
      this.getCategories()
    },
    methods:{
      getVendors(){
        axios.get('/api/v1/public/vendors/get_vendors').then(res=>{
          this.vendors = res.data.all
        })
      },
      getCategories(){
        axios.get('/api/v1/public/vendors/get_categories').then(res=>{
          let excludes = ['Not Assigned', 'Electrical, Plumbing']
          let filteredCats = _.reject(res.data.categories, c => _.some(excludes, e=> e.includes(c.main)))
          this.categories = _.chunk(filteredCats,4)
        })
      }
    }
  }
</script>