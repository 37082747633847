<template>
  <div>
    <div class="row">
      <!-- Interactive Map -->
      <div class="col-md-8 text-center d-none d-lg-block position-relative wrapper">
        <div class="position-relative p-0">
          <!-- MAP -->
          <img
            :src="map"
            class="map">
          <!-- Markers -->
          <img
            :src="ca"
            class="ca pin"
            @click="showDetail('7T')">
          <img
            :src="fl"
            class="fl pin"
            @click="showDetail('2')">
          <img
            :src="ky"
            class="ky pin"
            @click="showDetail('8')">
          <img
            :src="nj"
            class="nj pin"
            @click="showDetail('14')">
          <img
            :src="on"
            class="on pin"
            @click="showDetail('71')">
          <img
            :src="sc"
            class="sc pin"
            @click="showDetail('1')">
          <img
            :src="tx"
            class="tx pin"
            @click="showDetail('9')">
          <!-- Map Detail -->
          <MapDetail
            :activeBranch="activeBranch"
            :offset="offset"
            :headQuarters="headQuarters"
            @closeDetail="setNull"/>
        </div>
      </div>
      <!-- Distributions Text -->
      <div class="col-md-4 text-primary mt-5 px-5 p-lg-5">
        <h4>DISTRIBUTION LOCATIONS</h4>
        <p>We have the ability to supply you with the right products, at the right time, for the right price. With distribution centers in California, Florida, Kentucky, New Jersey, South Carolina, Texas and Ontairo Canada, Lancaster continues to improve our distribution systems to increase efficiency and hold down costs for our customers.</p>
      </div>
      <!-- Mobile Locations -->
      <div class="col-md-8 col-12 p-5 d-block d-lg-none">
        <div class="row align-items-stretch">
          <div
            v-for="b in branches"
            :key="b.branch_id"
            class="text-center my-3"
            :class="b.branch_id == '1' ? 'col-12' : 'col-6'">
            <div
              v-if="b.branch_id != 'HQ'"
              class="card pb-3 h-100 position-relative">
              <div class="card-body">
                <h5>{{b.state}}</h5>
                <div class="text-muted">
                  <div>{{b.address}}</div>
                  <div>{{b.city}}, {{b.state_code}} {{b.zip}}</div>
                  <div>Phone:
                    <a :href="`tel: + ${b.cs_number}`">{{b.cs_number}}</a>
                  </div>
                </div>
                 <!-- If activeBranch state is the same as HQ state, show the HQ address -->
                <div v-if="b.state == headQuarters.state" class="text-muted">
                  <hr>
                  <div class="fw-bold"><i class="fa fa-globe"></i> Worldwide Headquarters</div>
                  <div>{{headQuarters.address}}</div>
                  <div>{{headQuarters.city}}, {{headQuarters.state_code}} {{headQuarters.zip}}</div>
                  <div>Phone: {{headQuarters.cs_number}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import MapDetail from './MapDetail.vue'
  export default {
    data(){
      return{
        map: require('../../../../../assets/images/lan/about/map/Map_No_Icons.png'),
        ca: require('../../../../../assets/images/lan/about/map/CA_Icon.png'),
        fl: require('../../../../../assets/images/lan/about/map/FL_Icon.png'),
        ky: require('../../../../../assets/images/lan/about/map/KY_Icon.png'),
        nj: require('../../../../../assets/images/lan/about/map/NJ_Icon.png'),
        on: require('../../../../../assets/images/lan/about/map/ON_Icon.png'),
        sc: require('../../../../../assets/images/lan/about/map/SC_Icon.png'),
        tx: require('../../../../../assets/images/lan/about/map/TX_Icon.png'),
        activeBranch: null,
        branches: null,
        offset: null
      }
    },
    created(){
      // Click off of detail window funtion.
      // Listen to about us page map clicks. If click occurs on a map marker or on the map detail
      // window allow function to proceed, otherwise close map detail widow.
      document.addEventListener('click', function(e){
        const detail = document.getElementById('mapDetail')
        const pins = Array.from(document.getElementsByClassName('pin'))
        const clickCheck = ()=>{
          if(detail && detail.contains(e.target)){
            return true
          }else if(pins && pins.includes(e.target)){
            return true
          }else{
            return false
          }
        }

        if(clickCheck()){
          this.setWindowPosition(e.target.offsetLeft, e.target.offsetTop)
          return
        }else{
          this.setNull()
        }
          
      }.bind(this))

      this.getBranches()
    },
    components:{
      MapDetail
    },
    methods:{
      showDetail(id){
        this.activeBranch = _.find(this.branches, {branch_id: id})
      },
      setWindowPosition(left,top){
        this.offset = {left,top}
      },
      setNull(){
        this.activeBranch = null
      },
      getBranches(){
        axios.get('/api/v1/public/branches/get_branches').then(res =>{
          this.branches = res.data.branches
        })
      }
    },
    computed: {
      headQuarters(){ return _.find(this.branches, {branch_id: 'HQ'}) } 
    }
  }
</script>

<style scoped>
  .map{
    display: block;
    margin: auto;
    position: relative;
    width:100%;
  }
  .pin{
    height: auto;
    width: 50px;
    position: absolute;
    cursor:pointer;
  }
  .pin:hover{
    transform: scale(1.25) translateY(-10px);
    transition: .05s ease-in-out;
  }
  /* CA */
  @media(min-width: 1201px){
    .ca{
      top: 40%;
      left: 7%;
    }
  }
  @media(max-width: 1200px){
    .ca{
      top: 39%;
      left: 7%;
    }
  }
  @media(max-width: 992px){
    .ca{
      top: 32%;
      left: 6.5%;
    }
  }

  /* FL */
  @media(min-width: 1201px){
    .fl{
      top: 74%;
      left: 69%;
    }
  }
  @media(max-width: 1200px){
    .fl{
      top: 73%;
      left: 69%;
    }
  }
  @media(max-width: 992px){
    .fl{
      top: 73%;
      left: 69%;
    }
  }

  /* KY */
  @media(min-width: 1201px){
    .ky{
      top: 38%;
      left: 63%;
    }
  }
  @media(max-width: 1200px){
    .ky{
      top: 37%;
      left: 63%;
    }
  }
  @media(max-width: 992px){
    .ky{
      top: 30%;
      left: 62%;
    }
  }

  /* NJ */
  @media(min-width: 1201px){
    .nj{
      top: 24%;
      left: 76.5%;
    }
  }
  @media(max-width: 1200px){
    .nj{
      top: 22%;
      left: 76.5%;
    }
  }
  @media(max-width: 992px){
    .nj{
      top: 18%;
      left: 75.5%;
    }
  }

  /* ON */
  @media(min-width: 1201px){
    .on{
      top: 12%;
      left: 67.5%;
    }
  }
  @media(max-width: 1200px){
    .on{
      top: 11%;
      left: 67%;
    }
  }
  @media(max-width: 992px){
    .on{
      top: 20%;
      left: 66%;
    }
  }

  /* SC */
  @media(min-width: 1201px){
    .sc{
      top: 50%;
      left: 67.5%;
    }
  }
  @media(max-width: 1200px){
    .sc{
      top: 49%;
      left: 67.5%;
    }
  }
  @media(max-width: 992px){
    .sc{
      top: 40%;
      left: 67.5%;
    }
  }

  /* TX */
   @media(min-width: 1201px){
    .tx{
      top: 66%;
      left: 44%;
    }
  }
  @media(max-width: 1200px){
    .tx{
      top: 65%;
      left: 44%;
    }
  }
  @media(max-width: 992px){
    .tx{
      top: 48%;
      left: 44%;
    }
  }
  .wrapper{
    max-width: 1200px;
    margin: 0 auto;
  }
  .locations{
    position: absolute;
    padding: 5px 30px;
    top: 3%;
    z-index: 4;
    background: linear-gradient(to left, rgb(62,76,88), rgb(150, 158, 156));
    border-radius: 0px 10px 10px 0px;
    box-shadow: 2px 2px 10px grey;
    color: white;
    font-size: 1.3em;
    font-weight: 300;
  }
</style>