import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";
import env from '../env'
import {sessionStore} from './modules/private/sessionStore'
import {productStore} from './modules/private/productStore'
import {cartStore} from './modules/private/cartStore'
import {reorderPadStore} from './modules/private/reorderPadStore'
import {favoriteStore} from './modules/private/favoriteStore'
import {accountStore} from './modules/private/accountStore'
import {entityStore} from './modules/private/entityStore'
import {checkoutStore} from './modules/private/checkoutStore'
import {branchStore} from './modules/private/branchStore'
import {viewStore} from './modules/private/viewStore'
import {binlabelStore} from './modules/private/binlabelStore'
import {feedbackStore} from './modules/private/feedbackStore'
import {adminStore} from './modules/private/adminStore'
import {tmStore} from './modules/private/tmStore'
import {virtualShowStore} from './modules/private/virtualShowStore'

export default createStore({
  plugins: [createPersistedState({
    storage: window.localStorage,
    key: `${env}-VueX`
  })],
  modules: {
    sessionStore,
    productStore,
    cartStore,
    reorderPadStore,
    favoriteStore,
    accountStore,
    entityStore,
    checkoutStore,
    branchStore,
    viewStore,
    binlabelStore,
    feedbackStore,
    tmStore,
    adminStore,
    virtualShowStore
  }
})
