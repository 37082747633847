<template>
  <div class="row main">
    <div
      class="col-md-6 col-12 d-none d-md-block"
      :style="imgBackground"/>
    <div class="col-md-6 col-12 content-wrap">
      <span class="text-danger">RESIDENTIAL</span>
      <div class="my-4">
        <h2>Add value to the retail shopping experience</h2>
        <p>Catering to consumers means carrying a wide selection of popular favorites and trending styles. E.J. Welch stocks a wide variety of the latest styles and trends in residential flooring, from hardwood, resilient, sheet vinyl, carpet, decorative tile, and much more, with a convenient and turnkey experience for your client’s home remodel project. We provide all the support and tools for you to be successful in your business, from showroom display systems and sample options for your customers.</p>
        <ProSupplyBtn/>
      </div>
    </div>
  </div>
</template>

<script>
  import ProSupplyBtn from './ProSupplyBtn.vue'

  export default{
    data(){
      return{
        imgBackground:{
          backgroundImage: `url(${require('../../../../../assets/images/ejw/about/residential.jpg')})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }
      }
    },
    components:{
      ProSupplyBtn
    }
  }
</script>

<style scoped>
  @media(min-width: 992px){
    .main{
      height: 500px;
      overflow: hidden;
    }
  }
  .content-wrap{
    padding: 50px;
  }
</style>