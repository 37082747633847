<template>
  <div>
    <Header/>
    <Stats/>
    <Map/>
    <ProSupply/>
    <Residential/>
    <Commercial/>
    <Footer/>
  </div>
</template>

<script>
  import Header from './Header.vue'
  import Stats from './Stats.vue'
  import Map from './Map.vue'
  import ProSupply from './ProSupply.vue'
  import Residential from './Residential.vue'
  import Commercial from './Commercial.vue'
  import Footer from '../app/Footer.vue'

  export default {
    components:{
      Header,
      Stats,
      Map,
      ProSupply,
      Residential,
      Commercial,
      Footer
    }
  }
</script>