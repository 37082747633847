<template>
  <div
    class="position-relative my-2 main">
    <div
      class="text-center mt-4"
      style="height:48px;">
      <strong>{{asset.promo_title}}</strong>
    </div>
    <!-- Card -->
    <!-- PDF Card -->
    <a
      v-if="pdfOnly"
      :href="asset.pdf_url"
      style="text-decoration: none;"
      target="_blank">
      <div
        class="promo-card position-relative"
        :style="style">
        <div
          class="pdfOverlay">
          <span>
            PDF Only
          </span>
        </div>  
      </div>
    </a>
    <!-- Standard Promo Card -->
    <div
      v-else
      class="promo-card"
      @click="browsePromo"
      :style="style"/>
    <div
      v-if="!promo.vendor_program"
      class="text-center my-1">
      <!-- Dates-->
      <span class="text-muted">
        Starts {{moment(promo.start_date).format('MM-DD-YYYY')}}
        <br>
        Ends {{moment(promo.end_date).format('MM-DD-YYYY')}}
      </span>
      <!-- <a
        :href="asset.pdf_url"
        style="text-decoration: none;"
        target="_blank">
        <i class="fa fa-download download"/>
      </a> -->
    </div>
  </div>
</template>

<script>
  export default {
    props: ['promo', 'asset'],
    methods:{
      browsePromo(){
        this.$router.push({
          name: 'ProductSearch',
          query:{
            action: 'browsePromo',
            id: this.promo.id
          }
        })
      }
    },
    computed:{
      style(){
        return {
          backgroundImage: `url(${this.asset.thumbnail_url})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }
      },
      pdfOnly(){
        if(this.promo.pdf_only){
          return true
        }else{
          return false
        }
      }
    }
  }
</script>

<style scoped>
  .promo-card{
    height: 235px;
    width: 100%;
    margin: 0 auto;
  }
  .promo-card:hover{
    cursor: pointer;
  }
  .download{
    cursor:pointer;
  }
  .promo-alert{
    padding: 0px;
    width: 140px;
    margin: 0 auto;
  }
  .main:hover{
    box-shadow: 1px 1px 10px lightgray;
    border-radius: 10px;
    transform: scale(1.02);
    transition: .1s ease-in-out;
  }
  .flag{
    position: absolute;
    border-radius: 10px 0px 10px 0px;
    padding: 0px 10px;
    color:white;
  }
  .pdfOverlay{
    position: absolute;
    text-align: center;
    top: -5px;
    left: -5px;
    font-weight: 900;
    font-size: 12px;
    background: var(--bs-danger);
    width: 85px;
    color: white;
    padding: 0px;
    box-shadow: 1px 1px 10px slategrey;
  }
</style>