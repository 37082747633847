<template>
  <div class="contianer">
    <div
      class="row"
      v-for="(t,i) in list"
      :key="t.id">
      <div class="col-md-6 px-5 py-2 text-center">
        <img
          :src="t.image"
          class="img-fluid"
          style="max-height: 400px;">
      </div>
      <div class="col-md-6 p-3">
        <p>{{t.comment}}</p>
        <div>
          <i class="fa fa-caret-right"/>
          <strong class="ms-2">{{t.name}}</strong>
          <div>
            <strong class="text-theme-red">
              <small>{{t.customer_name}}</small>
              <br>
              <small v-if="t.state">{{t.state}}</small>
            </strong>
          </div>
        </div>
      </div>
      <hr
        v-if="(i + 1) < list.length"
        class="divider my-4">
    </div>
  </div>
</template>

<script>
  export default {
    props: ['list']
  }
</script>

<style scoped>
  .divider{
    max-width: 80%;
    margin: 0 auto;
    height: 3px;
  }
</style>