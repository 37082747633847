<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 position-relative">
        <Notification
          :show="showNotification"
          :data="notificationData"/>
        <div
          class="content"
          :class="adminMode ? 'admin' : 'bg-light'"
          :style="{'height': height}"
          id="content">
            <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Notification from './Notification.vue'


  export default {
    props:['activeView', 'adminMode'],
    data(){
      return{
        height: null
      }
    },
    methods:{
      setHeight(){
        this.height = window.innerHeight + 'px'
      }
    },
    created(){
      // Set app height on window size change
      window.addEventListener('resize', function(){
        this.setHeight()
      }.bind(this))
      // Set app height on orientation change
      window.addEventListener('orientationchange', function(){
        this.setHeight()
      }.bind(this))
    },
    mounted(){
      this.setHeight()
    },
    components:{
      Notification
    },
    computed:{
      showNotification(){return this.$store.getters.showNotification},
      notificationData(){return this.$store.getters.notificationData}
    }
  }
</script>

<style scoped>
  .content{
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 180px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: -1px;
    height: 100svh;
    background: var(--bs-body-bg) !important;
  }
  .bg-light{
    border: 1px solid var(--bs-primary);
  }
  .admin{
    background: var(--bs-admin-rgb) !important;
  }
</style>