<template>
  <div>
    <div v-if="!loading">
      <!-- Header -->
      <div class="sticky-top bg-white">
        <div class="row">
          <div 
            class="col-md-12 px-4 py-2"
            style="z-index:1;border-bottom: 1px solid lightgray;">
            <h4 class="float-start">
              Promotions
            </h4>
            <button
              v-if="!browsing && filteredPromos.length > 0"
              class="float-start ms-3 btn btn-primary btn-sm m-1"
              @click="browseAllPromos">
              <i
                class="fa fa-book-open"
                v-if="browsing"/>
              <i
                class="fa fa-book"
                v-if="!browsing"/>
              Browse All Promo Products
            </button>
            <button
              v-if="browsing"
              class="btn btn-sm btn-primary m-1 float-start"
              @click="toggleBrowse">
              <i class="fa fa-chevron-left"/>
              Back
            </button>
            <!-- Promo Sorting -->
            <div class="m-1 float-end d-block">
              <span class="mx-2 align-bottom">Sort By</span>
              <div class="btn-group">
                <button
                  class="btn btn-sm btn-primary"
                  :class="{active: sort == 'newest'}"
                  @click="setSort('newest')">
                  <i class="fa fa-certificate"/>
                  Newest
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  :class="{active: sort == 'ending'}"
                  @click="setSort('ending')">
                  <i class="fa fa-clock"/>
                  Ending Soon
                </button>
              </div>
            </div>
            <!-- <UnlockCode
              v-if="!browsing && filteredPromos.length > 0"
              class="m-1 float-end"/> -->
          </div>
        </div>
      </div>
      <!-- Listing -->
      <div class="container-fluid">
        <div
          v-if="filteredPromos.length > 0 && !loading"
          class="mt-3">
          <!-- Browsable Promos -->
          <div
            v-if="browsable"
            class="row mb-5">
            <template
              v-for="p in browsable"
              :key="p.id">
              <!-- Promo Assets -->
              <div
                v-for="a in p.promo_assets"
                :key="a.id"
                class="col-xl-2 col-lg-3 col-sm-4 col-6">
                <PromoCard
                  :promo="p"
                  :asset="a"/>
              </div>
            </template>
          </div>
          <!-- PDF Promos -->
          <div
            v-if="pdfOnly.length"
            class="row mb-5">
            <div class="col-md-12">
              <h5 class="text-muted">PDF Promos</h5>
            </div>
            <template
              v-for="p in pdfOnly"
              :key="p.id">
              <!-- Promo Assets -->
              <div
                v-for="a in p.promo_assets"
                :key="a.id"
                class="col-xl-2 col-lg-3 col-sm-4 col-6">
                <PromoCard
                  :promo="p"
                  :asset="a"/>
              </div>
            </template>
          </div>
          <!-- Vendor Program -->
          <div
            v-if="vendorPrograms.length"
            class="row mb-5">
            <div class="col-md-12">
              <h5 class="text-muted">Vendor Programs</h5>
            </div>
            <template
              v-for="p in vendorPrograms"
              :key="p.id">
              <!-- Promo Assets -->
              <div
                v-for="a in p.promo_assets"
                :key="a.id"
                class="col-xl-2 col-lg-3 col-sm-4 col-6">
                <PromoCard
                  :promo="p"
                  :asset="a"/>
              </div>
            </template>
          </div>
        </div>
        <div v-if="filteredPromos.length == 0 && !loading">
          <div class="row">
            <div class="col-md-12 text-center mt-5 text-muted">
              <h4>No promotions at this time</h4>
              <small>Please check back later</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import PromoCard from './PromoCard.vue'
  import Spinner from '../../../global/Spinner.vue'
  import UnlockCode from './UnlockCode.vue'
  import FilterControls from '../../menus/views/FilterControls/FilterControls.vue'

  export default {
    components:{
      Spinner,
      PromoCard,
      UnlockCode,
      FilterControls
    },
    data(){
      return{
        promos: null,
        loading: false,
        browsing: false,
        activeUnlocks: [],
        sort: 'newest'
      }
    },
    created(){
      if(!this.settings.promotions || !this.$store.getters.promoReceive){
        this.$router.push({path: '/'})
      }
      this.loading = true
      axios.get('/api/v1/private/promos/get_promos').then(res => {
        this.promos = res.data.promos
        this.loading = false
      })
    },
    methods:{
      setSort(sortType){
        this.sort = sortType
      },
      orderPromos(base){
        if(this.sort == 'newest') return _.orderBy(base, 'start_date', 'desc')
        if(this.sort == 'ending') return _.orderBy(base, 'end_date', 'asc')
      },
      browseAllPromos(){
        this.$router.push({
          name: 'ProductSearch',
          query:{
            action: 'browseAllPromos'
          }
        })
      }
    },
    computed:{
      filteredPromos(){
        if(this.unlockCodes){
          _.forEach(this.promos, function(p){
            if(_.includes(this.unlockCodes, p.promo_code.toLowerCase())){
              p.hidden = false
            }
          }.bind(this))
        }
        return _.filter(this.promos, p => p.hidden == false)
      },
      browsable(){
        const base = _.filter(this.filteredPromos, {pdf_only: false})
        return this.orderPromos(base)
      },
      pdfOnly(){
        const base = _.filter(this.filteredPromos, {pdf_only: true, vendor_program: false})
        return this.orderPromos(base)
      },
      vendorPrograms(){
        const base = _.filter(this.filteredPromos, {pdf_only: true, vendor_program: true})
        return this.orderPromos(base)
      },
      unlockCodes(){return this.$store.getters.unlockCodes},
      settings(){return this.$store.getters.settings},
      productLoading(){return this.$store.getters.productLoadState}
    }
  }
</script>
