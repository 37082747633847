<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster">
    <div class="row">
      <div class="col-md-12 mt-2">
        <h4>What's New</h4>
      </div>
    </div>
    <div
      v-if="!loading && patchNotes"
      class="row">
      <div
        v-for="(notes, index) in patchNotes"
        :key="index"
        class="col-md-12">
        <div v-if="notes.length > 0">
          <NoteSection :notes="notes"/>
        </div>
      </div>
    </div>
    <div
      v-if="!patchNotes && !loading"
      class="row">
      <div class="col-12-md text-center mt-5">
        <h4 class="text-muted">Check Back Later</h4>
      </div>
    </div>
    <div
      v-if="loading"
      class="row">
      <div class="col-md-12 text-center">
        <Spinner/>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import NoteSection from './NoteSection.vue'
  import Spinner from '../../../global/Spinner.vue'

  export default {
    data(){
      return{
        patchNotes: null,
        loading: true
      }
    },
    components:{
      NoteSection,
      Spinner
    },
    methods:{
      getPatchNotes(){
        axios.get('/api/v1/private/patch_notes/get_all').then(res => {
          if(res.data.patch_notes.length){
            this.patchNotes = _.groupBy(_.orderBy(res.data.patch_notes, 'release_date', 'desc'), 'version')
          }
          this.loading = false
        })
      }
    },
    created(){
      this.getPatchNotes()
    }
  }
</script>