<template>
  <section>
    <div class="row my-3 p-3">
      <div class="col-lg-3 col-md-6 col-6 text-center p-3">
        <div
          class="icon-section history"
          @click="goto('About')">
          <img
            :src="lancasterIcon"
            class="img-fluid header-icon">
          <h4 class="mt-4 icon-title">OUR HISTORY</h4>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6 text-center p-3">
        <div
          class="icon-section benefits"
          @click="goto('Advantage')">
          <img
            :src="partnerIcon"
            class="img-fluid header-icon">
          <h4 class="mt-4 icon-title">PARTNER BENEFITS</h4>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6 text-center p-3">
        <div
          class="icon-section brands"
          @click="goto('Brands')">
          <img
            :src="brandsIcon"
            class="img-fluid header-icon">
          <h4 class="mt-4 icon-title">DEPENDABLE BRANDS</h4>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6 text-center p-3">
        <div
          class="icon-section contact"
          @click="goto('Contact')">
          <img
            :src="connectIcon"
            class="img-fluid header-icon">
          <h4 class="mt-4 icon-title">CONTACT US</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data(){
      return{
        lancasterIcon: require('../../../../../assets/images/lan/home/LancasterRedIcon.png'),
        partnerIcon: require('../../../../../assets/images/lan/home/PartnerIcon.png'),
        brandsIcon: require('../../../../../assets/images/lan/home/BrandsIcon.png'),
        connectIcon: require('../../../../../assets/images/lan/home/ConnectIcon.png'),
      }
    },
    methods:{
      goto(routeName){
        this.$router.push({name: routeName})
      }
    }
  }
</script>

<style scoped>
  .header-icon{
    height:auto;
    max-height:200px;
    width: 100%;
    max-width:200px;
  }
  .icon-text{
    min-height:120px;
  }
  .icon-title{
    min-height: 25px;
  }
  .icon-section:hover{
    transition: .20s ease-in-out;
    text-shadow: 5px 5px 10px lightgray;
    transform: scale(1.10) translatey(-8%);
    border-radius:20px;
    cursor: pointer;
  }
  .history:hover{
    color: var(--bs-danger)
  }
  .benefits:hover{
    color: var(--bs-success)
  }
  .brands:hover{
    color: var(--bs-secondary)
  }
  .contact:hover{
    color: var(--bs-contact)
  }
</style>