<template>
  <span v-if="showSq">
    <span
      :class="pricing.promo && !originalPrice ? 'text-success' : 'text-muted'"
      style="font-weight: 400;font-size: 12px;">
      ({{ numeral(sqPrice).format('$0,0.00') }} {{ readableUom }})
    </span>
  </span>
</template>

<script>
  export default{
    props: ['pricing', 'product', 'originalPrice'],
    computed:{
      showSq(){
        if(!['sf','sy'].includes(this.pricing.priceUom)) return false
        return true
      },
      sqPrice(){
        if(!this.originalPrice && this.pricing.promo){
          const caseUom = _.find(this.product.eclipse_data.uoms, {name: 'cs'})
          const sqUom = _.find(this.product.eclipse_data.uoms, {name: this.pricing.priceUom})
          const caseQty = caseUom.quantity / sqUom.quantity
          return this.pricing.price / caseQty
        }else{
          return this.product.eclipse_data.unit_price
        }
      },
      readableUom(){
        switch(this.pricing.priceUom){
          case 'sf':
            return 'sqft'
          case 'sy':
            return 'sqyd'
        }
      }
    }
  }
</script>