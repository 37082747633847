<template>
  <div>
    <Stats :view="view"/>
    <Focus :view="view"/>
    <DealerTabs/>
    <DealerBenefits/>
    <WhyWait :view="view"/>
    <Testimonial :view="view"/>
  </div>
</template>

<script>
  import Stats from './components/Stats.vue'
  import Focus from './components/Focus.vue'
  import DealerTabs from './components/DealerTabs.vue'
  import DealerBenefits from './components/DealerBenefits.vue'
  import Testimonial from './components/Testimonial.vue'
  import WhyWait from './components/WhyWait.vue'

  export default {
    props:['view'],
    components:{
      Stats,
      Focus,
      DealerTabs,
      DealerBenefits,
      WhyWait,
      Testimonial
    }
  }
</script>