<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <h4
          class="zoom"
          @click="viewInvoice">
          <span>{{invoice.invoice_id}}</span>
        </h4>
        <strong
          v-if="invoice.open"
          class="badge bg-success">
          <i class="fa fa-envelope-open-text"/>
          Open Invoice
        </strong>
        <strong
          v-if="invoice.print_status == 'P'"
          class="badge bg-info ms-2">
          <i class="fa fa-hourglass-half"/>
          In Process
        </strong>
      </div>
      <div class="col-md-3">
        <small>
          <strong>Invoice Date:</strong>
          {{moment(invoice.post_date).format('YYYY-MM-DD')}}
        </small>
      </div>
      <div class="col-md-3">
        <small>
          <strong>Amount:</strong>
          {{numeral(total).format('$0,0.00')}}
        </small>
      </div>
      <div class="col-md-3">
        <small>
          <strong>PO#</strong>
          {{invoice.customer_po}}
        </small>
      </div>
      <div class="col-md-3">
        <button
          v-if="!inList"
          class="btn btn-primary btn-sm"
          @click="emailToggle">
          <i class="fa fa-envelope"/>
          Email&nbsp;
          <span v-if="invoice.print_status == 'N'">Invoice</span>
          <span v-if="invoice.print_status == 'P'">Acknowledgement</span>
        </button>
      </div>
    </div>   
    <hr/>
  </div>
</template>

<script>
  import {currencyConverter} from '../../../../shared/currencyConverter'
  
  export default {
    props: ['invoice', 'emailData'],
    methods:{
      viewInvoice(){
        this.$store.dispatch('viewInvoice', this.invoice.invoice_id)
        window.scrollTo(0,0)
      },
      emailToggle(){
        let invoice = {
          text: this.invoice.invoice_id,
          value: this.invoice.invoice_id,
          invoice: this.invoice.print_status == 'N'
        }
        this.$emit('emailToggle', invoice)
      },
      inEmailList(id){
        let inList = _.includes(this.emailData.orderList, id)
        if(inList == true){
          return true
        }else{
          return false
        }
      }
    },
    computed:{
      inList(){
        let orderList = _.map(this.emailData.orderList, o => o.text)
        return _.includes(orderList, this.invoice.invoice_id)
      },
      total(){
        if(this.invoice.foreign_currency == 'CAD'){
          return currencyConverter(this.invoice.exchange_rate, this.invoice.amount)
        }else{
          return this.invoice.amount
        }
      }
    }
  }
</script>

<style scoped>
  .zoom{ transition: all .2s ease-in-out; }
  .zoom:hover{
    transform: scale(1.02);
    cursor:pointer;
  }
</style>