<template>
  <div class="row p-2 bg-light shadow rounded">
    <div class="col-md-12 mb-3">
      <button
        class="btn btn-primary btn-sm float-start"
        @click="back">
        <i class="fa fa-chevron-left"/>
        Back
      </button>
    </div>
    <div class="col-md-12">
      <form
        name="editRecord"
        @submit.prevent="submit">
        <table
          v-if="data"
          class="table table-light table-striped">
          <thead v-if="activeRecord">
            <h5>Editing Record ID: {{activeRecord.id}}</h5>
          </thead>
          <tbody>
            <template
              v-for="t in data.table_data"
              :key="t" >
              <!-- hide dragonfly name columns -->
              <tr class="my-2">
                <td>
                  <label v-if="t.column != 'id'">
                    {{t.column}}
                  </label>
                </td>
                <td>
                  <!-- String Input -->
                  <input
                    v-if="!stringExcludes.includes(t.column) && !t.column.includes('uid') && t.type == 'varchar'"
                    :disabled="t.disabled"
                    :required="t.required"
                    v-model="form[t.column]"
                    class="form-control"
                    :type="t.column == 'email' ? 'email' : 'text'">
                  <!-- CheckBox / Toggle-->
                  <div
                    v-else-if="!checkBoxExcludes.includes(t.column) && t.column != 'id' && t.type == 'bit'"
                    class="form-check form-switch">
                    <input
                      class="form-check-input"
                      style="cursor: pointer;"
                      v-model="form[t.column]"
                      :disabled="t.disabled"
                      :required="t.required"
                      :checked="this.activeRecord ? this.activeRecord[t.column] : false"
                      type="checkbox">
                  </div>
                  <!-- Number -->
                  <input
                    v-else-if="!intExcludes.includes(t.column) && t.column != 'id' && t.type == 'int'"
                    class="form-control"
                    v-model="form[t.column]"
                    :disabled="t.disabled"
                    :required="t.required"
                    type="number">
                  <!-- Date -->
                  <input
                    v-else-if="t.column != 'id' && t.type == 'date'"
                    class="form-control"
                    v-model="form[t.column]"
                    :disabled="t.disabled"
                    :required="t.required"
                    type="date">
                  <!-- Company DropDown Select -->
                  <select
                    v-else-if="t.column == 'company' && t.type == 'varchar' && data.options.config.company_options"
                    v-model="form[t.column]"
                    class="form-select"
                    style="cursor: pointer;"
                    :disabled="t.disabled"
                    :required="t.required">
                    <option
                      v-for="o in data.options.config.company_options"
                      :key="o"
                      :value="o">{{ o }}
                    </option>
                  </select>
                    <!-- Asset Type DropDown Select -->
                  <select
                    v-else-if="t.column == 'asset_type' && t.type == 'varchar' && data.options.config.asset_options"
                    v-model="form[t.column]"
                    class="form-select"
                    style="cursor: pointer;"
                    :disabled="t.disabled"
                    :required="t.required">
                    <option
                      v-for="o in data.options.config.asset_options"
                      :key="o"
                      :value="o">{{ o }}
                    </option>
                  </select>
                    <!-- Media Type DropDown Select -->
                  <select
                    v-else-if="t.column == 'media_type' && t.type == 'varchar' && data.options.config.media_options"
                    v-model="form[t.column]"
                    class="form-select"
                    style="cursor: pointer;"
                    :disabled="t.disabled"
                    :required="t.required">
                    <option
                      v-for="o in data.options.config.media_options"
                      :key="o"
                      :value="o">{{ o }}
                    </option>
                  </select>
                  <!-- Area Select -->
                  <select
                    v-else-if="t.column == 'area' && t.type == 'varchar' && data.options.config.area_options"
                    v-model="form[t.column]"
                    class="form-select"
                    style="cursor: pointer;"
                    :disabled="t.disabled"
                    :required="t.required">
                    <option
                      v-for="o in data.options.config.area_options"
                      :key="o"
                      :value="o">{{ o }}
                    </option>
                  </select>
                  <!-- Exclude Type DropDown Select -->
                  <select
                    v-else-if="t.column == 'exclude_type' && t.type == 'varchar' && data.options.config.exclude_options"
                    v-model="form[t.column]"
                    class="form-select"
                    style="cursor: pointer;"
                    :disabled="t.disabled"
                    :required="t.required">
                    <option
                      v-for="o in data.options.config.exclude_options"
                      :key="o"
                      :value="o">{{ o }}</option>
                  </select>
                  <!-- File Input -->
                  <input
                    v-if="t.column.includes('uid') && t.type == 'varchar'"
                    type="file"
                    :id="'fileInput_' + t.column"
                    :accept="fileTypes(t.column)"
                    class="form-control"
                    @change="handleFileUpload(t.column)"
                    style="cursor: pointer;"
                    :required="t.required && !activeRecord"
                    :disabled="t.disabled">
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <BranchPermission
          v-if="form.all_branches == false"
          :activeRecord="activeRecord"
          :form="form"/>
        <div class="col-md-12">
          <div class="float-end my-3">
            <button
              class="btn btn-danger btn-sm mx-1"
              type="button"
              @click="back">
              Cancel
            </button>
            <button
              type="submit"
              :disabled="!branchPermissionValid"
              class="btn btn-primary btn-sm mx-1">
              <i class="fa fa-save"/>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import BranchPermission from './BranchPermission.vue'
  export default {
    props: ['activeRecord', 'data', 'view'],
    components:{
      BranchPermission
    },
    data(){
      return{
        form: {}
      }
    },
    mounted(){
      // populate form with active record details
      if(this.activeRecord){
        this.data.table_data.forEach(function(d){
          this.form[d.column] = this.activeRecord[d.column]
        }.bind(this))
      }else if(_.map(this.data.table_data, td => td.column).includes('position')){
        // If no active record and table has position column
        // find the current max position and add 1 and set position in the form
        const max = _.max(_.map(this.data.table_values, tv => tv.position))
        this.form.position = max + 1
      }
      // set all_branches in form to fale to trigger branch permission component to render if no active record
      if(!this.activeRecord && _.map(this.data.table_data, td => td.column).includes('all_branches')){
        this.form.all_branches = false
      }
    },
    methods:{
      back(submit = false){
        this.$store.dispatch('removeActiveRecord', submit == true ? this.view : null)
        this.$emit('cancelCreateNew')
      },
      submit(){
        const formData = new FormData()
        for(const f in this.form){
          if(f == 'branchPermission'){
            formData.set('branchPermission', Object.keys(_.pickBy(this.form.branchPermission, (v,k) => v == true)))
          }
          else if(!_.isNull(this.form[f])){
            formData.append(f, this.form[f])
          }
        }
        const data = {
          form: formData,
          action: this.activeRecord ? 'update' : 'create',
          zip_attached: this.form.file ? this.form.file.name.includes('.zip') : false
        }
        if(this.activeRecord){
          this.$store.dispatch('updateAdminRecord', data)
        }else{
          this.$store.dispatch('createAdminRecord', data)
        }
        this.back(true)
      },
      fileTypes(column){
        const imageTerms = ['image', 'thumbnail']
        const docTerms = ['pdf']
        const fileTerms = ['file']

        let list = null
        
        if(imageTerms.some(t => column.includes(t))){
          list = ".jpg, .jpeg, .png"
        }

        if(docTerms.some(d => column.includes(d))){
          list = ".pdf"
        }

        if(fileTerms.some(f => column.includes(f))){
          list = ".mp4, .pdf, .csv, .xls, .zip"
        }
        return list
      },
      handleFileUpload(column){
        const column_name = _.replace(column, '_uid', '')
        const file = document.getElementById('fileInput_' + column).files[0]
        this.form[column_name] = file
      }
    },
    computed:{
      stringExcludes(){
        return ['id', 'company', 'exclude_type', 'area','asset_type','media_type','file_name','thumbnail_name','created_at','updated_at', 'logo_name']
      },
      intExcludes(){
        return ['product_count']
      },
      checkBoxExcludes(){
        return ['match']
      },
      branchPermissionValid(){
        if(this.form && this.form.all_branches == true){
          return true
        }else if(this.form && this.form.all_branches == false && _.isEmpty(this.form.branchPermission)){
          return false
        }
        return true
      }
    }
  }
</script>

<style scoped>
  .checkbox:hover{
    cursor: pointer;
  }
</style>