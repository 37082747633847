<template>
  <div>
    <div
      class="row"
      style="height:300px;">
      <div
        class="col-md-6 text-center d-none d-md-block"
        :style="background">
      </div>
      <div class="col-md-6 col-12 p-5 bg-primary text-white position-relative">
        <div class="centered">
          <h2 style="font-weight: 400;">WHETHER YOU ARE A DEALER OR A SUPPLIER</h2>
          <p>We combine our strengths, expertise, and professionalism to provide a value-added link between each of suppliers and customers. Our goal is to provide the highest level of service and best products available on the market.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        background:{
          backgroundImage: `url(${require('../../../../../assets/images/ejw/advantage/handshake.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }
      }
    }
  }
</script>

<style scoped>
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    width: 85%;
    transform: translateY(-50%);
  }
</style>