<template>
<div class="mt-3">
  <!-- Dealer Focus -->
  <div
    v-if="view == 'dealer'"
    class="row">
    <div
      class="col-md-8"
      :style="background1">
    </div>
    <div class="col-md-4 bg-success text-white p-5">
      <div>
        <h2 style="font-weight: 400;">OUR FOCUS IS YOU</h2>
        <p>Our vision is to develop strategic relationships that will add value to the supply chain and propel the growth, profitability and longevity of our customers and suppliers.</p>
      </div>
    </div>
  </div>
  <!-- Mfg Focus -->
  <div
    v-if="view == 'manufacturer'"
    class="row">
    <!-- Image -->
    <div
      class="col-md-8 position-relative"
      :style="background2"/>
    <div class="col-md-4 bg-success text-white p-5">
      <div>
        <h2 style="font-weight: 400;">WE ARE THE LOGISTICS EXPERT YOU'VE BEEN SEARCHING FOR.</h2>
        <p>We bring value to our vendors by instantly giving you access to thousands of retail locations internationally. Get back to focusing on manufacturing and let Lancaster the rest today!</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    props:['view'],
    data(){
      return{
        background1: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/dealerBackground.jpg')})`,
          ...this.bgProperties()
        },
        background2: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/mfg/truck.jpg')})`,
          ...this.bgProperties()
        }
      }
    },
    methods:{
      bgProperties(){
        return{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '350px'
        }
      }
    }
  }
</script>