<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-2 d-none d-md-block">
        <img
          :src="icon"
          class="img-fluid">
      </div>
      <div class="col-md-10 col-12">
        <p style="font-size: 22px;">
          <strong>Today, we cover 13 states with strong knowledgeable team members dedicated to your success.</strong>
          With thousands of in-stock products and thousands more available within a two-day delivery window, you can rely on us as your single source for every residential and commercial flooring project. We have the tools you need to the get job done right the first time. Every time.
        </p>
      </div>
    </div>
    <div class="row text-center my-5">
      <div class="col-lg-3 col-md-6 col-6 my-5">
        <h5
          class="stat"
          id="states"/>
        <strong class="text-cool">STATES</strong>
      </div>
      <div class="col-lg-3 col-md-6 col-6 my-5">
        <h5
          class="stat"
          id="delivery"/>
        <strong class="text-cool">DAY DELIVERY WINDOW</strong>
      </div>
      <div class="col-lg-3 col-md-6 col-12 my-5">
        <h5
          class="stat"
          id="years"/>
        <strong class="text-cool">YEARS EXPERIENCE</strong>
      </div>
      <div class="col-lg-3 col-md-6 col-12 my-5 text-center">
        <h5
          class="stat"
          id="inStock"/>
        <strong class="text-cool">IN-STOCK PRODUCTS</strong>
      </div>
    </div>
  </div>
</template>

<script>
  import {CountUp} from 'countup.js/dist/countUp'

  export default {
    mounted(){
      const options = {suffix: '+'}

      const states = new CountUp('states', 13)
      const delivery = new CountUp('delivery', 2)
      const years = new CountUp('years', 60, options)
      const inStock = new CountUp('inStock', 10000, options)

      const list = [states, delivery, years, inStock]
      list.forEach(l =>{
        l.start()
      })

    },
    data(){
      return{
        icon: require('../../../../../assets/images/ejw/home/eStore_Icon_OurHistory.png')
      }
    }
  }
</script>

<style scoped>
  .stat{
    font-size: 75px;
    color: var(--bs-theme-red);
  }
</style>

