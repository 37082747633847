<template>
  <section
    class="position-relative main"
    id="mainSearch">
    <form
      v-if="route != '/virtual_show' && !adminMode"
      name="desktopSearch"
      @submit.prevent="submitSearch"
      class="me-end d-flex"
      style="width:100%;">
      <div
        class="input-group position-relative w-100"
        id="navSearch">
        <input
          id="searchInput"
          class="form-control p-2"
          type="text"
          placeholder="What can we help you find?"
          ref="searchInputDesktop"
          autocapitalize="off"
          autocomplete="off"
          spellcheck="false"
          maxlength="75"
          style="box-shadow: none; border-radius:5px 0px 0px 5px; height:40px;"
          v-model="query"
          required
          :disabled="initializing"
          @focus="setFocus(true)"
          @blur="setFocus(false)">
          <!-- Clear Query -->
          <i
            v-if="query"
            class="fa fa-times text-light"
            style="
              position: absolute;
              right: 40px;
              top: 10px;
              cursor: pointer;
              z-index: 5"
              @click="clearQuery"/>
        <button
          class="btn btn-outline-light btn-sm"
          type="submit"
          :disabled="initializing"
          style="height:40px;z-index:0;">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </form>
    <!-- Type Ahead Dropdown-->
    <TypeAhead
      v-if="user.type_ahead"
      :query="query"
      :focus="focus"
      :key="route"
      :route="route"
      @submitProduct="submitProduct"
      @submitSearch="typeAheadSubmitSearch"
      @setQuery="setQuery"
      @loading="setTypeAheadLoading"/>
  </section>
</template>

<script>
import TypeAhead from './TypeAhead.vue'
  
  export default{
    props: ['route', 'adminMode'],
    data(){
      return{
        query: '',
        focus:false,
        typeAheadLoading: false
      }
    },
    components:{
      TypeAhead
    },
    watch:{
      route(newVal){
        if(newVal && newVal != '/search'){
          this.query = ''
        }
      }
    },
    methods:{
      submitProduct(data){
        this.clearQuery()
        this.$emit('submitProduct', data)
      },
      submitSearch(){
        if(this.query.length) this.$emit('submitSearch', this.query)
      },
      typeAheadSubmitSearch(query){
        this.query = query
        this.submitSearch()
      },
      clearQuery(){
        this.query = ''
        this.focus = false
      },
      setFocus(val){
        this.focus = val
      },
      setQuery(text){
        this.query = text
      },
      setTypeAheadLoading(value){
        this.typeAheadLoading = value
      }
    },
    computed:{
      initializing(){return this.$store.getters.initializing},
      user(){return this.$store.getters.user}
    }
  }
</script>

<style scoped>
 .main{
    min-width:300px;
    max-width:800px;
    width: 100%;
  }
  .input-group{
    width:100%
  }
  input{
    background: rgb(255,255,255,0.3) !important;
    color: white;
  }
  input::placeholder{
    color: white !important;
  }
  input:focus{
    color:white;
  }
</style>