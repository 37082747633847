<template>
  <div class="row main">
  <div class="col-md-6 col-12 content-wrap">
      <span class="text-danger">COMMERCIAL</span>
      <div class="my-4">
        <h2>A trusted partner to commercial flooring contractors</h2>
        <p>No matter what type of commercial project you are bidding, we have the products, tools, and knowledge to help you win more projects across all verticals. Our dedicated commercial A&D team works closely with architects, designers, and other commercial consultants to spec flooring products that meet the design, functionality, and budget of every project. Whether you are bidding on healthcare, retail, education, or multi-family, our commercial offering delivers high-performance materials that fit your project parameters.</p>
        <ProSupplyBtn/>
      </div>
    </div>
    <div
      class="col-md-6 col-12 d-none d-md-block"
      :style="imgBackground"/>
  </div>
</template>

<script>
  import ProSupplyBtn from './ProSupplyBtn.vue'
  export default{
    data() {
      return {
        imgBackground: {
            backgroundImage: `url(${require("../../../../../assets/images/ejw/about/commercial.jpg")})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover"
        }
      }
    },
    components:{
      ProSupplyBtn
    }
}
</script>

<style scoped>
  @media(min-width: 992px){
    .main{
      height: 500px;
      overflow: hidden;
    }
  }
  .content-wrap{
    padding: 50px;
  }
</style>