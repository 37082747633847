<template>
  <div
    class="shadow-lg side-menu bg-white"
    :class="showLeftMenu ? 'menu-expanded' : ''"
    id="leftMenu">
    <!-- Close -->
    <i
      class="fa fa-times fa-2x float-end me-4 mt-2"
      style="cursor:pointer;"
      @click="toggleLeftMenu"/>
    <div class="clearfix"/>
    <div class="wrapper">
      <SideNav v-if="view == 'sideNav'"/>
    </div>
  </div>
</template>

<script>
  import SideNav from '../views/SideNav.vue'
  
  export default {
   components:{
     SideNav
   },
   methods:{
    toggleLeftMenu(){this.$store.dispatch('toggleLeftMenu')} 
   },
   computed:{
     showLeftMenu(){return this.$store.getters.showLeftMenu},
     view(){return this.$store.getters.leftMenuView}
   }
  }
</script>

<style scoped>
  .side-menu{
    z-index: 2000;
    position: absolute;
    left: 0;
    top:0;
    height:100vh;
    background: #c3c2c0;
    width:0px;
    overflow-x: hidden;
    transition: width 0.2s ease-in-out;
  }
  @media(max-width: 991px) {
    .menu-expanded{
      width:50vw;
    }
  }
  @media(max-width: 575px) {
    .menu-expanded{
      width:100vw;
    }
  }
  .wrapper{
    height: 100%;
  }
  @media(max-width: 991px){
    .wrapper{
      height: 100%;
      padding:20px;
    }
  }
</style>