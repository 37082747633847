<template>
  <div class="mt-5">
    <Header/>
    <Icons/>
    <Content/>
    <Footer/>
  </div>
</template>

<script>
  import Header from './Header.vue'
  import Icons from './Icons.vue'
  import Content from './Content.vue'
  import Footer from '../app/Footer.vue'

  export default {
    components:{
      Header,
      Icons,
      Footer,
      Content
    }
  }
</script>


