<template>
  <div>
    <form
      name="editUser"
      @submit.prevent="submit"
      class="animate__animated animate__fadeIn animate__faster">
      <div class="row p-2">
        <h5 class="fw-normal"><i class="fa fa-user-edit me-2"></i>Edit {{contact.contact_id == user.contact_id ? 'My Profile' : 'User'}}</h5>
        <div class="col-md-6 my-2">
          <label for="login">
            <small class="text-muted">Username</small>
          </label>
          <input
            id="login"
            type="text"
            class="form-control"
            disabled
            :value="formData.login_id">
        </div>
        <div class="col-md-6 my-2">
          <label for="email">
            <small class="text-muted">Email*</small>
          </label>
          <input
            id="email"
            type="email"
            class="form-control"
            autocomplete="off"
            autocapitalize="off"
            spellcheck="false"
            required
            :disabled="updating"
            v-model="formData.email">
        </div>
        <div class="col-md-6 my-2">
          <label for="firstName">
            <small class="text-muted">First Name*</small>
          </label>
          <input
            id="firstName"
            type="text"
            class="form-control"
            autocomplete="off"
            autocapitalize="off"
            spellcheck="false"
            required
            :disabled="updating"
            v-model="formData.first_name">
        </div>
        <div class="col-md-6 my-2">
          <label for="lastName">
            <small class="text-muted">Last Name*</small>
          </label>
          <input
            id="lastName"
            type="text"
            class="form-control"
            autocomplete="off"
            autocapitalize="off"
            spellcheck="false"
            required
            :disabled="updating"
            v-model="formData.last_name">
        </div>
        <div class="col-md-6 my-2">
          <label for="telle">
            <small class="text-muted">Phone (optional)</small>
          </label>
          <!-- Phone1 -->
          <input
            type="tel"
            class="form-control"
            autocomplete="off"
            autocapitalize="off"
            spellcheck="false"
            id="telle"
            maxlength="14"
            pattern="^(1-)?\d{3}-\d{3}-\d{4}$"
            placeholder="Optional"
            :disabled="updating"
            v-model="formData.phone_number">
        </div>
        <div class="col-md-6 my-2" v-if="contact.contact_id != user.contact_id">
          <div class="form-group">
            <div class="text-muted small">eStore Order Entry</div>
            <fieldset class="form-group">                
              <div class="d-flex align-items-center">
                <input v-model="formData.estore_noorder" value="false" type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" />
                <label for="optionsRadios1" class="form-check-label ms-1">
                  <span class="text-success">Allowed.</span> User can submit eStore orders.
                </label>
              </div>
              <div class="d-flex align-items-center">
                <input v-model="formData.estore_noorder" value="true" type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" />
                <label for="optionsRadios2" class="form-check-label ms-1">
                  <span class="text-danger">Disabled.</span> User sees pricing but cannot submit orders.
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <!-- Form Controls -->
      <div
        v-if="!updating"
        class="row">
        <div class="col-md-12 p-3 d-flex justify-content-end flex-nowrap"> 
          <button
            type="button"
            class="btn btn-outline-primary me-2"
            @click="cancel">
            <i class="fa fa-ban"/>
            Cancel
          </button>

          <button
            type="submit"
            :disabled="!validForSubmit"
            class="btn btn-primary">
            <i class="fa fa-save"/>
            Save
          </button> 
        </div>
      </div>
    </form>
    <!-- Updating Spinner - waiting for eclipse response -->
    <div
      v-if="updating"
      class="col-md-12 text-center">
      <Spinner/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../../global/Spinner.vue'
  
  export default {
    props: ['contact'],
    data(){
      return{
        formData: {
          login_id: this.contact.login_id,
          entity_id: this.contact.entity_id,
          contact_id: this.contact.contact_id,
          first_name: this.contact.first_name,
          last_name: this.contact.last_name,
          phone_number: this.contact.phone_number,
          estore_noorder: this.contact.estore_noorder,
          email: this.contact.email
        },
        updating: false
      }
    },
    mounted(){
      const tele = document.querySelector('#telle');
      tele.addEventListener('keyup', function(e){
        if (e.key != 'Backspace' && (tele.value.length === 3 || tele.value.length === 7)){
        tele.value += '-';
        }
      });
    },
    components:{
      Spinner
    },
    methods:{
      cancel(){
        this.$emit('cancelForm')
      },
      submit(){
        this.updating = true
        if(this.user.superuser && this.contact.contact_id != this.user.contact_id){
          axios.post('/api/v1/private/superuser/update_contact', {formData: {...this.formData}}).then(res => {
            if(res.status == 200){
              this.$emit('detailUpdated',this.formData)
            }
          })
        }else{
          axios.post('/api/v1/private/user_management/update_contact_detail', {formData: {...this.formData}}).then(res => {
            if(res.status == 200){
              this.$emit('detailUpdated',this.formData)
            }
          })
        }
      }
    },
    computed:{
      validForSubmit(){
        return this.contact.first_name != this.formData.first_name || this.contact.last_name != this.formData.last_name || this.contact.email != this.formData.email || this.contact.phone_number != this.formData.phone_number || this.contact.estore_noorder != this.formData.estore_noorder
      },
      user(){
        return this.$store.getters.user
      }
    }
  }
</script>