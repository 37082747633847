<template>
  <div
    class="side-menu bg-white"
    :class="showRightMenu ? 'menu-expanded' : ''"
    id="rightMenu">
    <!-- Close -->
    <div class="d-grid" style="position: absolute; top: 1em; right: 1em;">
      <i
        class="fa fa-times fa-2x text-light"
        style="cursor:pointer;"
        @click="toggleRightMenu"/>
    </div>
    <Cart v-if="rightMenuView == 'cart'"/>
    <User
      v-if="rightMenuView == 'user'"
      :vShowMode="vShowMode"
      @toggleRightMenu="toggleRightMenu"/>
  </div>
</template>

<script>
  import Cart from '../views/Cart.vue'
  import User from '../views/User.vue'
  
  export default {
    props:['vShowMode'],
    components:{
      Cart,
      User
    },
    methods:{
      toggleRightMenu(){this.$store.dispatch('toggleRightMenu')}
    },
    computed:{
      showRightMenu(){return this.$store.getters.showRightMenu},
      rightMenuView(){return this.$store.getters.rightMenuView}
    }
  }
</script>

<style scoped>
  .side-menu{
    z-index: 2000;
    position: absolute;
    right: 0;
    top:0;
    height:100vh;
    width:0px;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: width .2s ease-in-out;
    box-shadow: 5px 5px 100px black;
    visibility: hidden;
  }
  @media(min-width: 992px) {
    .menu-expanded{
      width:25vw;
      visibility: visible;
    }
  }
  @media(max-width: 991px) {
    .menu-expanded{
      width:50vw;
      visibility: visible;
    }
  }
  @media(max-width: 575px) {
    .menu-expanded{
      width:100vw;
      visibility: visible;
    }
  }
</style>
