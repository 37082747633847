<template>
  <div class="mt-2">
    <CadTerms v-if="company == 'Lancaster Canada'"/>
    <UsTerms v-if="company == 'Lancaster US'"/>
  </div>
</template>

<script>
  import CadTerms from './CadTerms.vue'
  import UsTerms from './UsTerms.vue'
  
  export default {
    components:{
      CadTerms,
      UsTerms
    },
    computed:{
      company(){
        return this.$store.getters.sessionCompany
      }
    }
  }
</script>