<template>
  <div
    class="row top-spacing"
    style="height: 350px;">
    <div
      class="col-md-6 p-0 d-none d-md-block"
      :style="style">
    </div>
    <div class="col-md-6 bg-secondary text-white position-relative p-5">
      <div class="centered">
        <h2 style="font-weight: 400;">CHENEY VROOMAN FILLS THE GAPS</h2>
        <p>We pride ourselves on being your single source of flooring related products. We stock all the key national and regional products you needed to supply customers.</p>
        <button
          class="btn btn-large btn-outline-light"
          @click="gotoCatalog">
          View Our Product Catalog
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        style: {
          backgroundImage: `url(${require('../../../../../../assets/images/cheney/brands/PXL_20240411_144657797.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      }
    },
    methods:{
      gotoCatalog(){
        this.$router.push({path: '/product_catalog'})
      }
    }
  }
</script>

<style scoped>
  .top-spacing{
    margin-top: 35px;
  }
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    transform: translateY(-50%);
    width: 85%;
  }
</style>