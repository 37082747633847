<template>
  <div class="row animate__animated animate__fadeIn animate__faster">
    <h5>ReorderPad Products</h5>
    <!-- <div class="col-md-12 text-center mt-5">
      <Spinner/>
    </div> -->
    <template v-if="reorderPadProducts && reorderPadProducts.length">
      <div
        v-for="p in reorderPadProducts"
        :key="p.product_id" 
        class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
        <ProductCard :product="p"/>
      </div>
    </template>
    <template v-if="reorderPadProducts && reorderPadProducts.length == 0">
      <div class="col-md-12 text-center text-muted mt-5">
        <h4>No ReorderPad Products</h4>
      </div>
    </template>
  </div>
</template>

<script>
  import ProductCard from './ProductCard.vue'
  import Spinner from '../../../global/Spinner.vue'
  
  export default {
    components:{
      ProductCard,
      Spinner
    },
    created(){
      this.$store.dispatch('getReorderPadProducts')
    },
    computed:{
      reorderPadProducts(){return this.$store.getters.reorderPadResults}
    } 
  }
</script>

