<template>
  <div class="mt-5">
    <Header/>
    <Icons/>
    <StatsVideo/>
    <DipIn/>
    <Footer/>
    <SundrySceneModal/>
  </div>
</template>

<script>
  import Header from './Header.vue'
  import Icons from './Icons.vue'
  import StatsVideo from './StatsVideo.vue'
  import DipIn from './DipIn.vue'
  import Footer from '../app/Footer.vue'
  import SundrySceneModal from './SundrySceneModal.vue'

  export default {
    components:{
      Header,
      Icons,
      StatsVideo,
      DipIn,
      Footer,
      SundrySceneModal
    }
  }
</script>


