<template>
  <div>
    <div
      v-if="profile && !loading"
      class="row">
      <div class="col-md-12">
        <div class="my-2">
          <strong>Shipping Address</strong>
          <div>{{profile.name}}</div>
          <div>Customer ID:{{profile.id}}</div>
          <div>{{profile.address1}}</div>
          <div>{{profile.address2}}</div>
          <div>{{profile.city}}</div>
          <div>{{profile.state_code}}</div>
          <div>{{profile.zip_code}}</div>
        </div>
        <hr>
        <div>
          <i class="fa fa-phone"/>
          <a
            :href="'tel:' + profile.phone"
            class="ms-2">
            {{profile.phone}}
          </a>
        </div>
        <hr>
        <div
          v-for="e in email"
          :key="e">
          <i class="fa fa-envelope"/>
          <a
            :href="'mail_to:' + e"
            class="ms-2">{{e}}
          </a>
        </div>
        <hr>
        <div v-if="profile.domain_name">
          Outside Sales Rep: 
          {{profile.domain_name}}
          <hr>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  
  export default {
    components:{
      Spinner
    },
    computed:{
      profile(){return this.$store.getters.accountProfile},
      email(){
        if(this.profile?.email){
          return this.profile.email.split(',')
        }
        return null
      },
      loading(){return this.$store.getters.getAccountLoadStatus}
    }
  }
</script>