<template>
  <li class="nav-item d-none d-lg-block" style="width:300px;">
    <a
      v-if="entity.activeShipTo"
      class="btn btn-outline-light btn-sm py-0 px-2"
      href="javascript:void(0)"
      @click="gotoRepHome"
      style="font-size:14px; line-height:14px; box-shadow:none; border:none; font-weight: 700;"
      :data-bs-toggle="allowModal ? 'modal' : ''"
      :data-bs-target="allowModal ? '#shipToSelector' : ''">
      <i class="fa fa-map-marker-alt"/>
      {{entity.activeShipTo.name}}
      <br>
      {{entity.activeShipTo.address.street_line_1}}
      <br>
      {{entity.activeShipTo.address.city}}, {{entity.activeShipTo.address.state}}
      <br>
      {{entity.activeShipTo.address.postal_code}}
    </a>
  </li>
</template>

<script>
  import {internalRoles} from '../../../../shared/roles'

  export default {
    computed:{
      entity(){return this.$store.getters.getEntity},
      user(){return this.$store.getters.user},
      isBillTo(){return this.$store.getters.isBillTo},
      allowModal(){
        if(!internalRoles.includes(this.user.role) && this.isBillTo){
          return true
        }else{
          return false
        }
      }
    },
    methods:{
      gotoRepHome(){
        if(!internalRoles.includes(this.user.role)){
          return
        }else{
          this.$router.push({name: 'PrivateHome'})
          this.$store.dispatch('setRepDashboardView', true)
        }
      }
    }
  }
</script>