<template>
  <div>
    <div class="row">
      <div
        class="col-md-6 text-center d-none d-md-block"
        :style="background">
      </div>
      <div
        class="col-md-6 col-12 p-5 bg-danger text-white position-relative"
        style="min-height:250px;">
        <div class="vertical-center">
          <h2 style="font-weight:400;">HISTORY IN THE MAKING</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row my-5">
        <div class="col-md-12 px-5">
          <p>In 1953 Lancaster was founded by George Lancaster, who stocked paint brushes in a spare bedroom of his home and would hand deliver or ship them via the US Postal Service. His ambition led his small enterprise to become a well-respected wholesale distributor to retail paint outlets throughout the Carolina’s. 37 years later, the company was acquired and under the new management Lancaster was on the way to becoming one of the largest names within the paint industry. Throughout the 1990’s Lancaster experienced tremendous growth through acquisitions, purchasing LPW and Leader Distribution in Florida, James T. Davis in Virginia, Hardware Sales and Service in Georgia, and the A.F. Schuermann Company in Ohio.</p>
          <p>These acquisitions jumpstarted Lancaster’s national footprint, providing distribution centers in South Carolina, Florida and Kentucky, servicing retailers in the Southeast, Mid-Atlantic and Mid-West regions. Lancaster’s growth continued into the following decade, acquiring additional companies. Hunt Supply, Centex Sales and Inland Sales Company in Texas, Five-Star in New Jersey and GMG in California filled in the gaps across the country and provided Lancaster with distribution points in the Southwest, Northeast and on the West Coast. In 2014, Lancaster ventured outside of the United States, purchasing Dynamic Paint Products in Ontario, Canada to form Lancaster Canada. From selling Baker Paint Brushes out a spare bedroom in the upstate of South Carolina to becoming an International Distributor, Lancaster, to this day still maintains the vision and integrity of its founder.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        background:{
          backgroundImage: `url(${require('../../../../../assets/images/lan/about/history.jpg')})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          height: '350px'
        },
      }
    }
  }
</script>

<style scoped>
  .vertical-center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
</style>