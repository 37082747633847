<template>
  <div class="modal" id="betaModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="btn-close float-end"
            data-bs-dismiss="modal"
            aria-label="Close"/>
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="jumbotron">
                <h3>{{ name }} eStore</h3>
                <p>Thank you for using our new ecommerce web application!</p>
                <hr class="my-4">
                <a 
                  class="btn btn-primary" href="#" role="button"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#feedbackModal">
                  <i class="far fa-comment"/>
                  Leave Feedback
                </a>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <h5>Feedback</h5>
              <p>When submitting feedback please consider the following aspects:</p>
              <ol class="text-left">
                <li>
                  <strong>UX (User Experience)</strong>
                  <ul>
                    <li>Did the app feel intuative and smooth in its interactions and was it your liking?</li>
                  </ul>
                </li>
                <li>
                  <strong>Function</strong>
                  <ul>
                    <li>Did things work properly? If you have an issue we want to hear about it.</li>
                  </ul>
                </li>
                <li>
                  <strong>Comparison</strong>
                  <ul>
                    <li>
                      As you are a user of the current website, how does this new site compare?
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Platform</strong>
                  <ul>
                    <li>Did you use the app on a Computer/Tablet/Mobile? Please be aware this single app can work accross all platforms and can even be installed to your device.</li>
                  </ul>
                </li>
                <li>
                  <strong>Personal Opinion</strong>
                  <ul>
                    <li>How do you feel about the app and experience overall? Is anything missing? What would you add next?</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {companyName} from '../../../shared/env'
  export default{
    inject: ['env'],
    computed:{
      name(){return companyName(this.env)}
    }
  }
</script>