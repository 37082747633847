<template>
	<div class="container p-4">
		<div class="row">
			<div
				v-if="!loading"
				class="col-md-12 animate__animated animate__fadeIn animate__faster">
				<button
					class="float-start btn btn-primary btn-sm d-none d-lg-block"
					@click="goBack">
					<i class="fa fa-chevron-left"/>
					Back
				</button>
				<div
					v-if="product"
					class="my-5">
					<!-- Product Data -->
					<div>
						<!-- Favorites -->
						<FavPanel
							v-if="!virtualShow && showFav"
							@toggleFav="toggleFav"
							:showFav="showFav"
							:product="product"/>
						<div v-if="!showFav">
							<div class="row">
								<div class="col-lg-3 col-md-5 position-relative">
									<Indicator :product="product"/>
									<!-- Fav Trigger -->
									<i
										class="fav text-danger float-end"
										:class="isFav ? 'fa fa-heart' : 'far fa-heart'"
										@click="toggleFav"/>
									<!-- Image -->
									<img
										:src="product.image_url"
										@error="imgError"
										class="img-fluid prod-img"
										style="height:320px; object-fit:contain; z-index: -1;">
								</div>
								<div class="col-lg-9 col-md-7 py-3">
									<!-- Description -->
									<h4>{{product.description}}</h4>
									<!-- General Info -->
									<p	
										v-if="generalInfo"
										class="text-muted">
										{{generalInfo}}
										<!-- Bullets -->
										<ul>
											<li
												v-for="b in bullets"
												:key="b.description">
													{{b.value}}	
											</li>
										</ul>
									</p>
									<div class="row">
										<div class="col-md-12">
											<Exclusion
												:product="product"
												class="mt-2"/>
										</div>
										<!-- Price -->
										<div class="col-xl-3 col-4 mb-2 price">
											<ProductPrice
												:product="product"
												:virtualShow="virtualShow"
												:hidePricing="hidePricing"
												@activeUom="setActiveUom"
												:key="product.product_id"/>
										</div>
										<!-- Qty Input -->
										<div class="col-xl-3 col-8 mb-2">
											<QtyForm
												:product="product"
												:virtualShow="virtualShow"
												class="mt-2"
												:activeUom="activeUom"
												:key="product.product_id"/>
											<QtyBreaks
												class="breaks"
												:product="product"
												:virtualShow="virtualShow"/>
										</div>
									</div>
									<div class="row my-2">
										<div class="part-and-prop">
											<CustomerPartNumber :product="product"/>
											<Prop65
												:product="product"
												class="float-end"
												style="font-size: 12px;"/>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<!-- Availability & Detail -->
									<ProductAccordion
										:product="product"
										:activeUom="activeUom"
										:info="info"
										:minQuantity="minQuantity"
										:caseQty="caseQty"
										:palletQty="palletQty"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Similar Products -->
				<div
				v-if="loadSimilar && !loading && !showFav"
				class="row mt-5">
					<hr>
					<div class="col-md-12">
						<SimilarProducts :product="product"/>
					</div>
				</div>
			</div>
			<div
				v-if="loading"
				class="col-md-12 text-center">
				<Spinner
					class="mt-5"
					:loading="loading"/>
			</div>
		</div>
	</div>
</template>

<script>
	import QtyForm from '../QtyForm.vue'
  import FavPanel from '../detail/FavPanel.vue'
  import Exclusion from '../Exclusion.vue'
  import Indicator from '../Indicator.vue'
  import CustomerPartNumber from '../detail/CustomerPartNumber.vue'
  import ProductPrice from '../price/ProductPrice.vue'
  import QtyBreaks from '../QtyBreaks.vue'
  import {caseQuantity,palletQuantity, minQuantity} from '../../../../../shared/ProductFunctions'
  import {showDetail} from '../../../../../shared/showDetail'
  import comingSoon from '../../../../../shared/comingSoon'
  import BrandLink from '../detail/BrandLink.vue'
  import { internalRoles } from '../../../../../shared/roles'
  import StockStatus from '../StockStatus.vue'
  import Prop65 from '../Prop65.vue'
  import Availability from '../detail/Availability.vue'
	import Spinner from '../../../../global/Spinner.vue'
	import SimilarProducts from './SimilarProducts.vue'
	import ProductAccordion from './ProductAccordion.vue'

	export default{
		mounted(){
			this.getProduct()
		},
		inject: ['env'],
		data(){
      return{
        virtualShow: false,
        internalRoles,
        activeUom: null,
				loadSimilar: false
      }
    },
		components:{
      QtyForm,
      FavPanel,
      Exclusion,
      Indicator,
      CustomerPartNumber,
      ProductPrice,
      QtyBreaks,
      BrandLink,
      StockStatus,
      Prop65,
      Availability,
			Spinner,
			SimilarProducts,
			ProductAccordion
    },
		watch:{
			product(newVal){
				if(newVal && !this.loading){
					this.loadSimilar = true
				}
			}
		},
		methods:{
			getProduct(){this.$store.dispatch('getProduct', this.$route.params)},
			toggleFav(){this.$store.dispatch('setShowFav', !this.showFav)},
      imgError(e){comingSoon(e)},
      setActiveUom(activeUom){this.activeUom = activeUom},
			goBack(){
				this.$router.go(-1)
			}
		},
		computed:{
      product(){return this.$store.getters.getActiveProduct},
      generalInfo(){
        let record = _.find(this.product.eclipse_data.specs, {description: 'General Information'})
        if(record) return record.value
        return null
      },
      minQuantity(){return minQuantity(this.product, this.activeUom, this.env)},
      showFav(){return this.$store.getters.showFavPanel},
      info(){return _.reject(this.product.eclipse_data.specs, s=> _.some(['Bullet', 'General Information'], v=> _.includes(s.description, v)))},
      bullets(){return _.filter(this.product.eclipse_data.specs, s => _.includes(s.description, 'Bullet'))},
      hidePricing(){return this.$store.getters.hidePricing},
      uomQty(){return _.find(this.product.eclipse_data.uoms, {name: this.product.eclipse_data.uom}).quantity},
      caseQty(){return caseQuantity(this.product)},
      palletQty(){return palletQuantity(this.product)},
      showDetail(){if(this.virtualShow) return showDetail(this.product)},
      role(){return this.$store.getters.user.role},
			loading(){return this.$store.getters.productLoadState},
			favoriteList(){return this.$store.getters.getFavList},
      isFav(){return _.includes(this.favoriteList, this.product.product_id)}
    }
	}
</script>

<style scoped>
	.prod-img{
		position: relative;
		top: 10rem;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.price{
		display: flex;
    align-items: center;
	}
	.part-and-prop{
		display: flex;
    justify-content: space-between;
    align-items: baseline;
	}
	.breaks{
		margin: -4px;
	}
	.fav{   
		cursor: pointer;
    z-index: 1;
    top: 20px;
    right: 10px;
    position: absolute;
  }
  .fav:hover{
    transform: scale(1.2);
    transition: .1s ease-in-out;
  }
	.far{
		background: white;
    border-radius: 50%;
    padding: 3px;
	}
</style>