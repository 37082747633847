<template>
  <div>
    <div class="row mt-2 px-4">
      <div class="col-md-12">
        <h4>Library</h4>
      </div>
    </div>
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
    <div class="container-fluid">
      <div
        v-if="!loading"
        class="animate__animated animate__fadeIn animate__faster">
        <!-- Magazines -->
        <div
          v-if="filteredLibrary && filteredLibrary.length > 0"
          class="row mb-5">
          <div
            v-for="l in filteredLibrary"
            :key="l.id"
            class="col-12 col-sm-6 col-md-3 col-xl-2 mb-4">
            
           <Item :item="l" type="library" />

          </div>
        </div>
        <div
          v-else
          class="row">
          <div class="col-md-12 text-center mt-5">
            <h4 class="text-muted">No Library Items</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../global/Spinner.vue'
  import Item from './Item.vue'

  export default {
    name: 'Library',
    data(){
      return{
        loading: false,
        library: null
      }
    },
    components:{
      Spinner,
      Item
    },
    created(){
      this.loading = true
      axios.get('/api/v1/private/digital_assets/get_assets').then(res =>{
        if(res.status == 200){
          this.library = _.filter(res.data.assets, a =>['library', 'external link'].includes(a.asset_type))
          //default sort is by title A-Z
          this.library = _.orderBy(this.library, 'title', 'asc')
          this.loading = false
        }
      })
    },
    computed: {
      filteredLibrary(){
         return _.filter(this.library, (l) => { return l.company == this.$store.getters.sessionCompany || l.company ==  'Both' })
      }
    }
  }
</script>