<template>
  <div>
    <div
      class="row"
      style="height:350px;">
      <div
        class="col-md-8 text-center d-none d-md-block"
        :style="background">
      </div>
      <div class="col-md-4 col-12 p-5 bg-danger text-white position-relative">
        <div class="centered">
          <h2 style="font-weight:400;">FOUNDED IN 1953</h2>
          <p>Headquartered in Spartanburg, South Carolina, Lancaster is the premier paint sundry distributor, of North America, South America and the Caribbean.</p>
        </div>
      </div>
    </div>
    <div
      class="col-md-12 text-center d-block d-md-none"
      style="height:350px;"
      :style="background">
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        background: {
          backgroundImage: `url(${require('../../../../../assets/images/lan/about/warehouse.jpg')})`,
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          backgroundAttachment: 'fixed',
          filter: 'brightness(0.8)'
        }
      }
    } 
  }
</script>

<style scoped>
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    transform: translateY(-50%);
    width: 85%;
  }
</style>