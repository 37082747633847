<template>
  <div>
    <div class="container-fluid">
      <div
        class="row my-4 bg-secondary"
        style="height: 300px;">
        <div class="col-lg-4 text-light d-none d-lg-block p-0">
          <div class="p-3">
            <div class="list-pad">
              <h5>Servicing the Industry</h5>
              <ul
                class="p-0"
                style="list-style-type: none;">
                <li>Dedicated Sales Support</li>
                <li>Residential Programs</li>
                <li>Commercial Quotes</li>
                <li>ProSupply</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Office Image -->
        <div class="col-lg-4 p-0 text-center">
          <img
            :src="office"
            style="height: 300px; width: 100%; object-fit: contain;">
        </div>
        <div
          class="col-lg-4 text-light d-none d-lg-block p-0"
          style="height: 300px;">
          <div class="text-pad">
            <p class="mt-2">Cheney Vrooman is committed to your success. By offering our customers a large selection of popular favorites and trending styles, Cheney Vrooman is here to help you win more jobs. Our sales and support staff are to help keep your project moving, whether that be product knowledge, training, technical solutions or coordinating orders and deliveries.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <h3>The Cheney Vrooman Advantage</h3>
          <hr class="ejw-hr">
          <!-- Control Links -->
          <div class="row my-2">
            <div class="col d-flex justify-content-center">
              <button
                v-for="(l, i) in links"
                :key="l"
                class="btn"
                :class="activeCard == i ? 'link-active' : 'not-active'"
                @click="setActiveCard(i)">
                <span class="d-none d-md-block">{{ l.desktop }}</span>
                <span class="d-block d-md-none mx-4">{{ l.mobile }}</span>
              </button>
            </div>
          </div>
          <!-- Content Card -->
        </div>
      </div>
      <ContentCard :cardData="cardData"/>
    </div>
  </div>
</template>

<script>
  import ContentCard from './ContentCard.vue'
  
  export default{
    data(){
      return{
        links: [
          {
            desktop: 'A Legacy of Trust',
            mobile: 'Legacy'
          },
          {
            desktop: 'Flooring Expertise & Service',
            mobile: 'Expertise'
          },
          {
            desktop: 'One-Stop Shopping',
            mobile: 'One-Stop'
          }
        ],
        office: require('../../../../../assets/images/cheney/CheneyOffice.jpg'),
        cards: [
          {
            image: require('../../../../../assets/images/ejw/home/employee-samples.jpg'),
            title: 'A Legacy of Trust',
            blurb: "Cheney Vrooman, an EJ Welch company, is a trusted partner you can turn to for answers and solutions that keep your business profitable. We’ve been earning customers’ trust since 1938, one relationship at a time.",
            text: "In this industry, trust is everything. With each new project you take on, you put your reputation on the line—so do we. Because, at the end of the day, our mission isn’t simply to sell products. We exist to serve as a trusted partner you can turn to for answers and solutions that keep your business profitable. Cheney Vrooman was founded on solid Midwest roots, and made stronger by joining forces with three incredible companies that not only share our vision and values, but that bring decades of industry knowledge and experience."
          },
          {
            image: require('../../../../../assets/images/ejw/home/employee-handshake.jpg'),
            title: 'Flooring Expertise & Service',
            blurb: "Decades of know-how to help you select the right products, solve more challenges and complete more projects on time and on budget.",
            text: "Time is money. That’s why we go the extra mile—so you don’t have to. From the truck drivers to the branch managers and executive office staff, you can rely on every member of the Cheney Vroman and EJ Welch family to go above and beyond to support your business. Consult with our expert staff at any of our 27 locations across the Midwest on new flooring tools, sundry supplies and installation techniques. We take pride in helping our customers solve challenges large and small."
          },
          {
            image: require('../../../../../assets/images/ejw/home/employee-clipboard.jpg'),
            title: 'One-Stop Shopping',
            blurb: "Thousands of products in-stock means you can rely on us as your single source for every project, large or small.",
            text: "You can rely on us as your single source for every residential and commercial flooring project. That means you can simplify your back-end office tasks with just one invoice and one delivery, so you can spend more time serving your customers and growing your business. Cheney Vrooman is dedicated to providing you with the largest selection of flooring tools and supplies from manufacturers you trust."
          }
        ],
        activeCard: 0
      }
    },
    components:{
      ContentCard
    },
    computed:{
      cardData(){
        return this.cards[this.activeCard]
      }
    },
    methods:{
      setActiveCard(index){
        this.activeCard = index
      }
    }
  }
</script>

<style scoped>
  .ejw-hr{
    height: 3px !important;
    width: 10%;
    margin: 0 auto;
    background: #D73E18;
    margin-bottom: 50px;
    opacity: 1;
  }
  .link-active{
    border-bottom: 5px solid #D73E18;
  }
  .not-active{
    border-bottom: 2px solid lightgray;
  }
  .btn:focus{
    box-shadow: none;
  }
  .btn{
    border-radius: 0px;
  }

  @media(max-width: 430px){
    .btn{
      border-radius: 0px;
      font-size:12px;
      padding: 0px;
    }
  }

  @media(min-width: 992px){
    .list-pad{
      padding: 35px 30px;
    }
    .text-pad{
      padding: 35px 30px;
    }
  }
  @media(min-width: 1400px){
    .list-pad{
      padding: 75px 100px;
    }
    .text-pad{
      padding: 75px 30px;
    }
  }
</style>