<template>
  <div class="row">
    <div class="col-md-12">
      <form
        name="quickPadCopyPaste"
        @submit.prevent="submit"
        class="mt-2">
        <textarea
          name="copyPaste"
          cols="30"
          rows="15"
          class="form-control"
          v-model="pastePad"
          placeholder="Enter one line per item"
          :disabled="loading"
          required>
        </textarea>
        <div class="float-start text-muted">
          Qty [COMMA or TAB] ProductID or UPC
        </div>
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-primary float-end mt-2">
          <i class="fa fa-paper-plane"/>
          Submit
        </button>
      </form>
    </div>
    <div
      v-if="loading"
      class="col-md-12 text-center">
      <Spinner/>
    </div>
    <div
      v-if="error"
      class="col-md-12 text-center animate__animated animate__bounceIn animate__faster">
      <strong class="text-danger">{{error}}</strong>
    </div>
    <div
      v-if="confirmation"
      class="col-md-12 text-center animate__animated animate__bounceIn animate__faster">
      <strong class="text-success">{{confirmation}}</strong>
    </div>
  </div>
</template>

<script>
  import Spinner from  '../../../global/Spinner.vue'
  export default {
    data(){
      return{
        pastePad: null,
        error: null,
        confirmation: null
      }
    },
    components:{
      Spinner
    },
    created(){
      this.$store.dispatch('setQuickPadPasteLoading', false)
    },
    methods:{
      submit(){
        if(this.pastePad){
          let status = []
          const lines = _.compact(this.pastePad.split('\n'))
          if(lines){
            lines.forEach(function(l){
              const separaters = [",", "\t"]
              const includesDelimeter = separaters.some((s) => l.includes(s))
              status.push(includesDelimeter)
            })
          }
          if(status.includes(false)){
            this.error = "Check input format, data not valid!"
          }else{
            this.error = null
            this.$store.dispatch('submitQuickPadPaste', this.pastePad)
            this.confirmation = "Adding product(s) to cart!"
            setTimeout(function(){
              this.confirmation = null
            }.bind(this), 5000)
          }
        }
      }
    },
    computed:{
      loading(){return this.$store.getters.quickPadPasteLoading}
    },
    watch:{
      loading(newVal){
        if(!newVal){
          this.pastePad = null
        }
      }
    }
  }
</script>