<template>
  <div class="border rounded p-3 animate__animated animate__fadeIn animate__faster mt-3">
    <form
      name="promoAsset"
      @submit.prevent="submit">
      <h4
        v-if="editAsset"
        class="text-center text-muted">
        Edit Asset
      </h4>
      <!-- ID -->
      <div class="mb-2">
        <label for="promoID">Promo ID</label>
        <input
          type="number"
          class="form-control"
          id="promoID"
          disabled
          v-model="form.promo_id">
      </div>
      <!-- Title -->
      <div class="mb-2">
        <label
          for="title"
          class="form-label">Title</label>
        <input
          type="text"
          class="form-control"
          id="title"
          v-model="form.title"
          required>
      </div>
      <p
        v-if="editAsset"
        class="text-muted mt-2">
        <small>*Only upload new files if you need to replace the current files.</small>
      </p>
      <!-- Thumbnail -->
      <div class="mb-2">
        <label
          for="thumbnail"
          class="form-label">
          Thumbnail Image
        </label>
        <br>
        <input
          id="thumbnail"
          type="file"
          style="cursor: pointer;"
          accept=".jpg, .jpeg, .png"
          ref="thumbnail"
          @change="uploadThumbnail"
          :required="editAsset ? false : true">
      </div>
      <!-- PDF -->
      <div class="mb-2">
        <label
          for="pdf"
          class="form-label">PDF File
        </label>
        <br>
        <input
          type="file"
          style="cursor: pointer;"
          id="pdf"
          accept=".pdf"
          ref="pdf"
          @change="uploadPdf"
          :required="editAsset ? false : true">
      </div>
      <!-- Form Actions -->
      <div class="row">
        <div class="col-md-12">
          <div class="float-end">
            <div class="input-group">
              <button
                class="btn btn-sm btn-danger"
                type="button"
                @click="$emit('formClose')">
                <i class="fa fa-ban"/>
                Cancel
              </button>
              <button
                class="btn btn-sm btn-primary"
                type="submit">
                <i class="fa fa-paper-plane"/>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  export default{
    props: ['promo', 'editAsset'],
    data(){
      return{
        form: {
          promo_id: this.promo.id,
          title: null,
          thumbnail: null,
          pdf: null
        }
      }
    },
    mounted(){
      if(this.editAsset){
        this.form.title = this.editAsset.promo_title
      }
    },
    methods:{
      submit(){
        if(this.editAsset){
          // Update
          const formData = new FormData()
          formData.append('asset_id', this.editAsset.id)
          const form  = _.omitBy(this.form, _.isNull)
          for (let k in form){
            formData.append(k, form[k])
          }
          this.$store.dispatch('updatePromoAsset', formData)
        }else{
          // Create
          const formData = new FormData()
          for (const k in this.form){
            formData.append(k, this.form[k])
          }
          this.$store.dispatch('createPromoAsset', formData)
        }
        this.$emit('formClose')
      },
      uploadThumbnail(){
        this.form.thumbnail = this.$refs['thumbnail'].files[0]
      },
      uploadPdf(){
        this.form.pdf = this.$refs['pdf'].files[0]
      }
    }
  }
</script>