<template>
  <div class="my-5">
    <div class="text-center">
      <h3
        class="text-secondary"
        style="font-weight: 400">
        BRANDS YOU DEPEND ON
      </h3>
      <small>From the floor to the ceiling and the kitchen to the patio, Lancaster's key partners fill product gaps.</small>
    </div>
    <LetterSelector
      :activeLetter="activeLetter"
      :vendors="vendors"
      @setLetter="setLetter"/>
    <VendorSlider
      :vendors="vendors"
      :activeLetter="activeLetter"/>
  </div>
</template>

<script>
  import LetterSelector from './LetterSelector.vue'
  import VendorSlider from './VendorSlider.vue'
  export default {
    props:['vendors'],
    data(){
      return{
        activeLetter: null
      }
    },
    methods:{
      setLetter(letter){
        this.activeLetter = letter
      }
    },
    components:{
      LetterSelector,
      VendorSlider
    }
  }
</script>


