<template>
  <div>
    <div class="mb-3">
      <div>Version: {{version}}</div>
      <div>Release Date: {{moment(releaseDate).format("MM-DD-YYYY")}}</div>
    </div>
    <div v-if="added.length > 0">
      <span class="badge bg-info">
        <i class="fa fa-plus"/>
        New Additions
      </span>
      <ul>
        <li
          v-for="p in added"
          :key="p.id">
          {{p.patch_note}}
        </li>
      </ul>
    </div>
    <div v-if="fixed.length > 0">
      <span class="badge bg-danger">
        <i class="fa fa-bug"/>
        Bug Fixes
      </span>
      <ul>
        <li
          v-for="p in fixed"
          :key="p.id">
          {{p.patch_note}}
        </li>
      </ul>
    </div>
    <div v-if="updated.length > 0">
      <span class="badge bg-success">
        <i class="fa fa-sync"/>
        Updates
      </span>
      <ul>
        <li
          v-for="p in updated"
          :key="p.id">
          {{p.patch_note}}
        </li>
      </ul>
    </div>
    <div v-if="feature.length > 0">
      <span class="badge bg-contact">
        <i class="fa fa-rocket"/>
        New Features
      </span>
      <ul>
        <li
          v-for="p in feature"
          :key="p.id">
          {{p.patch_note}}
        </li>
      </ul>
    </div>
    <hr>
  </div>
</template>

<script>
  export default {
    props: ['notes', 'title'],
    computed:{
      added(){
        return _.filter(this.notes, {patch_type: 'added'})
      },
      fixed(){
        return _.filter(this.notes, {patch_type: 'fixed'})
      },
      updated(){
        return _.filter(this.notes, {patch_type: 'updated'})
      },
      feature(){
        return _.filter(this.notes, {patch_type: 'feature'})
      },
      version(){
        return this.notes[0].version
      },
      releaseDate(){
        return this.notes[0].release_date
      }
    }
  }
</script>