<template>
  <div>
    <!-- Drop Ship Note -->
    <span
      v-if="routeAction == 'virtualShowDropShips' && !loading"
      class="ms-4 text-muted">
      * All dropships are subject to vendor's prepaid and minimum order policies.
    </span>
    <!-- Product Results -->
    <ProductSearch :virtualShow = 'true'/>
  </div>
</template>

<script>
  import ProductSearch from '../../product/ProductSearch.vue';
  export default{
    components: {
      ProductSearch
    },
    computed:{
      routeAction(){return this.$route.query?.action},
      loading(){return this.$store.getters.productLoadState}
    }
  }
</script>