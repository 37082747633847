import {createApp} from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import store from './stores/store'
import './stylesheets/application.scss'
import "./stylesheets/pretty-checkbox.css"
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/free-brands-svg-icons'
import 'animate.css'
import moment from 'moment'
import lodash from 'lodash'
import numeral from 'numeral'
import VueGtag from 'vue-gtag'
import router from './router'
import env from './env'
require('./serviceworker-companion')

// Main Component
import App from './App.vue'

const app = createApp(App)

app.config.globalProperties.numeral = numeral
app.config.globalProperties.moment = moment
app.config.globalProperties._ = lodash

// Pass env value into the Vue app, use inject inside of componets to access
app.provide('env', env)
const ejwGoogleKey = "AIzaSyCGlbyhfs9Y6CqG_VZgOQdX6ZH2uPP6vaM"
// EJW Stylesheet
if(env == 'EJW'){
  require('./stylesheets/ejw/bootstrap.css')
  app.use(VueGoogleMaps, {
    load: {key: ejwGoogleKey}
  })
}
// Cheney Stylesheet
if(env == 'EJW-CHENEY'){
  require('./stylesheets/cheney/bootstrap.css')
  app.use(VueGoogleMaps, {
    load: {key: ejwGoogleKey}
  })
}
// Lancaster Stylesheet
if(env == "LAN"){
  require('./stylesheets/lan/bootstrap.css')
}

app.use(router)
app.use(store)

if(process.env.NODE_ENV.includes('prod')){
  const ejwGa4 = 'G-58BE1N25RK'
  const lanGa4 = 'G-WP0H5W4TVN'
  app.use(VueGtag, {
    config: {id: env.includes('EJW') ? ejwGa4 : lanGa4}
  }, router)
}

app.mount('#app')