<template>
  <div class="container">
    <h5
      v-for="s in selectors"
      :key="s"
      :class="s == activeLetter ? 'active' : 'not-active'"
      class="d-inline-block"
      @click="setLetter(s)">
      <span :class="s == activeLetter ? 'active-text' : 'non-active-text'">
        {{s}}
      </span>
    </h5>
  </div>
</template>

<script>
  export default {
    props:['activeLetter', 'vendors'],
    data(){
      return{
        selectors: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
      }
    },
    methods:{
      setLetter(letter){
        this.$emit('setLetter', letter)
      }
    }
  }
</script>

<style scoped>
  .active{
    font-weight: 900;
    font-size: 25px;
    background: #D73E18;
    padding: 5px 10px;
    transition: .15s ease-in-out;
    box-shadow: 1px 1px 5px gray;
  }
  .active-text{
    color: #fff;
  }
  .not-active{
    font-size: 18px;
    cursor: pointer;
    padding: 10px;
    color: var(--bs-primary);
  }
  .not-active:hover{
    transform: translateY(-10px);
    font-weight: bolder;
    transition: .05s ease-in-out;
  }
</style>