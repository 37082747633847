<template>
  <div
    v-if="!internalRoles.includes(user.role)"
    class="fav"
    @click="showFavPanel"
    data-bs-toggle="modal"
    data-bs-target="#productDetail">
    <i
      v-if="isFav"
      class="fa fa-heart text-danger heart"/>
    <i
      v-else
      class="far fa-heart heart"/>
  </div>
</template>

<script>
  import {internalRoles} from '../../../../shared/roles'
  
  export default {
    props: ['product'],
    data(){
      return{
        internalRoles
      }
    },
    methods:{
      showFavPanel(){
        this.$store.dispatch('setActiveProduct', this.product, true)
        this.$store.dispatch('setShowFav', true)
      }
    },
    computed:{
      favorites(){return this.$store.getters.getFavList},
      isFav(){return _.includes(this.favorites, this.product.product_id)},
      user(){return this.$store.getters.user}
    }
  }
</script>

<style scoped>
  .fav{
    position: absolute;
    top: 2px;
    right: 5px;
    cursor:pointer;
  }
  .fav:hover{
    transform: scale(1.2);
    transition: .1s ease-in-out;
  }
  .heart{
    background: white;
    border-radius: 50%;
    padding: 3px;
  }
</style>