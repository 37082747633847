<template>
  <div>
    <div v-if="!loading">
      <Email
        v-if="emailData.orderList.length > 0"
        :emailData="emailData"
        type="Orders"
        @cancel="emailCancel"/>
      <!-- Pending Orders -->
      <div class="row">
        <div class="col-md-3">
          <ProductSearch
            view="open"/>
        </div>
        <!-- Status Filter -->
        <div class="col-md-3">
          <label for="statusFilter">Status Filter</label>
          <select
            v-show="statusList"
            id="statusFilter"
            class="form-select"
            @change="setFilter"
            :value="orderStatusFilter || 'All'"
            style="cursor: pointer;">
            <option
              v-for="s in statusList"
              :value="s">
              {{ s }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <button
            v-if="orderSearchResults || orderStatusFilter"
            class="btn btn-primary btn-sm my-2 reset w-100"
            @click="clear">
            <i class="fa fa-sync"/>
            Reset
          </button>
        </div>
        <PendingOrder
          v-for="(o,i) in filteredOrders"
          :key="o.id + i"
          :order="o"
          :emailData="emailData"
          @viewOrder="viewOrder"
          @emailToggle="emailToggle"/>
      </div>
      <!-- Handle empty -->
      <div class="row" v-if="filteredOrders.length == 0">
        <div
          cols="12"
          class="text-center text-muted">
          <h3
            v-if="!internalRoles.includes(user.role)"
            class="mt-5">
            No Pending Orders
          </h3>
          <h3
            v-else
            class="mt-5">
            No Bids & Quotes
          </h3>
        </div>
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
    </div>
  </div>
</template>

<script>
  import PendingOrder from './PendingOrder.vue'
  import Email from './Email.vue'
  import Spinner from '../../../global/Spinner.vue'
  import {internalRoles} from '../../../../shared/roles'
  import ProductSearch from './ProductSearch.vue'

  export default {
    data(){
      return{
        internalRoles,
        viewingOrder: false,
        emailData:{
          orderList: [],
          addressList: []
        }
      }
    },
    components:{
      PendingOrder,
      Email,
      Spinner,
      ProductSearch
    },
    methods:{
      viewOrder(order){
        this.$store.dispatch('viewOrder', order)
      },
      emailToggle(order){
        this.emailData.orderList.push(order)
      },
      emailCancel(){
        this.emailData = {
          orderList: [],
          addressList: []
        }
      },
      clear(){
        this.$store.dispatch('clearAccountProductSearch')
        this.$store.dispatch('setOrderStatusFilter', null)
      },
      setFilter(e){
        if(e.target.value == 'All'){
          this.$store.dispatch('setOrderStatusFilter', null)
        }else{
          this.$store.dispatch('setOrderStatusFilter', e.target.value)
        }
      }
    },
    created(){
      this.$store.dispatch('getPendingOrders')
    },
    computed:{
      pendingOrders(){return this.$store.getters.getPendingOrders},
      orderStatusFilter(){return this.$store.getters.orderStatusFilter},
      filteredOrders(){
        let base = this.pendingOrders
        // Status Filter Results
        if(this.orderStatusFilter){
          base = _.filter(base, b => b.order_status == this.orderStatusFilter)
        }
        // Product Search Results
        if(this.orderSearchResults?.view == 'open'){
          return _.filter(base, b => this.orderSearchResults.orders.includes(b.id))
        }
        return base
      },
      user(){return this.$store.getters.user},
      loading(){return this.$store.getters.getAccountLoadStatus},
      orderSearchResults(){return this.$store.getters.orderSearchResults},
      statusList(){
        let baseList = ['All']
        const statusList = _.sortBy(_.uniq(_.map(this.pendingOrders, p => p.order_status)), s => s)
        return [...baseList, ...statusList]
      }
    }
  }
</script>

<style scoped>
  @media(min-width: 768px){
    .reset{
      margin-top: 30px !important;
    }
  }
</style>