<template>
  <div v-if="cartList && cartList.length > 1">
    <!-- Transfer Control Button -->
    <div v-if="!transferring">
      <button
        class="float-end btn btn-link btn-sm text-muted trans-btn"
        style="font-size:12px;"
        :disabled="loading"
        @click="toggle">
        <i class="fa fa-cart-arrow-down"/>
        Transfer
      </button>
    </div>
    <!-- Container -->
    <div
      v-if="show && !transferring"
      class="row transfer shadow-lg"
      style="overflow:hidden;">
      <!-- Cart List -->
      <div class="col-12 animate__animated animate__fadeIn animate__faster p-2">
        <small class="text-muted float-start">Transfer To</small>
        <i
          class="fa fa-times float-end"
          style="cursor: pointer;"
          @click="toggle"/>
        <div class="clearfix"/>
        <hr class="m-0">
        <small style="font-weight: 600;">Carts</small>
        <ul
          class="p-1"
          style="list-style-type: none;">
          <li
            v-for="c in cartList"
            :key="c.id"
            class="hover mx-1">
            <small
              v-if="c.id != cart.details.id"
              @click="transfer(c.id)">
              {{c.cart_name ? c.cart_name : 'No Name'}} ({{c.cart_products.length}} items)
            </small>
          </li>
        </ul>
      </div>
      <!-- Transfer Spinner -->
      <div v-if="transferring">
        <button class="btn btn-outline-primary btn-sm">
          Transferring...
          <Spinner/>
        </button>
      </div>
      <!-- Error -->
      <div
        v-if="error"
        class="animate__animated animate__bounceIn animate__faster">
        <strong class="text-danger">{{error}}</strong>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../global/Spinner.vue'
  
  export default {
    props: ['product', 'loading'],
    data(){
      return{
        show: false,
        transferring: false,
        error: null
      }
    },
    components:{
      Spinner
    },
    methods:{
      toggle(){
        this.show = !this.show
      },
      transfer(id){
        this.transferring = true
        let transfer = {
          product:{
            eclipse_id: this.product.eclipse_id,
            quantity: this.product.quantity,
            uom: this.product.uom 
          },
          cart:{id}
        }
        axios.post('/api/v1/private/cart/transfer', {transfer}).then(res => {
          if(res.data.transferred == true){
            this.$store.dispatch('removeCartItem', this.product)
            this.$store.dispatch('getCarts')
            this.transferring = false
          }else{
            this.toggle()
            this.error = res.data.error
            this.transferring = false
            setTimeout(function(){
              this.error = null
            }.bind(this), 5000)
          }
        })
      }
    },
    computed:{
      cart(){return this.$store.getters.cart},
      cartList(){
        const vshowCart = this.cart.details.vshow_cart
        if(vshowCart){
          return _.filter(this.$store.getters.cartList, {vshow_cart: true})
        }else{
          return _.filter(this.$store.getters.cartList, {vshow_cart: false})
        }
      }
    }
  }
</script>

<style scoped>
  .hover:hover{
    background: lightgray;
    cursor: pointer;
    padding: 0px 5px;
    transition: all 100ms ease-in;
  }
  .trans-btn{
    box-shadow: none !important;
  }
  .transfer{
    position: absolute;
    top: 50%;
    left: 50%;
    background: white;
    z-index: 1;
    border: 2px solid var(--bs-primary);
    border-radius: 5px;
    width: 35dvh;
    transform: translatex(-50%) translatey(-50%);
  }
  ul{
    overflow-y: scroll;
    max-height: 5rem;
  }
</style>