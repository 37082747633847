<template>
  <div class="animate__animated animate__fadeIn animate__faster position-relative">
    <SideNav
      v-if="!adminMode"
      :activeView="activeView"
      class="left"/>
    <AdminNav v-if="adminMode"/>
  </div>
</template>


<script>
  import SideNav from '../views/SideNav.vue'
  import AdminNav from '../views/AdminNav.vue'
  
  export default {
    props: ['activeView'],
    components:{
      SideNav,
      AdminNav
    },
    computed:{
      adminMode(){return this.$router.currentRoute.value.path == '/admin'}
    }
  }
</script>

<style scoped>
  .left{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    padding:5px;
  }
</style>