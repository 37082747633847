<template>
  <div
    class="card m-1 p-1 shadow-sm animate__animated animate__fadeIn animate__faster"
    :ref="'mobile-' + product.product_id"
    style="z-index: 0; min-height: 140px;">
    <div class="card-body position-relative">
      <Indicator style="z-index:10;" :product="product" :virtualShow="virtualShow" view="mobile" />
      <!-- Favorites Editing / delete -->
      <FavDeleteBtn
        v-if="editingFavorites"
        @deleteFavProduct="deleteFavProduct"/>
      <Favorite style="z-index:10;" v-if="!virtualShow && !editingFavorites" :product="product" class="float-end" />
      <div class="row">
        <!-- left column -->
        <div 
          class="col-3 left"
          style="min-height: 60px;"
          data-bs-toggle="modal" 
          data-bs-target="#productDetail" 
          @click="setActiveProduct">
          <!-- Product Image -->
          <img
            v-if="visible"
            :src="product.image_url"
            style="max-height: 60px;"
            class="img-fluid p-0"
            @error="imgError">
        </div>
        <!-- right column -->
        <div class="col-9">
          <!-- Description -->
          <div
            class="description"
            data-bs-toggle="modal" 
            data-bs-target="#productDetail" 
            @click="setActiveProduct"
            v-html="product.description">
          </div>
          <div
            class="float-start mt-1"
            style="font-size: 12px;">
            <div>
              <span class="text-muted">UPC: {{product.upc}}</span>
            </div>
            <div>
              <span class="text-muted">Case Qty: {{caseQty}}</span>
            </div>
            <!-- Customer Part Number -->
            <div v-if="product.customer_part_number">
              <span class="text-muted">Part #: {{product.customer_part_number.part_number}}</span>
            </div>
          </div>
          <Prop65
            class="float-end mt-1"
            :product="product"
            style="font-size: 12px;"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ProductPrice
            :product="product"
            :virtualShow="virtualShow"
            :key="virtualShow ? `${product.product_id}-v` : product.product_id"
            class="mb-1 float-start"
            @activeUom="setActiveUom"/>
        </div>
        <div class="col-md-6">
          <StockStatus
            v-if="internalRoles.includes(role)"
            class="float-end"
            :product="product"/>
          <Exclusion :product="product" class="mb-1" />
          <QtyBreaks 
            ref="QtyBreaks"
            :product="product"
            :virtualShow="virtualShow"
            view="mobile"
            @hasBreaks="setHasBreaks"
            @showQtyStatus="setShowQtyStatus"/>
          <Availability
            :product="product"
            :virtualShow="virtualShow"
            view="mobile"
            :activeUom="activeUom"/>
          <LastOrdered :product="product" />
        </div>
        <div 
          style="position:relative;z-index:5;"
          class="row flex-nowrap justify-content-between">
          <div :class="hasBreaks ? 'col-8 pe-0' : 'col-12'">
            <QtyForm
              :product="product"
              :virtualShow="virtualShow"
              :mobile="true"
              :activeUom="activeUom"/>
          </div>
          <!-- Qty Breaks Toggle -->
          <div 
            v-if="hasBreaks"
            class="col-4">
            <button 
              @click.prevent="toggleQuantityBreaks"
              class="btn btn-primary btn-sm py-1 px-1 d-inline-flex align-items-center justify-content-around flex-nowrap"
              style="height:38px; white-space: nowrap;"> 
              <i class="me-1" :class="showQtyStatus ? 'fa fa-caret-down' : 'fa fa-info-circle'"/>
              <span>Qty</span>
            </button>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
  import QtyForm from './QtyForm.vue'
  import Indicator from './Indicator.vue'
  import QtyBreaks from './QtyBreaks.vue'
  import Exclusion from './Exclusion.vue'
  import Favorite from './Favorite.vue'
  import FavDeleteBtn from '../favorites/FavDeleteBtn.vue'
  import ProductPrice from './price/ProductPrice.vue'
  import Availability from './Availability.vue'
  import LastOrdered from './LastOrdered.vue'
  import comingSoon from '../../../../shared/comingSoon'
  import {caseQuantity,palletQuantity} from '../../../../shared/ProductFunctions'
  import { internalRoles } from '../../../../shared/roles'
  import StockStatus from './StockStatus.vue'
  import Prop65 from './Prop65.vue'
  
  export default {
    props:['product', 'virtualShow', 'editingFavorites', 'loadImages'],
    data(){
      return{
        showQtyStatus: false,
        hasBreaks: true,
        internalRoles,
        activeUom: null,
        visible: false
      }
    },
    mounted(){
      if(this.$refs[`mobile-${this.product.product_id}`]){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs[`mobile-${this.product.product_id}`])
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    components:{
      QtyForm,
      Indicator,
      QtyBreaks,
      Exclusion,
      Favorite,
      FavDeleteBtn,
      ProductPrice,
      Availability,
      LastOrdered,
      StockStatus,
      Prop65
    },
    watch:{
      loadImages(newVal){
        if(newVal) this.visible = this.loadImages.includes(this.product.product_id)
      }
    },
    methods:{
      setActiveProduct(){this.$store.dispatch('setActiveProduct', this.product)},
      qtyBreakToggle(status){this.showQtyStatus = status},
      imgError(e){comingSoon(e)},
      toggleQuantityBreaks(){this.$refs.QtyBreaks.toggle()},
      setShowQtyStatus(status){this.showQtyStatus = status},
      setHasBreaks(bool){this.hasBreaks = bool},
      setActiveUom(activeUom){this.activeUom = activeUom},
      handleIntersection(entries){
        for (let entry of entries) {
          if (entry.isIntersecting){
            this.visible = true
          }
        }
      },
      deleteFavProduct(){this.$emit('deleteFavProduct', this.product.product_id)}
    },
    computed:{
      caseQty(){
        return caseQuantity(this.product)
      },
      palletQty(){
        return palletQuantity(this.product, this.virtualShow)
      },
      role(){return this.$store.getters.user.role}
    }
  }
</script>

<style scoped>
  .description{
    font-size: 14px;
    line-height: 14px;
  }
</style>