import store from '../stores/store'
import moment from 'moment'

// Indentify the user company
// check for specific branch 14 show detail
// return branch 14 show detail if possible
// else return the standard company matching record
export const showDetail = (product) => {
  let vShowDates = store.getters.vShowDates

  if(vShowDates){
    // vShow Date Params
    const showDates = {
      startDate: moment(vShowDates.start_date),
      endDate: moment(vShowDates.end_date)
    }
    
    const today = moment(new Date())
    const branch = store.getters.homeBranch
    const earlyBirdActive = today.diff(showDates.startDate, 'days') <= 2
    const br14Record = _.find(product.show_details, {branch14: true}) || null
    const record = _.find(product.show_details, {branch14: false}) || null
    const earlyBird = _.find(product.show_details, {early_bird: true}) || null
    // Early Bird Pricing
    if(earlyBirdActive && earlyBird){
      return earlyBird
    }
    // Branch 14 Pricing
    if(branch == '14' && br14Record){
      return br14Record
    }
  
    // Standard vShow Pricing
    return record ? record : null
  }
}

// Analyze all 3 potential levels of qty breaks
// return only where breaks exists / do not return null breaks
export const showQtyBreaks = (detail) => {
  let breaks = []
  const levels = [1,2,3]
  levels.forEach((l)=>{
    if(detail && detail[`qty_break${l}`]){
      const qtyBreak = {
        [`qty_break${l}`]: detail[`qty_break${l}`],
        [`qty_break${l}_uom`]: detail[`qty_break${l}_uom`],
        [`qty_break${l}_price`]: detail[`qty_break${l}_price`]
      }
      breaks.push(qtyBreak)
    }
  })
  return breaks.length > 0 ? breaks : false
}