<template>
  <div class="container-fluid p-0">
    <PublicHome v-if="!loggedOn && env == 'LAN'" />
    <EJWPublicHome v-if="!loggedOn && env == 'EJW'"/>
    <CheneyPublicHome v-if="!loggedOn && env == 'EJW-CHENEY'"/>
    <PrivateHome v-if="loggedOn" />
  </div>
</template>

<script>
  import PublicHome from '../../public/lan/home/Main.vue'
  import EJWPublicHome from '../../public/ejw/home/Main.vue'
  import CheneyPublicHome from '../../public/cheney/home/Main.vue'
  import PrivateHome from '../../private/app/Main.vue'
  export default {
    inject: ['env'],
    components:{
      PublicHome,
      EJWPublicHome,
      CheneyPublicHome,
      PrivateHome
    },
    computed:{
      loggedOn(){return this.$store.getters.loggedOn}
    }
  }
</script>