<template>
  <div>
    <div v-if="shipWeekOptions">
      <small>Select Ship Week</small>
      <select
        class="form-control form-control-sm text-muted"
        style="cursor:pointer;"
        v-model="form.requiredDate">
        <option
          v-for="w in shipWeekOptions"
          :key="w.week"
          :value="w.start_date">
          {{w.text}}
        </option>
      </select>
    </div>
    <div v-else>
      <small>Requested Date</small>
      <input
        id="vShowRequestDate"
        class="form-control form-control-sm"
        type="date"
        v-model="form.requiredDate"
        placeholder="Required Date">
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'

  export default {
    props: ['form', 'company'],
    data(){
      return{
        shipWeeks: null
      }
    },
    mounted(){
      this.getShipWeeks()
    },
    methods:{
      getShipWeeks(){
        axios.get('/api/v1/private/virtual_show/get_ship_weeks').then(res=>{
          this.shipWeeks = res.data.ship_weeks
        })
      }
    },
    computed:{
      shipWeekOptions(){
        if(this.shipWeeks){
          const today = moment().format('YYYY-MM-DD')
          
          // Set the default date / option object for select
          const setDefaultDate = function(dates){
            let defaultValue = _.find(dates, d => {
              // return today <= moment(d.start_date).format('YYYY-MM-DD') *PREVIOUS LOGIC*
              return d.start_date == today
            })
            this.form.requiredDate = defaultValue.start_date
          }.bind(this)

          // Filter out ship weeks as actual today date passes them by
          let shipWeekData = _.filter(this.shipWeeks, s => {
            const dateObj = moment(s.start_date).format('YYYY-MM-DD')
            return today <= dateObj
          })

          const asap = {
            week: 0,
            start_date: today,
            text: 'Ship ASAP',
            company: 'Lancaster US'
          }

          shipWeekData = [asap, ...shipWeekData]

          setDefaultDate(shipWeekData)
          
          return shipWeekData

          // US vShow Ship Weeks, Account Name Letter Range Based Ship Weeks
          // Shipping – Store names that start with “The” or “A”, for example, The Paint Store, will fall under the Ps as Paint Store.
          // OPTION “A”: Store names beginning with A-F will ship the week of February 12th, G-O will ship the week of February 19th, P-Z will ship the week of February 26th.
          // OPTION “B”: Store names beginning with A-F will ship the week of March 11th, G-O will ship the week of March 18th, P-Z will ship the week of March 25th.
          // if(this.company == 'Lancaster US'){
          //   const shipTo = this.$store.getters.getEntity.activeShipTo
          //   const startLetter = _.upperCase(_.replace(shipTo.name, ['The ', 'A '], '').charAt(0))
          //   const shipWeekData = _.filter(this.shipWeeks, s => {
          //     if(s.range) return _.upperCase(s.range).includes(startLetter)
          //   })
          //   setDefaultDate(shipWeekData)
          //   return shipWeekData
          // }
        }
      }
    }
  }
</script>