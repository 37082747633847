<template>
  <div class="row result-row position-relative pt-4">
    <Indicator
      :product="product"
      :virtualShow="true"/>
    <div class="col-md-2 text-center">
      <img
        :src="product.image_url"
        @error="imgError"
        class="img-fluid"
        style="max-width: 75px; max-height: 100px;">
    </div>
    <div class="col-md-8">
      <ProductPrice
        :product="product"
        :virtualShow="true"/>
      <QtyBreaks
        :product="product"
        :virtualShow="true"/>
      <div>{{product.description}}</div>
      <small class="text-muted d-block">Product ID: {{product.eclipse_data.eclipse_id}}</small>
      <small class="text-muted d-block">UPC: {{product.eclipse_data.upc}}</small>
      <small class="text-muted d-block">
          Min Order Qty: {{minQty.quantity + minQty.name }}
      </small>
      <small class="text-muted d-block">
        Case Qty: {{caseQty}}
      </small>
      <small class="text-muted d-block">
        Pallet Qty: {{palletQty}}
      </small>
    </div>
    <div class="col-md-2 position-relative">
      <strong
        v-if="confirm"
        class="text-success animate__animated animate__fadeIn animate__faster"
        style="font-size: 12px;">
        <span>
          <i class="fa fa-check-circle"/>
          Added To Order
        </span>
      </strong>
      <form
        name="vShowDirectEntryQty"
        class="input-group"
        @submit.prevent="addToOrder">
        <input
          type="number"
          class="form-control"
          v-model="qty"
          :min="minQty.quantity"
          :step="minQty.quantity">
        <button
          class="btn btn-primary btn-sm"
          type="submit">
          <i class="fa fa-plus"/>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import {caseQuantity, palletQuantity, minQuantity} from '../../../../../../shared/ProductFunctions'
  import ProductPrice from '../../../product/price/ProductPrice.vue'
  import Indicator from '../../../product/Indicator.vue'
  import QtyBreaks from '../../../product/QtyBreaks.vue'
  import comingSoon from '../../../../../../shared/comingSoon'
  
  export default {
    inject: ['env'],
    props: ['product'],
    data(){
      return{
        qty: null,
        confirm: false
      }
    },
    components:{
      ProductPrice,
      Indicator,
      QtyBreaks
    },
    methods:{
      addToOrder(){
        this.confirm = true
        const product = {
          product: this.product,
          orderData: {
            product_id: this.product.product_id,
            qty: this.qty || this.minQty.quantity,
            uom: this.product.eclipse_data.uom
          }
        }
        this.$emit('addProduct', product)
        this.qty = null
        setTimeout(function(){
          this.confirm = false
        }.bind(this), 3000)
      },
      imgError(e){
        comingSoon(e)
      }
    },
    computed:{
      minQty(){return minQuantity(this.product, null, this.env)},
      caseQty(){return caseQuantity(this.product)},
      palletQty(){return palletQuantity(this.product)}
    }
  }
</script>