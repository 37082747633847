<template>
  <div
    class="row"
    id="topNav">
    <!-- Desktop Nav -->
    <nav class="navbar navbar-expand-sm navbar-dark p-3 bg-primary d-none d-lg-block">
      <div class="container-fluid">
        <!-- Logo Full -->
        <a
          class="navbar-brand d-none d-sm-block"
          href="#">
          <!-- Cheney Logo -->
          <img
            v-if="env == 'EJW-CHENEY'"
            :src="cheneyLogo"
            class="img-fluid"
            style="max-height: 60px;">
          <!-- EJW Logo -->
          <EjwLogo
            v-if="env=='EJW'"
            @click="goto('PrivateHome')"/>
          <!-- Lancaster vShow Logo -->
          <img
            v-if="env == 'LAN' && vShowMode"
            :src="vShowLogo"
            class="img-fluid"
            style="max-height: 60px;">
          <!-- Lancaster Standard Logo -->
          <img
            v-if="env == 'LAN' && !vShowMode" 
            class="img-fluid"
            style="width:240px;"
            @click="goto('PrivateHome')"
            :src="company == 'Lancaster Canada' ? logoCAD : logo"/>
        </a>
        <ul
          v-if="!adminMode"
          class="navbar-nav me-auto mt-lg-0">
          <ShipToSelector/>
        </ul>
        <!-- Desktop Search -->
        <Search
          v-if="!mobile"
          :route="route"
          :adminMode="adminMode"
          @submitProduct="submitDirectSearch"
          @submitSearch="submitSearch"/>
        <ul
          class="navbar-nav me-end mt-lg-0"
          style="font-size:16px;">
          <li
            v-if="!adminMode"
            :style="user.estore_noorder ? 'pointer-events:none;opacity:0.25;' : ''"
            class="nav-item d-none d-lg-block text-center"
            style="width:150px;"
            id="navCart">
            <Cart v-if="!cartLoading"/>
            <Spinner
              v-if="cartLoading"
              class="custom-spinner"/>
          </li>
          <li
            class="nav-item d-none d-lg-block"
            id="userMenuTop">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="toggleRightMenu('user')">
              <i class="fa fa-user-circle fa-2x"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Mobile Nav-->
    <nav class="navbar navbar-expand-sm mobile-nav navbar-dark bg-primary d-block d-lg-none">
      <!-- VshowLogo -->
      <div
        v-if="vShowMode"
        class="d-flex justify-content-center  w-100">
        <img
          :src="vShowLogo"
          class="img-fluid p-2"
          style="max-height: 65px;">
      </div>
      <div class="container-fluid p-2">
        <div class="row w-100">
          <div class="col-2 d-flex align-items-center">
            <i
              v-if="route != '/virtual_show'"
              class="fa fa-chevron-left text-light back"
              @click="goBack"/>
          </div>
          <div class="col-10">
            <Search
              v-if="mobile"
              :route="route"
              :adminMode="adminMode"
              @submitProduct="submitDirectSearch"
              @submitSearch="submitSearch"/>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
  import Cart from './components/Cart.vue'
  import ShipToSelector from './components/ShipToSelector.vue'
  import Spinner from '../../global/Spinner.vue'
  import EjwLogo from '../../global/ejw/Logo.vue'
  import Search from './components/Search.vue'

  export default{
    inject: ['env'],
    props: ['vShowMode'],
    components:{
      Cart,
      ShipToSelector,
      Spinner,
      EjwLogo,
      Search
    },
    data(){
      return{
        logo: require('../../../../assets/images/lan/LogoColor.png'),
        logoCAD: require('../../../../assets/images/lan/Logo_CAD.png'),
        smallLogo: require('../../../../assets/images/lan/LogoColorIcon96.png'),
        vShowLogo: require('../../../../assets/images/lan/virtual_show/vShowLogoLight.png'),
        cheneyLogo: require('../../../../assets/images/cheney/CheneyLogoLight.png'),
        mobile: false
      }
    },
    created(){
      window.addEventListener('resize', function(){
        this.mobileCheck()
      }.bind(this))
    },
    mounted(){
      this.mobileCheck()
      if(this.loggedOn){
        this.$store.dispatch('getCart')
        this.$store.dispatch('getVirtualShowStatus')
      }
    },
    methods:{
      submitSearch(query){
        this.$router.push({name: 'ProductSearch', query: {query, scan: false}})
        document.activeElement.blur()
      },
      submitDirectSearch(data){
        const query = Object.keys(data)[0] == 'id' ? ('.' + data.id) : data.query
        this.$router.push({name: 'ProductSearch', query: {query, scan: false}})
        document.activeElement.blur()
      },
      toggleShipToSelector(){
        const shipToSelector = document.getElementById('shipToSelector')
        if(shipToSelector){
          const shipToModal = new bootstrap.Modal(document.getElementById('shipToSelector'))
          shipToModal.show()
        }
      },
      goto(view){
        this.$router.push({name: view})
      },
      toggleLeftMenu(){this.$store.dispatch('toggleLeftMenu')},
      toggleRightMenu(view){this.$store.dispatch('toggleRightMenu', view)},
      goBack(){
        if(this.repDashboardView){
          this.$store.dispatch('setRepDashboardView', false)
        }else{
          this.$router.go(-1)
        }
      },
      clearQuery(){
        this.query = null
        this.$refs.searchInput.focus() || this.$refs.searchInputDesktop.focus()
      },
      mobileCheck(){this.mobile = window.innerWidth <= 991}
    },
    computed:{
      route(){return this.$router.currentRoute.value.path},
      adminMode(){return this.$router.currentRoute.value.path == '/admin'},
      company(){return this.$store.getters.sessionCompany},
      repDashboardView(){return this.$store.getters.repDashboardView},
      cartLoading(){return this.$store.getters.cartLoading},
      user(){return this.$store.getters.user},
      loggedOn(){return this.$store.getters.loggedOn}
    }
  }
</script>

<style scoped>
  .input-group{
    width:90%
  }
  input{
    background: rgb(255,255,255,0.3) !important;
    color: white;
  }
  input::placeholder{
    color: white !important;
  }
  input:focus{
    color:white;
  }
  .back:hover{
    cursor: pointer;
  }
  .custom-spinner{
    color: white !important;
    margin: 7px;
  }
  .mobile-nav{
    padding: 0px 20px;
    z-index: 2;
  }
</style>