<template>
  <div class="p-2">
    <!-- Desktop Category Browse -->
    <div class="d-none d-md-block">
      <div
        v-if="!activeCategory && categories"
        class="row animate__animated animate__fadeIn animate__faster">
        <div class="col-md-12 text-center">
          <h4 class="my-5">Browse by Categories</h4>
        </div>
        <div
          v-for="c in categories"
          :key="c"
          class="col-md-4 my-2">
          <div class="card h-100">
            <div class="card-header">
              <a
                href="#"
                class="btn btn-link"
                @click="browseCategory(c)">
                {{c.category}}
              </a>
            </div>
            <div class="ps-3">
              <small class="text-muted">Sub Categories</small>
            </div>
            <ul
              v-for="s in c.subs"
              :key="s"
              class="ps-3 py-0 my-0">
              <a
                href="#"
                class="btn btn-link btn-sm"
                @click="browseSub(c,s)">
                {{s}}
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Spinner  -->
    <div
      v-if="!categories"
      class="row">
      <div class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../../../global/Spinner.vue'
  
  export default {
    props: ['categories'] ,
    components:{
      Spinner
    },
    data(){
      return{
        activeCategory: null
      }
    },
    methods:{
      closeCard(){
        this.activeCategory = null
      },
      goBack(){
        this.$emit('goBack')
      },
      browseCategory(c){
        this.$router.push({
          name: 'VirtualShow',
          query:{
            action: 'virtualShowCategory',
            category: c.category
          }
        })
      },
      browseSub(c,s){
        this.$router.push({
          name: 'VirtualShow',
          query:{
            action: 'virtualShowCategory',
            category: c.category,
            sub: s
          }
        })
      }
    }
  }
</script>

<style scoped>
  .card-close{
    font-size: 20px;
    cursor: pointer;
  }
  .divider{
    max-width: 50%;
    margin: 0 auto;
  }
</style>