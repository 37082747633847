<template>
  <div class="mt-5">
    <Header/>
    <Content @setForm="setActiveForm"/>
    <ContactModal :activeForm="activeForm"/>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h4 class="text-contact">CONTACT US</h4>
          <div class="my-4">
            <strong>CUSTOMER SERVICE</strong>
            <br>
            <a href="tel:1-800-586-7337">1-800-586-7337</a>
            <br>
            <a href="mailto:orders@lancasterco.com">orders@lancasterco.com</a>
            <br>
            <span>8:00 a.m. – 5:00 p.m. (EST), Monday - Friday</span>
          </div>
          <!-- <div class="my-4">
            <strong>AFTER HOURS SUPPORT</strong>
            <br>
            <a href="tel:510-556-1030">510-556-1030</a>
            <br>
            <span>7:00 a.m. – 4:00 p.m. (PST), Monday - Friday</span>
          </div> -->
          <div class="my-4">
            <strong>CORPORATE HEADQUARTERS</strong>
            <br>
            <span>1310 Union Street</span>
            <br>
            <span>Spartanburg SC 29302</span>
          </div>  
        </div>
        <div class="col-md-6">
          <h4 class="text-contact">GENERAL INQUIRY</h4>
          <GeneralForm :form="form"/>
        </div>
      </div>
    </div>
    <ContactModal :activeForm="activeForm"/>
    <Footer/>
  </div>
</template>

<script>
  import ContactModal from './ContactModal.vue'
  import Content from './components/Content.vue'
  import Header from './components/Header.vue'
  import GeneralForm from './forms/GeneralForm.vue'
  import Footer from '../app/Footer.vue'

  export default {
    data(){
      return{
        form: {},
        activeForm: {
          formName: null,
          title: null
        }
      }
    },
    components:{
      ContactModal,
      Content,
      Header,
      GeneralForm,
      Footer
    },
    methods:{
      setActiveForm(formName){
        this.activeForm.formName = formName
        switch(formName){
          case 'customer':
            this.activeForm.title = 'Become a Customer'
            break;
          case 'join':
            this.activeForm.title = 'Join The Team'
            break;
          case 'sell':
            this.activeForm.title = 'Sell Through Lancaster'
            break;
          case 'general':
            this.activeForm.title = 'General Inquiry'
            break;
        }
      }
    }
  }
</script>