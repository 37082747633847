<template>
  <div class="row">
    <!-- Call In Numbers -->
    <div class="col-md-12 gen-section">
      <div class="row">
        <div class="col-md-2">
          <i class="fa fa-phone" style="font-size: 20px"/>
        </div>
        <div class="col-md-10" style="text-align: left;">
          <strong>Customer Service:</strong>
          8am - 5pm EST
          <br>
          <span>
            1-800-586-7337
          </span>
        </div>
        <div class="col-md-2 mt-3">
          <i class="fa fa-phone" style="font-size: 20px"/>
        </div>
        <div class="col-md-10 mt-3" style="text-align: left;">
          <strong>Customer Service:</strong>
          7am - 5pm EST
          <br>
          <span>
            510-556-1030
          </span>
        </div>
      </div>
    </div>
    <hr>
    <!-- Download App -->
    <div class="col-md-12 gen-section">
      <div class="row">
        <div class="col-md-3">
          <i
            class="fa fa-mobile-alt"
            style="font-size:70px"/>
        </div>
        <div class="col-md-9" style="text-align: left;">
          <strong>Download the app</strong>
          <br>
          <small>Manage your account and shop from your phone</small>
        </div>
      </div>
    </div>
    <hr>
    <!-- Email Sign Up -->
    <div class="col-md-12 gen-section">
      <div class="row">
        <div class="col-md-2">
          <i class="fa fa-envelope fa-2x"/>
        </div>
        <div class="col-md-10" style="text-align: left;">
          <strong>STAY CONNECTED WITH {{ name }}</strong>
          <br>
          <small>Be the first to know about promos, announcements, shipping schedules and more directly in your inbox!</small>
        </div>
        <div class="col-md-12">
          <div class="d-grid">
            <button class="btn btn-primary btn-sm">Sign Up Today</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {companyName} from '../../../../shared/env'
  export default{
    inject: ['env'],
    computed:{
      name(){return companyName(this.env)}
    }
  }
</script>

<style scoped>
  .gen-section{
    margin-top: 50px;
    padding-bottom: 50px;
    color: var(--bs-primary)
  }
</style>