<template>
  <div class="row py-3">
    <div class="col-12 col-lg-3 text-primary">
      <h5>Manage My Team</h5>
    </div>
    <div class="col-12 col-lg-9 d-flex justify-content-lg-end mb-2">
      <div class="form-group d-flex flex-column flex-lg-row align-items-lg-center justify-content-start">
        <label class="d-inline-block text-start" style="flex:1;" for="entitySelect">Select location:</label>
        <select
          ref="locationSelect"
          v-model="contactIDs"
          id="entitySelect" 
          style="flex:3;" 
          name="entitySelect" 
          class="form-select">
          <option v-for="e, index in selectOptions" :key="index" :value="e.value">{{ e.name }}</option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <AddNewContact 
        v-if="entityNameList && activeEntity" 
        :loading="loading"
        :entityNameList="entityNameList" 
        :activeEntity="activeEntity" />
    </div>
    <div class="col-12">
      <div v-if="newContacts.length > 0" class="border-bottom pb-2 mb-3">
        <div class="text-muted mb-2">Recently added:</div>
        <ContactCard 
          v-for="c,index in newContacts" 
          :contact="c" 
          :key="index"
          class="mb-2" />
      </div>
      
      <ContactCard 
        v-for="c,index in visibleContacts" 
        :contact="c" 
        :key="index"
        class="mb-2" />

        <p v-if="newContacts.length < 1 && visibleContacts.length < 1 && !loading" class="text-muted">No contacts to show for this location.</p>

      <div 
      class="text-center d-flex align-items-center justify-content-center my-3 animate__animated animate__fadeIn" 
      v-if="loading && !remainingContactIDs">
        <Spinner /><span>&nbsp;&nbsp;Loading user data</span>
      </div>

      <div class="d-flex flex-wrap align-items-center justify-content-end mt-3 mb-5">
        <button 
          :disabled="loading"
          v-if="remainingContactIDs != null && remainingContactIDs.length > 0"
          @click="loadMore"
          style="height:72px;"
          class="btn btn-outline-primary w-100 mx-auto p-3">
          <span v-if="loading" class="d-inline-flex align-items-center justify-content-center">
            <Spinner /><span>&nbsp;&nbsp;Loading user data</span>
          </span>  
          <span v-else>
            Load More
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import ContactCard from './ContactCard.vue'
import AddNewContact from './AddNewContact.vue'
import Spinner from '../../../../global/Spinner.vue'

export default{
  name: 'ManageContacts',
  props:['relatedEntityList','newContacts','newContactIDs','entityNameList'],
  components:{
    ContactCard,
    AddNewContact,
    Spinner
  },
  data(){
    return{
      contactIDs: null,
      processedIDs: [],
      visibleContacts: [],
      loading: false,
      selectOptions: null
    }
  },
  created(){
    this.selectOptions = this.relatedEntityList
    this.contactIDs = _.first(this.selectOptions).value
  },
  methods:{
    loadMore(){
      this.loading = true
      let params = {
        contact_id_list: this.remainingContactIDs ? this.remainingContactIDs : this.contactIDs
      }
      axios.get('/api/v1/private/superuser/get_contact_data', {params}).then(res => {
        if(res.status == 200){
          _.each(res.data.contacts,(c)=>{
            this.visibleContacts.push(c) 
          })
          _.each(res.data.processedIDs,(id)=>{
            this.processedIDs.push(id) 
          })
          this.loading = false
        }
      })
    }
  },
  computed:{
    remainingContactIDs(){
      return _.difference(this.contactIDs,this.processedIDs)
    },
    activeEntity(){
      return _.find(this.relatedEntityList, {value: this.contactIDs}).entity
    }
  },
  watch:{
    contactIDs(NewVal){
      if(NewVal){
        this.processedIDs = []
        this.visibleContacts = []
        this.$emit('clearNewContacts')
        this.loadMore()
      }
    }
  }
}
</script>