<template>
  <div class="p-2">
    <table
      v-if="data"
      class="table table-light table-sm bg-light table-striped table-borderless text-center shadow">
      <thead>
        <th
          v-for="t in data.table_data"
          :key="t">
          {{t.column}}
        </th>
      </thead>
      <tbody>
        <IndexTableRow
          v-for="(d, index) in data.table_values"
          :key="index"
          :values="d"
          :data="data"
          :deleteAllowed="deleteAllowed"/>
      </tbody>
    </table>
  </div>
</template>

<script>
  import IndexTableRow from './IndexTableRow.vue';

  export default{
    props: ['data', 'deleteAllowed'],
    components:{
      IndexTableRow,
      IndexTableRow
    }
  }
</script>