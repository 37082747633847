<template>
  <div
    v-if="promoProducts.length && !excludedRoute"
    class="mx-1">
    <button
      type="button"
      class="btn btn-sm"
      :class="promoOnly ? 'btn-success' : 'btn-primary'"
      :disabled="loading"
      @click="togglePromoOnly">
      On Promo
      <i
        v-if="promoOnly"
        class="fa fa-check-circle"/>
      <i
        v-else
        class="fa fa-times-circle"/>
    </button>
  </div>
</template>

<script>
  export default{
    props: ['loading', 'filters'],
    data(){
      return{
        promoOnly: false
      }
    },
    methods:{
      togglePromoOnly(){
        this.promoOnly = !this.promoOnly
        const params = {
          action: 'promoProducts',
          promoOnly: this.promoOnly
        }
        this.$store.dispatch('filterProducts', params)
      }
    },
    computed:{
      promoProducts(){return this.filters.selected.promoProducts},
      excludedRoute(){
        if(this.$route.query?.action){
          const excludedActions = ['browsePromo', 'browseAllPromos']
          return excludedActions.includes(this.$route.query.action)
        }
        return false
      }
    }
  }
</script>