<template>
  <div class="card my-2 animate__animated animate__fadeIn animate__faster position-relative">
    <!-- Card Header -->
    <div
      class="card-header text-muted active-header">
      <div class="row">
        <div class="col-md-12">
          <div class="float-start">
            <div>ID: {{customer.id}}</div>
            <div>
              <small class="me-1">
                Ship To:&nbsp;
                <i :class="customer.is_ship_to ? 'fa fa-check-circle text-white' : 'fa fa-times-circle text-white'"/>
              </small>
              <small class="ms-1">
                Bill To:&nbsp;
                <i :class="customer.is_bill_to ? 'fa fa-check-circle text-white' : 'fa fa-times-circle text-white'"/>
              </small>
            </div>
          </div>
          <!-- Display active status -->
          <div
            class="float-end badge bg-info shadow-sm"
            style="font-size:15px;">
            Active
          </div>
        </div>
        <!-- Manager visible data -->
        <div v-if="view == 'manager'">
          <!-- Assigned To -->
          <div class="col-md-12">
            <small>
              Assigned To:&nbsp;
              <strong>{{customer.domain_name ? customer.domain_name.replace('.', ' ') : customer.user_id}}</strong>
            </small>
          </div>
          <!-- Status Flags -->
          <div class="col-md-12">
            <small
              v-if="customer.no_order_entry"
              class="float-start badge bg-danger me-1 sm-badge">
              <i class="fa fa-ban"/>
              No Order Entry
              </small>
            <small
              v-if="customer.promo_receive_flag"
              class="float-start badge bg-success sm-badge">
              <i class="fa fa-tag"/>
              Promo Receive
            </small>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-body active-body">
      <div class="card-title">{{customer.name}}</div>
      <div class="card-text">
        <small>
          <div>{{customer.address1}}</div>
          <div>{{customer.address2}}</div>
          <div>{{customer.city}}, {{customer.state_code}}, {{customer.zip_code}}, {{customer.country_code}}</div>
        </small>
        <!-- Stats -->
        <ul class="p-0 my-2 text-white"
          style="list-style-type: none; line-height:18px;">
          <li v-if="lastLogin">
            <small>Last Login: {{moment(lastLogin.updated_at).format('MM-DD-YYYY')}}</small>
          </li>
          <li v-if="lastOrder">
            <small >Last eStore Order: {{moment(lastOrder.completed_at).format('MM-DD-YYYY')}}</small>
            <span
              v-if="lastOrder.tm_cart"
              class="badge bg-success p-1 ms-2">TM Submitted</span>
          </li>
        </ul>
        <span v-if="!lastLogin" class="me-1 badge bg-muted">No Login Data</span>
        <span v-if="!lastOrder" class="me-1 badge bg-muted">No eStore Orders</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {lastOrder, lastLogin} from '../../../../../shared/customerCard'

  export default {
    props: ['customer', 'activeShipTo', 'userStats', 'orderStats', 'view'],
    computed:{
      // Last order instance
      lastOrder(){
        return lastOrder(this.orderStats)
      },
      // last login instance
      lastLogin(){
        return lastLogin(this.userStats)
      },
    }
  }
</script>

<style scoped>
  .card{
    min-height: 220px;
  }
  .cust-type{
    width: 100%;
    margin: 0 auto;
    max-width: 85px;
  }
  .active-header{
    background: var(--bs-success);
    color: white !important;
  }
  .active-body{
    background: var(--bs-primary);
    color:white;
  }
</style>