<template>
  <div class="animate__animated animate__fadeIn animate__faster mt-2">
    <!-- Browse Mfg -->
    <div class="row">
      <!-- Spinner -->
      <div
        v-if="!mfgList"
        class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
      <!-- MFG Buttons -->
      <div class="col-md-12 text-center">
        <h4>Browse Manufacturers</h4>
      </div>
      <div
        v-for="m in mfgList"
        :key="m.id"
        class="col-xl-2 col-lg-3 col-md-6 col-6">
        <MfgCard :mfg="m"/>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../../../global/Spinner.vue'
  import MfgCard from './MfgCard.vue'

  export default {
    props: ['mfgClear'],
    data(){
      return{
        mfgList: null
      }
    },
    mounted(){
      this.getVirtualShowMfgs()
    },
    watch:{
      mfgClear(newVal){
        if(newVal){
          this.activeMfg = null
        }
      }
    },
    components:{
      Spinner,
      MfgCard
    },
    methods:{
      clearMfg(){
        this.activeMfg = null
      },
      getVirtualShowMfgs(){
        axios.get('/api/v1/private/virtual_show/get_mfg_list').then(res => {
          this.mfgList = _.reject(res.data.mfg_list, m => m.name == 'Not Assigned')
        })
      }
    }
  }
</script>

<style scoped>
  .card:hover{
    transform: scale(1.05);
    transition: 100ms ease-in-out;
    box-shadow: 1px 1px 10px gray;
  }
  .card-footer{
    border-radius: 0px 0px 20px 20px !important;
  }
</style>