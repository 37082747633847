<template>
  <div class="container main">
    <div class="row">
      <div class="col-md-12 text-center">
        <img
          :src="logo"
          class="img-fluid"
          style="height:200px;">
      </div>
      <div class="col-md-6 my-5 text-pad">
        Installation made easy with Premier Flooring Products. See how these products can increase the value of your projects with our line of tack strips, tapes and adhesives.
      </div>
      <div class="col-md-6 my-5 text-center">
        <img
          :src="image"
          class="img-fluid">
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        logo: require('../../../../../../assets/images/ejw/brands/PremierFlooringProductsLogo.png'),
        image: require('../../../../../../assets/images/ejw/brands/AdobeStock_122344884.jpeg')
      }
    }
  }
</script>

<style scoped>
  .main{
    max-width: 1025px;
    margin: 0 auto;
  }
  @media(min-width: 768px){
    .text-pad{
      padding: 7em 1em;
    }
  }
  @media(max-width: 767px){
    .text-pad{
      padding: 0em 5em
    }
  }
</style>