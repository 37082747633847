<template>
  <div>
    <div>
      Select Ship To Action
      <table class="table table-sm table-striped">
        <tbody>
          <tr
            v-for="s in shipTos"
            :key="s.id">
            <td class="row p-2">
              <!-- Ship To Details -->
              <div class="col-lg-6 ship-to">
                <div>
                  ({{ s.id }})
                  {{s.name}}
                </div>
                <div>
                  {{ s.address1 }}
                  <span v-if="s.address2">
                    {{ s.address2 }},
                  </span>
                  {{ s.city }},
                  {{ s.state_code }},
                  {{ s.zip_code }}
                </div>
              </div>
              <Actions
                v-if="activeShipToID != s.id || copyInvoice"
                :shipTo="s"
                :cart="cart"
                :shipToList="shipToList"
                :copyInvoice="copyInvoice"
                :activeShipToID="activeShipToID"
                @setAction="setAction"/>
              <!-- Current ship to indicator -->
              <div
                v-else
                class="col-lg-6 current overflow-hidden">
                <div class="text-success animate__animated animate__fadeInRight animate__faster">
                  <i class="fa fa-check-circle me-1"/>
                  Current Ship To
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Actions from './Actions.vue'

  export default{
    props: ['cart', 'shipToList', 'copyInvoice'],
    methods:{
      setAction(shipTo){
        this.$emit('setAction', shipTo)
      }
    },
    components:{
      Actions
    },
    computed:{
      entityData(){return this.$store.getters.getEntity},
      activeShipToID(){return parseInt(this.entityData.activeShipTo.entity_id)},
      shipTos(){return this.entityData.entity.ship_tos}
    }
  }
</script>

<style scoped>
  .ship-to{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .current{
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>