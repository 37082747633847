<template>
  <!-- orders@lancasterco.com -->
  <div>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.firstName"
      maxlength="100"
      placeholder="First Name"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.lastName"
      maxlength="100"
      placeholder="Last Name"
      required>
    <input
      type="email"
      class="form-control my-2"
      v-model="form.email"
      maxlength="100"
      placeholder="Email Address"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.company"
      maxlength="100"
      placeholder="Company Name"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.address"
      maxlength="100"
      placeholder="Address"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.city"
      maxlength="100"
      placeholder="City"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.state"
      maxlength="20"
      placeholder="State"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.zip"
      maxlength="10"
      placeholder="Zip"
      required>
    <input
      type="phone"
      class="form-control my-2"
      v-model="form.phone"
      maxlength="20"
      placeholder="Phone Number"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.buesinessType"
      maxlength="100"
      placeholder="Type of Business">
    <strong>Do you have any of the following?</strong>
    <ul
      class="p-0"
      style="list-style-type: none;">
      <li>
        <input
          v-model="form.businessHas.retailStores"
          class="check"
          type="checkbox">
        <label class="ms-2">Brick/Mortar Retail Location</label>
      </li>
      <li>
        <input
          v-model="form.businessHas.warehouse"
          class="check"
          type="checkbox">
        <label class="ms-2">Warehouse</label>
      </li>
      <li>
        <input
          v-model="form.businessHas.distribution"
          class="check"
          type="checkbox">
        <label class="ms-2">Distribution Center</label>
      </li>
      <li>
        <input
          v-model="form.businessHas.onlineSales"
          class="check"
          type="checkbox">
        <label class="ms-2">Online Sales</label>
      </li>
    </ul>
    <strong>About your business</strong>
    <ul
      class="p-0"
      style="list-style-type: none;">
      <li>
        <label>Are you a resale business?</label>
        <input
          v-model="form.resale"
          class="check ms-2"
          type="checkbox"
          true-value="yes"
          false-value="no">
        <span class="ms-1">Yes</span>
        <input
          v-model="form.resale"
          class="check ms-2"
          type="checkbox"
          true-value="no"
          false-value="yes">
        <span class="ms-1">No</span>
      </li>
      <li>
        <label>Can you meet an annual purchase of $12,000/year?</label>
        <input
          v-model="form.meet12k"
          class="check ms-2"
          type="checkbox"
          true-value="yes"
          false-value="no">
        <span class="ms-1">Yes</span>
        <input
          v-model="form.meet12k"
          class="check ms-2"
          type="checkbox"
          true-value="no"
          false-value="yes">
        <span class="ms-1">No</span>
      </li>
    </ul>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.yearsInBusiness"
      maxlength="100"
      placeholder="How many years have you been in business?"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.interestedIn"
      maxlength="100"
      placeholder="What items are you interested in?">
    <input
      type="text"
      class="form-control my-2"
      v-model="form.companyWebsite"
      maxlength="100"
      placeholder="What is your website?">
    <textarea
      type="text"
      rows="5"
      cols="5"
      class="form-control my-2"
      v-model="form.comments"
      maxlength="300"
      placeholder="Additional Comments"/>
  </div>
</template>

<script>
  export default {
    props: ['form'],
    created(){
      this.setForm()
    },
    mounted(){
      this.setForm()
    },
    methods:{
      setForm(){
        this.form.businessHas = {}
      }
    }
  }
</script>

<style scoped>
  .check{
    cursor: pointer;
  }
</style>