<template>
  <div class="my-5">
    <ProductSelection :categories="categories"/>
    <div
      class="row mt-5"
      style="background: lightgray;">
      <div class="col-md-12 text-center">
        <Vendors :vendors="vendors"/>
      </div>
    </div>
    <Dynamic/>
  </div>
</template>

<script>
  import Vendors from './Vendors.vue'
  import Dynamic from './Dynamic.vue'
  import ProductSelection from './ProductSelection.vue'

  export default {
    props: ['vendors', 'categories'],
    components:{
      Vendors,
      Dynamic,
      ProductSelection
    }
  }
</script>