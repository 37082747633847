<template>
  <div
    v-if="!agreed"
    class="cookies shadow-sm">
    <div class="row">
      <div class="col-md-10">
        <h4>Your Privacy</h4>
        <small>We use cookies to improve your experience on our site and tracking technologies to track activity on our Service and hold certain information.</small>
        <small>
          To find out more, read our
          <router-link :to="{name: 'PrivacyPolicy'}">
            privacy policy
          </router-link>
        </small>
      </div>
      <div class="col-md-2">
        <div class="d-grid mt-3">
          <button
            class="btn btn-outline-primary"
            @click="agree">
            <i class="fa fa-check"/>
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      agreed: false
    }
  },
  methods:{
    agree(){
      localStorage.setItem('lancasterEstoreCookies', 'agreed')
      this.agreed = true
    }
  },
  created(){
    let agreeStatus = localStorage.lancasterEstoreCookies
    if(agreeStatus == 'agreed'){
      this.agreed = true
    }
  }
}
</script>

<style scoped>
  .cookies{
    position:fixed;
    bottom: 0px;
    left: 0px;
    background: white;
    padding:10px;
    height: auto;
    width:100%;
    border: 1px solid lightgray;
    z-index: 2;
  }
</style>