<template>
  <div
    v-if="customerList"
    class="container-fluid">
    <div
      v-if="!loading"
      class="row">
      <div class="col-md-12 text-muted">
        <span>{{customerList.length}} Customer(s)</span>
        <div
          v-if="!tm && customerList.length == 100"
          style="font-size:12px;">
          * limited to 100 results, try search with more detail
        </div>
      </div>
      <div
        v-for="c in customerList"
        :key="c.id"
        class="col-md-4 p-2">
        <CustomerCard
          :customer="c"
          :userStats="filteredUserStats(c.id)"
          :orderStats="filteredOrderStats(c.id)"
          view="manager"/>
      </div>
      <div
        v-if="customerList.length == 0"
        class="col-md-12 text-center">
        <h4 class="text-muted mt-5">No Customers</h4>
      </div>
    </div>
    <div
      v-else
      class="row">
      <div class="col-md-12 text-center">
        <Spinner class="mt-5"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../../global/Spinner.vue'
  import CustomerCard from '../tm/CustomerCard.vue'
  
  export default {
    props: ['list', 'tm', 'subQuery'],
    components:{
      Spinner,
      CustomerCard
    },
    mounted(){
      if(this.tm){
        this.$store.dispatch('getTmCustomers', this.tm)
      }
    },
    methods:{
      filteredUserStats(id){
        return _.filter(this.userStats, {entity_id: id})
      },
      filteredOrderStats(id){
        return _.filter(this.orderStats, {customer_id: id})
      }
    },
    computed:{
      customerData(){return this.$store.getters.customerData},
      userStats(){return this.customerData.user_stats},
      orderStats(){return this.customerData.order_stats},
      customerList(){
        if(this.customerData){
          const base = this.customerData.customer_list
          if(this.subQuery){
            return _.filter(base, b => b.id.toString().includes(this.subQuery) || b.name.toLowerCase().includes(this.subQuery) || b.address1.toLowerCase().includes(this.subQuery) || b.zip_code.toLowerCase().includes(this.subQuery))
          }else{
            return _.sortBy(base, c => c.name)
          }
        }
      },
      loading(){return this.$store.getters.tmLoading}
    }
  }
</script>