import {createRouter, createWebHistory} from 'vue-router'
import store from './stores/store'
import RouteBuilder from './routeBuilder'
import env from './env'

// Lancaster Public Components
import PublicHome from './components/global/home/Home.vue'
import About from  './components/public/lan/about/About.vue'
import Advantage from './components/public/lan/advantage/Advantage.vue'
import Events from './components/public/lan/events/Events.vue'
import Brands from './components/public/lan/brands/Brands.vue'
import Contact from './components/public/lan/contact/Contact.vue'
import OrderLegacy from './components/public/lan/orderLegacy/OrderLegacy.vue'

// EJW Public Components
import EjwPublicHome from './components/global/home/Home.vue'
import EjwAbout from  './components/public/ejw/about/About.vue'
import EjwAdvantage from './components/public/ejw/advantage/Advantage.vue'
import EjwBrands from './components/public/ejw/brands/Brands.vue'
import EjwContact from './components/public/ejw/contact/Contact.vue'
import WholeSale from './components/public/ejw/wholesale/WholeSale.vue'


// Cheney Public Components
import CheneyPublicHome from './components/global/home/Home.vue'
import CheneyAbout from  './components/public/cheney/about/About.vue'
import CheneyAdvantage from './components/public/cheney/advantage/Advantage.vue'
import CheneyBrands from './components/public/cheney/brands/Brands.vue'
import CheneyContact from './components/public/cheney/contact/Contact.vue'
import CheneyTour from './components/public/cheney/tour/Tour.vue'

// Shared Components
import Theme from './components/global/Theme.vue'
import PublicProducts from './components/public/shared/products/PublicProducts.vue'

// Private Components
import PrivateHome from './components/private/views/home/Home.vue'
import DynamicMeritPro from './components/private/views/dynamicMeritPro/Index.vue'
import Catalogs from './components/private/views/resources/Catalogs.vue'
import Magazines from './components/private/views/resources/Magazines.vue'
import Library from './components/private/views/resources/Library.vue'
import ProductSearch from './components/private/views/product/ProductSearch.vue'
import BrowseProducts from './components/private/views/browse/BrowseProducts.vue'
import Cart from './components/private/views/cart/Cart.vue'
import Account from './components/private/views/account/Account.vue'
import PrivacyPolicy from './components/private/views/policies/PrivacyPolicy.vue'
import Policies from './components/private/views/policies/Policies.vue'
import ManageCarts from './components/private/views/cart/manage/ManageCarts.vue'
import BinLabels from './components/private/views/binLabels/BinLabels.vue'
import PriceLists from './components/private/views/priceLists/PriceLists.vue'
import Confirm from './components/private/views/cart/Confirm.vue'
import PrivateContact from './components/private/views/contact/Contact.vue'
import Faq from './components/private/views/faq/Faq.vue'
import PatchNotes from './components/private/views/patchNotes/PatchNotes.vue'
import Promos from './components/private/views/promos/Promos.vue'
import Admin from './components/private/admin/Admin.vue'
import Scanner from './components/private/scanner/Scanner.vue'
import Settings from './components/private/app/Settings.vue'
import PrivateEvents from './components/private/views/events/Events.vue'
import UserManagement from './components/private/views/user/UserManagement.vue'
import VirtualShow from './components/private/views/virtualShow/VirtualShow.vue'
import UserStats from  './components/private/views/user/components/UserStats.vue'
import BrowseBrands from './components/private/views/browseBrands/BrowseBrands.vue'
import ProductPage from './components/private/views/product/productPage/ProductPage.vue'
import QuickPad from './components/private/views/quickPad/QuickPad.vue'
import Favorites from './components/private/views/favorites/Favorites.vue'

// Public routes specific to Lancaster side only
const lanPublic = [
  { path: '/about', component: About, name: 'About'},
  { path: '/advantage', component: Advantage, name: 'Advantage'},
  { path: '/events', component: Events, name: 'Events'},
  { path: '/contact', component: Contact, name: 'Contact'},
  { path:'/brands', component: Brands, name: 'Brands'},
  { path: '/theme', component: Theme, name: 'Theme'},
  { path: '/product_catalog', component: PublicProducts, name: 'PublicProducts' },
  { path: '/privacy_policy', component: PrivacyPolicy, name: 'PrivacyPolicy'},
  { path: '/order_legacy', component: OrderLegacy, name: 'OrderLegacy'},
  { path: '/',component: PublicHome, name: 'PublicHome', children: null }
]

// Public routes specific to EJW side only
const ejwPublic = [
  { path: '/about', component: EjwAbout, name: 'About'},
  { path: '/advantage', component: EjwAdvantage, name: 'Advantage'},
  { path: '/contact', component: EjwContact, name: 'Contact'},
  { path:'/brands', component: EjwBrands, name: 'Brands'},
  { path: '/theme', component: Theme, name: 'Theme'},
  { path: '/product_catalog', component: PublicProducts, name: 'PublicProducts' },
  { path: '/privacy_policy', component: PrivacyPolicy, name: 'PrivacyPolicy'},
  { path: '/wholesale', component: WholeSale, name: 'WholeSale'},
  { path: '/', component: EjwPublicHome, name: 'PublicHome', children: null }
]

// Public routes specific to Cheney side only
const cheneyPublic = [
  { path: '/about', component: CheneyAbout, name: 'About'},
  { path: '/advantage', component: CheneyAdvantage, name: 'Advantage'},
  { path: '/contact', component: CheneyContact, name: 'Contact'},
  { path:'/brands', component: CheneyBrands, name: 'Brands'},
  { path: '/theme', component: Theme, name: 'Theme'},
  { path: '/product_catalog', component: PublicProducts, name: 'PublicProducts' },
  { path: '/privacy_policy', component: PrivacyPolicy, name: 'PrivacyPolicy'},
  { path: '/tour', component: CheneyTour, name: 'PublicTour'},
  { path: '/',component: CheneyPublicHome, name: 'PublicHome', children: null }
]

// Interal routes of application are the same accross business sides
const internalRoutes = [
  {
    path: '/', component: PrivateHome, name: 'PrivateHome',
    meta: { restricted: true }
  },
  {
    path: '/catalogs', component: Catalogs, name: 'Catalogs',
    meta: { restricted: true }
  },
  {
    path: '/magazines', component: Magazines, name: 'Magazines',
    meta: { restricted: true }
  },
  {
    path: '/library', component: Library, name: 'Library',
    meta: { restricted: true }
  },
  {
    path: '/contact_us', component: PrivateContact, name: 'PrivateContact',
    meta: { restricted: true }
  },
  {
    path: '/our_events', component: PrivateEvents, name: 'PrivateEvents',
    meta: { restricted: true }
  },
  {
    path: '/theme', component: Theme, name: 'PrivateTheme',
    meta: { restricted: true }
  },
  {
    path: '/privacy_policy', component: PrivacyPolicy, name: 'PrivatePrivacyPolicy',
    meta: { restricted: true }
  },
  {
    path: '/policies', component: Policies, name: 'Policies',
    meta: { restricted: true }
  },
  {
    path: '/search', component: ProductSearch, name: 'ProductSearch',
    meta: { restricted: true }
  },
  {
    path: '/cart', component: Cart, name: 'Cart',
    meta: { restricted: true }
  },
  {
    path: '/my_account', component: Account, name: 'MyAccount',
    meta: { restricted: true }
  },
  {
    path: '/manage_carts', component: ManageCarts, name: 'ManageCarts',
    meta: { restricted: true }
  },
  {
    path: '/bin_labels', component: BinLabels, name: 'BinLabels',
    meta: { restricted: true }
  },
  {
    path: '/price_lists', component: PriceLists, name: 'PriceLists',
    meta: { restricted: true }
  },
  {
    path: '/browse', component: BrowseProducts, name: 'BrowseProducts',
    meta: { restricted: true }
  },
  {
    path: '/clearance', component: BrowseProducts, name: 'BrowseClearance',
    meta: { restricted: true }
  },
  {
    path: '/confirm', component: Confirm, name: 'Confirm',
    meta: { restricted: true }
  },
  {
    path: '/faq', component: Faq, name: 'Faq',
    meta: { restricted: true }
  },
  {
    path: '/patch_notes', component: PatchNotes, name: 'PatchNotes',
    meta:{ restricted: true }
  },
  {
    path: '/promos', component: Promos, name: 'Promos',
    meta:{ restricted: true }
  },
  {
    path: '/admin', component: Admin, name: 'Admin',
    meta:{ restricted: true, role: 'admin' }
  },
  {
    path: '/camera', component: Scanner, name: 'Scanner',
    meta:{ restricted: true }
  },
  {
    path: '/manage_carts', component: ManageCarts, name: 'ManageCarts',
    meta:{ restricted: true }
  },
  {
    path: '/settings', component: Settings, name: 'Settings',
    meta:{ restricted: true }
  },
  {
    path: '/user_management', component: UserManagement, name: 'UserManagement',
    meta:{ restricted: true }
  },
  {
    path: '/virtual_show', component: VirtualShow, name: 'VirtualShow',
    meta:{ restricted: true }
  },
  {
    path: '/user_stats', component: UserStats, name: 'UserStats',
    meta:{ restricted: true }
  },
  {
    path: '/browse_brands', component: BrowseBrands, name: 'BrowseBrands',
    meta:{ restricted: true }
  },
  {
    path: '/dynamic_merit_pro', component: DynamicMeritPro, name: 'DynamicMeritPro',
    meta:{ restricted: true }
  },
  {
    path: '/product/:id', component: ProductPage, name: 'ProductPage',
    meta:{ restricted: true }
  },
  {
    path: '/quick_pad', component: QuickPad, name: 'QuickPad',
    meta:{ restricted: true }
  },
  {
    path: '/favorites', component: Favorites, name: 'Favorites',
    meta:{ restricted: true }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

// Get matching public routes for env var
const publicRoutes = {
  "EJW": ejwPublic,
  "EJW-CHENEY": cheneyPublic,
  "LAN": lanPublic
}

// Dynamically build out routes
const routes = new RouteBuilder(env, publicRoutes, internalRoutes)

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Auto flip users to standed cart when not interacting with the virtual show
const vShowCartToggle = (toRoute)=>{
  return new Promise(async (resolve)=>{
    // Routes a vShow cart is allowed to remain active in
    const excludedRoutes = ['/virtual_show', '/cart', '/my_account', '/confirmation', '/manage_carts']
    if(!excludedRoutes.includes(toRoute.path)) await store.dispatch('switchToStandardCart')
    resolve()
  })
}

router.beforeEach(async (to, from, next)=>{
  // Check for authorized routes && login status
  if((to.path == '/cart' || to.path == '/confirm' || to.path == '/checkout') ){
    if(store.state.sessionStore.user && store.state.sessionStore.user.estore_noorder == true){
      next('/')
    }
  }
  if(to.meta.restricted == true){
    if(store.state.sessionStore.loggedOn){
      const vShowCart = store.state.cartStore.cart?.details.vshow_cart
      // vShow Cart toggle
      if(vShowCart) await vShowCartToggle(to)
      // Check for admin access before accessing admin panel
      if(to.meta.role && store.state.sessionStore.user.role == to.meta.role){
        next()
        // Return to home if not admin role when manual route is attempted
      }else if(to.meta.role && store.state.sessionStore.user.role != to.meta.role){
        next('/')
      }else if(store.state.sessionStore.company == 'Lancaster Canada' && to.meta.exclude_canada){
        next('/')
      }else{
        next()
      }
      document.getElementById('content').scrollTo({top: 0, behavior: 'smooth'})
      return
    }
    next({name: 'PublicHome'})
    return
  }
  next()
  window.scrollTo({top: 0, behavior: 'smooth'})
})

export default router;