<template>
  <div class="row public-products">
    <template v-if="!country">
      <CountrySelector @countrySelect="getData"/>
    </template>
    <template v-else>
      <!-- Main Content -->
      <div
        class="col-12 public-body"
        :style="bodyHeight">
        <!-- Info Header & Search -->
        <div class="row header shadow-sm p-2">
          <div class="col-md-12 top-bar text-muted">
            <!-- Navigation Trigger -->
            <a
              v-if="show"
              class="float-start d-block d-lg-none btn btn-outline-light btn-sm mt-1 me-2 animate__animated animate__fadeInLeft animate__faster"
              data-bs-toggle="offcanvas"
              href="#publicProductNav">
              <i class="fa fa-caret-left"/>
              Browse
            </a>
            <div
              v-if="show"
              class="float-start stats animate__animated animate__fadeIn animate__faster d-none d-lg-block my-3 text-light">
              <!-- Results Data -->
              <span>
                {{pagination ? numeral(pagination.totalItems).format('0,0') : 0}} Result(s)
              </span>
              <span v-if="query">
                for
                <span class="badge bg-light text-dark px-2">
                  "{{ query }}"
                  <i
                    class="fa fa-times text-danger"
                    style="cursor: pointer;"
                    @click="clear"/>
                </span>
              </span>
            </div>
            <!-- Product Search -->
            <Search @search="handleSearch"/>
          </div>
        </div>
        <!-- Products -->
        <div
          class="row content"
          id="content">
          <!-- Left Column -->
          <div
            class="col-3 d-none d-lg-block bg-primary ps-4"
            style="height: 100%; overflow-y: scroll; padding-bottom: 100px;">
            <!-- Desktop Category Navigation -->
            <DesktopCategories
              :categories="categories"
              :categoryFilters="categoryFilters"
              :loading="loading"
              @setCategories="setCategories"/>
            <!-- Desktop Brand Navigation -->
            <DesktopBrands
              :brands="brands"
              :loading="loading"
              :brandFilters="brandFilters"
              @setBrands="setBrands"/>
          </div>
          <!-- Main Content Column -->
          <div
            class="col-12 col-lg-9 p-3"
            style="height: 100%; overflow-y: scroll;">
            <div class="row results">
              <!-- Product Results -->
              <template v-if="show">
                <div
                  v-for="(p,i) in products"
                  :key="p.product_id"
                  class="col-6 col-sm-4 col-md-3 col-xl-2">
                  <ProductCard :product="p"/>
                  <ShowMore
                    :index="i"
                    :pagination="pagination"
                    @getProducts="getProducts"/>
                </div>
                <div
                  v-if="loading"
                  class="row">
                  <div class="col-md-12 text-center mt-5">
                    <Spinner/>
                  </div>
                </div>
              </template>
              <!-- Spinner -->
              <div
                v-else
                class="col-12 text-center mt-5">
                <Spinner/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Navigation Menu / Flyout-->
    <div class="offcanvas offcanvas-start bg-primary text-light" tabindex="-1" id="publicProductNav">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="publicProductNavLabel">Browse By</h5>
        <button class="btn btn-outline-light" type="button" data-bs-dismiss="offcanvas">Go</button>
      </div>
      <!-- Mobile Browse Options -->
      <div class="offcanvas-body">
        <DesktopCategories
          :categories="categories"
          :categoryFilters="categoryFilters"
          :loading="loading"
          @setCategories="setCategories"/>
        <!-- Desktop Brand Navigation -->
        <DesktopBrands
          :brands="brands"
          :loading="loading"
          :brandFilters="brandFilters"
          @setBrands="setBrands"/>
      </div>
    </div>
    <InfoModal/>
  </div>
</template>

<script>
  import CountrySelector from './CountrySelector.vue'
  import DesktopCategories from './DesktopCategories.vue'
  import DesktopBrands from './DesktopBrands.vue'
  import ProductCard from './ProductCard.vue'
  import axios from 'axios'
  import ShowMore from './ShowMore.vue'
  import Spinner from '../../../global/Spinner.vue'
  import Search from './Search.vue'
  import InfoModal from './InfoModal.vue'

  export default{
    inject: ['env'],
    created(){
      this.getProducts()
      window.addEventListener('resize', this.setHeights())
    },
    mounted(){
      this.setHeights()
    },
    data(){
      return{
        country: this.env.includes('EJW') ? 'United States of America' : null,
        state: null,
        branch: null,
        categories: null,
        categoryFilters: null,
        brandFilters: null,
        products: null,
        brands: null,
        pagination: null,
        main: null,
        sub: null,
        query: null,
        show: false,
        loading: false,
        activeBrand: null,
        query: null,
        navHeight: null,
        headerHeight: null,
        activeBrands: null,
        activeCategories: null
      }
    },
    components:{
      CountrySelector,
      DesktopCategories,
      ProductCard,
      DesktopBrands,
      ShowMore,
      Spinner,
      Search,
      InfoModal
    },
    methods:{
      setHeights(){
        const elm = document.getElementsByClassName('navbar-dark')[0]
        const elm2 = document.getElementsByClassName('header')[0]
        if(elm) this.navHeight = elm.clientHeight
        if(elm2) this.headerHeight = elm2.clientHeight
      },
      // Set category filters and fetch data
      setCategories(categories){
        this.categoryFilters = categories
        this.show = false
        this.getProducts(1)
        this.toTop()
      },
      // Set brand filters and fetch data
      setBrands(brands){
        this.brandFilters = brands
        this.show = false
        this.getProducts(1)
        this.toTop()
      },
      // Core product data fetch
      getProducts(page=null){
        this.loading = true
        const params = {
          page: page ? page : this.pagination?.nextPage,
          brands: this.brandFilters,
          categoryFilters: this.categoryFilters,
          country: this.country,
          state: this.state,
          branch: this.branch,
          query: this.query,
          env: this.env
        }
        axios.get('/api/v1/public/products/get_products', {params}).then(res => {
          if(res.data){
            this.pagination = res.data.pagination
            if(this.pagination.currentPage > 1){
              // Ensure no duplicates are appended into the products array
              const existing = _.map(this.products, 'product_id')
              const incoming = _.reject(res.data.products, p => existing.includes(p.product_id))
              this.products = [...this.products, ...incoming]
            }else{
              this.products = res.data.products
              this.setFilters(res.data.filters)
            }
          }
          this.loading = false
          this.show = true
        })
      },
      setFilters(filters){
        // Set Filter Base
        if(!this.brands) this.brands = filters.brands
        if(!this.categories) this.categories = filters.categories
        
        // Set Visibile base on current filters applied
        if(!this.categoryFilters || this.categoryFilters.mains.length == 0 && this.categoryFilters.subs.length == 0) this.categories = filters.categories
        if(!this.brandFilters || this.brandFilters.length == 0) this.brands = filters.brands
      },
      // Core category data fetch
      // General get data trigger used by country selector
      // Set initial country, state, branch for backend query
      getData(countryData){
        this.country = countryData.country
        this.state = countryData.state
        this.branch = countryData.branch
        this.setCategories()
        this.setBrands()
        this.getProducts(1)
      },
      // Handle front end brand selection
      setBrand(brand){
        this.activeBrand = brand
        this.main = this.sub = null
        this.show = false
        this.query = null
        this.getProducts(1)
      },
      // Scroll main content column back to top
      toTop(){
        const elm = document.getElementById('content')
        if(elm) elm.scrollTo(0,0)
      },
      setMain(main){
        this.main = main
        this.sub = this.activeBrand = null
        this.show = false
        this.query = null
        this.getProducts(1)
        this.toTop()
      },
      setSub(data){
        this.main = data.main
        this.sub = data.sub
        this.activeBrand = null
        this.show = false
        this.query = null
        this.getProducts(1)
        this.toTop()
      },
      // Process front end search query and fetch data
      handleSearch(data){
        this.query = data.query
        this.categoryFilters = null
        this.show = false
        this.getProducts(1)
        this.toTop()
      },
      // Clear front end state and fetch data
      clear(){
        this.categoryFilters = this.query = this.activeBrand = null
        this.getProducts(1)
        this.setCategories()
        this.setBrands()
        this.toTop()
        this.categoryFilters = null
        this.brandFilters = null
      }
    },
    computed:{
      bodyHeight(){
        const height = (window.innerHeight - this.navHeight) + 'px'
        return {'height': height}
      },
      innerHeight(){
        const height = (window.innerHeight - this.navHeight - this.headerHeight) + 'px'
        return {'height': height}
      }
    }
  }
</script>

<style scoped>
  .public-products{
    position: relative;
    top: 72px;
  }
  .left-nav{
    overflow-y: scroll;
    height: 100%;
    border-right: 1px solid var(--bs-primary-rgb, 0.5);
    padding: 20px;
    padding-left:35px;
    background: var(--bs-primary);
  }
  .content{
    height: 100%;
    overflow-y: scroll;
  }
  .top-bar{
    height: 4rem;
    background: var(--bs-primary);
    padding: 20px 25px;
  }
  .header{
    border-bottom: 1px solid lightgray;
    background-color: var(--bs-primary);
    padding: 5px;
  }
  .stats{
    line-height: 20px;
  }
  .results{
    margin-bottom: 200px;
  }
</style>