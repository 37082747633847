<template>
  <div style="overflow: hidden;">
    <div
      v-if="!showForm && !loading"
      class="row animate__animated animate__fadeIn animate__faster">
      <!-- Actions -->
      <div class="col-md-12">
        <button
          class="btn btn-primary btn-sm float-end animate__animated animate__fadeInRight animate__faster"
          @click="toggleShowForm">
          <i class="fa fa-plus"/>
          Add Asset
        </button>
      </div>
      <!-- Content -->
      <template v-if="hasAssets">
        <div
          class="col-md-12"
          v-for="a in assets"
          :key="a.id">
          <PromoAssetRow
            :asset="a"
            @edit="handleEdit"/>
        </div>
      </template>
      <div
        v-else
        class="col-md-12 text-center my-5">
        <h4 class="text-muted">No Assets</h4>
      </div>
    </div>
    <!-- Form -->
    <div
      v-if="showForm && !loading" 
      class="col-md-12">
      <PromoAssetForm
        :promo="promo"
        :editAsset="editAsset"
        @formClose="toggleShowForm"/>
    </div>
    <!-- Spinner -->
    <div
      v-if="loading"
      class="col-md-12 text-center my-5">
      <Spinner/>
    </div>
  </div>
</template>

<script>
import PromoAssetForm from './PromoAssetForm.vue'
import PromoAssetRow from './PromoAssetRow.vue'
import Spinner from '../../../../../../global/Spinner.vue'

  export default{
    props: ['assets', 'promo'],
    data(){
      return{
        showForm: false,
        editAsset: null
      }
    },
    methods:{
      toggleShowForm(){
        this.showForm = !this.showForm
        if(!this.showForm){
          this.editAsset = null
        }
      },
      handleEdit(asset){
        this.editAsset = asset
        this.showForm = true
      }
    },
    computed:{
      loading(){return this.$store.getters.adminPromoAssetLoading},
      hasAssets(){return this.assets?.length}
    },
    components:{
      PromoAssetForm,
      PromoAssetRow,
      Spinner
    }
  }
</script>


<style scope>
  .pdf{
    max-height: 100px;
  }
</style>