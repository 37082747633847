<template>
  <div class="loading animate__animated animate__fadeIn ">
    <div class="load-text text-center">
      <div class="inner text-light">
        <img
          :src="logo"
          class="img-fluid">
        <div class="my-4">
          <Spinner color="text-light"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from './Spinner.vue'
  export default{
    inject: ['env'],
    components:{
      Spinner
    },
    data(){
      return{
        percent: 0,
        logoLan: require('../../../assets/images/lan/LogoColor.png'),
        logoEjw: require('../../../assets/images/ejw/EJW_Logo_White.png'),
        cheneyLogo: require('../../../assets/images/cheney/CheneyLogoLight.png')
      }
    },
    computed:{
      logo(){
        switch(this.env){
          case 'EJW':
            return this.logoEjw
          case 'EJW-CHENEY':
            return this.cheneyLogo
          default:
            return this.logoLan
        }
      }
    }
  }
</script>

<style scoped>
  .loading{
    position: absolute;
    top: 0;
    left: 0;
    background: var(--bs-primary);
    width: 100dvw;
    height: 100dvh;
    z-index: 2;
  }
  .load-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .inner{
    max-width:400px;
  }
</style>