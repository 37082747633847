<template>
  <div class="row d-md-none d-lg-block right-nav animate__animated animate__fadeIn animate__faster">
    <div class="col-md-12 py-2">
      <CartForm v-if="activeView=='cart'"/>
      <General v-else/>
    </div>
  </div>
</template>

<script>
  import CartForm from '../../views/cart/CartForm.vue'
  import General from '../views/General.vue'
  export default {
    props: ['activeView'],
    components:{
      CartForm,
      General
    },
    data(){
      return{
        banner1: require('../../../../../assets/images/lan/marketing_area/fallBanner.png'),
        banner2: require('../../../../../assets/images/lan/marketing_area/events.png'),
        banner3: require('../../../../../assets/images/lan/marketing_area/sundryscene.png'),
        banner4: require('../../../../../assets/images/lan/marketing_area/dynamic.png'),
      }
    }
  }
</script>