<template>
  <div class="row d-none d-sm-block">
    <div class="col-md-12">
      <ViewSwitcher
        class="float-end"
        :loading="loading"/>
      <SortBy
        v-if="show('SortBy')"
        class="float-end"
        :loading="loading"/>
      <BrandFilter
        v-if="show('BrandFilter') && !query.brand"
        class="float-end"
        :filters="filters"
        :loading="loading"
        :products="products"/>
      <CategoryFilter
        v-if="show('CategoryFilter') && !query.category"
        class="float-end"
        :filters="filters"
        :loading="loading"
        :products="products"/>
      <BranchFilter
        v-if="show('BranchFilter')"
        class="float-end"
        :filters="filters"
        :loading="loading"/>
      <PromoFilter
        class="float-end"
        :filters="filters"
        :loading="loading"/>
      <SubSearch
        v-if="show('SubSearch')"
        class="float-end"
        :filters="filters"
        :loading="loading"
        @subSearch="subSearch"/>
    </div>
  </div>
</template>

<script>
  import ViewSwitcher from './components/ViewSwitcher.vue'
  import SortBy from './components/SortBy.vue'
  import BrandFilter from './components/BrandFilter.vue'
  import CategoryFilter from './components/CategoryFilter.vue'
  import BranchFilter from './components/BranchFilter.vue'
  import SubSearch from './components/SubSearch.vue'
  import PromoFilter from './components/PromoFilter.vue'

  export default{
    // Exclude prop should be filter component name(s) to be excluded
    // Prop can be singular name (string) or array of component names to exclude
    // Example Names: ViewSwitcher, SortBy, BrandFilter, CategoryFilter, BranchFilter
    // Example Usage: 'ViewSwitcher' OR ['SortBy', 'CategoryFilter']
    props: ['loading', 'exclude', 'products'],
    methods:{
      show(name){
        return this.exclude != name || !this.exclude.includes(name)
      },
      subSearch(query){
        this.$emit('subSearch', query)
      }
    },
    components:{
      ViewSwitcher,
      SortBy,
      BrandFilter,
      CategoryFilter,
      BranchFilter,
      SubSearch,
      PromoFilter
    },
    computed:{
      filters(){return this.$store.getters.productFilters},
      query(){return this.$router.currentRoute.value.query}
    }
  }
</script>