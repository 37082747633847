<template>
  <div class="row">
    <div class="col-md-12">
      <strong>Branch Permission</strong>     
      <table class="table table-light table-striped">
        <tbody>
          <tr
            v-for="b in branches"
            :key="b.branch_id">
            <td>{{ b.branch_id }}</td>
            <td>
              <div class="form-check form-switch">
                <input  
                  class="form-check-input"
                  v-model="form.branchPermission[b.branch_id]"
                  type="checkbox">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default{
    props: ['form', 'activeRecord'],
    data(){
      return{
        branches: null
      }
    },
    mounted(){
      this.getBranches()
    },
    methods:{
      getBranches(){
        axios.get('/api/v1/public/branches/get_branches').then(res=>{
          this.branches = _.sortBy(_.reject(res.data.branches, {branch_id: 'HQ'}), function(b){
            switch(b.branch_id){
              case '1':
                return 1
              case '2':
                return 2
              case '7T':
                return 3
              case '8':
                return 4
              case '9':
                return 5
              case '14':
                return 6
              case '71':
                return 7
            }
          })
          this.form.branchPermission = {}
          if(this.activeRecord && this.activeRecord.slide_branches.length){
            this.activeRecord.slide_branches.forEach(function(s){
              this.form.branchPermission[s.branch_id] = true
            }.bind(this))
          }
        })
      }
    }
  }
</script>
