<template>
  <div>
    <div class="row">
      <ArPassword v-if="!authorized && !loading"/>
    </div>
    <div v-if="authorized && !loading">
      <div
        class="row mt-3">
        <div class="col-md-3">
          <label for="invNum">Search</label>
          <input
            id="invNum"
            type="text"
            placeholder="Invoice # or PO #"
            class=" form-control"
            v-model="invoiceQuery">
        </div>
        <!-- Between Date Search -->
        <div class="col-md-3">
          <label for="startDate">Start Date</label>
          <input
            id="startDate"
            type="date"
            v-model="startDate"
            class="form-control">
        </div>
        <div class="col-md-3">
          <label for="endDate">End Date</label>
          <input
            id="endDate"
            type="date"
            v-model="endDate"
            class="form-control">
          </div>
          <div class="col-md-3">
            <label for="aging">Aging</label>
            <select
              id="aging"
              class="form-control select"
              v-model="selectedAging">
              <option
                v-for="a in aging"
                :key="a.key">
                {{a.value}}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <ProductSearch
              class="my-2"
              view="invoice"/>
          </div>
          <div
            v-if="this.invoiceQuery || this.startDate && this.endDate || this.orderSearchResults"
            class="col-md-12 mt-2">
            <button
              class="btn btn-sm btn-primary"
              @click="clear">
              <i class="fa fa-sync"/>
              Reset
            </button>
          </div>
        </div>
      <!-- Show Open Invoices Only -->
      <div class="col-md-12 mt-4">
        <div class="input-group">
          <button
            class="btn btn-block btn-sm"
            :class="openOnly ? 'btn-success' : 'btn-outline-primary'"
            @click="openToggle">
            <i
              v-if="openOnly"
              class="fa fa-check"/>
            <i
              v-if="!openOnly"
              class="fa fa-filter"/>
            Open Invoices
          </button>
          <button
            class="btn btn-block btn-sm"
            :class="!openOnly ? 'btn-success' : 'btn-outline-primary'"
            @click="openToggle">
            <i
              v-if="!openOnly"
              class="fa fa-check"/>
            <i
              v-if="openOnly"
              class="fa fa-filter"/>
            All Invoices
          </button>
        </div>
        <button
          v-if="invoices && invoices.length > 0"
          class="btn btn-block btn-sm btn-outline-primary mt-2"
          @click="emailAll">
          <i class="fa fa-envelope"/>
          Email All
        </button>
        <div class="mt-2">
          <strong class="float-start">
            Total: {{numeral(invoiceTotal).format('$0,0.00')}}
          </strong>
        </div>
      </div>
      <div class="clearfix"/>
      <hr class="my-4">
      <Email
        v-if="emailData.orderList.length > 0"
        :emailData="emailData"
        type="Invoices"
        @cancel="emailCancel"/>
      <div
        v-if="filteredInvoices.length > 0"
        class="row">
        <Invoice
          v-for="i in filteredInvoices"
          :key="i.invoice_id"
          :invoice="i"
          :emailData="emailData"
          @emailToggle="emailToggle"/>
      </div>
      <div
        v-else
        class="row mt-5">
        <div
          class="cold-md-12 text-center text-muted">
          <h3>No Invoices</h3>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="row">
      <div
        class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
    </div>
  </div>  
</template>

<script>
  import Invoice from './Invoice.vue'
  import Email from './Email.vue'
  import moment from 'moment'
  import Spinner from '../../../global/Spinner.vue'
  import ArPassword from './ArPassword.vue'
  import ProductSearch from './ProductSearch.vue'

  export default {
    props:['authorized'],
    data(){
      return{
        emailData:{
          orderList: [],
          addressList: []
        },
        invoiceQuery: null,
        startDate: null,
        endDate: null,
        openOnly: true,
        aging: [
          {key: 0, value: 'All'},
          {key: 30, value: '1-30'},
          {key: 60, value: '31-60'},
          {key: 90, value: '61-90'},
          {key: 120, value: '91-120'},
          {key: 121, value: '120+'}
        ],
        selectedAging: this.$store.getters.activeAging || 'All',
        invoiceTotal: null
      }
    },
    components:{
      Invoice,
      Email,
      Spinner,
      ArPassword,
      ProductSearch
    },
    methods:{
      emailToggle(invoice){
        this.emailData.orderList.push(invoice)
      },
      emailCancel(){
        this.emailData = {
          orderList: [],
          addressList: []
        }
      },
      clear(){
        this.invoiceQuery = null
        this.startDate = null
        this.endDate = null
        this.$store.dispatch('clearAccountProductSearch')
      },
      openToggle(){
        this.openOnly = !this.openOnly
      },
      emailAll(){
        this.filteredInvoices.forEach(function(i){
          const data = {
            text: i.invoice_id,
            value: i.invoice_id
          }
          this.emailData.orderList.push(data)
        }.bind(this))
      },
      setTotal(){
        if(this.openInvoices.length){
          return _.sum(_.map(this.openInvoices, this.company == 'Lancaster Canada' ? 'cad_amount' : 'amount'))
        }else{
          return 0
        }
      }
    },
    computed:{
      invoices(){return this.$store.getters.accountInvoices},
      openInvoices(){return this.$store.getters.openInvoices},
      company(){return this.$store.getters.sessionCompany},
      filteredInvoices(){
        let result = null
        const invoiceBase = _.filter(this.invoices, i => i.amount != 0)
        const open = _.map(this.openInvoices, i => i.invoice_id)
        
        // Mark open invoices
        invoiceBase.forEach(function(i){
          if(open.includes(i.invoice_id)){
            i.open = true
          }else{
            i.open = false
          }
        }.bind(this))
        
        // Search by Query
        if(this.invoiceQuery){
          result = _.filter(invoiceBase, function(i){
            return i.invoice_id.toLowerCase().includes(this.invoiceQuery.toLowerCase()) || i.customer_po.toLowerCase().includes(this.invoiceQuery.toLowerCase())
          }.bind(this))
        }
        
        // Search by Date Range
        if(this.startDate && this.endDate){
          const base = result ? result : invoiceBase
          result = _.filter(base, function(i){
            return moment(i.post_date).isBetween(this.startDate, this.endDate, 'days', '[]')
          }.bind(this))
        }

        // Show open invoice only
        if(this.openOnly){
          const base = result ? result : invoiceBase
          result = _.filter(base, i => i.open == true)
        }

        // Filter on Aging
        if(this.selectedAging != 'All'){
          const base = result ? result : invoiceBase
          result = _.filter(base, {age: this.selectedAging})
        }

        // Product Search invoices
        if(this.orderSearchResults?.view == 'invoice'){
          const base = result ? result : invoiceBase
          result = _.filter(base, b => this.orderSearchResults.orders.includes(b.invoice_id))
        }

        this.invoiceTotal = this.setTotal()

        return result ? result : invoiceBase
      },
      loading(){return this.$store.getters.getAccountLoadStatus},
      orderSearchResults(){return this.$store.getters.orderSearchResults}
    }
  }
</script>

<style scoped>
  .zoom{ transition: all .2s ease-in-out; }
  .zoom:hover{
    transform: scale(1.01);
    cursor:pointer;
  }
  .select:hover{
    cursor: pointer;
  }
</style>