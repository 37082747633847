<template>
  <form
    name="promoProduct"
    @submit.prevent="submit"
    class="animate__animated animate__fadeInDown animate__faster">
    <table class="table table-light table-striped text-center">
      <tbody>
        <tr>
          <td>
            <input
              type="number"
              class="form-control form-control-sm"
              required
              v-model="form.product_id"
              placeholder="ProductID">
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              required
              v-model="form.class3_price"
              type="number"
              min="0.01"
              step="0.01"
              placeholder="Standard Price">
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              required
              v-model="form.promo_price"
              type="number"
              min="0.01"
              step="0.01"
              placeholder="Promo Price">
          </td>
          <!-- Qty Break 1 -->
          <td>
            <input
              class="form-control form-control-sm"
              v-model="form.qty_break1"
              type="number"
              min="1"
              step="1"
              placeholder="Qty Break 1">
          </td>
          <td>
            <select
              class="form-select form-select-sm"
              :class="form.qty_break1_uom ? '' : 'text-muted'"
              placeholder="Break 1 Uom"
              v-model="form.qty_break1_uom">
              <option selected disabled value="null">Break 1 Uom</option>
              <option value="ea">ea</option>
              <option value="cs">cs</option>
              <option value="pl">pl</option>
            </select>
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              v-model="form.qty_break1_price"
              type="number"
              min="0.01"
              step="0.01"
              placeholder="Break1 Price">
          </td>
        </tr>
        <tr>
          <!-- Qty Break 2 -->
          <td>
            <input
              class="form-control form-control-sm"
              v-model="form.qty_break2"
              type="number"
              min="1"
              step="1"
              placeholder="Qty Break 2">
          </td>
          <td>
            <select
              class="form-select form-select-sm"
              :class="form.qty_break2_uom ? '' : 'text-muted'"
              v-model="form.qty_break2_uom">
              <option selected disabled value="null">Break 2 Uom</option>
              <option value="ea">ea</option>
              <option value="cs">cs</option>
              <option value="pl">pl</option>
            </select>
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              v-model="form.qty_break2_price"
              type="number"
              min="0.01"
              step="0.01"
              placeholder="Break 2 Price">
          </td>
          <!-- Qty Break 3 -->
          <td>
            <input
              class="form-control form-control-sm"
              v-model="form.qty_break3"
              type="number"
              min="1"
              step="1"
              placeholder="Qty Break 3">
          </td>
          <td>
            <select
              class="form-select form-select-sm"
              :class="form.qty_break3_uom ? '' : 'text-muted'"
              placeholder="Break 3 Uom"
              v-model="form.qty_break3_uom">
              <option selected disabled value="null">Break 3 Uom</option>
              <option value="ea">ea</option>
              <option value="cs">cs</option>
              <option value="pl">pl</option>
            </select>
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              v-model="form.qty_break3_price"
              type="number"
              min="0.01"
              step="0.01"
              placeholder="Break 3 Price">
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="float-end">
              <div class="input-group">
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="$emit('closeProductForm')">
                  <i class="fa fa-ban"/>
                   Cancel
                </button>
                <button
                  type="submit"
                  class="btn btn-sm btn-primary">   
                  <i class="fa fa-paper-plane"/>
                  Submit
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form> 
</template>

<script>
  export default{
    props: ['activePriceBranch', 'promoHeader'],
    data(){
      return{
        form: {
          promo_id: null,
          price_branch: null,
          product_id: null,
          class3_price: null,
          promo_price: null,
          qty_break1: null,
          qty_break1_uom: null,
          qty_break1_price: null,
          qty_break2: null,
          qty_break2_uom: null,
          qty_break2_price: null,
          qty_break3: null,
          qty_break3_uom: null,
          qty_break3_price: null
        }
      }
    },
    mounted(){
      this.form.price_branch = this.activePriceBranch
      this.form.promo_id = this.promoHeader.id
    },
    methods:{
      submit(){
        this.$store.dispatch('createPromoProduct', this.form)
        this.$emit('closeProductForm')
      }
    }
  }
</script>

<style scoped>
  select{
    cursor: pointer;
  }
</style>