<template>
  <div class="row my-5">
    <div
      v-if="!creating"
      class="col-md-12">
      <form
        name="createCart"
        @submit.prevent="submit">
        <div class="text-muted my-5 text-center">
          Enter a name for your cart and click&nbsp;
          <strong>Create Cart</strong>
          <div>
            Once created you can mangage your carts and select which cart you would like to be active.
          </div>
        </div>
        <input
          name="cartName"
          class="form-control"
          placeholder="Enter Cart Name"
          v-model="cartName"
          type="text">
        <!-- Cart Types -->
        <div class="mt-2">
          <span>
            <input
              class="pointer"
              type="radio"
              id="standard"
              value="standard"
              v-model="cartType">  
            <label
              for="standard"
              class="ms-1">
              Standard Cart
            </label>
          </span>
          <br>
          <span v-if="settings.virtualShow && vShowAllowed">
            <input
              class="pointer"
              type="radio"
              id="vShow"
              value="vShow"
              v-model="cartType">  
            <label
              for="vShow"
              class="ms-1">
              Virtual Show Cart
            </label>
          </span>
        </div>
        <button class="btn btn-primary btn-sm float-end mt-2">Create Cart</button>
      </form>
    </div>
    <div
      v-if="creating"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../../global/Spinner.vue'

  export default {
    data(){
      return{
        creating: false,
        cartName: null,
        cartType: 'standard'
      }
    },
    components:{
      Spinner
    },
    methods:{
      submit(){
        this.creating = true
        let params = {
          cart_name: this.cartName,
          cart_type: this.cartType
        }
        axios.post('/api/v1/private/manage_carts/create_cart', params).then(res=>{
          if(res.status == 200){
            this.$emit('cartCreated', res.data.cart)
            this.creating = false
          }
        })
      }
    },
    computed:{
      settings(){return this.$store.getters.settings},
      vShowAllowed(){return this.$store.getters.vShowAllowed}
    }
  }
</script>

<style scoped>
  .pointer{
    cursor: pointer;
  }
</style>