<template>
  <div
    class="container p-4"
    style="min-height:400px;">
    <div
      v-if="show"
      class="row animate__animated animate__fadeIn animate__faster">
      <div class="col-lg-6 col-md-12">
        <img
          :src="cardData.image"
          class="img-fluid">
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="row">
          <div class="col-md-12 mt-2">
            <h4>{{ cardData.title }}</h4>
          </div>
          <div class="col-md-12 my-3">
            <strong class="large-font">{{ cardData.blurb }}</strong>
          </div>
          <div class="col-md-12">
            <p>{{ cardData.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        show: true
      }
    },
    props: ['cardData'],
    watch:{
      cardData(newVal){
        if(newVal){
          this.show = false
          setTimeout(function(){
            this.show = true
          }.bind(this),100)
        }
      }
    }
  }
</script>

<style scoped>
  .large-font{
    font-size:18px;
  }
</style>