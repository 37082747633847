<template>
  <div
    class="modal fade"
    id="videoModal"
    tabindex="-1"
    data-bs-keyboard="false"
    role="dialog">
    <div
      class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
      role="document">
      <div
        v-if="video"
        class="modal-content">
        <div class="modal-header">
          <h4>{{ video.title }}</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <video
            id="videoAsset"
            class="w-100 h-100"
            :src="video.video_url"
            controlsList="nodownload"
            controls>
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    created(){
      const modal = document.getElementById('videoModal')
      if(modal){
        modal.addEventListener('hide.bs.modal', function(){
          this.pause()
        }.bind(this))
        modal.addEventListener('shown.bs.modal', function(){
          this.play()
        }.bind(this))
      }
    },
    methods:{
      pause(){
        if(this.video){
          const videoElm = document.getElementById('videoAsset')
          videoElm.pause()
        }
      },
      play(){
        if(this.video){
          const videoElm = document.getElementById('videoAsset')
          videoElm.play()
        }
      }
    },
    computed:{
      video(){return this.$store.getters.modalVideo}
    }
  }
</script>