<template>
  <div
    v-if="displayOrder"
    class="mt-3">
    <div class="row order shadow-sm">
      <!-- Header -->
      <div class="col-md-12 position-relative">
        <h4 class="float-start text-muted">Order Pad</h4>
        <button
          v-if="order.length"
          class="float-end btn btn-primary btn-sm"
          :disabled="disabled || !calcState || missingQty"
          @click="addAll">
          <i class="fa fa-plus"/>
          Add All To Cart
        </button>
        <div class="clearfix mb-2"/>
        <!-- Order Products -->
        <template
          v-for="(o,i) in order"
          :key="i">
          <div class="animate__animated animate__fadeIn animate__faster">
            <OrderRow
              v-if="o"
              :order="o"
              @focus="disableAdd"
              @blur="enableAdd"
              @calcState="handleCalcState"/>
          </div>
        </template>
        <div
          v-if="order.length"
          class="row">
          <div class="col-md-12">
            <h4 class="float-end">Estimated Total: {{numeral(grandTotal()).format('$0,0.00')}}</h4>
          </div>
        </div>
        <template v-if="!order.length">
          <div class="row">
            <div class="col-md-12 text-center my-5">
              <strong class="text-muted">No Products</strong>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import OrderRow from './OrderRow.vue'
  import {showDetail} from '../../../../../../shared/showDetail'
  
  export default {
    props: ['order', 'displayOrder'],
    data(){
      return{
        disabled: false,
        calcState: true
      }
    },
    components:{
      OrderRow
    },
    methods:{
      grandTotal(){
        let total = 0
        this.order.forEach((o)=>{
          const lineTotal = showDetail(o.product).show_price * o.orderData.qty
          total += lineTotal
        })
        return total
      },
      addAll(){
        let productList = []
        this.order.forEach((o)=>{
          productList.push(o.orderData)
        })
        const params = {
          productList,
          vShow: true
        }
        this.$store.dispatch('quickPadAdd', params)
      },
      disableAdd(){
        this.disabled = true
      },
      enableAdd(){
        this.disabled = false
      },
      handleCalcState(status){
        this.calcState = status
      }
    },
    computed:{
      missingQty(){
        return _.map(this.order, o => o.orderData.qty).includes('')
      }
    }
  }
</script>

<style scoped>
  .order{
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px;
  }
</style>