<template>
  <button
    class="btn btn-primary"
    @click="gotoContact">
    Find Your ProSupply
  </button>
</template>

<script>
  export default{
    methods:{
      gotoContact(){
        this.$router.push({name: 'Contact'})
      }
    }
  }
</script>