<template>
  <span>
    <select
      :name="'uomSelector' + product.product_id"
      class="form-select form-sleect-sm d-inline p-1"
      style="max-width: 3.5em;"
      v-model="activeUom"
      @change="emitData">
      <option
        v-for="o in options"
        :key="o.quantity"
        :value="o">
        {{ displayUom(o.uom) }}
      </option>
    </select>
    <div class="overflow-hidden">
      <div
        v-if="qtyMsg"
        class="animate__animated animate__fadeInLeft animate__faster">
        <small
          class="badge bg-theme-red">
          <i class="fa fa-info-circle"/>
          {{ qtyMsg }}
        </small>
      </div>
    </div>
  </span>
</template>

<script>
  import numeral from 'numeral'
  import { displayUom } from '../../../../../shared/ProductFunctions'

  export default{
    props: ['product'],
    data(){
      return{
        options: _.filter(this.product.eclipse_data.uom_pricing, {visible: true}),
        activeUom: null
      }
    },
    mounted(){this.setDefault()},
    methods:{
      setDefault(){
        this.activeUom = _.find(this.options, {uom: this.product.eclipse_data.uom})
        this.emitData()
      },
      emitData(){this.$emit('setActiveUom', this.activeUom)},
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      qtyMsg(){
        if(this.activeUom){
          const uoms = _.reject(this.product.eclipse_data.uom_pricing, {uom: 'hf'})
          const match = _.find(uoms, {uom: this.activeUom.uom})
          const min = _.minBy(uoms, 'quantity')
          const qty = match.quantity / min.quantity
          let uom
          switch(min.uom){
            case 'sf':
              uom = 'sqft'
              break
            default:
              uom = min.uom
          }
          if(qty > 1) return `1 ${displayUom(match.uom)} = ${qty % 1 == 0 ? numeral(qty).format('0,0') : numeral(qty).format('0,0.00')} ${uom}`
        }
      }
    }
  }
</script>

<!-- shipBranchAvail.quantity >= 10000 ? '0a' : '0,0' -->

<style scoped>
  .form-select{
    max-width: 4em;
    padding: 0px 5px;
    cursor: pointer;
  }
</style>