<template>
  <div class="animate__animated animate__fadeInRight animate__faster">
    <div class="input-group d-block">
      <button
        @click="$emit('toggleEdit')"
        class="btn btn-sm btn-link">
        <i class="fa fa-ban"/>
        Cancel
      </button>
      <button
        class="btn btn-sm btn-link"
        @click="$emit('saveProductRow')">
        <i class="fa fa-save"/>
        Save
      </button>
    </div>
  </div>
</template>