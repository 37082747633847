<template>
  <div>
    <Generate v-if="generate"/>
    <div
      v-else
      class="row animate__animated animate__fadeIn animate__faster">
      <!-- Header Controls -->
      <div class="col-md-12">
        <strong>Data Sources</strong>
        <div>
          <div class="btn-group">
            <button
              class="btn btn-primary btn-sm"
              :class="{active: dataSource == 'reorderPad'}"
              @click="setDataSource('reorderPad')">
              ReorderPad
            </button>
            <button
              class="btn btn-primary btn-sm"
              :class="{active: dataSource == 'favorites'}"
              @click="setDataSource('favorites')">
              Favorite Groups
            </button>
            <button
              class="btn btn-primary btn-sm"
              :class="{active: dataSource == 'search'}"
              @click="setDataSource('search')">
              Search
            </button>
          </div>
          <button
            class="btn btn-primary btn-sm float-end mx-2"
            @click="reset">
            Start Over
          </button>
          <div class="clearfix"/>
        </div>
        <hr>
      </div>
      <!-- Label List -->
      <List/>
      <!-- Content -->
      <div
        class="col-md-8"
        style="height: calc(100vh - 300px); overflow-y: scroll;">
        <ReorderPad v-if="dataSource == 'reorderPad'"/>
        <Favorites v-if="dataSource == 'favorites'"/>
        <Search v-if="dataSource == 'search'"/>
        <!-- No Data Souirce Selected -->
        <div
          v-if="!dataSource"
          class="row">
          <div class="col-md-12 text-center">
            <h4 class="text-muted mt-5">Select Data Source</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ReorderPad from './ReorerPad.vue'
  import List from './List.vue'
  import Favorites from './Favorites.vue'
  import Search from './Search.vue'
  import Generate from './Generate.vue'
  
  export default {
    data(){
      return{
        dataSource: null
      }
    },
    components:{
      ReorderPad,
      List,
      Favorites,
      Search,
      Generate
    },
    methods:{
      setDataSource(source){
        this.dataSource = source
      },
      reset(){
        this.dataSource = null
        this.$store.dispatch('clearBinLabelList')
      }
    },
    computed:{
      generate(){return this.$store.getters.generateStatus}
    }
  }
</script>