<template>
  <div class="modal fade" id="favViewModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div
        class="modal-content"
        v-if="activeFav">
        <!-- Fav Group Header Detail -->
        <div class="modal-header">
          <div class="modal-title">
            Group Name: {{activeFav.name}}
            <br>
            Description: {{activeFav.description || 'N/A'}}
            <br>
            <button
              class="btn btn-primary btn-sm"
              :disabled="favProducts.length == 0"
              @click="toggleEdit">
                <span v-if="!editing">
                  <i class="fa fa-pencil-alt mr-2"/>
                  Edit Products
                </span>
                <span v-else>
                  <i class="fa fa-ban mr-2"/>
                  Cancel
                </span>
            </button>
          </div>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- Contents -->
        <div class="modal-body">
          <div>
            <div class="mb-5">
              <div class="col-md-12">
                <div class="animate__animated animate__fadeIn animate__faster">
                  <!-- Product Views -->
                  <div>
                    <!-- Grid View -->
                    <div
                      v-if="favProducts.length > 0 && productView == 'grid' && !loading"
                      class="row">
                      <div
                        class="col-md-4 col-lg-3 d-none d-sm-block"
                        v-for="(p, i) in favProducts"
                        :key="p.eclipse_id">
                        <ProductCard
                          :product="p"
                          :browsingFavorites="true"
                          :editingFavorites="editing"
                          @deleteFavProduct="deleteProduct"/>
                      </div>
                    </div>
                    <!-- List View -->
                    <div
                      v-if="favProducts.length > 0 && productView == 'list' && !loading"
                      class="row">
                      <div
                        class="col-md-12 d-none d-sm-block"
                        v-for="(p, i) in favProducts"
                        :key="p.eclipse_id">
                        <ProductList
                          :product="p"
                          :browsingFavorites="true"
                          :editingFavorites="editing"
                          @deleteFavProduct="deleteProduct"/>
                      </div>
                    </div>
                    <!-- Mobile View -->
                    <div 
                      v-for="(p, i) in favProducts"
                      :key="p.product_id"
                      class="col-12 d-block d-sm-none">
                      <ProductMobile
                        :product="p"
                        :browsingFavorites="true"
                        :editingFavorites="editing"
                        @deleteFavProduct="deleteProduct"/>
                    </div>
                    <!-- Spinner -->
                    <div
                      v-if="loading || showMoreLoading"
                      class="row">
                      <div class="col-md-12 text-center">
                        <Spinner/>
                      </div>
                    </div>
                    <div v-if="favProducts.length == 0 && !loading">
                      <div>
                        <h3 class="text-center text-muted">
                          No Products
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProductCard from '../views/product/ProductCard.vue'
  import ProductList from '../views/product/ProductList.vue'
  import ProductMobile from '../views/product/ProductMobile.vue'
  import Spinner from '../../global/Spinner.vue'
  import ShowMore from '../views/product/ShowMore.vue'

  export default {
    components:{
      ProductCard,
      ProductList,
      ProductMobile,
      Spinner,
      ShowMore
    },
    data(){
      return{
        editing: false
      }
    },
    watch:{
      activeFav(newFav){
        if(newFav){
          this.$store.dispatch('getFavProducts')
        }
      },
      loading(newVal){
        if(newVal) this.editing = false
      }
    },
    methods:{
      closeGroup(){
        this.$store.dispatch('unsetActiveFav')
        this.$emit('refresh')
      },
      deleteProduct(eclipse_id){
        let detail = {
          id: this.activeFav.id,
          eclipse_id
        }
        this.$store.dispatch('deleteFavProduct', detail)
      },
      toggleEdit(){
        this.editing = !this.editing
      }
    },
    computed:{
      activeFav(){return this.$store.getters.getActiveFav || null},
      favProducts(){return _.orderBy(this.$store.getters.favProducts, f => f.description, 'asc')},
      productView(){return this.$store.getters.productView || null},
      loading(){return this.$store.getters.favLoadStatus},
      showMoreLoading(){return this.$store.getters.showMoreLoadState}
    }
  }
</script>

<style scoped>
  .view-switcher{
    position: relative;
    top: 20px;
  }
  .edit-btn{
    position: relative;
    top: 20px;
    right: 20px;
  }
</style>