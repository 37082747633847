<template>
  <div
    class="row"
    style="height: 100vh;">
    <div class="col-md-12 p-4">
      <h4>Admin Panel</h4>
      <hr>
      <ul class="p-0 nav-list">
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'dashboard'}"
            href="javascript: void(0);"
            @click="setView('dashboard')">
            <i class="fa fa-chart-line"/>
            Dashboard
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'auth_tms'}"
            href="javascript: void(0);"
            @click="setView('auth_tms')">
            <i class="fa fa-user-secret"/>
            Authorized TM's
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'category_images'}"
            href="javascript: void(0);"
            @click="setView('category_images')">
            <i class="fa fa-sitemap"/>
            Category Images
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'ppf_exclusions'}"
            href="javascript: void(0);"
            @click="setView('ppf_exclusions')">
            <i class="fa fa-truck"/>
            PPF Exclusions
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'promos'}"
            href="javascript: void(0);"
            @click="setView('promos')">
            <i class="fa fa-tags"/>
            Promos
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'digital_assets'}"
            href="javascript: void(0);"
            @click="setView('digital_assets')">
            <i class="fa fa-file-alt"/>
            Digital Assets
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'settings'}"
            href="javascript: void(0);"
            @click="setView('settings')">
            <i class="fa fa-cogs"/>
            Settings
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'slides'}"
            href="javascript: void(0);"
            @click="setView('slides')">
            <i class="fa fa-image"/>
            Slides
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'tm_details'}"
            href="javascript: void(0);"
            @click="setView('tm_details')">
            <i class="fa fa-user-tag"/>
            TM Details
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            :class="{active: view == 'vendors'}"
            href="javascript: void(0);"
            @click="setView('vendors')">
            <i class="fa fa-building"/>
            Vendors
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    methods:{
      setView(view){
        this.$store.dispatch('setAdminView', view)
      }
    },
    computed:{
      view(){return this.$store.getters.adminView}
    }
  }
</script>

<style scoped>
  .nav-link{
    padding: 0px;
    color: white;
  }
  .nav-link:hover{
    color: var(--bs-success);
  }
  .nav-list{
    list-style-type: none;
    font-weight: 600;
    line-height: 2em;
  }
  .nav-section{
    border-bottom: 1px solid var(--bs-primary);
  }
  .active{
    color: var(--bs-success);
  }
</style>

