<template>
  <div>
    <CadPolicy v-if="company == 'Lancaster Canada'"/>
    <UsPolicy v-if="company == 'Lancaster US'"/>
    <EjwPolicy v-if="company == 'EJ Welch'"/>
    <CheneyPolicy v-if="company == 'Cheney'"/>
  </div>
</template>

<script>
  import CadPolicy from './CadPolicy.vue'
  import UsPolicy from './UsPolicy.vue'
  import EjwPolicy from './EjwPolicy.vue'
  import CheneyPolicy from './CheneyPolicy.vue'
  
  export default {
    components:{
      CadPolicy,
      UsPolicy,
      EjwPolicy,
      CheneyPolicy
    },
    computed:{
      company(){return this.$store.getters.sessionCompany}
    }
  }
</script>