<template>
  <div>
    <div class="row d-none d-md-block">
      <div class="col-md-12">
        <div :style="showHeader"/>
      </div>
    </div>
    <!-- mobile show floor image -->
    <div
      class="d-block d-md-none"
      :style="buyingShow"/>
    <div class="row overlap">
      <div
        class="col-lg-4 col-md col-12 text-center p-5"
        style="z-index: 1;">
        <div class="outer-circle">
          <div
            class="circle-div shadow"
            :style="img1">
          </div>
        </div>
        <h4 class="text-event mt-4">NETWORKING</h4>
        <div class="px-5">
          Lancaster’s Buying Shows will allow customers to meet face to face with over 100 exhibitors and see new and exciting products hitting the market. In addition, get inspired by discussing ideas and business practices with other retailers.
        </div>
      </div>
      <div
        class="col-lg-4 col-md text-center p-5"
        style="z-index: 1;">
        <div class="outer-circle">
          <div
            class="circle-div shadow"
            :style="img2">
          </div>
        </div>
        <h4 class="text-event mt-4">DEEP DISCOUNTS</h4>
        <div class="px-5">
          Lancaster’s Buying Shows offers tremendous deals to give customers a competitive edge to grow their business in the coming year.
        </div>
      </div>
      <div
        class="col-lg-4 col-md text-center p-5"
        style="z-index: 1;">
        <div class="outer-circle">
          <div
            class="circle-div shadow"
            :style="img3">
          </div>
        </div>
        <h4 class="text-event mt-4">CASH SPIFFS</h4>
        <div class="px-5">
          Many exhibitors offer spiffs as an additional incentive, giving customers the opportunity to leave with cash in hand or off invoice. Over $20,000 USD in prizes given away and over $100,000 USD in cash spiffs given out.
        </div>
      </div>
    </div>
    <div class="row my-5 p-5">
      <div class="col-lg-4 col-md-6 text-center d-none d-lg-block">
        <div :style="[show2018, {height: '400px'}]"/>
        <strong>2018 ORLANDO FLORIDA</strong>
        <div>
          <small>SeaWorld</small>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 text-center d-none d-lg-block">
        <div :style="[show2019, {height: '400px'}]"/>
        <strong>2019 ORLANDO FLORIDA</strong>
        <div>
          <small>Cirque De Lancaster Buying Show</small>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 text-center d-none d-lg-block">
        <div :style="[show2020, {height: '400px'}]"/>
        <strong>2020 ORLANDO FLORIDA</strong>
        <div>
          <small>The Magic of Lancaster</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        showHeader: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/showHeader.jpg')})`,
          height: '300px',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }, 
        marketing: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/dealer/dealerTab2.jpg')})`,
          ...this.bgProperties()
        },
        img1: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/event1.jpg')})`,
          ...this.bgProperties()
        },
        img2: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/event2.jpg')})`,
          ...this.bgProperties()
        },
        img3: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/event3.jpg')})`,
          ...this.bgProperties()
        },
        buyingShow: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/buyingShows.jpg')})`,
          height: '250px',
          backgroundPosition: 'center'
        },
        show2018: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/2019Show.jpg')})`,
          ...this.bgProperties()
        },
        show2019: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/2018Show.jpg')})`,
          ...this.bgProperties()
        },
        show2020: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/2020Show.jpg')})`,
          ...this.bgProperties()
        }
      }
    },
    methods:{
      bgProperties(){
        return {
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          border: '5px solid white'
        }
      }
    }
  }
</script>

<style scoped>
  .circle-div{
    height: 300px;
    width: 300px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  .outer-circle{
    border: 1px solid var(--bs-event);
    border-radius: 50%;
    height: 304px;
    width: 304px;
    overflow: hidden;
    margin: 0 auto;
  }
  .overlap{
    margin-top: -8em;
  }
</style>