<template>
  <div class="container">
    <div class="row my-5 p-5">
      <div class="col-lg-4 col-md-12 text-center my-3">
        <button
          class="btn btn-outline-contact w-100"
          style="height:80px; max-width:350px;"
          @click="setForm('customer')"
          data-bs-toggle="modal"
          data-bs-target="#ContactModal">
          BECOME A CUSTOMER
        </button>
        <div class="px-5 py-2">
          <strong>Why wait? Sign-up for FREE! We bring you the right products, at the right price, exactly when you need it. We are just a click or call away.</strong>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 text-center my-3">
        <button 
          class="btn btn-outline-contact w-100"
          style="height:80px; max-width:350px;"
          @click="setForm('sell')"
          data-bs-toggle="modal"
          data-bs-target="#ContactModal">
          BECOME A SUPPLIER
        </button>
        <div class="px-5 py-2">
          <strong>Lancaster is the logistics expert you've been searching for. We bring value to our partners by instantly giving you access to thousands of retail locations internationally, consolidating your accounts and increasing your efficiency.</strong>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 text-center my-3">
        <button
          class="btn btn-outline-contact w-100"
          style="height:80px; max-width:350px;"
          @click="setForm('join')"
          data-bs-toggle="modal"
          data-bs-target="#ContactModal">
          JOIN THE TEAM
        </button>
        <div class="px-5 py-2">
          <strong>Thank you for your interest. Lancaster is always looking for fantastic people to join our team!</strong>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
  export default {
    methods:{
      bgProperties(){
        return {
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }
      },
      setForm(formName){
        this.$emit('setForm', formName)
      }
    }
  }
</script>
