<template>
  <section>
    <div class="row">
      <div class="col-lg-5 col-md-12 bg-primary">
        <div class="header-wrapper">
          <div class="slim-title">YOUR PREMIER</div>
          <div class="fat-title">
            PAINT SUNDRY
            <br>
            DISTRIBUTOR
          </div>
          <div>
            <Login/>
          </div>
        </div>
      </div>
      <Carousel class="col-lg-7 p-0 d-none d-lg-block"/>
    </div>
  </section>
</template>

<script>
  import Carousel from './Carousel.vue'
  import Login from '../app/Login.vue'

  export default {
    components:{
      Carousel,
      Login
    }
  }
</script>

<style scoped>
  .header-wrapper{
    color: white;
    font-size: 40px;
    height: 500px;
    overflow: hidden;
    font-size:40px;
  }
  @media(min-width: 992px){
    .header-wrapper{
      padding: 60px 45px;
    }
  }
  @media(min-width: 1400px){
    .header-wrapper{
      padding: 60px 90px;
    }
  }
  @media(max-width: 992px){
    .header-wrapper{
      padding: 60px 20px;
    }
  }
  /* @media(max-width: 1182){
    .header-wrapper{
      padding: 20px 100px;
    }
  } */
  .header-wrapper > .slim-title {
    font-weight: 200;
    font-size: 35px;
  }
  .header-wrapper > .fat-title {
    font-weight: 500;
    font-size: 35px;
  }
</style>