<template>
  <div class="modal fade" id="ContactModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{activeForm ? activeForm.title : null}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form
          v-if="activeForm"
          name="contactForm"
          @submit.prevent="submit"
          style="position:relative;">
          <div class="modal-body">
            <!-- Forms -->
            <CustomerForm
              v-if="!confirm && activeForm.formName == 'customer'"
              :form="form"/>
            <JoinForm
              v-if="!confirm && activeForm.formName == 'join'"
              :form="form"/>
            <SellForm
              v-if="!confirm && activeForm.formName == 'sell'"
              :form="form"/>
            <!-- Confirmation -->
            <div v-if="confirm">
              <div class="text-center text-success my-5 animate__animated animate__fadeIn">
                <h4>Form Submitted!</h4>
                <small>We'll be in touch shortly.</small>
              </div>
            </div>
          </div>
          <div
            class="modal-footer"
            v-if="!confirm">
            <button type="submit" class="btn btn-primary">
              <i class="fa fa-paper-plane"/>
              Submit
            </button>
          </div>
          <!-- overlay and spinner -->
          <div 
            v-if="submitting"
            class="overlay">
            <Spinner/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomerForm from './forms/CustomerForm.vue'
  import JoinForm from './forms/JoinForm.vue'
  import SellForm from './forms/SellForm.vue'
  import GeneralForm from './forms/GeneralForm.vue'
  import Spinner from '../../../global/Spinner.vue'

  export default {
    props:['activeForm', 'activeTitle'],
    data(){
      return{
        form: {},
        submitting: false,
        confirm: false
      }
    },
    components:{
      CustomerForm,
      JoinForm,
      SellForm,
      GeneralForm,
      Spinner
    },
    methods:{
      completeForm(){
        this.form = {}
        this.submitting = false
        this.confirm = true
        setTimeout(function(){
          this.confirm = false
        }.bind(this), 5000)
      },
      submit(){
        this.form.activeForm = this.activeForm.formName
        this.submitting = true
        axios.post('/api/v1/public/contact/submit_contact_form', this.form).then(res=>{
          if(res.status == 200){
            this.completeForm()
          }
        })
      }
    }
  }
</script>

<style scoped>
  .overlay{
    background: rgb(211,211,211, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
  }
  .spinner{
    position: absolute;
    top: 35%;
    margin: 0 auto;
    left:45%;
  }
</style>