<template>
  <div
    id="bottomNav"
    class="text-light"
    style="background: var(--bs-primary); z-index:2; font-size:20px;">
    <div class="row">
      <!-- Home -->
      <div
        class="col text-center"
        style="cursor:pointer;"
        @click="goto('PrivateHome')">
        <i class="fa fa-home"/>
      </div>
      <!-- Cart -->
      <div  
        v-if="!cartLoading"
        class="col text-center position-relative"
        style="cursor:pointer;"
        :style="user.estore_noorder ? 'pointer-events:none;opacity:0.25;' : ''"
        id="navCartMobile"
        @click="goto('Cart')">
        <i class="fa fa-shopping-cart"/>
        <small>({{numeral(cartProductCount).format('0,0')}})</small>
        <!-- Vshow Cart indicator -->
        <div
          v-if="vShowCart"
          class="badge bg-success v-show-indicator">
          <i class="fa fa-laptop"/>
          {{vShowData ? vShowData.title : 'Virtual Show'}}
        </div>
      </div>
      <div
        v-else
        class="col text-center">
        <Spinner style="color: white !important;"/>
      </div>
      <!-- Camera -->
      <div  
        class="col text-center camera"
        id="mobileCamera"
        @click="goto('Scanner')">
        <img
          :src="env.includes('EJW') ? ejwCamera : camera"
          class="img-fluid camera-img">
      </div>
      <!-- Select Ship To -->
      <div
        class="col text-center"
        style="cursor:pointer;"
        :data-bs-toggle="!internalRoles.includes(user.role) ? 'modal' : null"
        :data-bs-target="!internalRoles.includes(user.role) ? '#shipToSelector' : null"
        @click="gotoTmDashboard()">
        <i class="fa fa-map-marker-alt"/>
      </div>
      <!-- User Menu -->
      <div
        class="col text-center"
        style="cursor:pointer;"
        id="userMenuMobile"
        @click="goto('MyAccount')">
        <i class="fa fa-user"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../global/Spinner.vue'
  import {internalRoles} from '../../../shared/roles'
  
  export default {
    inject: ['env'],
    data(){
      return{
        camera: require('../../../../assets/images/lan/LanCam.png'),
        ejwCamera: require('../../../../assets/images/ejw/ejwCameraIcon.png'),
        internalRoles
      }
    },
    components:{
      Spinner
    },
    methods:{
      goto(view){
        this.$router.push({name: view})
        if(internalRoles.includes(this.user.role) && view == 'PrivateHome'){
          this.$store.dispatch('setRepDashboardView', false)
        }
      },
      toggleRightMenu(view){this.$store.dispatch('toggleRightMenu', view)},
      gotoTmDashboard(){
        if(!internalRoles.includes(this.user.role)){
          return
        }else{
          this.$router.push({name: 'PrivateHome'})
          this.$store.dispatch('setRepDashboardView', true)
        }
      }
    },
    computed:{
      cartProductCount(){return this.$store.getters.cartProductCount},
      cartLoading(){return this.$store.getters.cartLoading},
      hidePricing(){return this.$store.getters.hidePricing},
      user(){return this.$store.getters.user},
      cartLoading(){return this.$store.getters.cartLoading},
      vShowAllowed(){return this.$store.getters.vShowAllowed},
      vShowData(){
        if(this.vShowAllowed){
          return this.$store.getters.vShowDates
        }
        return null
      },
      vShowCart(){return this.$store.getters.vShowCart},
      user(){return this.$store.getters.user}
    }
  }
</script>

<style scoped>
  #bottomNav{
    height:75px;
    position: fixed;
    width: 100vw;
    bottom: 0px;
    padding: 10px 0px 0px 0px;
  }
  .camera{
    cursor: pointer;
    position: relative;
    bottom: 25px;
    background: var(--bs-primary);
    border-radius: 50%;
    padding: 12px;
  }
  .camera-img{
    width: 45px;
  }
  .v-show-indicator{
    font-size: 10px;
    position: absolute;
    left: 0px;
    padding: 2px;
    width: 100%;
    top: 28px;
  }
</style>