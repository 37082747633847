<template>
  <div class="d-grid">
    <button
      class="btn btn-primary btn-sm"
      @click="addToList">
      <i class="fa fa-plus"/>
      Add to List
    </button>
  </div>
</template>

<script>
  export default {
    props:['product'],
    methods:{
      addToList(){
        this.$store.dispatch('addToBinLabelList', this.product)
      }
    }
  }
</script>