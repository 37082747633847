import store from '../stores/store'

export const branchName = (branchDetail) => {
  let id
  const branchData = store.getters.branches
  if(_.isObject(branchDetail)){
    id = branchDetail.branch_id
  }else{
    id = branchDetail
  }
  const match = _.find(branchData, {branch_id: id})
  if(match){
    return `${match.city}, ${match.state} (${match.branch_id})`
  }
  return _.isObject(branchDetail) ? `${branchDetail.branch_name}(${branchDetail.branch_id})` : id
}