<template>
  <div class="spinner-border"
    role="status"
    :class="color ? color : 'text-primary'"/>
</template>

<script>
  export default {
    props: ['color']
  }
</script>

