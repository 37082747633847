<template>
  <div class="container">

    <div class="row mt-5 py-4">
      <div class="col-md-12 text-center">
        <div class="position-relative">
          <img
            class="img-fluid"
            :src="img1">
          <button
            class="btn btn-primary contact"
            data-bs-toggle="modal"
            data-bs-target="#wholesaleModal">
            Contact us Now!
          </button>
        </div>
        <div>
          <img
          class="img-fluid"
          :src="img2">
        </div>
        <div>
          <img
          class="img-fluid"
          :src="img3">
        </div>
        <div>
          <img
          class="img-fluid"
          :src="img4">
        </div>
        <div class="position-relative">
          <img
          class="img-fluid"
          :src="img5">
          <button
            class="btn btn-primary contact"
            data-bs-toggle="modal"
            data-bs-target="#wholesaleModal">
            Contact us Now!
          </button>
        </div>
      </div>
      <div class="col-md-12 text-center mt-5">
        <div>
          <strong>For more information or to place orders:</strong>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 col-12">
            <h3>
              <i class="fa fa-phone"/>
              <a
                class="ms-1"
                href="tel:314-888-6328">
                314-888-6328
              </a>
            </h3>
          </div>
          <div class="col-md-6 col-12">
            <h3>
              <i class="far fa-envelope"/>
              <a
                class="ms-1"
                href="mailto:wholesale@ejwelch.com">
                wholesale@ejwelch.com
              </a>
            </h3>
          </div>
        </div>
      </div>
      <ContactModal/>
    </div>
  </div>
</template>

<script>
  import ContactModal from './ContactModal.vue'

  export default{
    data(){
      return{
        img1: 'https://merit-production.s3.amazonaws.com/ejwEstore/wholesale/01.jpg',
        img2: 'https://merit-production.s3.amazonaws.com/ejwEstore/wholesale/03.jpg',
        img3: 'https://merit-production.s3.amazonaws.com/ejwEstore/wholesale/05.jpg',
        img4: 'https://merit-production.s3.amazonaws.com/ejwEstore/wholesale/07.gif',
        img5: 'https://merit-production.s3.amazonaws.com/ejwEstore/wholesale/09.jpg'
      }
    },
    components:{
      ContactModal
    }
  }
</script>

<style scoped>
  .wholesale{
    position: absolute;
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .contact{
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    border: 2px solid white;
  }
</style>