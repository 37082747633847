<template>
  <div v-if="newContacts.length > 0" class="mb-3 col-12">
    <span class="text-muted">Recently Added Users:</span>
    <div class="py-2">
      <ContactCard v-for="c,index in newContacts" :key="index" :contact="c" />
    </div>
    <span class="text-muted small"><i class="fa fa-info-circle"></i> On page refresh, recently added users will appear under Manage My Team.</span>
  </div>
</template>
<script>
  import ContactCard from './ContactCard.vue'
  export default{
    name: 'NewContactList',
    props:['newContacts'],
    components:{
      ContactCard
    },
    data(){
      return{
        
      }
    }
  }
</script>