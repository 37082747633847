<template>
  <div>
    <div
      v-if="!loading && productSets.length"
      class="animate__animated animate__fadeInUp animate__faster">
      <div>
        <h5>Similar Products</h5>
      </div>
      <div
        v-for="(ps, i) in productSets"
        :key="i"
        class="row mt-3">
        <template v-if="ps">
          <SimilarProductCard
            v-for="(p, i) in ps"
            :key="i"
            :product="p"/>
        </template>
      </div>
    </div>
    <div
      v-if="loading"
      class="row">
      <div class="col-md-12 text-center mt-3">
        <Spinner :loading="loading"/>
      </div>
    </div>
  </div>
</template>

<script>
  import SimilarProductCard from './SimilarProductCard.vue'
  import Spinner from '../../../../global/Spinner.vue'

  export default{
    props: ['product'],
    mounted(){
      this.getSimilar()
    },
    components:{
      SimilarProductCard,
      Spinner
    },
    methods:{
      getSimilar(){
        const data = {
          query: {
            id: this.$route.params.id,
            sub_category: this.product.sub_category,
            priceline: this.product.priceline,
            query: this.$route.query ? this.$route.query.query : null
          }
        }
        this.$store.dispatch('getSimilarProducts', data)
      }
    },
    computed:{
      productSets(){return _.chunk(this.$store.getters.similarProducts, 6)},
      loading(){return this.$store.getters.similarLoadState}
    }
  }
</script>