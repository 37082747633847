<template>
  <div
    class="map"
    :style="mapStyle">
    <div class="wrapper">
      <h1 class="text-white">With Branch locations across 13 states, you'll enjoy the support whever you need it.</h1>
    </div>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        mapStyle:{
          backgroundImage: `url(${require('../../../../../assets/images/ejw/about/Website_Map.png')})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }
      }
    }
  }
</script>

<style scoped>
  .map{
    height: 450px;
  }
  .wrapper{
    padding: 75px;
  }

  @media(min-width: 992px){
    .wrapper{
      max-width: 50vw;
    }
  }
</style>