import store from '../stores/store'

// Determine if the promo is allowed to be seen by the user
export const promoAllowed = function(promoProduct){

  if(!promoProduct?.active){
    return false
  }
  // Initialize
  const $store = store
  const unlockCodes = $store.getters.unlockCodes
  const homeBranch = $store.getters.homeBranch
  const settings = $store.getters.settings
  const promoReceive = $store.getters.promoReceive
  const promo = promoProduct?.promo
  const authBranchPass = _.map(promoProduct.auth_branch, ab => ab.sell_branch).includes(homeBranch)
  const priceTypes = $store.getters.getEntity.priceTypes
  // Handle security tags
  const permitted = ()=>{
    if(promo.security_tags){
      let status = false
      const tags = promo.security_tags.split(' ')
      if(priceTypes && tags){
        tags.forEach(t => {
          if(priceTypes.includes(t.toUpperCase())){
            status = true
          }
        })
      }
      return status
    }
    return true
  }
  if(settings.promotions && promo && authBranchPass && promoReceive && permitted()){
    if(promo.hidden){
      if(_.includes(unlockCodes, promo.promo_code)){
        return true
      }
      return false
    }
    return true
  }
}

// Check if product is a promo product
export const promoProductV2 = (product) => {
  return product?.promo
}

// Indicate if the promo product carries qty Breaks
export const promoProductBreaks = function(product){
  // Check for promo break points
  if(product.promo?.qty_break3 || product.promo?.qty_break2 || product.promo?.qty_break1){
    return true
  }
  return false
}