<template>
  <div>
    <ContactDetail
      :branch="branch"
      :company="company"/>
  </div>
</template>

<script>
  import ContactDetail from './ContactDetail.vue'
  import axios from 'axios'

  export default{
    inject: ['env'],
    data(){
      return{
        branch:null
      }
    },
    components:{
      ContactDetail
    },
    mounted(){
      this.getBranch(this.branchID)
    },
    methods:{
      getBranch(id){
        const params = {branch_id: id}
        axios.get('/api/v1/public/branches/get_branch', {params}).then(res =>{
          this.branch = res.data.branch
        })
      }
    },
    computed:{
      branchID(){return this.$store.getters.homeBranch},
      company(){return this.$store.getters.sessionCompany}
    }
  }
</script>