<template>
  <div class="row bg-primary mt-3 p-3 px-5 footer auto-height">
    <div class="col-md-12">
      <!-- Top -->
      <div class="row">
        <div class="col-md-3 col-12 d-none d-md-block">
          <router-link to="/">
            <Logo/>
          </router-link>
        </div>
        <div class="col-md-9 col-12">
          <div class="float-end">
            <a
              href="javascript:void(0)"
              class="btn btn-link footer-link"
              @click="goto('About')">
              About Us
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-link footer-link"
              @click="goto('Advantage')">
              Advantage
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-link footer-link"
              @click="goto('Brands')">
              Brands
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-link footer-link"
              @click="goto('Contact')">
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <!-- Middle -->
      <div class="row">
        <div class="col-md-12">
          <hr style="color:white; height:2px; opacity:1;">
          <div class="row">
            <!-- Left Side -->
            <div class="col-md-6 my-3">
              Already a EJ Welch Dealer? Log in to access orders, shipping and more.
              <br>  
              <button
                class="btn btn-outline-light mt-2"
                style="width:200px;"
                @click="gotoLogin">
                <i class="fa fa-sign-in-alt"/>
                Login
              </button>
            </div>
            <!-- Right Side -->
            <div class="col-md-6">
              <h4>Stay connected, get inspired and learn more.</h4>
              <a
                href="https://www.facebook.com/ejwelchco"
                target="_blank"
                class="text-white">
                <i class="fab fa-facebook fa-2x px-2"/>
              </a>
              <a
                href="https://www.linkedin.com/company/1170922/"
                target="_blank"
                class="text-white">
                <i class="fab fa-linkedin fa-2x px-2"/>
              </a>
              <a
                href="https://www.instagram.com/ejwelchco/"
                target="_blank"
                class="text-white">
                <i class="fab fa-instagram fa-2x px-2"/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Bottom -->
      <div class="row">
        <div class="col-md-12">
          <hr style="color:white; height:2px; opacity:1;">
          <div class="row">
            <div class="col-md-12">
              Copyright © 2023 EJ Welch Company. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Logo from '../../../global/ejw/Logo.vue'
  
  export default {
    data(){
      return{
        logo: require('../../../../../assets/images/lan/LogoColor.png')
      }
    },
    components:{
      Logo
    },
    methods:{
      goto(routeName){
        window.scrollTo(0,0)
        this.$router.push({name: routeName})
      },
      gotoLogin(){
        this.$router.push({name: 'PublicHome'})
        let el = document.getElementById('router-view')
        el.scrollTo(0,0)
      }
    },
    computed:{
      route(){
        return this.$router.currentRoute.value.path
      }
    }
  }
</script>

<style scoped>
  .footer{
    color:white;
  }
  @media(max-width: 590px){
    .auto-height{
      height: 450px;
    }
  }
  @media(min-width: 591px){
    .auto-height{
      height: 300px;
    }
  }
  .footer-link{
    display: inline-block;
    color:white;
    text-decoration: none;
  }
</style>