<template>
  <div>
    <div class="row benefits">
      <div class="col-md-12 text-center mt-5">
        <h2
          class="text-success"
          style="font-weight: 400;">
          ONLINE BENEFITS
        </h2>
      </div>
      <div class="col-md-4 col text-center">
        <div class="px-5 pb-2">
          <i
            class="fa fa-users my-3"
            style="font-size:50px;"/>
          <h5 class="text-success">MULTIPLE USERS</h5>
          <p>Whether you’re a team of two or twenty, we can customize your experience so each user can manage orders under a single account.</p>
        </div>
      </div>
      <div class="col-md-4 col text-center">
        <div class="px-5 pb-2">
          <i
            class="fa fa-box my-3"
            style="font-size:50px;"/>
          <h5 class="text-success">AVAILABLE 24/7</h5>
          <p>Flexibility to check on quotes, open orders and current invoices. Conveniently search by your PO or release number.</p>
        </div>
      </div>
      <div class="col-md-4 col text-center">
        <div class="px-5 pb-2">
          <i
            class="fa fa-sync my-3"
            style="font-size:50px;"/>
          <h5 class="text-success">REORDER PAD</h5>
          <p>If you have items that you order consistently, simply click the reorder button below your previous order to have the same set of products delivered to your location.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .benefits{
    background: rgb(196, 219, 184, 0.5);
  }
</style>