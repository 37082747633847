<template>
  <form
    name="browseSearch"
    class="input-group search my-4"
    @submit.prevent="submit">
    <input
      type="text"
      class="form-control"
      placeholder="Search Products..."
      required
      v-model="query">
    <button class="btn btn-primary">
      <i class="fa fa-search"/>
    </button>
  </form>
</template>

<script>
  export default {
    data(){
      return{
        query:null
      }
    },
    mounted(){
      if(this.cameraSearch){
        this.query = this.cameraSearch.query
        this.submit()
        this.$store.dispatch('clearCamera')
      }
    },
    methods:{
      submit(){
        this.$router.push({
          name: 'VirtualShow',
          query:{
            query: this.query,
            action: 'virtualShowSearch'
          }
        })
      }
    },
    computed:{
      cameraSearch(){
        return this.$store.getters.vShowCameraSearch
      }
    }
  }
</script>