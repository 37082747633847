<template>
  <div v-if="product.stock_status == 'NonStock'">
    <small class="text-muted me-1">Stock Status:</small>
    <span
      class="badge bg-warning"
      style="padding: 1px 5px;">
      {{ product.stock_status }}
    </span>
  </div>
</template>

<script>
  export default{
    props: ['product']
  }
</script>