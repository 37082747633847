<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center my-3">
        <img
          :src="logo"
          class="img-fluid"
          style="max-height: 75px;">
        <div class="my-3">
          To enroll in BillTrust, find the enrollment token located in the upper right corner of the invoice sent to you by email or mail. Please note that enrollment tokens are not included on any eStore order, invoice, or email. If you have trouble locating your enrollment token, contact the accounting department for assistance.
        </div>
        <div class="my-3">
          BillTrust and eStore are synchronized except when new orders or payments are made, in which case updates will appear within 24 hours or less. ACH payments and all major credit cards are accepted. If you are already a BillTrust customer, you may continue using your existing link or access it via the button below.
        </div>
        <div class="my-3">
          Please contact Accounting if you need assistance.
          <a :href="'mailto:' + email">{{ email }}</a>
        </div>
        <a
          class="btn btn-primary"
          :href="link"
          target="_blank">
          Access BillTrust Now!
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    inject:['env'],
    data(){
      return{
        lan: {
          link: 'https://secure2.billtrust.com/lancasterco/ig/signin',
          email: 'accounting@lancasterco.com'
        },
        ejw:{
          link: 'https://secure2.billtrust.com/ejwelch/ig/signin',
          email: 'accounting@ejwelch.com'
        },
        logo: require('../../../../../assets/images/logos/billtrust-logo.png')
      }
    },
    computed:{
      email(){return this.env.includes('EJW') ? this.ejw.email : this.lan.email},
      link(){return this.env.includes('EJW') ? this.ejw.link : this.lan.link}
    }
  }
</script>