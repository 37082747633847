<template>
  <div class="row">
    <div class="col-md-12">
      <a
        :href="cheneyGuide"
        class="btn btn-sm btn-primary float-end m-1"
        target="_blank">
        Download Guide
        <i class="fa fa-download"/>
      </a>
      <a
        :href="cheneyVideo"
        class="btn btn-sm btn-primary float-end m-1"
        target="_blank">
        Take Video Tour
        <i class="fa fa-play"/>
      </a>
    </div>
    <!-- Question 1 -->
    <div class="col-md-12 my-3">
      <strong>Q: What are your business hours?</strong>
      <p>
        Our Showroom, ProSupply, and Warehouse are open 7 am-4 pm Monday through Friday.
      </p>
      <p>
        Showroom – It is highly recommended to set up an appointment to ensure you receive the best service possible. Our team will be happy to assist you with scheduling an appointment. 
      </p>
    </div>
    <!-- Question 2 -->
    <div class="col-md-12 my-3">
      <strong>Q: Where do I pick up my material?</strong>
      <p>
        You can pick up your material at our warehouse located at 10901 Elm Creek Blvd N Maple Grove MN 55369 or show room located at 4402 2nd Ave, Ste 15, Fargo, ND 58103. We have a loading dock on the south side of both buildings where we can easily load your material. Our last pick-up time is at 3:45 pm, so please make sure to plan accordingly. 
      </p>
    </div>
    <!-- Question 3 -->
    <div class="col-md-12 my-3">
      <strong>Q: Do you provide installation?</strong>
      <p>
        Cheney Vrooman does not provide installation services however, we can provide you with a list of recommended installers for your specific needs.
      </p>
    </div>
    <!-- Question 4 -->
    <div class="col-md-12 my-3">
      <strong>Q: Can you deliver my products?</strong>
      <p>
        Yes, we offer delivery services for our products. However, the cost of delivery will depend on the size and weight of the product, as well as your location. We can provide you with an LTL (less than truckload) freight quote for shipping your product. Please refer to our policies and procedures for the most up-to-date freight guidelines.
      </p>
    </div>
    <!-- Question 5 -->
    <div class="col-md-12 my-3">
      <strong>Q: How do I order samples?</strong>
      <p>
        To order samples, please reach out to your sales representative or customer service and they will be happy to assist you.
      </p>
    </div>
    <!-- Question 6 -->
    <div class="col-md-12 my-3">
      <strong>Q: Do you sell tools?</strong>
      <p>
        Our Prosupply carries a wide variety of tools that are needed for various types of installations.
      </p>
    </div>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        cheneyGuide: 'https://merit-production.s3.amazonaws.com/ejwEstore/pdf/Cheney_eStore_Guide.pdf',
        cheneyVideo: 'https://merit-production.s3.amazonaws.com/ejwEstore/video/CheneyVroomanEstoreCustomerTour.mp4'
      }
    }
  }
</script>