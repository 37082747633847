<template>
  <div style="overflow: hidden;">
    <div class="row animate__animated animate__fadeIn animate__faster">
      <!-- Nav Tabs -->
      <div>
        <strong class="text-muted">Price Branches</strong>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li
            v-for="p in priceBranches"
            :key="p"  
            class="nav-item"
            role="presentation">
            <button
              class="nav-link"
              :class="p == activePriceBranch ? 'active' : null"
              type="button"
              role="tab"
              @click="setActivePriceBranch(p)">
              {{ p }}
            </button>
          </li>
        </ul>
      </div>
      <!-- Content -->
      <div class="row overflow-hidden">
        <!-- Actions -->
        <div
        v-if="!showForm && !showUploadForm && activePriceBranch"
          class="col-md-12 mt-3 animate__animated animate__fadeInRight animate__faster">
          <!-- Bulk Delete -->
          <div
            v-if="activePriceBranchProducts"
            class="float-start">
            <div class="form-check form-switch">
              <label class="form-check-label text-muted">Bulk Delete Mode</label>
              <input
                class="form-check-input"
                style="cursor: pointer; float: none !important;"
                v-model="deleteMode"
                checked="false"
                type="checkbox">
            </div>
            <button
              v-if="deleteMode & !deleteAllMode"
              class="btn btn-sm btn-link animate__animated animate__fadeInLeft animate__faster"
              @click="toggleDeleteAllMode">
              <i class="fa fa-trash"/>
              Delete All
            </button>
            <button
              v-if="deleteMode & !deleteAllMode"
              class="btn btn-sm btn-link animate__animated animate__fadeInLeft animate__faster"
              :disabled="deleteProducts.length == 0"
              @click="bulkDelete">
              <i class="fa fa-trash"/>
              Delete Selected
            </button>
            <div
              class="btn-group animate__animated animate__bounceIn animate__faster"
              v-if="deleteAllMode">
              <span>Delete All?</span>
              <button
                class="btn btn-link btn-sm"
                @click="deleteAll">
                <i class="fa fa-check"/>
                Yes
              </button>
              <button
                class="btn btn-link btn-sm"
                @click="toggleDeleteAllMode">
                <i class="fa fa-times"/>
                No
              </button>
            </div>
          </div>
          <!-- Add Buttons -->
          <div class="float-end">
            <div class="input-group">
              <button
                class="btn btn-sm btn-primary"
                @click="toggleUploadForm">
                <i class="fa fa-upload"/>
                Bulk Add Products
              </button>
              <button
                class="btn btn-sm btn-primary"
                @click="toggleShowForm">
                <i class="fa fa-plus"/>
                Add Product
              </button>
            </div>
          </div>
          <div class="clearfix"/>
        </div>
        <PromoProductForm
          v-if="showForm"          
          @closeProductForm="toggleShowForm"
          :activePriceBranch="activePriceBranch"
          :promoHeader="promoHeader"/>
        <UploadForm
          v-if="showUploadForm"
          @closeUploadForm="toggleUploadForm"
          :activePriceBranch="activePriceBranch"
          :promoHeader="promoHeader"/>
        <template v-if="!showUploadForm && !loading">
          <!-- Products -->
          <div
            v-if="activePriceBranchProducts && activePriceBranch"
            class="col-md-12 my-2">
              <table
                class="table table-light table-striped text-center"
                style="font-size: 12px;">
                <thead>
                  <th v-if="deleteMode">Delete</th>
                  <th>Product ID</th>
                  <th>Standard Price</th>
                  <th>Promo Price</th>
                  <th>Qty Break 1</th>
                  <th>Qty Break 1 Uom</th>
                  <th>Qty Break 1 Price</th>
                  <th>Qty Break 2</th>
                  <th>Qty Break 2 Uom</th>
                  <th>Qty Break 2 Price</th>
                  <th>Qty Break 3</th>
                  <th>Qty Break 3 Uom</th>
                  <th>Qty Break 3 Price</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  <PromoProductsRow
                    v-for="p in activePriceBranchProducts"
                    :key="p.product_id"
                    :deleteMode="deleteMode"
                    :activePriceBranch="activePriceBranch"
                    :p="p"
                    @toggleDeleteProduct="toggleDeleteProduct"/>
                </tbody>
              </table>
          </div>
          <!-- No Product Handler -->
          <div
            v-else
            class="col-md-12 text-center">
            <h4 class="text-muted my-3 p-5">No Products</h4>
          </div>
        </template>
        <div class="row">
          <div class="col-md-12 text-center">
            <Spinner
              v-if="loading"
              class="my-5"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PromoProductsRow from './PromoProductsRow.vue'
  import PromoProductForm from './PromoProductForm.vue'
  import UploadForm from './UploadForm.vue'
  import Spinner from '../../../../../../global/Spinner.vue'

  export default{
    props: ['branchData', 'promoProducts', 'promoHeader'],
    data(){
      return{
        activePriceBranch: null,
        showForm: false,
        showUploadForm: false,
        deleteMode: false,
        deleteAllMode: false,
        initialLoad: true,
        deleteProducts: []
      }
    },
    watch:{
      deleteMode(newVal){
        if(!newVal){
          this.deleteProducts = []
        }
      }
    },
    components:{
      PromoProductsRow,
      PromoProductForm,
      UploadForm,
      Spinner
    },
    methods:{
      setActivePriceBranch(branch){
        this.activePriceBranch = branch
      },
      toggleShowForm(){
        this.showForm = !this.showForm
      },
      toggleUploadForm(){
        this.showUploadForm = !this.showUploadForm
      },
      toggleDeleteProduct(id){
        if(this.deleteProducts.includes(id)){
          this.deleteProducts = _.reject(this.deleteProducts, p => p == id)
        }else{
          this.deleteProducts.push(id)
        }
      },
      toggleDeleteAllMode(){
        this.deleteAllMode = !this.deleteAllMode
      },
      bulkDelete(){
        const params = {
          ids: this.deleteProducts
        }
        this.$store.dispatch('promoBulkDelete', params)
        this.deleteProducts = []
        this.deleteMode = false
      },
      deleteAll(){
        const params = {
          promo: this.promoHeader,
          priceBranch: this.activePriceBranch,
          all: true
        }
        this.$store.dispatch('promoBulkDelete', params)
        this.deleteAllMode = false
      }
    },
    computed:{
      priceBranches(){
        const priceBranches = _.uniq(_.map(this.branchData, 'price_branch'))
        const productPriceBranches = _.uniq(_.map(this.promoProducts, 'price_branch'))
        const combined = _.uniq([...priceBranches, ...productPriceBranches])
        if(this.initialLoad){
          this.activePriceBranch = combined[0]
          this.initialLoad = false
        }
        return combined
      },
      priceBranchProducts(){return _.groupBy(this.promoProducts, 'price_branch')},
      activePriceBranchProducts(){return this.priceBranchProducts[this.activePriceBranch]},
      loading(){return this.$store.getters.adminPromoProductsLoading}
    }
  }
</script>

<style scoped>
  .form-switch .form-check-input{
    margin-left:10px !important;
  }
</style>