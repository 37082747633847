<template>
  <div class="float-end">
    <form
      name="unlockPromo"
      @submit.prevent="submit">
      <div class="input-group">
        <input
          type="text"
          v-model="unlockCode"
          placeholder="Enter Unlock Code"
          class="form-control form-control-sm">
        <button
          class="btn btn-primary btn-sm"
          type="submit">
          <i class="fa fa-unlock"/>
          Unlock
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        unlockCode: null
      }
    },
    methods:{
      submit(){
        this.$store.dispatch('submitUnlockCode', this.unlockCode)
        this.unlockCode = null
      }
    }
  }
</script>