<template>
  <div class="p-3">
    <div class="bg-light shadow p-4 border border-primary">
      <form
        name="priceLists"
        @submit.prevent="submitRequest" style="max-width:800px;">
        <h3 class="mb-4">Price List Form</h3>
        <!-- select price list -->
        <div class="form-group mb-4">
          <label
            v-if="options"
            class="form-label fw-bold fs-5" for="priceListOptions">Select manufacturer&ast;</label>
          <p class="small text-muted">Price lists are broken down by manufacturer. One list is emailed at a time.</p>
          <!-- MFG select -->
          <template 
            v-if="options" 
            class="form-group">
            <select 
              id="priceListOptions"
              class="form-select" 
              v-model="selected"
              required>
                <option value="" disabled>Manufacturer List</option>
                <option v-for="o, index in options" :key="index" :value="o">{{o}}</option>
            </select>
          </template>

          <div class="text-muted" v-else>
            <i class="d-inline-block fa fa-spinner fa-spin me-1"></i><span class="d-inline-block">Getting manufacturer list...</span>
          </div>

        </div>

        <!-- specify email  -->
        <div class="form-group mb-3">
          <label class="form-label fw-bold fs-5" for="email">Select recipient email&ast;</label>
          <p class="small text-muted">Enter an address or select from the addresses on file.</p>
          <!-- email input  -->
          <input
            class="form-control"
            type="email"
            id="email"
            v-model="sendTo"
            placeholder="Enter email address"
            autocomplete="false"
            required/>
            <!-- email quick add -->
            
            <ul class="list-unstyled p-0 mx-0 mt-3 mb-0 d-flex w-100 flex-wrap">
              <li class="mb-3">
                <button 
                  v-if="!sendTo && repEmail" 
                  @click="populateEmail(repEmail)" 
                  class="btn btn-outline-primary btn-sm small me-2">
                   {{repEmail}}
                </button> 
              </li>  
              <li v-for="e, index in quickAddEmail" :key="index" class="mb-3">
                <button 
                  v-if="!sendTo && e" 
                  @click="populateEmail(e)" 
                  class="btn btn-outline-primary btn-sm small me-2">
                   {{e}}
                </button> 
              </li>
              <li v-if="sendTo && sendTo != ''" class="mb-3">
                <button 
                  @click="clearEmail()" 
                  class="btn btn-outline-primary btn-sm small me-2">
                   <i class="fa fa-times"></i> Clear Email
                </button> 
              </li>
            </ul> 
        </div>

        <Status :submitting="submitting" :success="success" :selected="selected" :sendTo="sendTo" />

        <button class="btn btn-primary fs-5 btn-lg my-4" :disabled="options == null || submitting" type="submit">
          <span class="text-lg">Submit</span>
        </button>

      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Status from './Status.vue'

export default {
  name: "PriceListForm",
  components: {
    Status
  },
  data() {
    return {
      selected: '',
      sendTo: null,
      options: null,
      quickAddEmail: null,
      submitting: false,
      success: null,
      repEmail: null
    }
  },
  created(){
    //Get price lists options
    if(this.options == null){
      this.getOptions()
    }
    if(this.$store.getters.user.role == 'rep'){
      axios.get('/api/v1/private/user_management/contact_details').then(res => {
        if(res.data.contact.email.length){
          this.repEmail = res.data.contact.email
        }
      })
    }
  },
  methods: {
    submitRequest(){
      this.success = false
      this.submitting = true
      let params = {
        email: this.sendTo,
        mfg: this.selected,
      }      
      axios.post('/api/v1/private/product/send_price_list', params).then(res =>{
        if(res.status == 200){
          this.success = res.data.success
          this.submitting = false
        }
      })
    },
    getOptions(){
      axios.get('/api/v1/private/product/get_price_list_options').then(res => {
        if(res.status == 200){
          this.options = res.data.options
          this.quickAddEmail = res.data.email
        }
      })
    },
    populateEmail(e){
      this.sendTo = e
    },
    clearEmail(){
      this.sendTo = null
    }
  },
  watch:{
    selected(NewVal){
      if(NewVal){
        this.success = null
      }
    },
    sendTo(NewVal){
      if(NewVal || NewVal == null){
        this.success = null
      }
    }
  }
}
</script>
<style>
button{
  word-break: break-all;
}
</style>