const initialState = {
  timedFeedback: true
}

export const feedbackStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    disableTimedFeedback(state){
      state.timedFeedback = false
    }
  },
  actions:{
    feedbackResetState(context){
      context.commit('resetState')
    },
    disableTimedFeedback(context){
      context.commit('disableTimedFeedback')
    },
    // openFeedbackModal(context){
    //   if(this.state.feedbackStore.timedFeedback == true){
    //     const feedBackModal = document.querySelector('#feedbackModal')
    //     if(feedBackModal){
    //       let modal = new bootstrap.Modal(document.querySelector('#feedbackModal'))
    //       modal.show()
    //     }
    //   }
    // },
    openPostOrderModal(context){
      const feedbackModal = document.querySelector('#feedbackModal')
      if(feedbackModal){
        let modal = new bootstrap.Modal(document.querySelector('#feedbackModal'))
        modal.show()
      }
    }
  },
  gettters:{
    timedFeedbackState: state => {return state.timedFeedback}
  }
}