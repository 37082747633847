<template>
  <div class="container-fluid mt-2">
    <div class="row animate__animated animate__fadeIn animate__faster">
      <div class="col-md-12">
        <h2>Bin Labels</h2>
        <small class="text-muted">Select your data source, then add items to your label list and when you're finished click "Generate Bin Labels"</small>
      </div>
      <hr>
    </div>
    <Panel
      v-if="view == 'panel'"
      @goto="goto"/>
  </div>
</template>

<script>
  import Panel from './Panel.vue'
  
  export default {
    created(){
      if(!this.settings.binLabels){
        this.$router.push({path: '/'})
      }else{
        this.$store.dispatch('masterReset')
      }
    },
    data(){
      return{
        view: 'panel'
      }
    },
    components:{
      Panel
    },
    methods:{
      goto(view){
        this.view = view
      }
    },
    computed:{
      settings(){return this.$store.getters.settings}
    }
  }
</script>

