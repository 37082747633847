<template>
  <div class="container-fluid">
    <div class="row mt-2">
      <div class="col-md-12">
        <h2 class="p-2">Settings</h2>
        <table class="table table-striped table-bordered">
          <tbody>
            <!-- Hide Pricing -->
            <tr class="text-center">
              <td class="align-middle">Hide Pricing</td>
              <td class="align-middle">
                <a
                  class="btn btn-link text-primary user-menu"
                  href="javascript:void(0)"
                  @click="toggleHidePricing">
                  <div
                    class="form-check form-switch"
                    style="cursor: pointer;">
                    <input
                      class="form-check-input ms-1"
                      type="checkbox"
                      :checked="hidePricing">
                  </div>
                </a>
              </td>
            </tr>
            <tr class="text-center">
              <td class="align-middle">Suggested Products</td>
              <td class="align-middle">
                <a
                  class="btn btn-link text-primary user-menu"
                  href="javascript:void(0)"
                  @click="toggleSuggested">
                  <div
                    class="form-check form-switch"
                    style="cursor: pointer;">
                    <input
                      class="form-check-input ms-1"
                      type="checkbox"
                      :checked="user.type_ahead">
                  </div>
                </a>
              </td>
            </tr>
            <tr
              v-if="managerRoles.includes(user.role)"
              class="text-center">
              <td class="align-middle">Show Cost</td>
              <td class="align-middle">
                <a
                  class="btn btn-link text-primary user-menu"
                  href="javascript:void(0)"
                  @click="toggleShowCost">
                  <div
                    class="form-check form-switch"
                    style="cursor: pointer;">
                    <input
                      class="form-check-input ms-1"
                      style="float:right;"
                      type="checkbox"
                      :checked="showCost">
                  </div>
                </a>
              </td>
            </tr>
            <!-- User Management -->
            <tr class="text-center">
              <td
                class="align-middle"
                colspan="2">
                <a
                  class="btn btn-link text-primary my-2 user-menu w-100"
                  href="javascript:void(0)"
                  @click="goto('UserManagement')">
                  <i class="fa fa-users"/>
                  User Management
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { managerRoles } from '../../../shared/roles'
  
  export default {
    data(){
      return{
        managerRoles
      }
    },
    methods:{
      toggleHidePricing(){
        this.$store.dispatch('toggleHidePricing')
      },
      toggleShowCost(){
        this.$store.dispatch('toggleShowCost')
      },
      toggleSuggested(){
        this.$store.dispatch('toggleSuggested')
      },
      goto(view){
        this.$router.push({name: view})
      },
    },
    computed:{
      hidePricing(){return this.$store.getters.hidePricing},
      showCost(){return this.$store.getters.showCost},
      user(){return this.$store.getters.user}
    }
  }
</script>