<template>
  <div class="modal fade" id="cartCopyMoveModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitleId">
            <i class="fa fa-copy me-1"/>
            <span v-if="copyInvoice">Copy as New Order</span>
            <span v-else>Copy / Move Cart</span>
          </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- Step 1 -->
          <section
            v-if="step == 1"
            class="animate__animated animate__fadeIn animate__faster">
            <CartSummary
              v-if="!detail && !copyInvoice"
              @detailToggle="detailToggle"
              :cart="cart"/>
            <CartDetail
              v-if="detail && !copyInvoice"
              class="animate__animated animate__slideInUp animate__faster"
              @detailToggle="detailToggle"
              :cart="cart"/>
          </section>
          <!-- Step 2 -->
          <section
            v-if="step == 2"
            class="animate__animated animate__fadeIn animate__faster">
            <ShipToSelect
              :cart="cart"
              :shipToList="shipToList"
              :copyInvoice="copyInvoice"
              @setAction="setAction"/>
          </section>
          <!-- Step 3 -->
          <section
            v-if="step == 3"
            style="overflow:hidden;">
            <div class="row">
              <div class="col-md-12 text-center my-5">
                <Spinner v-if="processing"/>
                <h4
                  v-else
                  class="text-success animate__animated animate__bounceIn animate__faster">
                  <i class="fa fa-check-circle"/>
                  {{ msg }}
                </h4>
              </div>
            </div>
          </section>
        </div>
        <!-- Footer / step control -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
          <button
            v-if="step > 1 && step < 3 && !copyInvoice"
            type="button"
            class="btn btn-primary"
            @click="previousStep">
            <i class="fa fa-arrow-left"/>
            Back
          </button>
          <button
            v-if="step < 3"
            type="button"
            class="btn btn-primary"
            :disabled="step == 2 && !shipToList.length || copyInvoice && !shipToList.length"
            @click="nextStep">
            {{step == 2 ? 'Go!' : 'Next'}}
            <i
              v-if="step == 1"
              class="fa fa-arrow-right"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CartSummary from './CartSummary.vue'
  import CartDetail from './CartDetail.vue'
  import ShipToSelect from './ShipToSelect.vue'
  import Spinner from '../../../global/Spinner.vue'
  import axios from 'axios'

  export default{
    data(){
      return{
        detail: false,
        step: 1,
        processing: true,
        shipToList: [],
        msg: null
      }
    },
    components:{
      CartSummary,
      CartDetail,
      ShipToSelect,
      Spinner
    },
    mounted(){
      // Reset modal body components to default state on modal close
      const modal = document.getElementById('cartCopyMoveModal')
      if(modal){
        modal.addEventListener('hidden.bs.modal', function (event) {
          this.detail = false
          this.step = 1
          this.processing = true
          this.showComplete = false
          this.shipToList = [],
          this.msg = null
          this.$store.dispatch('setCopyInvoice', null)
        }.bind(this))
      }
    },
    watch:{
      copyInvoice(newVal){
        if(newVal){
          this.step = 2
        }
      }
    },
    methods:{
      detailToggle(){this.detail = !this.detail},
      nextStep(){
        if(this.step == 2 && this.shipToList.length){
          this.process()
          this.step += 1
        }
        if(this.step < 2){
          this.step += 1
        }
      },
      previousStep(){
        if(this.step > 1){
          this.step -= 1
        }
      },
      setAction(shipTo){
        if(shipTo.action){
          this.shipToList.push(shipTo)
        }else{
          this.shipToList = _.reject(this.shipToList, {id: shipTo.id})
        }
      },
      process(){
        const params = {list: this.shipToList}
        axios.post('/api/v1/private/copy_move/process_copy', params).then((res)=> {
          this.processing = false
          this.msg = res.data.msg
          const cartID = res.data.cart_id
          // Reload current cart upon sucessful move as initial cart will have been deleted
          if(this.msg.includes('Moved')){
            switch (this.$router.currentRoute.value.path){
              case '/manage_carts':
                this.$store.dispatch('getCart')
                this.$store.dispatch('getCarts')
                break;
              case '/cart':
                this.$store.dispatch('getCart')
                break;
            }
          }
          if(cartID){
            this.$store.dispatch('getCart', cartID)
          }
        })
      }
    },
    computed:{
      cart(){return this.$store.getters.cart},
      copyInvoice(){return this.$store.getters.copyInvoice}
    }
  }
</script>