<template>
  <div class="p-3">
    <div>
      <strong>Cart Summary</strong>
      <button
        class="btn btn-sm btn-link text-muted float-end"
        @click="detailToggle">
        Show Details
        <i class="fa fa-caret-down"/>
      </button>
    </div>
    <table
      v-if="cart"
      class="table table-sm table-striped">
      <tbody>
        <tr>
          <td>Status</td>
          <td>{{cart.details.status}}</td>
        </tr>
        <tr>
          <td>Products</td>
          <td>{{ cart.cartProducts.length }}</td>
        </tr>
        <tr>
          <td>Cart Total</td>
          <td>{{ numeral(cartTotal).format('$0,0.00') }}</td>
        </tr>
        <tr v-if="vShowCart">
          <td>Virtual Show Cart</td>
          <td>Yes</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default{
    props: ['cart'],
    methods:{
      detailToggle(){
        this.$emit('detailToggle')
      }
    },
    computed:{
      cartTotal(){return _.sum(_.map(this.cart.cartProducts, cp => cp.pricing?.extended_price))},
      vShowCart(){
        if(this.cart){
          return this.cart.details?.vshow_cart
        }
      }
    }
  }
</script>