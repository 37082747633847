<template>
  <div class="container-fluid mt-2">
    <div class="row">
      <div class="col-md-12">
        <div class="float-start">
          <h3>
            Contact {{ name }}
          </h3>
          <small
            v-if="env.includes('EJW')"
            class="d-block text-muted">*Please call ahead and schedule an appointment before visiting show rooms.</small>
        </div>
        <a
          v-if="env.includes('EJW')"
          href="https://editor.ne16.com/Subscribe/Form.ashx?l=1004408&p=d4527b9b-61d7-4d2b-a549-16562f8e4feb&&WebFormTest=Y"
          target="_blank"
          class="float-end btn btn-sm btn-primary">
          <i class="fa fa-paper-plane"/>
          Sign up for our email list
        </a>
        <a
          v-else
          href="https://lp.constantcontactpages.com/su/3akGGof/LancasterEmailSignUpForm"
          target="_blank"
          class="float-end btn btn-sm btn-primary">
          <i class="fa fa-paper-plane"/>
          Sign up for our email list
        </a>
      </div>
    </div>
    <hr>
    <div class="row" style="min-height:400px;">
      <div class="col-md-6">
        <h3>Your Branch</h3>
        <div v-if="branch">
          <table class="table table-sm table-striped">
            <tbody>
              <tr>
                <td>
                  Branch
                </td>
                <td>
                  {{ branch.branch_id }}
                </td>
              </tr>
              <tr v-if="env.includes('EJW')">
                <td>
                  Region
                </td>
                <td>
                  {{ branch.region }}
                </td>
              </tr>
              <tr v-if="env.includes('EJW')">
                <td>
                  Sub Region
                </td>
                <td>
                  {{ branch.sub_region }}
                </td>
              </tr>
              <tr>
                <td>
                  Address
                </td>
                <td>
                  {{ branch.address }}
                </td>
              </tr>
              <tr>
                <td>
                  City
                </td>
                <td>
                  {{ branch.city }}
                </td>
              </tr>
              <tr>
                <td>
                  State
                </td>
                <td>
                  {{ branch.state }}
                </td>
              </tr>
              <tr>
                <td>
                  Zip
                </td>
                <td>
                 {{ branch.zip }}
                </td>
              </tr>
              <tr>
                <td>
                  Phone
                </td>
                <td>
                  <a :href="'tel:' + branch.branch_contact.phone">
                    {{ env == 'EJW-CHENEY' ? branch.branch_contact.phone2 : branch.branch_contact.phone }}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Email
                </td>
                <td>
                  <a :href="'mailto:' + branch.branch_contact.email">
                    {{ branch.branch_contact.email }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Spinner -->
        <div
          v-else
          class="row">
          <div class="col-md-12 text-center">
            <Spinner class="mt-5"/>
          </div>
        </div>
      </div>
      <SalesRep/>
      <div class="col-md-6">
        <h3>Departments</h3>
        <table
          v-if="['Cheney', 'EJ Welch'].includes(company)"
          class="table table-striped table-sm table-bordered">
          <tbody>
            <tr>
              <td>Accounting</td>
              <td>
                <a href="mailto:accounting@ejwelch.com">accounting@ejwelch.com</a>
              </td>
            </tr>
            <tr>
              <td>Pricing</td>
              <td>
                <a href="mailto:pricing.team@ejwelch.com">pricing.team@ejwelch.com</a>
              </td>
            </tr>
            <tr>
              <td>IT Support</td>
              <td>
                <a href="mailto:it.support@ejwelch.com">it.support@ejwelch.com</a>
              </td>
            </tr>
            <tr>
              <td>Marketing</td>
              <td>
                <a href="mailto:marketing@ejwelch.com">marketing@ejwelch.com</a>
              </td>
            </tr>
            <tr>
              <td>Commercial Specified Projects Pricing</td>
              <td>
                <a href="mailto:projects@ejwelch.com">projects@ejwelch.com</a>
              </td>
            </tr>
            <tr>
              <td>Customer Service</td>
              <td v-if="env.includes('CHENEY')">
                <a href="mailto:uppermidwestcustserv@ejwelch.com">uppermidwestcustserv@ejwelch.com</a>
              </td>
              <td v-else>
                <a href="mailto:customerservice@ejwelch.com">customerservice@ejwelch.com</a>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          v-if="company == 'Lancaster US'"
          class="table table-striped table-sm table-bordered">
          <tbody>

            <tr>
              <td>Accounting</td>
              <td>
                <a href="mailto: accounting@lancasterco.com">accounting@lancasterco.com</a>
              </td>
            </tr>
            <tr>
              <td>Customer Service</td>
              <td>
                <a href="mailto: orderse@lancasterco.com">orders@lancasterco.com</a>
              </td>
            </tr>
            <tr>
              <td>IT Support</td>
              <td>
                <a href="mailto: it.support@lancasterco.com">it.support@lancasterco.com</a>
              </td>
            </tr>
            <tr>
              <td>Special Pricing</td>
              <td>
                <a href="mailto: special.pricing@lancasterco.com">special.pricing@lancasterco.com</a>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- CAD Departments -->
        <table
          v-if="company == 'Lancaster Canada'"
          class="table table-striped table-sm table-bordered">
          <tbody>
            
            <tr>
              <td>Accounting</td>
              <td>
                <a href="mailto: accounting.canada@lancasterco.com">accounting.canada@lancasterco.com</a>
              </td>
            </tr>
            <tr>
              <td>Customer Service</td>
              <td>
                <a href="mailto: customer.serviceca@lancasterco.com">customer.serviceca@lancasterco.com</a>
              </td>
            </tr>
            <tr>
              <td>IT Support</td>
              <td>
                <a href="mailto: it.support@lancasterco.com">it.support@lancasterco.com</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import SalesRep from './SalesRep.vue'
  import Spinner from '../../../global/Spinner.vue'
  import { companyName } from '../../../../shared/env'

  export default{
    props: ['branch', 'company'],
    inject: ['env'],
    components:{
      SalesRep,
      Spinner
    },
    computed:{
      name(){return companyName(this.env)}
    }
  }
</script>