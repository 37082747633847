import axios from 'axios'
import Qs from 'qs'

// Returns the appropriate API for the given admin view
const getUrl = function(view, action = 'get'){
  if(view == 'promos'){
    switch (action){
      case 'get':
        return `/api/v1/private/admin/promo_admin/get_${view}`
    }
  }else{
    switch (action){
      case 'get':
        return `/api/v1/private/admin/get_${view}`
      case 'update':
        return `/api/v1/private/admin/update_${view}`
    }
  }
}

export const adminStore = {
  state:{
    view: 'dashboard',
    viewData: null,
    activeRecord: null,
    activePromo: null,
    statusMessage: null,
    loading: false,
    promoAssetLoading: false,
    promoProductsLoading: false,
    uploading: false,
    message: null
  },
  mutations:{
    setAdminView(state, view){
      state.view = view
    },
    setViewData(state, data){
      state.viewData = data
    },
    setActiveRecord(state, record){
      state.activeRecord = record
    },
    removeActiveRecord(state){
      state.activeRecord = null
    },
    setStatusMessage(state, status){
      switch(status){
        case true:
          state.status = 'Success'
          break
        case false:
          state.status = 'Failed'
          break
      }
    },
    setLoadStatus(state, status){
      state.loading = status
    },
    setActivePromo(state, data){
      if(data.promo){
        state.activePromo = data.promo
      }else{
        state.activePromo = data
      }
    },
    unsetActivePromo(state){
      state.activePromo = null
    },
    setPromoAssetLoading(state, status){
      state.promoAssetLoading = status
    },
    updatePromoAssets(state, assets){
      state.activePromo.promo_assets = assets
    },
    setAdminMessage(state, msg){
      clearTimeout(msgTimeout)
      state.message = msg
      const msgTimeout = setTimeout(()=>{
        state.message = null
      },3000)
    },
    updatePromoProducts(state, data){
      // Singular Interactions
      if(data.created){
        state.activePromo.promo_products = [...state.activePromo.promo_products, data.product]
      }
      if(data.deleted){
        state.activePromo.promo_products = [..._.reject(state.activePromo.promo_products, p => p.id == data.product.id)]
      }
      if(data.updated){
        let match = _.find(state.activePromo.promo_products, {id: data.product.id})
        const index = _.indexOf(state.activePromo.promo_products, match)
        match = data.product
        state.activePromo.promo_products.splice(index, 1, match)
      }
      // Bulk Upload and bulk delete interactions
      if(data.uploaded){
        if(state.activePromo.promo_products?.length > 0){
          state.activePromo.promo_products = [...state.activePromo.promo_products, ...data.products]
        }else{
          state.activePromo.promo_products = data.products
        }
      }
      if(data.bulkDeleted){
        if(data.ids.length){
          const products = data.ids.map(Number)
          state.activePromo.promo_products = [..._.reject(state.activePromo.promo_products, p => products.includes(p.id))]
        }else{
          state.activePromo.promo_products = []
        }
      }
    },
    promoProductsLoading(state, status){
      state.promoProductsLoading = status
    },
    setAdminUploadStatus(state, status){
      state.uploading = status
    }
  },
  actions:{
    setAdminView(context, view){
      context.commit('setAdminView', view)
      if(view != 'dashboard'){
        context.commit('setLoadStatus', true)
        if(view == 'promos'){
          this.dispatch('unsetActivePromo')
        }
        const url = getUrl(view)
        axios.get(url).then(res=>{
          context.commit('setViewData', res.data)
          context.commit('setLoadStatus', false)
        })
      }
    },
    setActiveRecord(context, record){
      context.commit('setActiveRecord', record)
    },
    removeActiveRecord(context, activeView){
      context.commit('removeActiveRecord')
      if(activeView){
        this.dispatch('setAdminView', activeView)
      }
    },
    updateAdminRecord(context, data){
      data.form.append('view', this.state.adminStore.view)
      if(data.zip_attached){
        context.commit('setAdminUploadStatus', true)
      }
      axios.put('/api/v1/private/admin/update', data.form).then(res=>{
        context.commit('setStatusMessage', res.data.message)
        context.commit('setAdminUploadStatus', false)
      })
    },
    deleteAdminRecord(context, data){
      const params = {
        view: this.state.adminStore.view,
        id: data.id
      }
      axios.delete('/api/v1/private/admin/delete', {params}).then(res=>{
        context.commit('setStatusMessage', res.data.message)
        this.dispatch('setAdminView', this.state.adminStore.view)
      })
    },
    createAdminRecord(context, data){
      data.form.append('view', this.state.adminStore.view)
      if(data.zip_attached){
        context.commit('setAdminUploadStatus', true)
      }
      axios.post('/api/v1/private/admin/create', data.form).then(res=>{
        context.commit('setStatusMessage', res.data.message)
        context.commit('setAdminUploadStatus', false)
        this.dispatch('setAdminView', this.state.adminStore.view)
      })
    },
    // Promo Admin
    setActivePromo(context, data){
      context.commit('setActivePromo', data)
    },
    unsetActivePromo(context){
      context.commit('unsetActivePromo')
    },
    getPromoDetails(context){
      context.commit('setPromoAssetLoading', true)
      const params = {
        id: this.state.adminStore.activePromo.id || this.state.adminStore.activePromo.promo_detail.id
      }
      axios.get('/api/v1/private/admin/promo_admin/get_promo_detail', {params}).then(res=>{
        context.commit('setActivePromo', res.data)
        context.commit('setPromoAssetLoading', false)
        context.commit('promoProductsLoading', false)
      })
    },
    // Promo Assets
    createPromoAsset(context, form){
      context.commit('setPromoAssetLoading', true)
      axios.post('/api/v1/private/admin/promo_admin/create_promo_asset', form).then(res=>{
        context.commit('updatePromoAssets', res.data.assets)
        context.commit('setPromoAssetLoading', false)
      })
    },
    deletePromoAsset(context, id){
      context.commit('setPromoAssetLoading', true)
      const params = {
        promo_id: this.state.adminStore.activePromo.promo_detail.id,
        asset_id: id
      }
      axios.delete('/api/v1/private/admin/promo_admin/delete_promo_asset', {params}).then(res=>{
        context.commit('updatePromoAssets', res.data.assets)
        context.commit('setPromoAssetLoading', false)
      })
    },
    updatePromoAsset(context, form){
      context.commit('setPromoAssetLoading', true)
      axios.put('/api/v1/private/admin/promo_admin/update_promo_asset', form).then(res=>{
        context.commit('updatePromoAssets', res.data.assets)
        context.commit('setPromoAssetLoading', false)
      })
    },
    setAdminMessage(context, message){
      context.commit('setAdminMessage', message)
    },
    // Promo Branch Permissions
    savePromoBranch(context, form){
      axios.post('/api/v1/private/admin/promo_admin/save_promo_branch', form).then(res=>{
        this.dispatch('setAdminMessage', res.data.msg)
        this.dispatch('getPromoDetails')
      })
    },
    // Promo Products
    createPromoProduct(context, form){
      axios.post('/api/v1/private/admin/promo_admin/create_promo_product', form).then(res=>{
        if(res.data.created){
          context.commit('updatePromoProducts', res.data)
        }
      })
    },
    deletePromoProduct(context, product){
      const params = {
        product
      }
      axios.delete('/api/v1/private/admin/promo_admin/delete_promo_product', {params}).then(res=>{
        context.commit('updatePromoProducts', res.data)
      })
    },
    updatePromoProduct(context, form){
      axios.put('/api/v1/private/admin/promo_admin/update_promo_product', form).then(res=>{
        context.commit('updatePromoProducts', res.data)
      })
    },
    uploadPromoProducts(context, form){
      context.commit('promoProductsLoading', true)
      axios.post('/api/v1/private/admin/promo_admin/upload_promo_products', form).then(res=>{
        context.commit('updatePromoProducts', res.data)
        context.commit('promoProductsLoading', false)
      })
    },
    promoBulkDelete(context, data){
      context.commit('promoProductsLoading', true)
      const params = data
      axios.delete('/api/v1/private/admin/promo_admin/bulk_delete_products',  {params, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})}).then(res=>{
        context.commit('updatePromoProducts', res.data)
        context.commit('promoProductsLoading', false)
        this.dispatch('getPromoDetails')
      })
    },
    changePosition(context, data){
      axios.post('/api/v1/private/admin/change_position', data).then(res=>{
        this.dispatch('setAdminView', res.data.view)
      })
    },
    setAdminUploadStatus(context, status){
      context.commit('setAdminUploadStatus', status)
    }
  },
  getters:{
    adminView: state => {return state.view},
    adminViewData: state => {return state.viewData},
    adminActiveRecord: state => {return state.activeRecord},
    adminLoading: state => {return state.loading},
    adminActivePromo: state => {return state.activePromo},
    adminPromoAssetLoading: state => {return state.promoAssetLoading},
    adminMessage: state => {return state.message},
    adminPromoProductsLoading: state => {return state.promoProductsLoading},
    adminUploadStatus: state => {return state.uploading}
  }
}