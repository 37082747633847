// Set unit price in cart store for add to cart function
const setUnitPrice = (product, vShow = null, vShowDetail = null) => {
  let price
  if(!vShow && product.promo){
    price = product.promo.promo_price
  }else if(vShow && vShowDetail){
    price = parseFloat(vShowDetail.show_price)
  }else if(product.cartData.activeUom){
    price = product.cartData.activeUom.price
  }else{
    price = product.eclipse_data.unit_price
  }
  return price
}

// Set Cart Product Object
const setCartProduct = (product, unitPrice) => {
  return {
    ...product.cartProduct,
    product_data: _.omit(product, 'cartData', 'cartProduct'),
    pricing: {
      eclipse_id: product.cartProduct.eclipse_id,
      unit_price: unitPrice,
      extended_price: unitPrice * product.cartProduct.quantity
    }
  }
}

// Look for existing product exisitng in cart already, update match if found
// if no match just add the product to the cart
export const findUpdateMatch = (state, data) => {
  const {product, vShow, vShowDetail} = data
  const unitPrice = setUnitPrice(product, vShow, vShowDetail)
  let cartProduct = setCartProduct(product, unitPrice)
  let match = _.find(state.cart.cartProducts, cp => cp.eclipse_id == cartProduct.eclipse_id)

  if(match){
    const index = _.indexOf(state.cart.cartProducts, match)
    cartProduct.pricing.extended_price = (_.find(cartProduct.product_data.eclipse_data.uom_pricing, {uom: cartProduct.uom}).price * cartProduct.quantity) || null
    state.cart.cartProducts[index] = cartProduct
  }else{
    state.cart.cartProducts.push(cartProduct)
  }
}