import axios from 'axios'
import Qs from 'qs'

const initialState = {
  results: [],
  loading: false,
  showMoreLoadStatus: false
}

export const reorderPadStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setReorderPadResults(state, results){
      state.results = results
    },
    setLoadStatus(state, status){
      state.loading = status
    },
    setShowMoreLoadStatus(state, status){
      state.showMoreLoadStatus = status
    }
  },
  actions:{
    reorderResetState(context){
      context.commit('resetState')
    },
    getReorderPadProducts(context, data){
      if(data && data.page){
        this.dispatch('showMoreLoading', true)
      }else{
        context.commit('setLoadStatus', true)
      }
      let params = {
        query:{
          action: 'reorderPadSearch',
          query: data && data.query ? data.query : null
        }
      }
      axios.get('/api/v1/private/reorder_pad/get_products', {params, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})}).then(res=>{
        context.commit('setReorderPadResults', res.data.results)
        context.commit('setLoadStatus', false)
      })
    },
    reorderPadSearch(context, query){
      const data = {
        query
      }
      this.dispatch('getReorderPadProducts', data)
    }
  },
  getters:{
    reorderPadResults: state => {return state.results},
    reorderPadLoadStatus: state => {return state.loading},
    reorderShowMoreLoadStatus: state => {return state.showMoreLoadStatus}
  }
}