<template>
  <div class="row my-5">
    <div class="col-md-12">
      <div class="text-center text-theme-red">
        <h2>Printed Materials</h2>
      </div>
      <div class="row p-5">
        <div
          class="col-md-6"
          :style="promotions"/>
        <div class="col-md-6 d-flex align-items-center">
          <p>EJ Welch’s experienced marketing department has the capabilities to create, design, print, and communicate effective promotional and informational print products. EJ Welch utilizes this team to announce, promote, and educate our sales force and mutual customers.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        promotions: {
          backgroundImage: `url(${require('../../../../../../assets/images/ejw/advantage/promoBooks.png')})`,
          ...this.bgProperties()
        }
      }
    },
    methods:{
      bgProperties(){
        return {
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '500px'
        }
      }
    }
  }
</script>