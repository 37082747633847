<template>
<div class="container d-none d-md-block">
  <!-- Dealer Stats -->
  <div
    v-if="view == 'dealer'"
    class="row my-5"
    style="min-height:150px;">
    <div class="col-md-4 col-4 position-relative">
      <div class="center-x">
        <strong>✓ Broken Case Available</strong>
        <br>
        <strong>✓ Low Acquisition Costs</strong>
      </div>
    </div>
    <div class="col-md-4 col-4 position-relative">
      <div class="center-x">
        <strong>✓ Low Order Minimums</strong>
        <br>
        <strong>✓ Mix & Match Brands</strong>
      </div>
    </div>
    <div class="col-md-4 col-4 position-relative">
      <div class="center-x">
        <strong>✓ No Membership Fees</strong>
      </div>
    </div>
  </div>
  <!-- MFG Stats -->
  <div
    v-if="view == 'manufacturer'"
    class="row my-5"
    style="height:150px;">
    <div class="col-md-4 position-relative">
      <div class="center-x">
        <strong>✓ Consolidate Logistics</strong>
        <br>
        <strong>✓ Distribute Through A Single Source</strong>
        <br>
        <strong>✓ Increase Your Average Order</strong>
      </div>
    </div>
    <div class="col-md-4 position-relative">
      <div class="center-x">
        <strong>✓ Increase Inventory Turns</strong>
        <br>
        <strong>✓ Increase Efficiency And Market Penetration</strong>
        <br>
        <strong>✓ Reduce The Number Of Small Transactions</strong>
      </div>
    </div>
    <div class="col-md-4 position-relative">
      <div class="center-x">
        <strong>✓ Reduce Warehousing Space</strong>
        <br>
        <strong>✓ Save Time And Money</strong>
      </div>
    </div>
      </div>
</div>
</template>

<script>
  export default {
    props:['view']
  }
</script>

<style scoped>
  .center-x{
    line-height: 35px;
    left: 50%;
    position: relative;
    transform: translateX(-25%);
  }
</style>