<template>
  <div class="row my-5">
    <div class="col-md-12">
      <!-- Buttons for tabs -->
      <ul
        class="nav nav-pills nav-fill"
        id="myTab"
        role="tablist"
        style="width:60%; margin: 0 auto;">
        <li class="nav-item" role="presentation">
          <button
            class="btn btn-outline-success my-2 active"
            id="promotions-tab"
            data-bs-toggle="tab"
            data-bs-target="#promotions"
            type="button"
            role="tab">
            PROMOTIONS
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="btn btn-outline-success my-2"
            id="shows-tab"
            data-bs-toggle="tab"
            data-bs-target="#shows"
            type="button"
            role="tab">
            BUYING SHOWS
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="btn btn-outline-success my-2"
            id="conference-tab"
            data-bs-toggle="tab"
            data-bs-target="#conference"
            type="button"
            role="tab">
            TRAINING CONFERENCES
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="btn btn-outline-success my-2"
            id="sundry-tab"
            data-bs-toggle="tab"
            data-bs-target="#sundry"
            type="button"
            role="tab">
            SUNDRY SCENE
          </button>
        </li>
      </ul>
      <!-- Tabs -->
      <!-- promotions -->
      <div
        class="tab-content my-5"
        style="width:60%; margin: 0 auto;">
        <div
          class="tab-pane active"
          id="promotions"
          role="tabpanel">
          <div class="row">
            <div
              class="col-md-6 mb-3"
              :style="promotions"/>
            <div class="col-md-6 py-2">
              <p>Lancaster’s experienced Marketing Department has the capabilities to create, design, print and communicate effective promotions and everyday flyers. These are a great way to announce, promote and educate Lancaster customers and Territory Managers on your products.</p>
            </div>
          </div>
        </div>
        <div
          class="tab-pane"
          id="shows"
          role="tabpanel">
          <div class="row">
            <div
              class="col-md-6 mb-3"
              :style="buyingShows"/>
            <div class="col-md-6 py-2">
              <p>The top two buying shows of the year! Lancaster offers customers the chance to make purchases for their retail stores at a discount giving you the opportunity to meet with Lancaster customers and reps to showcase your wares. These deals are also available on Lancaster’s online store via a special ordering portal for those who cannot make the show. On top of increasing your sales, Lancaster hosts entertainment, cash spiffs, prize giveaways, entertainment and complimentary food & drink.</p>
            </div>
          </div>
        </div>
        <div
          class="tab-pane"
          id="conference"
          role="tabpanel">
          <div class="row">
            <div
              class="col-md-6 mb-3"
              :style="training"/>
            <div class="col-md-6 py-2">
              <p>Lancaster’s National Training Conference (NTC) is a two-day event that occurs once a year. This event offers you the chance to be face-to-
face with the Lancaster sales team and train our staff on your company programs, promotions, products and more! We encourage participants
to bring samples, create a hands-on lesson and teach sales tactics our reps can use in the field. Lancaster keeps it interesting, fun, and focused with themed events by breaking up the sales reps into teams and holding a competition.</p>
            </div>
          </div>
        </div>
        <div
          class="tab-pane"
          id="sundry"
          role="tabpanel">
          <div class="row">
            <div
              class="col-md-6 mb-3"
              :style="sundry"/>
            <div class="col-md-6 py-2">
              <p>The <i>Sundry Scene Magazine</i> is Lancaster’s educational tool for the independent retailer and is FREE to all Lancaster customers. It has a wide variety of ad spaces available to match any budget. Since 2008, the Sundry Scene Magazine has been a vanguard for education and growth within the independent paint sundry retailer community.</p>
              <p>So what does that mean for you? As one of Lancaster’s select vendors offered to advertise in the <i>Sundry Scene Magazine</i>, you have the unique opportunity to speak directly to business owners each quarter who buy, sell and use products like yours everyday! Unlike other forms of advertising, the <i>Sundry Scene Magazine</i> is the most cost effective way to reach independent paint retailers across the U.S., Caribbean, Mexico, Central & South America.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        promotions: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/dealer/dealerTab2.jpg')})`,
          ...this.bgProperties()
        },
        buyingShows: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/mfg/buyingShows.jpg')})`,
          ...this.bgProperties()
        },
        training: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/mfg/training.jpg')})`,
          ...this.bgProperties()
        },
        sundry: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/advantage/mfg/news.jpg')})`,
          ...this.bgProperties()
        },
      }
    },
    methods:{
      bgProperties(){
        return {
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '350px'
        }
      }
    }
  }
</script>