<template>
  <div class="to-top-wrapper">
    <i
      class="fa fa-arrow-up back-to-top"
      @click="toTop"/>
  </div>
</template>

<script>
  export default {
    methods:{
      toTop(){
        let el = this.route == '/product_catalog' ? document.getElementById('content') : document.getElementById('router-view')
        el.scrollTo({top: 0, behavior: 'smooth'})
      }
    },
    computed:{
      route(){return this.$router.currentRoute.value.path}
    }
  }
</script>

<style scoped>
  .to-top-wrapper{
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 1;
  }
  .back-to-top{
    color: lightgray;
    border: 1px solid lightgray;
    padding: 10px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 20%;
  }
  .back-to-top:hover{
    box-shadow: 1px 1px 10px black;
    transform: scale(1.05) translateY(-10%);
    transition: .05s ease-in-out;
  }
</style>