<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster">
    <div class="row">
      <div class="col-md-12 text-muted">
        <div class="float-end">
          <span>Last Updated: </span>
          <span>12/01/2023</span>
        </div>
      </div>
      <div class="col-12">
        <h1 class="text-center mt-2 ">Lancaster Canada Policies & Procedures</h1>
        <hr>
        <div class="row">
          <div
            class="col-md-12"
            ref="index">
            <div
              class="card"
              style="box-shadow:2px 2px 5px lightgray;">
              <div class="card-body text-center">
                <h3>Index</h3>
                <hr>
                <div class="row">
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('hoursOfOperation')">
                      Hours of Operation
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('orderInformation')">
                      Order Information
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('cutOff')">
                      Order Cut-Off Time Policy
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('PrepaidFreightMinimum')">
                      Prepaid Freight Minimum
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('parcel')">
                      Parcel Carriers
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('willCall')">
                      Will Call/ Pick Up Orders
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('international')">
                      Delivery to International Customers
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('charges')">
                      Pricing / Additional Charges
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('backorder')">
                      Backorder Policy
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('special')">
                      Special Orders
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('sds')">
                      Safety Data Sheets (SDS)
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('warranty')">
                      Warranty
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('terms')">
                      Payment Terms
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('holiday')">
                      Holiday Schedules
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('delivery')">
                      Delivery of Product
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('damages')">
                      Damages/ Shortages
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('return')">
                      Return Policy
                    </a>
                  </div>
                  <div class="col-md-4">
                    <a href="javascript:void(0)" @click="goto('contact')">
                      Who to contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-3"
              ref="hoursOfOperation">
              <h3>Hours of Operation</h3>
              <div>
                Our hours of operation are Monday - Friday 8:00am to 5:00pm EST  Contact customer service at&nbsp;
                <a href="tel:18006681124">1-(800)668-1124</a>&nbsp;or by email at&nbsp;
                <a href="mailto:customer.serviceca@lancasterco.com">customer.serviceca@lancasterco.com</a>
              </div>
              <div>Fax orders 24 hours a day, 7 days a week to (866) 286-8089</div>
            </div>
            <div
              class="mt-3"
              ref="orderInformation">
              <h3>Order Information</h3>
              Lancaster Canada is committed to providing our customers with the best possible service. This includes providing consistent,&nbsp;
              reliable delivery times that meet the expectations of our customers. Any order placed prior to the cut off time of 1 PM EST,&nbsp;
              on any business day, will be carefully prepared and shipped the same day. Orders placed after your assigned cut off time will ship&nbsp;
              the following business day.  If your order is placed prior to the requested ship date, you may add on to it until the close of business&nbsp;
              on the day prior to shipment.  All orders meeting the predetermined minimum will ship prepaid.  We can/will ship directly to your customer&nbsp;
              upon request; prepaid minimums and/or freight charges apply. All direct to end user shipments MUST be a physical address and not an Unassumed&nbsp;
              location or jobsite.
            </div>
            <div
              class="mt-3"
              ref="cutOff">
              <h3>Order Cut-Off Time Policy</h3>
              <div class="row">
                <div class="col-md-8">
                  <table class="table table-bordered table-striped text-center table-condensed">
                    <thead>
                      <tr>
                        <th class="text-center">
                          <b>Branch</b>
                        </th>
                        <th class="text-center">
                          <b>Time</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>BR#71 (Canada)</td>
                        <td>1:00pm EST</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="mt-3"
              ref="PrepaidFreightMinimum">
              <div class="row">
                <div class="col-md-8">
                  <h3>Prepaid Freight Minimum</h3>
                  <small>The minimum order is $150 for invoicing, to receive prepaid freight our Prepaid Levels are:</small>
                  <table class="table table-bordered table-striped table-condensed text-center">
                    <thead>
                      <tr>
                        <th class="text-center">Area</th>
                        <th class="text-center">Mixed Goods</th>
                        <th class="text-center" nowrap>Dry Goods</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Toronto Area</td>
                        <td>$500</td>
                        <td>$350</td>
                      </tr>
                      <tr>
                        <td>Southern Ontario</td>
                        <td>$600</td>
                        <td>$350</td>
                      </tr>
                      <tr>
                        <td>Barrie and Surrounding</td>
                        <td>$900</td>
                        <td>$350</td>
                      </tr>
                      <tr>
                        <td>Lakehead Area</td>
                        <td>$1,000</td>
                        <td>$550</td>
                      </tr>
                      <tr>
                        <td>Southern Quebec excluding Gaspe Area</td>
                        <td>$800</td>
                        <td>$500</td>
                      </tr>
                      <tr>
                        <td>Northern Quebec including Gaspe Area</td>
                        <td>$900</td>
                        <td>$500</td>
                      </tr>
                      <tr>
                        <td>Atlantic NB, NS, PEI</td>
                        <td>$1,100</td>
                        <td>$600</td>
                      </tr>
                      <tr>
                        <td>Newfoundland excluding Labrador</td>
                        <td>$1,600</td>
                        <td>$900</td>
                      </tr>
                      <tr>
                        <td>Labrador</td>
                        <td>$2,800</td>
                        <td>$1,500</td>
                      </tr>
                      <tr>
                        <td>Manitoba</td>
                        <td>$1,000</td>
                        <td>$500</td>
                      </tr>
                      <tr>
                        <td>Provinces of AB, SK, BC (excluding GVA,VAN ISL)</td>
                        <td>$1,100</td>
                        <td>$500</td>
                      </tr>
                      <tr>
                        <td>Greater Vancouver</td>
                        <td>$1,100</td>
                        <td>$600</td>
                      </tr>
                      <tr>
                        <td>Vancouver Island</td>
                        <td>$1,200</td>
                        <td>$700</td>
                      </tr>
                    </tbody>
                  </table>
                  <small>Adjustments to an order can be made at any time prior to picking of the order and the morning of shipment. Freight charges may incur if products are not able to be packed with the original order. Please contact customer service for additional information on these charges.</small>
                </div>
              </div>  
            </div>
            <div
              class="mt-3"
              ref="parcel">
              <h3>Parcel Carriers</h3>
              Orders that do not meet the required minimum, may ship using a parcel carrier. Keep in mind that some products, because of weight and contents, may incur additional shipping and packaging charges.
            </div>
            <div
              class="mt-3"
              ref="willCall">
              <h3>Will Call / Pick Up Orders</h3>
              Lancaster Canada is always happy to assist the customer. Please allow our customer service and distribution centre a minimum of two hours from the time you call or email your order, before arriving to pick up.
              Pick up orders can qualify for a 3.5% pick up allowance on invoices of $200 or more. Back ordered items are not included or taxes/ eco fees.
            </div>
            <div
              class="mt-3"
              ref="international">
              <h3>Delivery to International Customers</h3>
              Lancaster Canada will prepay the shipment to the freight forwarder of your choice on orders. Minimums for prepaid shipments are still applicable. Customs and duties charges will be handled by the customer. Upon receipt of your order please inspect and report any issues with your freight forwarder within 48 hours.
            </div>
            <div
              class="mt-3"
              ref="charges">
              <h3>Pricing / Additional Charges</h3>
              While Lancaster Canada desires to maintain prices for as long as possible, often circumstances arise that are unexpected, yet warranted, where an immediate price adjustment must be made. As in any industry, we experience increases that are unavoidable.&nbsp;
              Fuel surcharges seem to be the most common of these and we will only charge this as we are being charged from our vendors and common carrier partners. We appreciate your willingness to work with us during these times and pledge our effort to control these costs.
            </div>
            <div
              class="mt-3"
              ref="backorder">
              <h3>Backorder Policy</h3>
              In the event an item you requested does not ship with your order, please reorder the item with your next shipment. With the exclusion of trade show orders, Lancaster Canada does not backorder any items, unless you request it.
            </div>
            <div
              class="mt-3"
              ref="special">
              <h3>Special Orders</h3>
              While Lancaster Canada inventories more than 14,000 items, occasionally our customers will need an item we do not stock. If this situation arises, we will be happy to place a special order of $200 or more to be shipped directly to your store from the&nbsp;
              manufacturer if the item is available to Canada. Please be aware that freight charges may apply to special orders if manufacturer prepaid minimums are not met. At Lancaster, we want to make sure you receive the item you are requesting; therefore, we will&nbsp;
              provide you with a special-order form detailing the specifics of your order. Your signature must be on this order form before it is submitted to the manufacturer. Special order items are non-returnable. Please contact your sales representative or our Customer&nbsp;
              Service Department if you need to place a special order.
            </div>
            <div
              class="mt-3"
              ref="sds">
              <h3>Safety Data Sheets (SDS)</h3>
              Lancaster Canada has access to material safety data sheets. Contact your sales representative or a customer service associate for additional information. Or visit the manufacturers/brand website 24 hours a day, 7 days a week.
            </div>
            <div
              class="mt-3"
              ref="warranty">
              <h3>Warranty</h3>
              Lancaster Canada recognizes the manufacturer's warranty as is in lieu of all other warranties expressed or implied, including warranties of merchantability or fitness for a particular purpose, and any other representations or&nbsp;
              obligations, including liability for indirect, punitive, incidental or consequential damages. This limitation of warranty and liability supersedes all prior representations or warranties of any kind.
            </div>
            <div
              class="mt-3"
              ref="terms">
              <h3>Payment Terms</h3>
              Lancaster Canada operates with terms of Net 30 Days. There will be a 1.5% monthly service charge on past due balances. Please assist yourself and us by avoiding these additional charges.
            </div>
            <div
              class="mt-3"
              ref="holiday">
              <h3>Everyone Needs a Day Off (Holiday Schedules)</h3>
              Lancaster observes the following as national holidays and operates under holiday schedules: 
              <ul>
                <li style="list-style-type: circle;">
                  New Year's Day
                </li>
                <li style="list-style-type: circle;">
                  Family Day
                </li>
                <li style="list-style-type: circle;">
                  Victoria Day
                </li>
                <li style="list-style-type: circle;">
                  1st of July
                </li>
                <li style="list-style-type: circle;">
                  Civic Holiday
                </li>
                <li style="list-style-type: circle;">
                  Labor Day
                </li>
                <li style="list-style-type: circle;">
                  Thanksgiving Day & the following Friday
                </li>
                <li style="list-style-type: circle;">
                  Christmas Eve & Christmas Day
                </li>
                <li style="list-style-type: circle;">
                  Boxing Day
                </li>
                <li style="list-style-type: circle;">
                  New Year's Eve
                </li>
              </ul>
              <br>
              Should any of these days fall on a weekend, we will observe either the Friday before or Monday after. Lancaster Canada appreciates your business and respects your comments. Please contact your sales representative or a member of the Customer Service Department with any concerns.
            </div>
            <div
              class="mt-3"
              ref="delivery">
              <h3>Delivery of Product</h3>
              Upon deliver please sign your bill of lading “SUBJECT TO INSPECTION”. Lancaster Canada applies a green pallet cap, clear stretch wraps, and attaches “Lancaster” custom security tape to all common carrier deliveries. If your shipment does not arrive with our pallet cap, tape, or other than clear stretch wrap,&nbsp;
              please mark bill of lading  NO PALLET CAP / TAPE. Note on the bill of lading any damage that is apparent. For any problems with your order (shortages, damages, wrong items, etc.), please contact Customer Service at 1-800-668-1124 within 48 hours. Please note: If bill of lading is not signed SUBJECT TO INSPECTION and&nbsp;
              reported within 48 hours, customers will be responsible for all shortages and damages.
            </div>
            <div
              class="mt-3"
              ref="damages">
              <h3>Damages / Shortages</h3>
              Upon inspection of your order and packing slip, if there are any inconsistencies or damage in your order, a freight claim will need to be filed with the carrier. Contact Lancaster Canada Customer Service to file claims, we will require images, packing slip number, and other relevant details in writting.&nbsp;
              All visible damage must be noted on the delivery receipt at time of delivery and you will need to report this information to Customer Service immediately upon receiving the shipment. Please remember that we will not be able to file a freight claim if the bill of lading is signed clear by the customer. On freight collect orders, you will need to contact the carrier directly to file a claim.
            </div>
            <div
              class="mt-3"
              ref="return">
              <h3>Return Policy</h3>
              <div>
                If Lancaster Canada made an error, Customer Service will authorize a return to our distribution center.  We will mail or email a call tag, if needed, or arrange to have the item picked up.  Instructions for the return will be e-mailed or faxed.  Items must be returned within 10 business days of the issuance of the call tag to the customer or the return authorization will be cancelled. 
              </div>
              <div style="margin-top:10px;">
                If a return is due to circumstances beyond Lancaster’s control (i.e. customer ordered the wrong item or quantity), this return will be subject to a restocking fee. Please contact Customer Service for return authorization and then contact UPS, FedEx, or a common carrier to arrange for the return. Freight charges and a 15% restocking fee will apply.
              </div>
              <div style="margin-top:10px;">
                Lancaster Canada will accept returns of any resalable products purchased from us within 60 days of purchase and must be accompanied by a Return Goods Authorization. Special order items, close-out specials, discontinued items, and final sales cannot be returned. Before the credit is issued an inspection of the returned products will be made and the following conditions must apply:
                <ul> 
                  <li style="list-style-type:circle;">
                    The product(s) must be returned in its original packaging
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) must not have price stickers attached 
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) must not have any prices or codes handwritten on them 
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) packaging must not be damaged
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) must not be damaged
                  </li>
                  <li style="list-style-type:circle;">
                    The product(s) must be included on the return goods authorization
                  </li>
                </ul>
                <strong>Lancaster Canada Territory Managers are not authorized to pick up any products.</strong>
              </div>
            </div>
            <div
              class="mt-3"
              ref="contact">
              <div class="row">
                <div class="col-md-6">
                  <h3>Who to contact</h3>
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Issue</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Order Errors</td>
                        <td>Customer Service</td>
                      </tr>
                      <tr>
                        <td>Damages/ Shortages</td>
                        <td>Customer Service</td>
                      </tr>
                      <tr>
                        <td>Prepaid Order</td>
                        <td>Customer Service</td>
                      </tr>
                      <tr>
                        <td>Collect Order</td>
                        <td>Carrier</td>
                      </tr>
                      <tr>
                        <td>Promotional Pricing or Terms (Lancaster Error)</td>
                        <td>Customer Service</td>
                      </tr>
                      <tr>
                        <td>Check the status of a pending order</td>
                        <td>Order Taker</td>
                      </tr>
                      <tr>
                        <td>Regular Pricing Discrepancies</td>
                        <td>Territory Manager</td>
                      </tr>
                      <tr>
                        <td>Defectives</td>
                        <td>Territory Manager</td>
                      </tr>
                      <tr>
                        <td>Samples</td>
                        <td>Territory Manager</td>
                      </tr>
                      <tr>
                        <td>Other Issues</td>
                        <td>Territory Manager</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
   methods:{
     goto(section){
       this.$refs[section].scrollIntoView({behavior: 'smooth'})
     }
   } 
  }
</script>