<template>
  <div
    class="col-lg-6 actions"
    style="overflow-x: hidden;">
    <!-- Actions -->
    <div
      v-if="!action && !copyInvoice"
      class="my-2">
      <button
        class="btn btn-primary me-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Cart will be copied to this ship to"
        :disabled="shipToList.length"
        @click="setAction('copy')">
        <i class="fa fa-copy"/>
        Copy Cart
      </button>
      <button
        class="btn btn-primary"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Cart will be moved to this ship to and removed from the current ship to"
        :disabled="shipToList.length"
        @click="setAction('move')">
        <i class="fa fa-dolly"/>
        Move Cart
      </button>
    </div>
    <div
      v-if="!action && copyInvoice"
      class="copy-actions">
      <div
        v-if="shipTo.id == activeShipToID"
        class="text-success my-1">
        <i class="fa fa-check-circle"/>
        Current Ship To
      </div>
      <button
        class="btn btn-primary"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Copy as new order to this ship to"
        :disabled="shipToList.length"
        @click="setAction('copyInvoice')">
        <i class="fa fa-check"/>
        Select  
      </button>
    </div>
    <!-- Action Indicators -->
    <!-- Copy Action Indicator-->
    <div
      v-if="action == 'copy'"
      class="text-center animate__animated animate__fadeInRight animate__faster my-2">
      <div class="text-success">
        <i class="fa fa-copy"/>
        Copy Cart
        <i
          class="fa fa-times text-muted"
          style="cursor: pointer;"
          @click="cancelAction"/>
      </div>
      <small class="text-muted">Copy cart to this ship to.</small>
    </div>
    <!-- Move Action Indicator-->
    <div
      v-if="action == 'move'"
      class="text-center animate__animated animate__fadeInRight animate__faster my-2">
      <div class="text-success ">
        <i class="fa fa-dolly"/>
        Move Cart
        <i
          class="fa fa-times text-muted"
          style="cursor: pointer;"
          @click="cancelAction"/>
      </div>
      <small class="text-muted">Move cart to this ship to and delete cart from the current ship to.</small>
    </div>
    <!-- Copy as New Order Action Indicator-->
    <div
      v-if="action == 'copyInvoice'"
      class="text-center animate__animated animate__fadeInRight animate__faster my-2">
      <div class="text-success ">
        <i class="fa fa-copy"/>
        Copy as New Order
        <i
          class="fa fa-times text-muted"
          style="cursor: pointer;"
          @click="cancelAction"/>
      </div>
      <small class="text-muted">Copy order as a new order to this ship to</small>
    </div>
  </div>
</template>

<script>
  export default{
    props: ['shipTo', 'cart', 'shipToList', 'copyInvoice', 'activeShipToID'],
    data(){
      return{
        action : null
      }
    },
    methods:{
      setAction(action){
        this.action = action
        if(action == 'copyInvoice'){
          this.$emit('setAction', {id: this.shipTo.id, action, invoice: this.copyInvoice})
        }else{
          this.$emit('setAction', {id: this.shipTo.id, action, cart_id: this.cart.details.id})
        }
      },
      cancelAction(){
        this.action = null
        this.$emit('setAction', {id: this.shipTo.id, action: null, cart_id: this.cart.details.id})
      }
    }
  }
</script>

<style scoped>
  .actions{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .copy-actions{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>