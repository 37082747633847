<template>
  <router-link
    v-if="product"
    class="col-lg-2 col-sm-4 col-6 sim-link position-relative"
    :to="`/product/${product.product_id}`">
    <Indicator :product="product"/>
    <div class="text-center">
      <img
        class="img-fluid"
        style="height:100px; object-fit: contain;"
        :src="product.image_url"
        @error="imgError">
    </div>
    <div class="max-text">{{product.description}}</div>
    <ProductPrice
      :product="product"
      :promoEndDate="false"
      :hideSelector="true"/>
  </router-link>
</template>

<script>
  import ProductPrice from '../price/ProductPrice.vue';
  import Indicator from '../Indicator.vue';
  import comingSoon from '../../../../../shared/comingSoon';

  export default{
    props: ['product'],
    components:{
      ProductPrice,
      Indicator
    },
    methods:{
      imgError(e){comingSoon(e)}
    }
  }
</script>

<style scoped>
  .max-text{
    overflow: hidden;
    max-height: 75px;
  }
  .sim-link{
    text-decoration: none;
  }
</style>