<template>
  <div class="row animate__animated animate__fadeIn animate__faster">
    <h5 v-if="!activeFav">Favorite Groups</h5>
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
    <div
      v-if="!loading && !activeFav"
      class="row">
      <template v-if="favorites && favorites.length">
        <div
          v-for="f in favorites"
          :key="f.id"
          class="col-md-6 col-lg-4">
          <div
            class="card my-2"
            @click="setActive(f)">
            <div class="card-body">
              <div>
                Name: {{f.name}}
              </div>
              <div>
                Description: {{f.description || 'N/A'}}
              </div>
            </div>
            <div class="card-footer">
              Products({{f.favorite_products.length}})
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-md-12 text-center text-muted mt-5">
          <h4>No Favorites</h4>
        </div>
      </template>
    </div>
    <Favorite
      v-if="activeFav"
      :fav="activeFav"
      @resetFav="resetFav"/>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import Favorite from './Favorite.vue'

  export default {
    data(){
      return{
        loading:true,
        activeFav: null
      }
    },
    components:{
      Spinner,
      Favorite
    },
    watch:{
      favorites(newFavs){
        if(newFavs){
          this.loading = false
        }
      }
    },
    created(){
      this.$store.dispatch('getFavorites')
    },
    methods:{
      setActive(fav){
        this.activeFav = fav
      },
      resetFav(){
        this.activeFav = null
      }
    },
    computed:{
      favorites(){return this.$store.getters.favorites}
    }
  }
</script>

<style scoped>
  .card:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: .02s ease-in-out;
    box-shadow: 2px 2px 10px lightgray;
  }
</style>