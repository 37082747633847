<template>
  <div
    v-if="inboundInv && branch_id != '71'"
    class="text-muted">
    <i
      class="fa fa-info-circle"
      title="Inventory on order that is not yet availble in warehouse but is on the way!"/>
    <span>
      Inbound: {{ numeral(inboundInv.qty).format('0,0') }} {{ displayUom(inboundInv.uom) }}
    </span>
  </div>
</template>

<script>
  import { inboundAvail } from '../../../../../shared/availability'
  import { displayUom } from '../../../../../shared/ProductFunctions'
  
  export default{
    props: ['product', 'branch_id', 'activeUom'],
    methods:{
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      inboundInv(){return inboundAvail(this.product, this.branch_id, this.activeUom)}
    }
  }
</script>