<template>
  <!-- human.resources@lancasterco.com -->
  <div>
    <input
      type="text"
      class="form-control my-2"
      placeholder="First Name"
      v-model="form.firstName"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Last Name"
      v-model="form.lastName"
      required>
    <input
      type="email"
      class="form-control my-2"
      placeholder="Email Address"
      v-model="form.email"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Address"
      v-model="form.address"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="City"
      v-model="form.city"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="State"
      v-model="form.state"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Zip"
      v-model="form.zip"
      required>
    <input
      type="text"
      class="form-control my-2"
      placeholder="Phone Number"
      v-model="form.phone"
      required>
    <textarea
      type="text"
      rows="5"
      cols="5"
      class="form-control my-2"
      v-model="form.comments"
      placeholder="Additional Comments"/>
    <!-- <label>Attach Resume</label> -->
    <!-- <br>
    <input
      type="file"
      accept=".pdf, .doc, .docx"
      @change="processFile"> -->
  </div>
</template>

<script>
  export default {
    props: ['form'],
    methods:{
      processFile(e){
        this.form.file = e.target.files[0]
      }
    }
  }
</script>