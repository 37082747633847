<template>
  <div>
    <div class="row">
      <div
        class="col-md-6 col-12 bg-event p-5 text-white position-relative"
        style="min-height: 250px;">
        <div class="centered">
          <h2 style="font-weight: 400;">BUYING SHOWS</h2>
          <p>All Lancaster customers are invited to the buying events to stock up for the upcoming busy seasons.</p>
        </div>
      </div>
      <div
        class="col-md-6 d-none d-md-block"
        :style="shows"/>
    </div>
    <div class="container-fluid d-none d-md-block">
      <div class="row m-5 px-5 text-center">
        <div class="col-md-4">
          <strong>✓ Cash Spiffs</strong>
          <br>
          <strong>✓ Delayed Shipping & Extended Payment Terms</strong>
          <br>
          <strong>✓ Discounted Host Hotel Rates</strong>
          <br>
          <strong>✓ Family Friendly Live Entertainment</strong>
        </div>
        <div class="col-md-4">
          <strong>✓ Learn about New Products</strong>
          <br>
          <strong>✓ Live Product Demonstrations</strong>
          <br>
          <strong>✓ NetworkWith Fellow Retailers</strong>
          <br>
          <strong>✓ Prize Giveaways</strong>
        </div>
        <div class="col-md-4">
          <strong>✓ Seminars</strong>
          <br>
          <strong>✓ Travel And Lodging Reimbursements</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        shows: {
          backgroundImage: `url(${require('../../../../../../assets/images/lan/events/buyingShows.jpg')})`,
          ...this.bgProperties()
        }
      }
    },
    methods:{
      bgProperties(){
        return {
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '300px'
        }
      }
    }
  }
</script>

<style scoped>
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    width: 85%;
    transform: translateY(-50%);
  }
</style>