<template>
  <div
    v-if="vShowAllowed && !inVshow"
    class="form-check form-switch"
    style="cursor: pointer;">
    <label
      class="form-check-label">
      <i class="fa fa-laptop"></i>
      Show Virtual Show Results
    </label>
    <input
      class="form-check-input ms-1"
      style="float:right; cursor:pointer;"
      type="checkbox"
      @click="vShowCameraToggle"
      :checked="vShowCameraResults">
  </div>
</template>

<script>
  export default {
    methods:{
      vShowCameraToggle(){
        this.$store.dispatch('vShowCameraToggle')
      }
    },
    computed:{
      vShowCameraResults(){return this.$store.getters.vShowCameraResults},
      vShowAllowed(){return this.$store.getters.vShowAllowed},
      inVshow(){return this.$router.currentRoute.value.path == '/virtual_show'}
    }
  }
</script>