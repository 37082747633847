<template>
  <div>
    <button
      class="btn btn-secondary btn-sm d-block d-sm-none mobile-filter"
      :style="{'top': virtualShow ? '132px' : '60px'}"
      data-bs-toggle="offcanvas"
      data-bs-target="#mobileFilterControls"
      :disabled="loading">
      <i class="fa fa-filter"/>
    </button>
  </div>
</template>

<script>
  export default {
    props: ['loading', 'virtualShow']
  }
</script>


<style scoped>
  .mobile-filter{
    position: fixed;
    left: 6px;
    border-radius: 50%;
    opacity: 0.7;
    z-index: 1;
    height: 2.5rem;
    width: 2.5rem;
    border: 2px solid var(--bs-primary);
  }
</style>