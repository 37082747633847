<template>
  <div class="row">
    <!-- Manager Dashboard Header -->
    <div
      v-if="!activeTm"
      class="col-md-12 p-3"
      style="color: white;background: var(--bs-primary);">
      <h4 class="text-center">Manager Dashboard</h4>
      <form
        name="custSearch"
        class="input-group mt-3"
        style="max-width:350px; margin: 0 auto;"
        @submit.prevent="submitSearch">
        <input
          type="text"
          class="form-control"
          placeholder="Search Customers..."
          autocomplete="off"
          autocapitalize="off"
          spellcheck="false"
          v-model="query"
          required>
          <i
            v-if="query"
            class="fa fa-times text-danger clear"
            @click="clearQuery"/>
        <button
          class="btn btn-outline-light">
          <i class="fa fa-search"/>
        </button>
      </form>
    </div>
    <!-- Customer view content -->
    <div>
      <div class="active-tm-wrapper">
        <ActiveTmCard
          v-if="activeTm"
          :tm="activeTm"
          @deleteActive="deleteActiveTm"/>
      </div>
      <div
        v-if="showTeam"
        class="col-md-12">
        <BrowseTms
          v-if="!activeTm"
          :tmList="tmList"
          :user="user"
          @setActiveInManager="setActiveTm"/>
      </div>
      <div
        v-if="!showTeam"
        class="col-md-12">
        <CustomerList class="mt-3"/>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import ActiveTmCard from './ActiveTmCard.vue'
  import BrowseTms from './BrowseTms.vue'
  import CustomerList from './CustomerList.vue'
  
  export default {
    data(){
      return{
        tmList: null,
        activeTm: null,
        query: null,
        showTeam: true
      }
    },
    components:{
      ActiveTmCard,
      BrowseTms,
      CustomerList
    },
    watch:{
      query(newVal){
        if(!newVal){
          this.clearQuery()
        }
      }
    },
    methods:{
      getTMList(){
        axios.get('/api/v1/private/tm/tm_list').then(function(res){
          this.tmList = res.data.tm_list
        }.bind(this))
      },
      setActiveTm(tm){
        this.activeTm = tm
      },
      deleteActiveTm(){
        this.activeTm = null
      },
      submitSearch(){
        if(this.query){
          this.showTeam = false
          this.$store.dispatch('managerCustomerSearch', this.query)
          document.activeElement.blur()
        }
      },
      clearQuery(){
        this.query = null
        this.showTeam = true
        this.$store.dispatch('clearTmCustomerList')
      },
      hideDashboard(){
        this.$store.dispatch('setRepDashboardView', false)
      },
      searchCustomer(id){
        this.query = ('.' + id).toString()
        this.submitSearch()
      }
    },
    computed:{
      user(){return this.$store.getters.user}
    },
    created(){
      this.getTMList()
    }
  }
</script>

<style scoped>
  .active-tm-wrapper{
    overflow: hidden;
    position: relative;
  }
  .clear{
    position: absolute;
    right: 55px;
    top: 1px;
    z-index: 3;
    padding: 10px;
  }
  .clear:hover{
    cursor: pointer;
  }
</style>