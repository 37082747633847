<template>
  <div style="margin-top: 85px;">
    <Header/>
    <EjwAdvan/>
    <Footer/>
  </div>
</template>

<script>
  import Header from './Header.vue'
  import EjwAdvan from './EjwAdvan.vue'
  import Footer from '../app/Footer.vue'

  export default {
    components:{
      Header,
      EjwAdvan,
      Footer
    }
  }
</script>