<template>
  <!-- customer.service@lancasterco.com -->
  <div class="mb-5">
    <form
      v-if="!confirm"
      name="generalForm"
      @submit.prevent="submit">
      <input
        type="text"
        class="form-control my-2"
        placeholder="First Name"
        v-model="form.firstName"
        required>
      <input
        type="text"
        class="form-control my-2"
        placeholder="Last Name"
        v-model="form.lastName"
        required>
      <input
        type="text"
        class="form-control my-2"
        placeholder="Company Name"
        v-model="form.company"
        required>
      <input
        type="text"
        class="form-control my-2"
        placeholder="Address"
        v-model="form.address"
        required>
      <input
        type="text"
        class="form-control my-2"
        placeholder="City"
        v-model="form.city"
        required>
      <input
        type="text"
        class="form-control my-2"
        placeholder="State"
        v-model="form.state"
        required>
      <input
        type="text"
        class="form-control my-2"
        placeholder="Zip"
        v-model="form.zip"
        required>
      <input
        type="email"
        class="form-control my-2"
        placeholder="Email Address"
        v-model="form.email"
        required>
      <input
        type="text"
        class="form-control my-2"
        placeholder="Phone Number"
        v-model="form.phone"
        required>
      <textarea
        type="text"
        rows="5"
        cols="5"
        class="form-control my-2"
        placeholder="Comments / Questions"
        v-model="form.comments"/>
      <button class="btn btn-outline-primary submit">Submit</button>
    </form>
    <!-- Confirm Message -->
    <div v-if="confirm">
      <div class="text-center text-success my-5 animate__animated animate__fadeIn">
        <h4>Form Submitted!</h4>
        <small>We'll be in touch shortly.</small>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    props: ['form'],
    data(){
      return{
        confirm: false
      }
    },
    methods:{
      completeForm(){
        // this.form = {}
        this.confirm = true
        setTimeout(function(){
          for(const key in this.form){
            delete this.form[key]
          }
          this.confirm = false
        }.bind(this), 5000)
      },
      submit(){
        const params = {
          form: this.form,
          activeForm: null
        }
        axios.post('/api/v1/public/contact/submit_contact_form', params).then(res=>{
          if(res.status == 200){
            this.completeForm()
          }
        })
      }
    }
  }
</script>

<style scoped>
  .submit{
    width: 200px;
    position: relative;
    left: 50%;
    transform: translatex(-50%);
  }
</style>