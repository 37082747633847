// The purpose of this class is to build out vue router routes for the
// different business sides of the app based on environment env variable
import _ from 'lodash'

class RouteBuilder{
  constructor(env, publicRoutes, internalRoutes){
    this.env = env
    this.publicRoutes = publicRoutes
    this.internalRoutes = internalRoutes
    this.routes = null
    this.#buildRoutes()
    return this.routes
  }

  #buildRoutes(){
    this.routes = [...this.publicRoutes[this.env]]
    let match = _.find(this.routes, {name: 'PublicHome'})
    match.children = this.internalRoutes
  }
}

export default RouteBuilder;