<template>
  <div class="container-fluid">
    <div class="row mt-2">
      <!-- Header -->
      <div class="col-12">
        <h4>User Management</h4>
      </div>
      <div
        class="col-12 my-2 text-primary">
        <h5> My User Profile</h5>
      </div>
      <!-- Logged In Contact Section -->
      <ContactCard class="mb-3" v-if="loggedInContact" :contact="loggedInContact" />
      <!-- Spinner -->
      <div
        v-if="!loggedInContact"
        class="col-12 text-center my-3 d-flex align-items-center">
        <Spinner/>&nbsp;&nbsp;Getting your user details
      </div>

      <div class="col-12" v-if="user.superuser">
        <ManageContacts 
          v-if="relatedEntityList" 
          :relatedEntityList="relatedEntityList"
          :entityNameList="entityNameList" 
          :newContacts="newContacts"
          :newContactIDs="newContactIDs"
          @clearNewContacts="clearNewContacts"
          @addNewSuccess="addNewContactsToList" />
        <div
          v-else
          class="text-center my-3 d-flex align-items-center">
          <Spinner/>&nbsp;&nbsp;Fetching all users for your Bill To account
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import ContactCard from './components/ContactCard.vue'
  import AddNewContact from './components/AddNewContact.vue'
  import NewContactList from './components/NewContactList.vue'
  import ManageContacts from './components/ManageContacts.vue'
  import Spinner from '../../../global/Spinner.vue'
  
  export default {
    data(){
      return{
        loggedInContact: null,
        editing: false,
        editPassword: false,
        detailConfirm: false,
        passwordConfirm: false,
        previousContactIdList: null,
        contactIdList: null,
        entityNameList: null,
        relatedEntityList: null,
        newContacts: [],
        newContactIDs: []
      }
    },
    components:{
      Spinner,
      ContactCard,
      AddNewContact,
      NewContactList,
      ManageContacts
    },
    created(){
      if(!this.settings.userManagement){
        this.$router.push({path: '/'})
      }else{
        this.dataFetch()
        if(this.user.superuser){
          this.getContacts()
        }
      }
    },
    methods:{
      dataFetch(){
        axios.get('/api/v1/private/user_management/contact_details').then(res => {
          this.loggedInContact = res.data.contact
        })
      },
      getContacts(){
        axios.get('/api/v1/private/superuser/get_contacts').then(res => {
          if(res.status == 200){
            //this is used to switch between all ship tos and edit contacts for that ship to account
            this.relatedEntityList = res.data.related_entity_list
            //used to generate location select options on add new user form
            this.entityNameList = res.data.entity_name_list            
            //this list is used to determine the contact id for new users without reloading the page
            this.contactIdList = res.data.contact_id_list
          }
        })
      },
      addNewContactsToList(data){
        this.newContacts.push(data)
        this.previousContactIdList = this.contactIdList
        this.getContacts()
      },
      clearNewContacts(){
        this.newContacts = []
      }
    },
    watch:{
      contactIdList(NewVal,OldVal){
        if(OldVal != null && NewVal != OldVal && this.newContacts.length > 0){
          // add the latest contact id to the last new user. allows editing without reloading page.
          let new_id = _.difference(this.contactIdList,this.previousContactIdList)[0]
          _.last(this.newContacts).contact_id = new_id
          this.newContactIDs.push(new_id)
        }      
      }
    },
    computed:{
      user(){return this.$store.getters.user},
      settings(){return this.$store.getters.settings}
    }
  }
</script>

<style scoped>
  .contact{
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    min-height: 85px;
  }
  .contact-icon{
    font-size: 48px;
  }
  .action-btns{
    position: absolute;
    bottom: 2px;
    right: -10px;
    width: 220px;
  }
  .action-btns, button{
    font-size: 12px;
  }
</style>