import axios from 'axios'

// Get type ahead product suggestions
export const typeAheadSuggestions = (query) => {
  const params = {query}
  const response = axios.get('/api/v1/private/product/type_ahead', {params})
  return response
}

// Get type ahead user search history
export const typeAheadHistory = () => {
  const response = axios.get('/api/v1/private/product/type_ahead')
  return response
}

// Provides keyboard controls to the type ahead menu
export const keyboardControl = function(){
  // Navigate Dropdown via up and down arrow keys, esc to exit, enter to select
  document.onkeyup = function(e){
    // Valid keys for keyboard nav 
    const keys = ['ArrowUp', 'ArrowDown', 'Escape', 'Enter']
    if(this.navData?.data?.length && keys.includes(e.key)){
      // Exit keyboard mode via escape
      if(e.key == 'Escape'){
        this.position = null
        document.getElementById('searchInput').focus()
        return
      }
      // Select Product
      if(e.key == 'Enter'){
        if(this.position == null){
          this.display = false
          return
        }
        // build object key based on navData mode for submit function down the line
        const modeSubmit = ()=>{
          document.activeElement.blur()
          if(this.navData.mode == 'suggestions'){
            this.submitProduct({id: this.navData.data[this.position].product_id})
          }
          if(this.navData.mode == 'history'){
            this.submitSearch(this.navData.data[this.position])
          }
        }
        modeSubmit()
      }
      // Set nav limit
      const indexCount = this.navData.data.length - 1
      // Unfocus search box text input
      if(['ArrowUp', 'ArrowDown'].includes(e.key) && this.position == null) document.activeElement.blur()
      // navigate elements when position is defined
      if(e.key == 'ArrowUp'){
        if(this.position == null || this.position == 0){
          this.position = indexCount
        }else{
          this.position -= 1
        }
      }
      if(e.key == 'ArrowDown'){
        if(this.position == null || this.position == indexCount){
          document.activeElement.blur()
          this.position = 0
        }else{
          this.position += 1
        }
      }
    }
  }.bind(this)
}

// Auto close dropdown on click outside of dropdown element or search field
export const closeOnClickOutside = function(){
  document.onclick = function(e){
    const allowedAreas = ['mainSearch', 'typeAheadDropDown']
    const parent = e.target.closest('section')
    // Click inside allowed elements
    if(parent && parent.id && allowedAreas.includes(parent.id)){
      return
    }else{
      // Click outside allowed elements
      this.display = false
    }
  }.bind(this)
}

export const resetPositiononMouseMove = function(){
  // Reset keyboard position on mouse move
  window.onmousemove = function(e){
    if(this.position != null) this.position = null
  }.bind(this)  
}