<template>
  <section>
    <div
      class="row"
      style="background: #3c464d;">
      <!-- Left -->
      <div
        class="col-lg-4 col-md-6 col-12 text-white position-relative"
        style="min-height:300px;">
        <div class="centered">
          <h4 class="font-size: 20px;">SERVICING 10,000+</h4>
          <ul 
            class="p-0"
            style="list-style-type: none;">
            <li>PAINT</li>
            <li>HARDWARE</li>
            <li>INDUSTRY SUPPLY</li>
            <li>FARM SUPPLY</li>
            <li>AND OTHER VARIOUS RETAIL CENTERS</li>
          </ul>
        </div>
      </div>
      <!-- Middle -->
      <div class="col-lg-4 col-md-6 col-6 d-none d-md-block">
        <video
          muted
          loop
          controls
          style="height: 100%; width:100%; min-height: 100%; min-width:100%; object-fit:cover;">
          <source src="https://merit-production.s3.amazonaws.com/lanEstore/video/LandingPageVideo_HQ.mp4" type="video/mp4">
        </video>
      </div>
      <!-- Right -->
      <div class="col-md-4 col-12 p-3 text-white position-relative d-none d-lg-block">
        <div class="centered">
          <strong>Our comprehensive mix of products, promotions and programs allow our customers to stay profitable and competitive in today's demanding marketplace.</strong>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .centered{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 19em;
    transform: translate(-50%, -50%);
  }
</style>