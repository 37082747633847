<template>
  <!-- customer.service@lancasterco.com -->
  <div>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.firstName"
      maxlength="100"
      placeholder="First Name"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.lastName"
      maxlength="100"
      placeholder="Last Name"
      required>
    <input
      type="email"
      class="form-control my-2"
      v-model="form.email"
      maxlength="100"
      placeholder="Email Address"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.company"
      maxlength="100"
      placeholder="Company Name"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.address"
      maxlength="100"
      placeholder="Address"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.city"
      maxlength="100"
      placeholder="City"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.state"
      maxlength="20"
      placeholder="State"
      required>
    <input
      type="text"
      class="form-control my-2"
      v-model="form.zip"
      maxlength="10"
      placeholder="Zip"
      required>
    <input
      type="phone"
      class="form-control my-2"
      v-model="form.phone"
      maxlength="20"
      placeholder="Phone Number"
      required>
    <div class="my-2">
      <label>What is your main type of business?</label>
      <div class="px-4">
        <div class="form-check form-switch mt-2">
          <input
            class="form-check-input"
            v-model="form.typeOfBusiness.eCommerce"
            type="checkbox">
          <label class="form-check-label">eCommerce</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.typeOfBusiness.retail"
            type="checkbox">
          <label class="form-check-label">Flooring Retail</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.typeOfBusiness.commercial"
            type="checkbox">
          <label class="form-check-label">Commercial Flooring</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.typeOfBusiness.builderMultiFamily"
            type="checkbox">
          <label class="form-check-label">Builder / Multi-Family Flooring</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.typeOfBusiness.hardware"
            type="checkbox">
          <label class="form-check-label">Hardware</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.typeOfBusiness.lumber"
            type="checkbox">
          <label class="form-check-label">Lumber</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.typeOfBusiness.other"
            type="checkbox">
          <label class="form-check-label">Other</label>
        </div>
      </div>
    </div>
    <div class="my-2">
      <label>Are you a resale business?</label>
      <br>
      <input
        type="radio"
        class="form-check-input"
        value="false"
        v-model="form.resaleBusiness"
        checked>
      <label class="form-check-label mx-1">No</label>
      <input
        type="radio"
        class="form-check-input"
        value="true"
        v-model="form.resaleBusiness">
      <label class="form-check-label mx-1">Yes</label>
    </div>
    <div class="my-2">
      <label>What do you anticiapte your annual purchases to be?</label>
      <input
        type="number"
        class="form-control my-2"
        v-model="form.anticipatedSales"
        placeholder="Dollar Amount">
    </div>
    <div class="my-2">
      <label>How many years have you been in business?</label>
      <input
        type="number"
        class="form-control my-2"
        v-model="form.yearsInBusiness">
    </div>
    <div class="my-2">
      <label>What items are you interested in?</label>
      <input
        type="text"
        class="form-control my-2"
        v-model="form.interestedIn"
        placeholder="Items of interest">
    </div>
    <div class="my-2">
      <label>What is your company website?</label>
      <input
        type="text"
        class="form-control my-2"
        v-model="form.companyWebsite"
        placeholder="URL">
    </div>
    <div class="my-2">
      <label>Do you have any of the following?</label>
      <div class="px-3">
        <div class="form-check form-switch mt-2">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="form.businessHas.retailStores">
          <label class="form-check-label">Retails Store</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.businessHas.warehouse"
            type="checkbox">
          <label class="form-check-label">Warehouse</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.businessHas.distribution"
            type="checkbox">
          <label class="form-check-label">Distribution Center</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="form.businessHas.onlineSales"
            type="checkbox">
          <label class="form-check-label">Online Sales</label>
        </div>
      </div>
    </div>
    <textarea
      type="text"
      rows="5"
      cols="5"
      class="form-control my-2"
      v-model="form.comments"
      maxlength="300"
      placeholder="Additional Comments"/>
  </div>
</template>

<script>
  export default {
    props: ['form'],
    created(){
      this.form.resaleBusiness = false
      this.form.anticipatedSales = null
      this.form.yearsInBusiness = null
      this.form.interestedIn = null
      this.form.companyWebsite = null
      this.form.typeOfBusiness = {
        eCommerce: false,
        retail: false,
        commercial: false,
        builderMultiFamily: false,
        hardware: false,
        lumber: false,
        other: false
      }
      this.form.businessHas = {
        retailStores: false,
        warehouse: false,
        distribution: false,
        onlineSales: false
      }
    }
  }
</script>