<template>
  <div
    :ref="'card-' + product.product_id"
    class="w-100 p-2 my-1 shadow-sm position-relative"
    style="height:275px; border: 1px solid lightgray; border-radius: 10px; position:relative; background:white;">
    <div>
      <span
        v-if="product.voc_item"
        data-bs-toggle="modal"
        data-bs-target="#publicInfoModal"
        class="badge bg-danger"
        style="cursor: pointer; position: absolute; top: 5px; left: 5px; font-size: 10px;"
        title="May not be available in your area due to VOC restrictions. Please contact us for more information">
        * VOC Restricted
      </span>
      <div
        class="d-flex justify-content-center mb-2"
        style="height:150px;overflow:hidden;">
        <img 
          v-if="visible"
          class="img-fluid mt-3"
          :src="product.image_url"
          style="object-fit:contain;"
          @error="imgError">
      </div>
      <div>
        <!-- Description -->
        <div
          class="my-2"
          style="overflow: hidden; height: 35px; line-height: 18px;">
          <span class="text-muted">{{product.description}}</span>
        </div>
      </div>
      <div class="text-muted" style="font-size:12px;">
        <small class="d-block">
          UPC: {{ product.upc }}
        </small>
      </div>
      <!-- Prop 65 -->
      <div
        class="mt-1"
        style="line-height: 15px; font-size: 12px;">
        <small>
          <a
            class="text-danger"
            href="https://www.P65Warnings.ca.gov"
            target="_blank">
            {{ product.prop65_warning }}
          </a>
        </small>
      </div>
    </div>
  </div> 
</template>

<script>
  import comingSoon from '../../../../shared/comingSoon'
  import Prop65 from '../../../private/views/product/Prop65.vue'
  
  export default {
    props:['product'],
    data(){
      return{
        visible: false
      }
    },
    mounted(){
      if(this.$refs[`card-${this.product.product_id}`]){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs[`card-${this.product.product_id}`])
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    methods:{
      imgError(e){comingSoon(e)},
      handleIntersection(entries, observer){
        for (let entry of entries) {
          if (entry.isIntersecting){
            this.visible = true
          }
        }
      }
    }
  }
</script>

<style scoped>
  .card-avail{
    position: absolute;
    left: 0px;
    bottom: 65px;
    width: 100%;
    padding: 0px;
  }
</style>