import store from '../stores/store'
import { promoProductV2 } from '../shared/promoHelpers'
import { showDetail } from '../shared/showDetail'

//Virtual Show Logic not present
class PriceDetail {

  pricing = {
    price: 0,
    unitPrice: 0,
    uom: '',
    priceUom: '',
    promo: false,
    promoEndDate: null,
    closeout: false,
    vShow: false,
    bestPrice:false,
    originalPrice: 0,
    calcOriginalPrice: 0,
    eclipsePrice: 0
  }

  constructor(product, virtualShow, company){
    this.product = product
    this.virtualShow = virtualShow
    this.company = company
    this.hidePricing = store.getters.hidePricing
    this.#calcPrice()
    return this.pricing
  }

  #calcPrice(){
    // Set base values
    const priceData = _.find(this.product.eclipse_data.uom_pricing, {uom: this.product.eclipse_data.uom})
    this.promoProduct = promoProductV2(this.product)
    this.pricing.unitPrice = this.product.eclipse_data.unit_price
    this.pricing.priceUom = this.product.eclipse_data.price_uom

    if(priceData){
      // Virtual Show Pricing
      if(this.virtualShow){
        const showDetailData = showDetail(this.product)
        if(showDetailData){
          this.pricing.price = showDetailData.show_price
          this.pricing.originalPrice = showDetailData.standard_price
          this.pricing.uom = 'ea'
          this.pricing.eclipsePrice = priceData?.price
          if(this.pricing.eclipsePrice < this.pricing.price) this.pricing.bestPrice = true
          this.pricing.vShow = true
        }
      }
      // Promo Pricing
      else if(!this.virtualShow && this.promoProduct){
        // Set Promo Pricing
        this.pricing.promo = true
        this.pricing.price = this.promoProduct.promo_price
        this.pricing.uom = this.product.eclipse_data.uom
        this.pricing.originalPrice = this.promoProduct.class3_price
        this.pricing.eclipsePrice = priceData.price
        this.pricing.promoEndDate = this.promoProduct.end_date
        
        // Check to see if customers standard price is better than the promo price
        // If so flagging best price will display the customers price instead of the promo price
        if(this.pricing.eclipsePrice < this.pricing.price) this.pricing.bestPrice = true
      }else{
        // Standard && Closeout Pricing
        this.pricing.price = priceData.price
        this.pricing.uom = priceData.uom
        // Set Closeout Data if applicable
        const authBranches = store.getters.getEntity.activeShipTo.auth_branches
        const closeoutBranches = this.product.closeout_branches?.split(',')
        const closeoutAuthorized = closeoutBranches ? closeoutBranches.some(cb => authBranches.includes(cb)) : false
        const closeoutData = closeoutAuthorized && this.product.original_price ? JSON.parse(this.product.original_price) : null
        if(closeoutAuthorized){
          this.pricing.closeout = true
          this.pricing.originalPrice = closeoutData && closeoutData[this.company] ? closeoutData[this.company] : null
        }
      }
    }
  }

}

export const productPricing = (product, virtualShow, company)=>{
  return new PriceDetail(product, virtualShow, company)
}