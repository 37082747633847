<template>
  <nav
    class="navbar navbar-expand-md navbar-dark bg-primary fixed-top"
    ref="publicNav">
    <div class="container">
      <a
        class="navbar-brand"
        @click="goto('PublicHome')">
        <!-- Standard Logo -->
        <Logo/>
      </a>
      <button class="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavId">
        <ul class="navbar-nav ms-auto mt-2 mt-lg-0">
          <li
            class="nav-item"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavId"
            @click="goto('PublicHome')">
            <div
              class="nav-link"
              :class="{active: route == '/'}">
              Home
            </div>
          </li>
          <li
            class="nav-item"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavId"
            @click="goto('About')">
            <div
              class="nav-link"
              :class="{active: route == '/about'}">
              About
            </div>
          </li>
          <li
            class="nav-item"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavId"
            @click="goto('Advantage')">
            <div
              class="nav-link"
              :class="{active: route == '/advantage'}">
              Advantage
            </div>
          </li>
          <li
            class="nav-item"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavId"
            @click="goto('Brands')">
            <div
              class="nav-link"
              :class="{active: route == '/brands'}">
              Brands
            </div>
          </li>
          <li
            class="nav-item"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavId"
            @click="goto('Contact')">
            <div
              class="nav-link"
              :class="{active: route == '/contact'}">
              Contact
            </div>
          </li>
          <li
            v-if="route != '/'"
            class="nav-item ms-2">
            <button
              class="btn btn-outline-light"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavId"
              style="min-width: 110px; border-radius: 5px;"
              @click="goto('PublicHome')">
              <i class="fa fa-sign-in-alt"/>
              <span class="ms-2">Login</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  import Logo from '../../../global/ejw/Logo.vue'
  export default {
    components:{
      Logo
    },
    methods:{
      goto(name){
        this.$router.push({name})
        window.scrollTo(0,0)
      }
    },
    computed:{
      route(){
        return this.$router.currentRoute.value.path
      }
    }
  }
</script>

<style scoped>
  .nav-item{
    cursor: pointer;
  }
  .active{
    border-radius: 5px;
    padding: 10px;
    background: rgb(255,255,255,0.3);
  }
  .navbar-brand{
    cursor: pointer;
  }
</style>