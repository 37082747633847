<template>
  <div class="row">
    <!-- Authorization -->
    <ArPassword v-if="!authorized && !loading"/>
    <!-- Account Status Details -->
    <div
      v-if="authorized && !loading"
      class="col-md-12 mt-3">
      <div class="row">
        <div class="col-md-6">
          <table class="table table-striped table-hover table-sm">
            <thead>
              <strong>Aging</strong>
            </thead>
            <tbody>
              <tr>
                <td>Current</td>
                <td>{{numeral(detail.future).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="javascript: void(0);"
                    @click="setAging('1-30')">
                    1-30
                  </a>
                </td>
                <td>{{numeral(detail.current).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>
                  <a 
                    href="javascript: void(0);"
                    @click="setAging('31-60')">
                    31-60
                  </a>
                </td>
                <td>{{numeral(detail.thirty).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>
                  <a 
                    href="javascript: void(0);"
                    @click="setAging('61-90')">
                    61-90
                  </a>
                </td>
                <td>{{numeral(detail.sixty).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>
                  <a 
                    href="javascript: void(0);"
                    @click="setAging('91-120')">
                    91-120
                  </a>
                </td>
                <td>{{numeral(detail.ninety).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="javascript: void(0);"
                    @click="setAging('120+')">
                    120+
                  </a>
                </td>
                <td>{{numeral(detail.one_twenty).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{{numeral(detail.total).format('$0,0.00')}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table table-striped table-hover table-sm">
            <thead>
              <strong>Orders</strong>
            </thead>
            <tbody>
              <tr>
                <td>Deposits</td>
                <td>{{numeral(detail.deposits).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>Orders</td>
                <td>{{numeral(detail.orders).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>MTD Sales</td>
                <td>{{numeral(detail.mtd_sales).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>YTD Sales</td>
                <td>{{numeral(detail.ytd_sales).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>Days to Pay</td>
                <td>{{detail.payment_days}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table table-striped table-hover table-sm">
            <thead>
              <strong>Credit</strong>
            </thead>
            <tbody>
              <tr>
                <td>Credit Limit</td>
                <td>{{numeral(detail.credit_limit).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>Credit Available</td>
                <td>{{numeral(detail.credit_available).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>Last Sale</td>
                <td>{{detail.last_sale_date}}</td>
              </tr>
              <tr>
                <td>Last Sale $</td>
                <td>{{numeral(detail.last_sale_amount).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>Last Payment</td>
                <td>{{detail.last_payment_date}}</td>
              </tr>
              <tr>
                <td>Last Payment $</td>
                <td>{{numeral(detail.last_payment_amount).format('$0,0.00')}}</td>
              </tr>
              <tr>
                <td>Terms</td>
                <td>{{detail.terms}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table table-striped table-hover table-sm">
            <tbody>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import ArPassword from './ArPassword.vue'
  
  export default {
    props: ['detail', 'authorized'],
    components:{
      Spinner,
      ArPassword
    },
    methods:{
      setAging(value){
        this.$store.dispatch('setActiveAging', value)
      }
    },
    computed:{
      loading(){return this.$store.getters.getAccountLoadStatus}
    }
  }
</script>