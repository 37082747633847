<template>
  <section class="my-5">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2 style="font-weight: 400;">DIP IN</h2>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-4 text-center">
          <div>
            <img
              :src="magenta"
              class="img-fluid brush"
              data-bs-toggle="modal"
              data-bs-target="#sundrySceneModal"/>
            <h4 class="dip-title magenta">
              SUNDRY SCENE
              <br>
              MAGAZINE
            </h4>
            <hr class="magenta-bar">
            <div style="height:80px;">
              <p>
                Gain access to our in-house industry magazine.
                <small>*USA ONLY</small>
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4 text-center">
          <div>
            <img
              :src="orange"
              class="img-fluid brush"
              @click="goto('Events')"/>
            <h4 class="dip-title orange">EVENTS</h4>
            <hr class="orange-bar">
            <div style="height:80px;">
              <p>There is always something going on at Lancaster.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4 text-center">
          <div>
            <img
              :src="teal"
              class="img-fluid brush"
              @click="goto('Contact')"/>
            <h4 class="dip-title teal">
              JOIN THE
              <br>
              TEAM
            </h4>
            <hr class="teal-bar">
            <div style="height:80px;">
              <p>Lancaster is always looking for fantastic people to join our team.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data(){
      return{
       magenta: require('../../../../../assets/images/lan/home/Brush_Magenta.png'),
       orange: require('../../../../../assets/images/lan/home/Brush_Orange.png'),
       teal: require('../../../../../assets/images/lan/home/Brush_Teal.png')
      }
    },
    methods:{
      goto(routeName){
        this.$router.push({name: routeName})
        window.scrollTo(0,0)
      }
    }
  }
</script>

<style scoped>
  .centered-btn{
    position: absolute;
    bottom: 5px;
    width:100%;
    max-width:300px;
    left:50%;
    transform: translateX(-50%);
  }
  .dip-title{
    min-height: 50px;
    position: relative;
    background: white;
    z-index: 2;
  }
  .brush{
    height: 200px;
    padding: 10px 10px 0px 10px;
    position: relative;
  }
  .brush:hover{
    animation: dip .4s ease;
    transform: translateY(-10px);
    cursor: pointer;
    z-index: 1;
  }
  @keyframes dip{
    0% {transform: translateY(0px)}
    50% {transform: translateY(20px)}
    100%{transform: translateY(-10px)}
  }
  .magenta{
    color: var(--magenta);
  }
  .magenta-bar{
    width: 50%;
    position: relative;
    color: var(--magenta);
    opacity: 0.7;
    left: 25%;
    height: 5px;
  }
  .orange-bar{
    width: 50%;
    position: relative;
    color: var(--orange);
    opacity: 0.7;
    left: 25%;
    height: 5px;
  }
  .teal-bar{
    width: 50%;
    position: relative;
    color: var(--teal);
    opacity: 0.7;
    left: 25%;
    height: 5px;
  }
  .orange{
    color: var(--orange);
  }
  .teal{
    color: var(--teal);
  }
</style>