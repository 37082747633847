<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 mt-2  ">
        <h4 class="text-center">Terms & Details</h4>
      </div>
      <div class="col-md-12 mt-3 text-center">
        <ul style="list-style-type: none;">
          <li>
            Order Dates: Sept 3 – Oct 12, 2024
          </li>
          <li>
            Standard Prepaid Freight Terms and Credit Policies Apply
          </li>
          <li>
            There is No Minimum Show Order
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
  ul{
    line-height: 2em;
  }
</style>