<template>
  <div class="row">
    <div class="col-md-12">
      <a
        :href="ejwGuide"
        class="btn btn-sm btn-primary float-end m-1"
        target="_blank">
        Download Guide
        <i class="fa fa-download"/>
      </a>
      <a
        :href="ejwVideo"
        class="btn btn-sm btn-primary float-end m-1"
        target="_blank">
        Take Video Tour
        <i class="fa fa-play"/>
      </a>
    </div>
    <!-- Question 1 -->
    <div class="col-md-12 my-3">
      <strong>Q: What are your store hours?</strong>
      <p>
        Please see&nbsp;
        <router-link :to="{path: '/policies'}">
          policy page
        </router-link>
        &nbsp;for store hours in your area.
      </p>
    </div>
    <!-- Question 2 -->
    <div class="col-md-12 my-3">
      <strong>Q: Am I allowed to order from another branch? </strong>
      <p>Yes! You can order from any EJ Welch branch provided that all products follow local laws and guidelines. Orders from branches outside your home branch may incur additional freight charges. Speak to your account manager today to get a quote.</p>
    </div>
    <!-- Question 3 -->
    <div class="col-md-12 my-3">
      <strong>Q: Does EJ Welch special order product?</strong>
      <p>Yes! EJ Welch offers our customers the ability to special order items outside of our offerings without the need to open yet another account.</p>
    </div>
    <!-- Question 4 -->
    <div class="col-md-12 my-3">
      <strong>Q: I can’t find what I'm looking for. Is there another way to look up products?</strong>
      <p>Products can be found searching any number of things from the brand, description, size, color, item number, barcode, sheen, material, finish and more! Look up past purchases in the Buy Again ordering tool and save items for later in the Favorites ordering tool.</p>
    </div>
    <!-- Question 5 -->
    <div class="col-md-12 my-3">
      <strong>Q: What do I do if my shipment was damaged in transit?</strong>
      <p>Please report any shipping related issues within 48 hours of receiving your order. If you are able to, please take photos of any issues you discover.</p>
    </div>
  </div>
</template>


<script>
  export default{
    data(){
      return{
        ejwGuide: 'https://merit-production.s3.amazonaws.com/ejwEstore/pdf/EJW_eStore_Guide.pdf',
        ejwVideo: 'https://merit-production.s3.amazonaws.com/ejwEstore/video/EJW_eStore_Tour.mp4'
      }
    }
  }
</script>