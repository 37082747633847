<template>
  <div>
    <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img 
            style="opacity: 0.65;"
            class="bd-placeholder-img" 
            aria-hidden="true"
            focusable="false"
            :src="dynamicHeaderImage" alt="Dynamic Header Image" />

          <div class="container">
            <div class="carousel-caption text-start">
              <h1>Lancaster's Private Label Brands</h1>
              <p>Stand out among the rest with Dynamic and Merit Pro.</p>
              <div class="d-flex flex-column flex-lg-row align-items-stretch align-items-lg-center">
                <a
                  class="btn btn-outline-light mb-2 mb-lg-0 me-lg-4"
                  @click="gotoDynamic">
                  Explore Dynamic
                </a>
                <a
                  class="btn btn-outline-light"
                  @click="gotoMerit">
                  Explore Merit Pro
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img 
            style="opacity: 0.85;"
            class="bd-placeholder-img" 
            aria-hidden="true"
            focusable="false"
            :src="meritHeaderImage" alt="Merit Pro Header Image" />

          <div class="container">
            <div class="carousel-caption text-start">
              <h1>Designing and producing quality product at a competitive price.</h1>
              <p>Combining our spirit of innovation and commitment to impeccable service with the latest logistics and manufacturing technology, our complete offering is designed to delight your customers.</p>
              <div class="d-flex flex-column flex-lg-row align-items-stretch align-items-lg-center">
                 <a
                  class="btn btn-outline-light mb-2 mb-lg-0 me-lg-4"
                  @click="gotoDynamic">
                  Explore Dynamic
                </a>
                <a
                  class="btn btn-outline-light"
                  @click="gotoMerit">
                  Explore Merit Pro
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>


    <!-- Marketing messaging and featurettes
    ================================================== -->
    <!-- Wrap the rest of the page in another container to center all the content. -->

    <div class="container marketing">

      <!-- Three columns of text below the carousel -->
      <div class="row text-center">
        <div class="col-lg-6 px-lg-5 d-flex flex-column justify-content-end align-items-center mb-4 pb-4 pb-lg-0 mb-lg-0">
          <div class="mb-4">
            <img style="width:250px;height:auto;object-fit:contain;" :src="dynamicLogo" alt="Dynamic Logo">
          </div>

          <h2 class="text-primary">Dynamic Paint Products</h2>
          <p>With one of the most comprehensive product offerings in the market of paint sundry products, Dynamic is able to fill the gap.</p>
          <div class="d-flex">
            <a
              class="btn btn-outline-primary me-2"
              @click="gotoDynamic">
              Explore Dynamic
            </a> 
          </div>
        </div><!-- /.col-lg-6 -->

        <div class="col-lg-6 px-lg-5 d-flex flex-column justify-content-between align-items-center">
          <div class="mb-4">
            <img style="width:250px;height:auto;object-fit:contain;" :src="meritLogo" alt="Merit Pro Logo">
          </div>
          <h2 class="text-primary">Merit Pro</h2>
          <p>Known for its applicators, Merit Pro boasts an impressive line of brushes, roller covers, kits and more!</p>
          <div class="d-flex">
            <a
              class="btn btn-outline-primary me-2"
              @click="gotoMerit">
              Explore Merit Pro
            </a> 
          </div>
        </div><!-- /.col-lg-6 -->
        
      </div><!-- /.row -->


      <!-- START THE FEATURETTES -->

      <hr class="featurette-divider">

      <div class="row featurette align-items-stretch">
        <div class="col-lg-7 pe-lg-5 d-flex flex-column justify-content-center align-items-start">
          <h2 class="text-primary fs-1">Partners</h2>
          <p>Dynamic and Merit Pro have strategically partnered with <span class="fw-bold">over 60 manufacturing partners</span> around the world. Combined with the spirit of innovation, a commitment to impeccable service, the latest logistical and manufacturing technologies, Dynamic and Merit Pro now supply over 800 SKUs. Whether your customer is a seasoned contractor or a first time DIYer, Dynamic and Merit Pro has something to delight them.</p>
        </div>
        <div class="col-lg-5">
          <img :src="featureImage1" alt="Feature Image" class="img-fluid my-4 my-lg-0">
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette align-items-stretch">
        <div class="col-lg-7 ps-lg-5 order-lg-2 d-flex flex-column justify-content-center align-items-start">
          <h2 class="text-primary fs-1">Products</h2>
          <p>The breadth of dynamic &amp; merit pro <span class="fw-bold">product offerings</span> is expansive, including; caulk, spackle, putty knives, tape, sanding sponges, drop cloths, rags, brushes, roller trays, roller handles, roller covers and a wide variety of paint accessories. That breadth offers you the ability to display many products together without the need to spend hours merchandising. This is made even easier due to the multitude of premade assortments or displays available exclusively through Lancaster.</p>
        </div>
        <div class="col-lg-5 order-lg-1">
          <img :src="featureImage2" alt="Feature Image" class="img-fluid my-4 my-lg-0">

        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette align-items-stretch">
        <div class="col-lg-7 pe-lg-5 d-flex flex-column justify-content-center align-items-start">
          <h2 class="text-primary fs-1">Programs</h2>
          <p>The Dynamic &amp; Merit Pro <span class="fw-bold">programs</span> from Lancaster provides you with the flexibility to offer a wide variety of products, covering multiple price points that fit your unique local demographics. With that flexibility, you can improve your margins, margin dollars and net profit, without committing to large inventories. You get just what you need, when you need it, at a competitive price.</p>
        </div>
        <div class="col-lg-5">
          <img :src="featureImage3" alt="Feature Image" class="img-fluid my-4 my-lg-0">

        </div>
      </div>
      <!-- /END THE FEATURETTES -->

    </div><!-- /.container -->


  </div>
</template>
<script>
export default {
  name: 'MyPrivateLabel',
  props: [],
  data(){
    return{
      dynamicLogo: require('../../../../../assets/images/logos/dynamic-logo.png'),
      meritLogo: require('../../../../../assets/images/logos/merit-logo.png'),
      dynamicHeaderImage: require('../../../../../assets/images/marketing_area/Dynamic-Paint-Brush-Header.jpg'),
      meritHeaderImage: require('../../../../../assets/images/marketing_area/BrushStrokes-Grey.jpg'),
      featureImage1: require('../../../../../assets/images/marketing_area/Vendors_Dynamic_Products_1.jpg'),
      featureImage2: require('../../../../../assets/images/marketing_area/Vendors_Dynamic_Products_2.jpg'),
      featureImage3: require('../../../../../assets/images/marketing_area/Vendors_Dynamic_Products_3.jpg'),
      catalogLink: '/catalogs'
    }
  },
  methods:{
    gotoDynamic(){
      this.$router.push({
        name: 'ProductSearch',
        query: {
          action: 'browseBrand',
          brand: 'dynamic'
        }
      })
    },
    gotoMerit(){
      this.$router.push({
        name: 'ProductSearch',
        query: {
          action: 'browseBrand',
          brand: 'merit pro'
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
   
  /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */

  /* Carousel base class */
  .carousel {
    margin-bottom: 4rem;
  }
  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    bottom: 3rem;
    z-index: 10;
  }

  /* Declare heights because of positioning of img element */
  .carousel-item {
    height: 32rem;
    overflow: hidden;
    background-color: #243746;
  }
  .carousel-item > img {
    position: absolute; 
    top: 0;
    left: 0;
    min-width: 100%;
    height: 32rem;
    object-fit: cover;
    object-position: center top;
  }


  /* MARKETING CONTENT
  -------------------------------------------------- */

  /* Center align the text within the three columns below the carousel */
  .marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .marketing h2 {
    font-weight: 400;
  }
  /* rtl:begin:ignore */
  .marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
  }
  /* rtl:end:ignore */


  /* Featurettes
  ------------------------- */

  .featurette-divider {
    margin: 5rem 0; /* Space out the Bootstrap <hr> more */
  }

  /* Thin out the marketing headings */
  .featurette-heading {
    font-weight: 300;
    line-height: 1;
    /* rtl:remove */
    letter-spacing: -.05rem;
  }


  /* RESPONSIVE CSS
  -------------------------------------------------- */

  @media (min-width: 40em) {
    /* Bump up size of carousel content */
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }

    .featurette-heading {
      font-size: 50px;
    }
  }

  @media (min-width: 62em) {
    .featurette-heading {
      margin-top: 7rem;
    }
  }

</style>