<template>
  <div class="animate__animated animate__fadeIn animate__faster">
    <!-- Header and Search Bar -->
    <div
      class="row p-3 shadow-sm"
      style="background: var(--bs-primary);">
      <div class="col-12 text-center text-white">
        <h5>
          <i class="fa fa-users"/>
          TM Dashboard
        </h5>
        <form
          name="tmCustSearch"
          @submit.prevent="submitSearch"
          class="mx-auto mb-3"
          style="max-width:350px; width:100%;">
          <div class="input-group">
            <input
              class="form-control p-2"
              type="text"
              id="tmDashboardForm"
              ref="search"
              placeholder="Search Name, ID, City, or State"
              style="box-shadow: none; border-radius:5px 0px 0px 5px; height:40px;"
              autocomplete="off"
              autocapitalize="off"
              spellcheck="false"
              v-model="query">
              <i
                v-if="query"
                class="fa fa-times text-danger clear"
                @click="clearSearch"/>
            <button
              class="btn btn-outline-light btn-sm"
              type="submit"
              style="height:40px;">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </form>
        <div
          v-if="!unselect"
          class="btn-group float-start">
          <button
            @click="toggleShowAll"
            class="btn btn-outline-light btn-sm">
            <span v-if="showAll == false">
              <i class="fa fa-eye"/>
              Show All
            </span>
            <span v-if="showAll == true"><i class="fa fa-times"/>&nbsp;Hide All</span>
          </button>
          <button
            v-if="activeCustomer && !tmAccounts.includes(user.enttity_id)"
            class="btn btn-outline-light btn-sm"
            @click="unselectCustomer">
            <i class="fa fa-undo"/>
            Unselect Customer
          </button>
        </div>
        <Spinner
          v-else
          class="unselect-spinner"/>
        <!-- Sort Options -->
        <div class="float-end">
          <div class="dropdown">
            <button
              class="btn btn-sm btn-light dropdown-toggle"
              type="button"
              id="triggerId"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              Sort By
            </button>
            <div class="dropdown-menu" aria-labelledby="triggerId">
              <a
                class="dropdown-item" href="#"
                :class="{active: sort == 'name-asc'}"
                @click="setSort('name-asc')">
                Name
                <i class="fa fa-caret-up float-end"/>
              </a>
              <a
                class="dropdown-item" href="#"
                :class="{active: sort == 'name-desc'}"
                @click="setSort('name-desc')">
                Name
                <i class="fa fa-sort-down float-end"/>
              </a>
              <a
                class="dropdown-item" href="#"
                :class="{active: sort == 'city-asc'}"
                @click="setSort('city-asc')">
                City
                <i class="fa fa-caret-up float-end"/>
              </a>
              <a
                class="dropdown-item" href="#"
                :class="{active: sort == 'city-desc'}"
                @click="setSort('city-desc')">
                City
                <i class="fa fa-sort-down float-end"/>
              </a>
              <a
                class="dropdown-item" href="#"
                :class="{active: sort == 'id-asc'}"
                @click="setSort('id-asc')">
                Customer ID
                <i class="fa fa-caret-up float-end"/>
              </a>
              <a
                class="dropdown-item" href="#"
                :class="{active: sort == 'id-desc'}"
                @click="setSort('id-desc')">
                Customer ID
                <i class="fa fa-sort-down float-end"/>
              </a>
            </div>
          </div>    
        </div>
        <!-- Filter by Letter -->
        <div class="float-end">
          <button
            class="btn btn-sm btn-light me-1"
            @click="toggleLetterSelector">
            Letter Selector
            <i
              class="fa"
              :class="letterSelector ? 'fa-caret-up' : 'fa-caret-down'"/>
          </button>
        </div>
      </div>
    </div>
    <div>
      <!-- Filter Customer Name by Letter -->
      <div
        v-if="letter || letterSelector"
        class="row p-4 shadow-sm"
        style="background: lightgray;">
        <div class="col-md-12 text-center position-relative">
          <a
            v-if="letter"
            class="letter-clear animate__animated animate__fadeInRight animate__faster"
            @click="setLetter(letter)"
            href="#">
            <i class="fa fa-eraser"/>
            Clear Letter Selection
          </a>
        </div>
        <template v-if="letterSelector">
          <!-- Letter Keypad -->
          <div
            v-if="!letter"
            class="col-md-12 text-center animate__animated animate__fadeIn animate__fast">
            <small>Filter accounts by selecting the starting letter of the account name.</small>
          </div>
          <div
            v-for="l in alpha"
            :key="l"
            class="col text-center animate__animated animate__slideInDown animate__faster mt-3">
            <button
              class="btn btn-sm btn-light m-1 w-100"
              :class="letter == l ? 'bg-primary text-light shadow-sm' : ''"
              @click="setLetter(l)"
              href="#">
              {{ l.toUpperCase() }}
            </button>
          </div>
        </template>
      </div>
      <!-- Territory Manger Info -->
      <div
        v-if="!showAll && !query && !hasActiveCustomer"
        class="row">
        <div class="col-12 text-center">
          <ul
            v-if="customerData && customerDatalength > 0"
            class="list-unstyled">
            <li>Territory Manager: [TM Name]</li>
            <li>Total Customers: {{customerData.length}}</li>
            <li>Activate a customer to use the eStore with their account.</li>
          </ul>
          <div
            v-else
            class="d-flex align-items-center justify-content-center">
            <Spinner />&nbsp;&nbsp;Getting Customers...
          </div>
        </div>
      </div>
      <!-- Currently Active Customer -->
      <div
        class="row p-4">
        <div
          v-if="activeCustomer && !query && !showAll"
          class="col-12 col-md-6 col-lg-4 mx-auto my-2">
          <ActiveCustomerCard
            :customer="activeCustomer"
            :userStats="filteredUserStats(activeCustomer.id)"
            :orderStats="filteredOrderStats(activeCustomer.id)"
            view="tm"/>
        </div>
      </div>
      <!-- No Active Customer Handler -->
      <div
        v-if="!activeCustomer && !query && !showAll"
        class="row">
        <div class="col-md-12 text-center text-muted">
          <h5>No Active Customer</h5>
        </div>
      </div>
      <!-- Search Results -->
      <div v-if="query">
        <div class="col-12 text-center text-muted mb-2">
          {{customerResults.length > 0 ? customerResults.length : 'No'}}
          result<span v-if="customerResults.length > 1 || customerResults.length < 1">s</span>
          for &ldquo;<span class="bold">{{query}}</span>&rdquo;
          &nbsp;&nbsp;
          <button
            class="btn btn-outline-primary btn-sm"
            @click="clearSearch">
            <i class="fa fa-times"/>
            Clear Search
          </button>
        </div>
        <div class="row p-4">
          <div
            v-for="c, index in customerResults"
            :key="index"
            class="col-xl-3 col-lg-4 col-md-6 p-2">
            <CustomerCard
              :customer="c"
              :userStats="filteredUserStats(c.id)"
              :orderStats="filteredOrderStats(c.id)"
              view="tm"/>
          </div>
        </div>
      </div>
      <!-- No Result Handler -->
      <div
        v-if="customerData && !customerData.customer_list.length"
        class="row">
        <div class="col-md-12 mt-2 text-center">
          <h4 class="text-muted">No Results</h4>
        </div>
      </div>
      <!-- Show all customers -->
      <div v-if="showAll && !query && customerData">
        <div
          v-if="!letter"
          class="col-12 text-center text-muted">
          Showing all {{customerData.customer_list.length}} customers assigned to:&nbsp;
          <strong>{{user.username}}</strong>
        </div>
        <div class="row px-4">
          <div
            class="col-xl-3 col-lg-4 col-md-6 p-2"
            v-for="c, index in customerData.customer_list"
            :key="index">
            <CustomerCard
              :customer="c"
              :userStats="filteredUserStats(c.id)"
              :orderStats="filteredOrderStats(c.id)"
              :unselect="unselect"
              view="tm"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '../../../../global/Spinner.vue'
import ActiveCustomerCard from './ActiveCustomerCard.vue'
import CustomerCard from './CustomerCard.vue'

export default {
  name: 'TmDashboard',
  components: {
    Spinner,
    ActiveCustomerCard,
    CustomerCard
  },
  data() {
    return{
      query: null,
      showAll: true,
      unselect: false,
      sort: 'name-asc',
      letterSelector: false,
      letter: null
    }
  },
  watch:{
    activeShipTo(newVal){
      if(newVal) this.unselect = false
      if(newVal && !this.tmAccounts.includes(parseInt(newVal.entity_id))){
        window.scrollTo(0,0)
        this.hideDashboard()
      }
    },
    query(newVal){
      if(newVal && this.letter){
        this.letter = null
      }
    }
  },
  created(){
    this.$store.dispatch('getTmCustomers')
  },
  computed: {
    customerData(){
      const customers = this.$store.getters.customerData
      let data = customers
      if(customers){
        if(this.sort){
          const sortData = this.sort.split('-')
          data.customer_list = _.orderBy(data.customer_list, [[sortData[0]]], [[sortData[1]]])
        }
        if(this.letter){
          data.customer_list = _.filter(data.customer_list, c=> c.name.charAt(0).toLowerCase() == this.letter.toLowerCase())
        }
        return data
      }
    },
    userStats(){return this.customerData.user_stats},
    orderStats(){return this.customerData.order_stats},
    customerResults() {
      const customers = _.filter(this.customerData.customer_list, (c) => {
        if(c) return Object.values(c).join(',').toLowerCase().includes(this.query.toLowerCase())
      })
      return customers
    },
    activeShipTo(){return this.$store.getters.getEntity.activeShipTo},
    hasActiveCustomer(){return this.activeShipTo && this.activeShipTo != []},
    activeCustomer(){
      if(this.customerData && this.activeShipTo){
        return _.find(this.customerData.customer_list, (c) => {return c.id == this.activeShipTo.entity_id})
      }
    },
    user(){return this.$store.getters.user},
    tmAccounts(){return this.$store.getters.settings.tmPortalAccounts},
    alpha(){
      return ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
    }
  },
  methods: {
    toggleShowAll(){
      this.showAll = !this.showAll
    },
    clearSearch(){
      this.query = null
    },
    hideDashboard(){
      this.$store.dispatch('setRepDashboardView', false)
    },
    filteredUserStats(id){
      return _.filter(this.userStats, {entity_id: id})
    },
    filteredOrderStats(id){
      return _.filter(this.orderStats, {customer_id: id})
    },
    submitSearch(){
      document.activeElement.blur()
    },
    unselectCustomer(){
      this.unselect = true
      this.$store.dispatch('selectCustomer', {customer_id: parseInt(this.user.entity_id)})
    },
    setSort(sort){
      this.$store.dispatch('getTmCustomers')
      this.sort = sort
    },
    setLetter(letter){
      this.$store.dispatch('getTmCustomers')
      this.query = null
      // Reset if same letter is active
      if(this.letter == letter){
        this.sort = 'name-asc'
        this.letter = null
        document.getElementById('tmDashboardForm').focus()
        return
      }
      if(letter) this.sort = null
      this.letter = letter
    },
    toggleLetterSelector(){
      this.letterSelector = !this.letterSelector
    }
  }
}
</script>

<style scoped>
  .clear{
    position: absolute;
    right: 55px;
    top: 1px;
    z-index: 3;
    padding: 10px;
  }
  .unselect-spinner{
    color: white !important;
  }
  .unselect-spinner:hover{
    color: var(--bs-primary) !important;
  }
  .letter-selector:hover{
    cursor:pointer;
    font-weight: 900;
  }
  .letter-clear{
    position: absolute;
    top: -15px;
    right: 8px;
    font-size: 14px;
  }
</style>
