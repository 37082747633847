<template>
  <div>
    <form
      name="accountProductSearch"
      @submit.prevent="productSearch">
      <label for="productIdSearch">Product Search</label>
      <div class="input-group">
        <input
          id="productIdSearch"
          type="number"
          class="form-control"
          placeholder="Enter Product ID"
          v-model="productIds">
        <button
          class="btn btn-sm btn-primary"
          type="submit">
          <i class="fa fa-search"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  export default{
    props:['view'],
    data(){
      return {
        productIds: null
      }
    },
    mounted(){
      this.productIds = this.query
    },
    watch:{
      query(newVal){
        this.productIds = newVal
      }
    },
    methods: {
      productSearch(){
        const data = {
          productIds: this.productIds,
          view: this.view,
          query: this.productIds
        }
        this.$store.dispatch('orderProductSearch', data)
      }
    },
    computed:{
      query(){return this.$store.getters.orderSearchResults?.query}
    }
  }
</script>