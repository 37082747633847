<template>
  <div class="row header">
    <div class="col-md-6 col-12 bg-primary text-white">
      <div class="wrapper">
        <h2>Why EJ Welch</h2>
        <p>EJ Welch brings you the most innovative flooring and installation products in the industry, so you can focus on growing your business and you bottom line.</p>
      </div>
    </div>
    <div
      class="col-md-6 d-none d-md-block"
      :style="headerStyle"/>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        headerStyle: {
          backgroundImage: `url(${require('../../../../../assets/images/ejw/home/about-header-img.jpeg')})`,
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }
      }
    }
  }
</script>

<style scoped>
  .header{
    margin-top: 85px;
    max-height: 450px;
    overflow: hidden;
  }
  .wrapper{
    margin: 16% 8%;
    font-size: 22px;
  }
</style>