<template>
  <div
    :class="{'deleted' : contactData.estore_delete}"
    class="col-md-12 contact-card shadow-sm p-0">
    <div
      :class="isUser ? 'user' : 'contact'"
      class="header px-3 py-2">
      <span>
        <i 
        :class="isUser ? 'fa-house-user' : 'fa-user-circle'"
        class="fa me-2"></i> 
        {{ contactData.login_id }}<span v-if="contactData.estore_delete">&nbsp;(deleted)</span>
      </span>
      
      <span>
        <span v-if="!expanded" class="d-none d-md-inline-block">
          <span><i class="fa fa-map-marker-alt"></i> <span v-html="accountHtml"></span></span>
          <span class="mx-5"><i class="fas fa-shopping-cart"></i> <span v-html="contactData.estore_delete ? '(deleted)' : orderEntryHtml"></span></span>
        </span>
        <button 
          v-if="user.superuser"
          @click="toggleExpand"
          :disabled="contactData.estore_delete"
          class="btn btn-outline-dark btn-sm">
          <i :class="expanded ? 'fa-eye-slash' : 'fa-eye'" class="fa"></i>
          {{ expanded ? 'Hide' : 'View' }}
        </button>
      </span>
    </div>
    <div v-if="expanded" class="px-3 pt-3">
      <!-- Contact Data -->
      <div class="row" v-if="!editing && !editPassword && !deleting">
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="text-muted">
            <small>Username</small>
          </div>
          <div>
            {{contactData.login_id}}
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="text-muted">
            <small>Account</small>
          </div>
          <div v-html="accountHtml">
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="text-muted">
            <small>Order Entry</small>
          </div>
          <div v-html="orderEntryHtml">
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="text-muted">
            <small>Name</small>
          </div>
          <div>
            {{contactData.first_name}} {{contactData.last_name}}
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="text-muted">
            <small>Email</small>
          </div>
          <div>
            {{contactData.email ? contactData.email : 'No email'}}
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mb-3">
          <div class="text-muted">
            <small>Phone</small>
          </div>
          <div>
            {{contactData.phone_number ? contactData.phone_number : 'No Phone'}}
          </div>
        </div>
      </div>
      <EditForm
        v-if="editing"
        :contact="contactData"
        @cancelForm="cancel"
        @detailUpdated="toggleDetailConfirm"/>
      <PasswordForm
        v-if="editPassword"
        :contact="contactData"
        @cancelForm="cancel"
        @passwordUpdated="togglePasswordConfirm"/>
      <DeleteForm 
        v-if="deleting" 
        @confirmDelete="confirmDelete"
        @cancel="cancel"
        :contact="contactData"/>
    </div>
    <div v-if="expanded" class="row px-3 pb-3">
      <!-- Action Buttons -->
      <div
        v-if="!editing && !editPassword && !deleting"
        class="col-12 d-flex flex-column flex-md-row align-items-stretch justify-content-end">
        <button
          :disabled="contactData.estore_delete"
          class="btn btn-sm btn-outline-primary mb-2"
          @click="toggleEdit">
          <i class="fa fa-user-edit"/>
          {{ isUser ? 'Edit My Profile' : 'Edit User' }}
        </button>
        <button
          :disabled="contactData.estore_delete"
          class="btn btn-sm btn-outline-primary mb-2 ms-md-2"
          @click="togglePassword">
          <i class="fa fa-key"/>
          {{ isUser ? 'Change My Password' : 'Change Password' }}
        </button>
        <button
          :disabled="contactData.estore_delete"
        v-if="contactData.contact_id != user.contact_id"
          class="btn btn-sm btn-outline-primary mb-2 ms-md-2"
          @click="toggleDelete">
          <i class="fa fa-user-times"/>
          Delete User
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EditForm from './EditForm.vue'
import PasswordForm from './PasswordForm.vue'
import DeleteForm from './DeleteForm.vue'
import {setNotification} from '../../../../../shared/Notification'

export default {
  name: 'ContactCard',
  props: ['contact'],
  components:{
    EditForm,
    PasswordForm,
    DeleteForm
  },
  data(){
    return{
      contactData: null,
      editing: false,
      deleting: false,
      editPassword: false,
      detailConfirm: false,
      passwordConfirm: false,
      expanded: false
    }
  },
  created(){
    this.contactData = this.contact
    if(this.isUser){
      this.expanded = true
    }
  },
  methods:{
    toggleEdit(){
      this.editing = !this.editing
    },
    togglePassword(){
      this.editPassword = !this.editPassword
    },
    cancel(){
      this.editing = false
      this.editPassword = false
      this.deleting = false 
    },
    toggleDetailConfirm(formData){
      this.contactData = formData
      this.editing = false
      setNotification('User Profile Updated!', 'success')
    },
    togglePasswordConfirm(){
      this.editPassword = false
      setNotification('User Password Updated!', 'success')
    },
    toggleDelete(){
      this.deleting = !this.deleting
    },
    confirmDelete(){
      this.contactData.estore_delete = true
      setNotification('User Successfully Deleted!', 'success')
      this.expanded = false
      this.cancel()
    },
    toggleExpand(){
      this.expanded = !this.expanded
    }
  },
  computed:{
    orderEntryHtml(){
      if(this.contactData.estore_noorder == false || this.contactData.estore_noorder == 'false'){
        return'<i class="fa fa-check text-success"></i> Allowed' 
      }
      if(this.contactData.estore_noorder == true || this.contactData.estore_noorder == 'true'){
        return '<i class="fa fa-times text-danger"></i> Disabled' 
      }
    },
    accountHtml(){
      let billTo = this.$store.getters.getEntity.entity.bill_to
      let account = this.contactData.entity_id + (this.contactData.entity_id == billTo ? ' (Bill To)' : ' (Ship To)')
      return account
    },
    user(){
      return this.$store.getters.user
    },
    isUser(){
      return this.contactData.contact_id == this.user.contact_id
    }
  },
  watch:{
    expanded(NewVal){
      if(NewVal == false){
        this.cancel()
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .contact-card{
    border: 1px solid lightgray;
    border-radius: 10px; 
    overflow: hidden;

    .header{
      font-size: 1rem;
      display: flex;
      align-items:center;
      justify-content: space-between;
      color: #2A2A2A;
      &.user{
        background-color: #cdead4;
      }
      &.contact{
       background-color: #a9c7d5;
      }
    }
    &.deleted{
      background: lightgray;
      border-color:gray;
      .header {
        background-color:lightgray;
        color: #888;
      }
    }
  }
</style>