// Common compute methods for tm customer cards

// Last order instance
export const lastOrder = (orderStats) => {
  const max_date = _.max(_.map(orderStats, o => o.completed_at))
  return _.find(orderStats, {completed_at: max_date})
}

// last login instance
export const lastLogin = (userStats) => {
  const max_date = _.max(_.map(userStats, o => o.updated_at))
  return _.find(userStats, {updated_at: max_date})
}