<template>
  <div>
    <div
      class="modal fade"
      id="publicInfoModal"
      tabindex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      role="dialog">
      <div
        class="modal-dialog modal-dialog-scrollable modal-dialog-centered"
        role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-danger" id="modalTitleId">
              <i class="fa fa-exclamation-triangle"/>
              VOC Restricted
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"/>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger text-center">
              May not be available in your area due to VOC restrictions. Please contact us for more information
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>