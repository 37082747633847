<template>
  <div>
    <ul class="nav nav-pills nav-justified sticky-top">
      <li class="nav-item m-2">
        <a
          class="nav-link"
          :class="{active: activeView == 'browse' && !activeBrand}"
          href="#"
          @click="goto('browse')">
          <i class="fa fa-search"/>
          <span class="d-none d-lg-block">Browse & Search</span>
        </a>
      </li>
      <li class="nav-item m-2">
        <a
          class="nav-link"
          :class="{active: activeView == 'mfg' || activeBrand}"
          href="#"
          @click="goto('mfg')">
          <i class="fa fa-book-open"/>
          <span class="d-none d-lg-block">Browse Manufacturer</span>
        </a>
      </li>
      <li class="nav-item m-2">
        <a
          class="nav-link"
          :class="{active: activeView == 'direct'}"
          href="#"
          @click="goto('direct')">
          <i class="fa fa-keyboard"/> 
          <span class="d-none d-lg-block">Direct Order Entry</span>
        </a>
      </li>
      <li
        v-if="company == 'Lancaster US'"
        class="nav-item m-2">
        <a
          class="nav-link"
          :class="{active: activeView == 'drop'}"
          href="#"
          @click="goto('drop')">
          <i class="fa fa-shipping-fast"/>
          <span class="d-none d-lg-block">Drop Shipments</span>
        </a>
      </li>
      <li class="nav-item m-2">
        <a
          class="nav-link"
          :class="{active: activeView == 'downloads'}"
          href="#"
          @click="goto('downloads')">
          <i class="fa fa-download"/>
          <span class="d-none d-lg-block">Downloads</span>
        </a>
      </li>
      <li class="nav-item m-2">
        <a
          class="nav-link"
          :class="{active: activeView == 'terms'}"
          href="#"
          @click="goto('terms')">
          <i class="fa fa-file-alt"/>
          <span class="d-none d-lg-block">Terms</span>
        </a>
      </li>
      <li class="nav-item m-2">
        <router-link
          class="nav-link"
          to="/"
          @click="clearInit">
          <i class="fa fa-door-open"/>
          <span class="d-none d-lg-block">Exit {{ vShowData ?  vShowData.title : 'Virtual Show'}}</span>
        </router-link>
      </li>
    </ul>
    <!-- Filters -->
    <div
      v-if="results && subView && activeView != 'direct'"
      class="row mb-2">
      <div class="col-md-12">
        <button
          v-if="['browse','mfg'].includes(activeView) && searchSubmitted || subView"
          class="btn btn-primary btn-sm float-start ms-2"
          @click="goBack">
          <i class="fa fa-chevron-left"/>
          Back
        </button>
        <template v-if="searchSubmitted">
          <FilterControls
            class="float-end mx-1 d-none d-sm-block"
            :loading="loading"/>
          <MobileFilterButton
            class="float-end mx-1"
            :loading="loading"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import FilterControls from '../../../menus/views/FilterControls/FilterControls.vue'
  import MobileFilterButton from '../../../menus/mobile/filters/MobileFilterButton.vue'

  export default {
    props: ['activeView', 'subView', 'searchSubmitted'],
    components:{
      FilterControls,
      MobileFilterButton
    },
    methods:{
      goto(view){
        switch(view){
          case 'browse':
            this.$router.push({name: 'VirtualShow'})
            break
          case 'drop':
            this.$router.push({name: 'VirtualShow', query: {action: 'virtualShowDropShips'}})
            break
        }
        this.$emit('navEvent', view)
      },
      goBack(){
        this.$emit('goBack')
      },
      clearInit(){
        setTimeout(function(){
          this.$store.dispatch('vShowInitialized', false)
        }.bind(this), 1000);
      }
    },
    computed:{
      company(){
        return this.$store.getters.sessionCompany
      },
      results(){return this.$store.getters.productResults},
      filters(){return this.$store.getters.activeFilters},
      loading(){return this.$store.getters.productLoadState},
      vShowAllowed(){return this.$store.getters.vShowAllowed},
      vShowData(){
        if(this.vShowAllowed){
          return this.$store.getters.vShowDates
        }
        return null
      },
      activeBrand(){return this.$route.query?.brand || null},
      routeAction(){return this.$route.query?.action}
    }
  }
</script>

<style scoped>
  .nav{
    font-size: 14px;
  }
  .nav-link{
    padding:5px;
  }
  i{
    font-size: 18px;
  }
</style>