<template>
  <a
    class="my-2 col-sm-3 col-6"
    :href="vendor.link"
    target="_blank">
    <div class="row">
      <div class="col-md-12 text-center bg-light">
        <img
          :src="vendor.logo_url"
          style="object-fit: contain; height: 150px; width: 150px;">
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props:['vendor']
  }
</script>