<template>
    <div class="card h-100 rounded-0 border-light shadow">
      <div class="card-body">
        <a
          class="d-flex text-decoration-none flex-column w-100 h-100 align-items-center justify-content-between position-relative"
          :href="extLink ? item.link_url : item.link"
          :class="type"
          target="_blank">
          <i
            v-if="extLink"
            class="fa fa-link"
            style="position: absolute; top: -10px; right: -10px;"/>
          <img
              :src="item.thumbnail"
              :alt="item.thumbnail_name"
              class="img-fluid d-block mb-3" />
          <span class="fw-bold inline-block">{{item.title}}</span>
        </a>
      </div>
    </div>
</template>
<script>
  export default {
    name: 'Item',
    props: ['item','type'],
    computed:{
      extLink(){
        return this.item.asset_type == 'external link'
      }
    }
  }
</script>
<style scoped lang="scss" >
  img{
    object-fit:contain;
    object-position:center;
    height: auto;
    min-height: 100px;
    max-height: 200px;
     @media(min-width:992px){
      height: auto;
      min-height: 150px;
      max-height: 250px;
    }
  }
  .catalog {
    @media(min-width:992px){
      img{
        height: 300px;
        min-height: 300px;
        max-height: 300px;
      }
    }
  }
</style>