<template>
  <div>
    <div class="row mt-2 px-4">
      <div class="col-md-12">
          <h4>Magazines</h4>
      </div>
    </div>
    <div
      v-if="loading"
      class="col-md-12 text-center mt-5">
      <Spinner/>
    </div>
    <div class="container-fluid">
      <div
        v-if="!loading"
        class="animate__animated animate__fadeIn animate__faster">
        <!-- Magazines -->
         <div
          v-if="magazines && magazines.length > 0"
          class="row mb-5">
          <div
            v-for="m in magazines"
            :key="m.id"
            class="col-12 col-sm-6 col-md-3 col-lg-2 mb-4">
            
            <Item :item="m" type="magazine" />

          </div>
        </div>
        <div
          v-else
          class="row">
          <div class="col-md-12 text-center mt-5">
            <h4 class="text-muted">No Magazines</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../global/Spinner.vue'
  import Item from './Item.vue'

  export default {
    name: 'Magazines',
    data(){
      return{
        loading: false,
        magazines: null
      }
    },
    components:{
      Spinner,
      Item
    },
    created(){
      this.loading = true
      axios.get('/api/v1/private/digital_assets/get_assets').then(res =>{
        if(res.status == 200){
          this.magazines = _.filter(res.data.assets, {asset_type: 'magazine'})
          //default sort is by most recent
          this.magazines = _.orderBy(this.magazines, 'created_at','desc')
          this.loading = false
        }
      })
    }
  }
</script>
