<template>
  <div class="row animate__animated animate__fadeInUp animate__faster">
    <div class="col-md-12 border rounded m-4 p-5">
      <form
        name="bulkUpload"
        @submit.prevent="submit">
        <div>
          <label class="form-label text-muted">Upload Bulk Products</label>
          <input
            class="form-control"
            type="file"
            accept=".csv"
            @change="handleUpload"
            ref="upload"
            required>
        </div>
        <div class="mt-2 float-end">
          <div class="input-group">
            <button
              class="btn btn-sm btn-danger"
              @click="$emit('closeUploadForm')">
              <i class="fa fa-ban"/>
              Cancel
            </button>
            <button class="btn btn-sm btn-primary">
              <i class="fa fa-ban"/>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default{
    props: ['promoHeader', 'activePriceBranch'],
    data(){
      return{
        form: {
          promo_id: null,
          price_branch: null,
          file: null
        }
      }
    },
    mounted(){
      this.form.promo_id = this.promoHeader.id
      this.form.price_branch = this.activePriceBranch
    },
    methods:{
      submit(){
        const formData = new FormData()
        for(const f in this.form){
          formData.append(f, this.form[f])
        }
        this.$store.dispatch('uploadPromoProducts', formData)
        this.$emit('closeUploadForm')
      },
      handleUpload(){
        this.form.file = this.$refs['upload'].files[0]
      }
    }
  }
</script>