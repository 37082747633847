<template>
  <div
    class="accordion accordion-flush"
    id="accordionFlushProduct">
    <!-- Availability -->
    <div class="accordion-item my-2">
      <h2
        class="accordion-header"
        id="flush-headingOne">
        <button
          class="accordion-button"
          :class="open ? '' : 'collapsed'"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseOne">
          <div class="row">
            <div class="col-md-12">
              Availability
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 home-avail">
              <Availability
                :activeUom="activeUom"
                :product="product"
                :showHome="true"/>
            </div>
          </div>
        </button>
      </h2>
      <div
        id="flush-collapseOne"
        class="accordion-collapse collapse"
        :class="open ? 'show' : ''">
        <div class="accordion-body">
          <Availability
            :activeUom="activeUom"
            :product="product"/>
        </div>
      </div>
    </div>
    <!-- Product Detail -->
    <div class="accordion-item my-2">
      <h2
        class="accordion-header"
        id="flush-headingTwo">
        <button
          class="accordion-button"
          :class="open ? '' : 'collapsed'"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseTwo">
          Product Details
          <span class="upc text-muted">UPC: {{ product.upc }}</span>
        </button>
      </h2>
      <div
        id="flush-collapseTwo"
        class="accordion-collapse collapse"
        :class="open ? 'show' : ''">
        <div class="accordion-body">
          <div>
            <div class="row mt-1">
              <div
                class="col-md-12">
                <!-- <BrandLink
                  v-if="product.brand_match"
                  :product="product"/> -->
                <!-- <StockStatus
                  v-if="internalRoles.includes(role)"
                  :product="product"/> -->
                <table class="table table-sm detail-table">
                  <tbody>
                    <tr>
                      <td>UPC</td>
                      <td>{{product.upc}}</td>
                    </tr>
                    <tr>
                      <td>Min Qty</td>
                      <td>{{minQuantity.quantity}}{{displayUom(minQuantity.name)}}</td>
                    </tr>
                    <tr>
                      <td>Case Qty</td>
                      <td>{{caseQty}}</td>
                    </tr>
                    <tr v-if="palletQty">
                      <td>Pallet Qty</td>
                      <td>{{palletQty}}</td>
                    </tr>
                    <tr>
                      <td>Product ID</td>
                      <td>{{product.eclipse_data.eclipse_id}}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{{product.eclipse_data.status}}</td>
                    </tr>
                    <tr>
                      <td>Last Ordered</td>
                      <td>{{product.eclipse_data.last_ordered_date || 'N/A'}}</td>
                    </tr>
                    <tr v-if="product.eclipse_data.last_ordered_quantity && product.eclipse_data.last_ordered_quantity > 0">
                      <td>Last Order Qty</td>
                      <td>{{product.eclipse_data.last_ordered_quantity}} {{displayUom(product.eclipse_data.uom)}}</td>
                    </tr>
                    <tr
                      v-for="b in info"
                      :key="b.description">
                      <td>{{b.description}}</td>
                      <td>{{b.value}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Availability from '../detail/Availability.vue'
  import { displayUom } from '../../../../../shared/ProductFunctions'

  export default{
    props:{
      product:{type: Object},
      activeUom:{type: Object},
      info:{type: Array},
      minQuantity:{type: Object},
      caseQty:{type: String},
      palletQty:{type: String},
      open:{
        type: Boolean,
        default: false
      }
    },
    methods:{
      displayUom(uom){ return displayUom(uom) }
    },
    components:{
      Availability
    }
  }
</script>

<style scoped>
  .upc{
		position: relative;
    top: 0px;
    left: 1rem;
    font-size: 14px;
	}
	.accordion-button{
		height: 10px;
		padding: 15px 0px;
		background: none !important;
    font-size: 14px;
	}
	.accordion-button:focus{
		box-shadow: none;
	}
  .detail-table{
		font-size: 14px;
	}
  .home-avail{
    margin-bottom: 17px;
    padding: 25px;
  }
  .accordion-header{
    background: var(--bs-gray-200);
    padding: 0px 10px;
  }
  .accordion-header:hover{
    box-shadow: 1px 5px 10px lightgray;
    transition: 200ms ease-in-out;
  }
</style>