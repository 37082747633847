<template>
  <div>
    <div class="row news">
      <div
        class="col-md-6 text-center d-none d-md-block"
        :style="background">
      </div>
      <div class="col-md-6 col-12 p-5 bg-danger text-white position-relative">
        <div class="centered">
          <h2 style="font-weight: 400;">GIVING BACK</h2>
          <p>Lancaster is committed to giving back to our community. We proudly support The Blood Connection, National Breast Cancer Foundation, The Salvation Army, and United Way.</p>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row">
        <div
          class="col-md-4 col-sm-6 p-3 col-6 text-center position-relative"
          style="height:150px;">
          <div>
            <img
              :src="bloodConnection"
              class="img-fluid"
              style="height: 75px;">
          </div>
        </div>
        <div
          class="col-md-4 col-sm-6 p-3 col-6 text-center position-relative"
          style="height:150px;">
          <div>
            <img
              :src="breastCancer"
              class="img-fluid"
              style="height: 75px;">
          </div>
        </div>
        <div
          class="col-md-4 col-sm-6 p-3 col-6 text-center position-relative"
          style="height:150px;">
          <div>
            <img
              :src="unitedWay"
              class="img-fluid"
              style="height: 75px;">
          </div>
        </div>
        <div
          class="col-md-4 col-sm-6 p-3 col-6 text-center position-relative"
          style="height:150px;">
          <div>
            <img
              :src="salvation"
              class="img-fluid"
              style="height: 150px;">
          </div>
        </div>
        <div
          class="col-md-4 col-sm-6 p-3 col-6 text-center position-relative"
          style="height:150px;">
          <div>
            <img
              :src="bus"
              class="img-fluid"
              style="height: 150px;">
          </div>
        </div>
        <div
          class="col-md-4 col-sm-6 p-3 col-6 text-center position-relative"
          style="height:150px;">
          <div style="transform: translateY(50%)">
            <img
              :src="overflow"
              class="img-fluid"
              style="height: 75px;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        background:{
          backgroundImage: `url(${require('../../../../../assets/images/lan/about/BloodDriveBus.jpg')})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '350px'
        },
        bloodConnection: require('../../../../../assets/images/lan/about/BloodConnectionLogo.png'),
        breastCancer: require('../../../../../assets/images/lan/about/BreastCancerLogo.png'),
        unitedWay: require('../../../../../assets/images/lan/about/UnitedWayLogo.png'),
        salvation: require('../../../../../assets/images/lan/about/SalvationArmy.jpg'),
        bus: require('../../../../../assets/images/lan/about/StuffTheBusLogo.jpg'),
        overflow: require('../../../../../assets/images/lan/about/OverflowLogo.png')
      }
    }
  }
</script>

<style scoped>
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    width: 85%;
    transform: translateY(-50%);
  }
  .centered-btn{
    position: absolute;
    bottom: 5px;
    width:100%;
    max-width:300px;
    left:50%;
    transform: translateX(-50%);
  }
  .news{
    min-height:250px;
  }
</style>