<template>
  <tr ref="row">
    <td 
      v-for="t in data.table_data"
      :key="t"
      style="width:200px; max-height: 200px;">
      <div v-if="t.column.includes('uid')">
        <img
          v-if="visible"
          :src="getImgUrl(t.column)"
          class="w-100"
          style="max-height:100px; object-fit: contain;">
      </div>
      <div v-else>
        {{values[t.column]}}
      </div>
    </td>
    <td style="overflow: hidden;">
      <EditDelete
        :deleteAllowed="deleteAllowed"
        :values="values"/>
    </td>
  </tr>
</template>

<script>
  import EditDelete from './EditDelete.vue'

  export default{
    props:['values', 'data', 'deleteAllowed'],
    data(){
      return{
        visible: false,
        observer: null
      }
    },
    components:{
      EditDelete
    },
    mounted(){
      this.observer = new IntersectionObserver(([entry])=>{
        if(entry && entry.isIntersecting){
          this.visible = true
          if(this.visible){
            this.observer.unobserve(entry.target);
          }
        }
      })
      this.observer.observe(this.$refs['row'])
    },
    unmounted(){
      this.observer.disconnect()
    },
    methods:{
      getImgUrl(column){
        const columnName = _.replace(column, '_uid', '_url')
        return this.values[columnName]
      }
    }
  }
</script>