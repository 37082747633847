<template>
  <div class="container">

    <div class="mt-2">
      <div class="row">
        <div class="col-md-12">
          <h2>Direct Order Entry</h2>
          <div class="text-center my-4">
            <small class="text-muted">Know exactly what you are looking for? Search for items based on Product ID, UPC, Part Number etc.. Add them to the OrderPad, once you are ready click on the OrderPad button, adjust qty as needed and add all items to cart.</small>
          </div>
        </div>
        <!-- Product Form -->
        <div class="col-md-6">
          <form
            name="vshowDirectEntry"
            class="input-group"
            @submit.prevent="submit">
            <input
              type="text"
              class="form-control"
              v-model="query"
              placeholder="Enter ProductID, Part #, UPC..."
              required>
              <i
                v-if="query"
                class="fa fa-times text-danger clear-times"
                style="z-index: 3;"
                @click="clearQuery"/>
            <button class="btn btn-primary">
              <i class="fa fa-search"/>
            </button>
          </form>
        </div>
        <div class="col-md-6 mt-2 position-relative">
          <div class="float-end">
              <span
                v-if="showHelper"
                ref="helper"
                class="badge bg-success helper animate__animated animate__bounceIn">
                Submit Order Pad Here
                <br>
                <i class="fa fa-arrow-down"/>
              </span>
            <div class="input-group">
              <button
                class="btn btn-outline-primary btn-sm"
                :disabled="!query"
                :class="{active: showResults}"
                @click="showProducts">
                Show Products
              </button>
              <button
                class="btn btn-outline-primary btn-sm"
                :class="{active: displayOrder}"
                @click="showOrder">
                Order Pad: {{numeral(orderTotal).format('$0,0.00')}} ({{order.length}})
              </button>
            </div>
          </div>
        </div>
        <Order
          :order="order"
          :displayOrder="displayOrder"/>
        <!-- Search Results -->
        <template v-if="!loading && showResults && !displayOrder">
          <div
            v-for="p in products"
            :key="p.product_id">
            <ResultRow
              class="animate__animated animate__fadeIn animate__faster"
              :product="p"
              @addProduct="addProduct"/>
          </div>
          <div
            class="row mt-5"
            v-if="!products.length">
            <div class="col-md-12 text-center">
              <h5 class="text-muted">No Results</h5>
            </div>
          </div>
        </template>
        <div
          v-if="loading"
          class="col-md-12 text-center mt-5">
          <Spinner/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ResultRow from './ResultRow.vue'
  import Order from './Order.vue'
  import Spinner from '../../../../../global/Spinner.vue'
  import {showDetail} from '../../../../../../shared/showDetail'

  export default {
    data(){
      return{
        query: null,
        showResults: false,
        displayOrder: true,
        showHelper: false
      }
    },
    components:{
      ResultRow,
      Order,
      Spinner
    },
    methods:{
      showOrder(){
        this.displayOrder = true
        this.showResults = false
      },
      showProducts(){
        this.displayOrder = false
        this.showResults = true
      },
      clearQuery(){
        this.query = null
        this.showResults = false
      },
      addProduct(product){
        this.$store.dispatch('addOrderPadProduct', product)
        this.showHelper = true
        setTimeout(function(){
          if(this.$refs['helper']) this.$refs['helper'].classList.add('animate__fadeOutRight')
        }.bind(this), 3000)
        setTimeout(function(){
          this.showHelper = false
        }.bind(this), 4000)
      },
      submit(){
        this.showResults = true
        this.displayOrder = false
        const params = {
          query: {
            action: 'virtualShowDirectOrderSearch',
            query: this.query
          }
        }
        this.$store.dispatch('getSearchProducts', params)
      }
    },
    computed:{
      results(){return this.$store.getters.productResults},
      products(){return this.results.products},
      loading(){return this.$store.getters.productLoadState},
      orderTotal(){
        if(!this.order.length){return}
        let total = 0
        this.order.forEach(function(o){
          const record = showDetail(o.product)
          const amount = record.show_price * o.orderData.qty
          total += amount
        })
        return total
      },
      order(){return this.$store.getters.vShowOrderPad}
    }
  }
</script>

<style scoped>
  .result-row{
    border-bottom: 1px solid lightgray;
    padding: 10px;
  }
  .clear-times{
    position: absolute;
    right: 60px;
    top: 10px;
    cursor: pointer;
  }
  .filter{
    cursor: pointer;
  }
  .filter:hover{
    transform: translateY(-3px);
    transition: .2s ease-in-out;
  }
  .helper{
    position: absolute;
    top: -35px;
    right: 13px;
    z-index: 3;
    width: 158px;
  }
</style>