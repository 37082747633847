<template>
  <div class="row">
    <div class="col-md-12 text-center my-5">
      <h3 style="font-weight: 500;">BUYING SHOW DEALS</h3>
      <h3 style="font-weight: 500;">EXTENDED PAYMENT TERMS</h3>
      <p
        style="max-width: 40em; margin: 0 auto;"
        class="py-3">
        <span v-if="canada">Not only will you receive the deepest discounts of the year by attending the show. Show attendees will also receive net 60 day payment terms, giving you the ability to stock up on key items for the spring. Non-attend customers who order more than $750 will also receive net 60 day payment terms.</span>
        <span v-else>Not only will you receive the deepest discounts of the year by attending the show. Show attendees will also receive net 60 day payment terms, giving you the ability to stock up on key items for the spring.</span>
      </p>
      <!-- Dates -->
      <div>
        <strong class="d-block">Preorder Dates: 1/22/2024 - 2/4/2024</strong>
        <strong class="d-block">2024 Lancaster Buying Show Dates: 2/3/2024 - 2/4/2024</strong>
      </div>
      <div
        v-if="canada"
        class="text-center mt-3">
        <span class="text-info">*Non-attending Customers: $750 Virtual Show Book minimum order and receive net 60-day terms.</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    computed:{
      canada(){return this.sessionCompany == 'Lancaster Canada'}
    }
  }
</script>