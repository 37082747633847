<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="wholesaleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Contact us</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div
              v-if="confirm"
              class="animate__animated animate__bounceIn animate__faster text-center py-5">
              <h4 class="text-success">
                <i class="fa fa-check-circle"/>
                Form Submitted
              </h4>
              <p class="text-muted">Thank you, we will be in contact shortly!</p>
            </div>
            <form
              v-else
              @submit.prevent="submit">
              <input
                class="form-control my-2"
                type="text"
                v-model="form.name"
                placeholder="Name"
                required>
              <input
                class="form-control my-2"
                type="text"
                v-model="form.phone"
                placeholder="Phone Number"
                required>
              <input
                class="form-control my-2"
                type="email"
                v-model="form.email"
                placeholder="Email"
                required>
              <input
                class="form-control my-2"
                type="text"
                v-model="form.city"
                placeholder="City"
                required>
              <input
                class="form-control my-2"
                type="text"
                v-model="form.state"
                placeholder="State"
                required>
              <input
                class="form-control my-2"
                type="text"
                v-model="form.zip"
                placeholder="Zip"
                required>
              <textarea
                class="form-control my-2"
                v-model="form.comments"
                placeholder="Comments"/>
              <div class="float-end">
                <div class="input-group">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <i class="fa fa-ban"/>
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-primary">
                    <i class="fa fa-paper-plane"/>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default{
    data(){
      return{
        form: null,
        confirm: false
      }
    },
    created(){
      this.setForm()
    },
    methods:{
      setForm(){
        this.form = {
          name: null,
          phone: null,
          email: null,
          city: null,
          state: null,
          zip: null,
          comments: null
        }
      },
      submit(){
        this.confirm = true
        axios.post('/api/v1/public/wholesale/form_submit', this.form).then(res => {
          setTimeout(()=>{
            this.setForm()
            this.confirm = false
          }, 5000)
        })
      }
    }
  }
</script>