<template>
  <div style="position: relative;">
    <!-- Spinner -->
    <div
      v-if="loading"
      class="row">
      <div class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
    </div>
    <!-- Header -->
    <div
      v-if="!loading"
      class="text-center p-4">
      <h4>
        <span>
          {{ route == '/clearance' ? 'CLEARANCE ' : 'PRODUCT ' }}
        </span>
         CATEGORIES
      </h4>
    </div>
    <!-- Simple View-->
    <div
      v-if="!loading"
      class="animate__animated animate__fadeIn animate__faster">
      <div class="container-fluid">
        <CategoryDetailed :categories="categories"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import CategoryDetailed from './CategoryDetailed.vue'
  
  export default {
    data(){
      return{
        loading: false
      }
    },
    components:{
      Spinner,
      CategoryDetailed
    },
    watch:{
      categories(newVal){
        if(newVal.length > 0){
          this.loading = false
        }
      }
    },
    created(){
      if(!this.settings.shopByCategory){
        this.$router.push({path: '/'})
      }
      if(this.$route.query['referred'] == 'true'){
        return
      }else{
        this.loading = true
        this.$store.dispatch('getCategories')
      }
    },
    computed:{
      categories(){return _.reject(this.$store.getters.getCategories, (c)=>{
        return c.main == 'Not Assigned' || c.main == 'Electrical, Plumbing'
      })},
      settings(){return this.$store.getters.settings},
      route(){return this.$route.path}
    }
  }
</script>