<template>
  <!-- Email Invoices -->
  <div class="card my-2">
    <div class="card-header">
      <span>Email {{type}}</span>
    </div>
    <div
      class="card-body"
      style="overflow:hidden;">
      <!-- Standard View -->
      <div
        v-if="!confirm"
        class="row animate__animated animate__slideInDown animate__faster">
        <div class="col-md-12">
          <div v-if="emailList">
            <small class="text-muted">Address List:&nbsp;</small>
            <div class="row">
              <!-- Email List -->
              <a
                v-for="e in emailList"
                :key="e"
                href="javascript: void(0);"
                @click="addAccountEmail(e)"
                class="col-md-3 col-md-4 col-sm-12 text-center my-1">
                {{e}}
              </a>
            </div>
          </div>
          <!-- Email send to list -->
          <div
            v-if="haveEmail"
            class="border-top rounded p-2">
            <span class="float-start mt-3">Send To:</span>
            <!-- Control Buttons -->
            <div class="btn-group float-end animate__animated animate__fadeInRight animate__faster">
              <button
                class="btn btn-primary btn-sm my-1"
                @click="cancel">
                <i class="fa fa-ban"/>
                Cancel
              </button>
              <button
                class="btn btn-primary btn-sm my-1"
                @click="send">
                <i class="fa fa-paper-plane"/>
                Send
              </button>
            </div>
            <div class="clearfix"/>
            <!-- Send to email list -->
            <table class="table table-sm table-bordered">
              <tbody>
                <tr
                  v-for="e, i in emailData.addressList"
                  :key="i">
                  <td>
                    <button class="btn btn-link btn-sm animate__animated animate__bounceIn animate__faster">
                      {{e}}
                      <i
                        class="fa fa-times text-danger"
                        @click="removeEmail(e)"/>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Email Address Input -->
          <form
            name="addEmail"
            @submit.prevent="addEmail"
            class="mb-2">
            <div class="input-group mt-3">
              <input
                type="email"
                class="form-control"
                v-model="email"
                placeholder="Enter Email Address"
                required>
              <button class="btn btn-primary btn-sm">
                <i class="fa fa-plus"/>
                Add
              </button>
            </div>
          </form>
          <!-- Order Listing -->
          <div class="row">
            <div
              v-for="i in emailData.orderList"
              :key="i"
              class="col-md-4">
              <div class="d-grid">
                <button class="btn btn-outline-primary m-2 animate__animated animate__bounceIn animate__faster position-relative">
                  <small
                    v-if="i.quote"
                    class="badge bg-primary">
                    Quote
                  </small>
                  {{i.text}}
                  <i
                    class="fa fa-times texst-danger"
                    @click="remove(i)"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Confirmation View -->
      <div
        v-if="confirm"
        class="row my-5 animate__animated animate__bounceIn animate__faster">
        <div class="col-md-12 text-center">
          <h4 class="text-success">
            <i class="fa fa-check-circle"/>
            Email(s) Sent!
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { internalRoles } from '../../../../shared/roles'

  export default {
    props: ['emailData', 'type'],
    data(){
      return{
        confirm: false,
        email: null
      }
    },
    methods:{
      cancel(){
        this.$emit('cancel')
      },
      remove(order){
        this.emailData.orderList = _.filter(this.emailData.orderList, o => o.text != order.text)
        if(this.emailData.orderList.length == 0){
          this.cancel()
        }
      },
      addEmail(){
        if(!this.emailData.addressList.includes(this.email)){
          this.emailData.addressList.push(this.email)
        }
        delete this.email
      },
      addAccountEmail(email){
        if(!this.emailData.addressList.includes(email)){
          this.emailData.addressList.push(email)
        }
      },
      removeEmail(email){
        this.emailData.addressList = _.filter(this.emailData.addressList, a => a != email)
      },
      send(){
        this.emailData.orderList = _.map(this.emailData.orderList, o => {
          return {id: o.value, invoice: o.invoice, quote: o.quote || false}
        })
        this.$store.dispatch('emailOrder', this.emailData)
        this.confirm = true
        setTimeout(function(){
          this.confirm = false
          this.cancel()
        }.bind(this), 2000)
      }
    },
    computed:{
      haveEmail(){
        if(this.emailData.addressList.length > 0){
          return true
        }else{
          return false
        }
      },
      user(){return this.$store.getters.user},
      showTmUserEmail(){
        if(internalRoles.includes(this.user.role) && this.user.tm_detail?.email){
          return true
        }
        return false
      },
      emailList(){
        const email = this.$store.getters.accountProfile.email
        if(email){
          let emailList = email.split(',')
          if(this.showTmUserEmail){
            emailList = [this.user.tm_detail.email, ...emailList]
          }
          return emailList
        }else{
          return []
        }
      }
    }
  }
</script>