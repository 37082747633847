<template>
  <a
    v-if="showCart"
    class="nav-link" href="#" @click="toggleRightMenu('cart')">
    <button
      class="btn btn-outline-light btn-sm position-relative"
      style="box-shadow: none; border:none;">
      <i class="fa fa-shopping-cart fa-2x"/>
      <span v-if="!cartLoading">
        <span
          class="ms-2"
          style=" font-weight: 700;">
          {{numeral(cartTotal).format('$0,0.00')}}
        </span>
        <span
          class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-info"
          style="font-size:12px;">
          {{numeral(cartProductCount).format('0,0')}}
        </span>
        <div
          v-if="cart && cart.details.vshow_cart"
          class="badge bg-success">
          <i class="fa fa-laptop"/>
          {{ vShowData ?  vShowData.title : 'Virtual Show'}}
        </div>
      </span>
      <span v-else>
        <Spinner
          class="ms-2"
          color="text-white"/>
      </span>
    </button>
  </a>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import {internalRoles} from '../../../../shared/roles'

  export default {
    components:{
      Spinner
    },
    methods:{
      toggleRightMenu(view){this.$store.dispatch('toggleRightMenu', view)}
    },
    computed:{
      cart(){return this.$store.getters.cart},
      cartTotal(){return this.$store.getters.cartTotal},
      cartProductCount(){return this.$store.getters.cartProductCount},
      cartLoading(){return this.$store.getters.cartLoading},
      hidePricing(){return this.$store.getters.hidePricing},
      vShowAllowed(){return this.$store.getters.vShowAllowed},
      vShowData(){
        if(this.vShowAllowed){
          return this.$store.getters.vShowDates
        }
        return null
      },
      showCart(){
        const user = this.$store.getters.user
        if(internalRoles.includes(user.role)){
          return this.cart && this.hidePricing == false
        }else{
          return this.hidePricing == false
        }
      }
    }
  }
</script>