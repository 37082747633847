<template>
  <div
    class="card my-2"
    style="overflow: hidden;">
    <!-- Standard Non Editing View -->
    <div
      v-if="!editing"
      class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="float-start">
            <small>
                Products({{fav.favorite_products.length || 0}})
            </small>
          </div>
          <div class="float-end">
            <button
              class="btn btn-primary btn-sm"
              @click="toggleEdit">
              <i class="fa fa-pencil-alt"/>
              Edit
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{fav.name}}</h4>
        </div>
      </div>
      <div class="card-text">
        <p
          v-if="fav.description"
          class="mb-3">
          {{fav.description}}
        </p>
        <p
          v-else
          class="text-muted mb-3">
          *No Description
        </p>
      </div>
      <div class="d-grid">
        <button
          class="btn btn-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#favViewModal"
          @click="viewFav">
          View Products
        </button>
      </div>
    </div>
    <!-- Editing View -->
    <div
      v-if="editing"
      class="card-body animate__animated animate__fadeIn animate__faster"
      style="padding:14px;">
      <form
        name="editFav"
        @submit.prevent="update">
        <div class="row">
          <div class="col-md-12">
            <button
              type="button"
              class="btn btn-danger btn-sm float-end"
              @click="toggleEdit">
                <i class="fa fa-ban me-1"/>
                Cancel
            </button>
          </div>
        </div>
        <div class="row">
          <div class="pt-0">
            <input 
              class="form-control form-control-sm my-1"
              v-model="form.name"
              autocomplete="off"
              placeholder="Name"
              required/>
          </div>
          <div class="col-md-12 pt-0">
            <input
              class="form-control form-control-sm my-1"
              v-model="form.description"
              placeholder="Description"
              autocomplete="off"/>
          </div>
        </div>
        <!-- Form Controls -->
        <div
          v-if="!confirm"
          class="btn-group w-100">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="confirmDelete">
            <i class="fa fa-trash me-1"/>
            Delete
          </button>
          <button
            class="btn btn-primary btn-sm"
            type="submit">
            <i class="fa fa-check-circle me-1"/>
            Save
          </button>
        </div>
        <!-- Confirm Delete -->
        <div
          v-if="confirm"
          class="w-100 animate__animated animate__bounceIn animate__faster">
          <small>
            Are you sure?
          </small>
          <div class="btn-group">
            <button
              class="btn btn-link px-1"
              @click="deleteFavGroup">
              Yes
            </button>
            <button
              class="btn btn-link px-1"
              @click="cancelConfirm">
              No
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['fav'],
  data(){
    return{
      editing: false,
      confirm: false,
      form: {}
    }
  },
  methods:{
    viewFav(){
      this.$store.dispatch('setActiveFav', this.fav)
    },
    toggleEdit(){
      this.editing = !this.editing
      if(this.editing == true){
        this.form.id = this.fav.id
        this.form.name = this.fav.name
        this.form.description = this.fav.description
      }else{
        this.confirm = false
      }
    },
    update(){
      this.$store.dispatch('updateFavGroup', this.form)
      this.toggleEdit()
    },
    confirmDelete(){
      this.confirm = true
    },
    cancelConfirm(){
      this.confirm = false
    },
    deleteFavGroup(){
      this.$store.dispatch('deleteFavGroup', this.fav.id)
    }
  }
}
</script>
