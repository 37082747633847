<template>
  <div class="position-relative d-none d-md-block">
    <input
      class="form-control form-control-sm pe-4"
      placeholder="Enter Keywords"
      v-model="query"
      @keyup="submit"
      type="text">
    <i
      v-if="query && query.length"
      class="fa fa-times text-danger close animate__animated animate__bounceIn"
      @click="clear"/>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        query: null
      }
    },
    methods:{
      submit(){
        this.$emit('subSearch', this.query)
      },
      clear(){
        this.query = ''
        this.submit()
      }
    }
  }
</script>

<style scoped>
  .close{
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }
</style>