// Is it stocked at this branch?
export const stockedInBranch = (product) => {
  const branchList = product.rel_branches && product.status_code == 1 ? _.compact(_.uniq(product.rel_branches.split(','))) : null
  return branchList
}

export const stockFlagInBranch = (product) => {
  const branchList = product.stock_branches ? _.compact(_.uniq(product.stock_branches.split(','))) : null
  return branchList
}

export const invInBranch = (product) => {
  // Check SQL inventory data first and once live eclipse data is available check for live data
  // Sql Based invetory data
  let branchList = product.inv_branches ? _.compact(_.uniq(product.inv_branches.split(','))) : null
  if(branchList && product.eclipse_data){
    // Eclipse Live Inventory
    const liveBranches = product.eclipse_data.branches
    const results = _.map(_.filter(liveBranches, l => branchList.includes(l.id) && parseInt(l.avail) > 0), 'id') || null
    return results
  }
  return branchList
}