<template>
  <div
    v-if="activeBranch"
    id="mapDetail"
    :style="style"
    class="detail card shadow animate__animated animate__fadeIn animate__faster">
    <div class="card-body">
      <h5>{{activeBranch.state}}</h5>
      <div class="text-muted">
        <div>{{activeBranch.address}}</div>
        <div>{{activeBranch.city}}, {{activeBranch.state_code}} {{activeBranch.zip}}</div>
        <div>Phone: {{activeBranch.branch_contact.phone}}</div>
      </div>
      <!-- If activeBranch state is the same as HQ state, show the HQ address -->
      <div v-if="activeBranch.state == headQuarters.state" class="text-muted">
        <hr>
        <div class="fw-bold"><i class="fa fa-globe"></i> Worldwide Headquarters</div>
        <div>{{headQuarters.address}}</div>
        <div>{{headQuarters.city}}, {{headQuarters.state_code}} {{headQuarters.zip}}</div>
        <div>Phone: {{headQuarters.branch_contact.phone}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['activeBranch', 'offset','headQuarters'],
    methods:{
      close(){
        this.$emit('closeDetail')
      }
    },
    computed:{
      style(){
        return {
          top: this.offset ? this.offset.top + 70 + 'px' : null,
          left: this.offset ? this.offset.left - 100 + 'px' : null,
          height: this.activeBranch.state == this.headQuarters.state ? '275px' : '160px'
        }
      }
    }
  }
</script>

<style scoped>
  .detail{
    width: 255px;
    position: absolute;
    background: white;
    z-index: 1;
    border-radius: 10px;
    border: 1px solid lightgray;
    transition: .15s ease-in-out;
  }
  .close{
    cursor: pointer;
  }
</style>