<template>
  <div
    class="row"
    style="height:350px;">
    <div
      class="col-md-8 text-center d-none d-md-block"
      :style="background">
    </div>
    <div class="col-md-4 col-12 p-5 bg-danger text-white position-relative">
      <div class="centered">
        <h4>DISTRIBUTION LOCATIONS</h4>
        <p>
          We have the ability to supply you with the right products, at the right time, for the right price. With distribution centers in California, Florida, Kentucky, New Jersey, South Carolina, Texas and Ontairo Canada, Lancaster continues to improve our distribution systems to increase efficiency and hold down costs for our customers.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        background: {
          backgroundImage: `url(${require('../../../../../assets/images/lan/about/warehouseWork.jpg')})`,
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          backgroundAttachment: 'fixed',
          filter: 'brightness(0.8)'
        }
      }
    }
  }
</script>

<style scoped>
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    transform: translateY(-50%);
    width: 420px;
  }
</style>