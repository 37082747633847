<template>
  <div
    v-if="show && data"
    class="notification animate__animated animate__slideInRight animate__fast"
    :class="background"
    ref="notification">
    <div
      class="inner text-center position-relative"
      :class="data.messageType == 'warning' ? 'text-dark' : 'text-light'">
      <strong>
        <i
          v-if="data.messageType == 'success'"
          class="fa fa-check-circle"/>
        <i
          v-if="data.messageType == 'info'"
          class="fa fa-info-circle"/>
        <i
          v-if="data.messageType == 'warning'"
          class="fa fa-exclamation-circle"/>
        <i
          v-if="data.messageType == 'danger'"
          class="fa fa-times-circle"/>
        <span class="ms-1">{{data.message}}</span>
      </strong>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['data', 'show'],
    watch:{
      show(newVal){
        if(newVal){
          this.fadeOut(4000).then(function(res){
            console.log(res)
            this.$store.dispatch('clearNotification')
          }.bind(this))
        }
      }
    },
    created(){
      this.$store.dispatch('clearNotification')
    },
    methods:{
      fadeOut(time){
        return new Promise(function(resolve){
          // Apply slide away animation after time expired
          setTimeout(function(){
            const note = this.$refs['notification']
            if(note){
              note.classList.remove('animate__slideInRight')
              note.classList.add('animate__slideOutRight')
            }
          }.bind(this), time)
          // Waiting for animation to complete before resolving
          setTimeout(() => {
            resolve('Animation Complete')
          }, (time + 800))
        }.bind(this))
      }
    },
    computed:{
      background(){
        if(this.data.messageType == 'success'){
          return 'bg-success'
        }else if(this.data.messageType == 'info'){
          return 'bg-info'
        }else if(this.data.messageType == 'warning'){
          return 'bg-warning'
        }else if(this.data.messageType == 'danger'){
          return 'bg-danger'
        }
      }
    }
  }
</script>

<style scoped>
  .notification{
    position: absolute;
    top: 10px;
    right: 0px;
    min-height: 50px;
    width: 300px;
    border-radius: 10px 0px 0px 10px;
    z-index: 2;
    box-shadow: 1px 1px 5px black;
    padding: 5px;
    border: 2px solid var(--bs-primary);
  }
  .inner{
    border-radius: 5px;
    height: 100%;
    padding: 5px;
  }
  .close-times{
    position: absolute;
    left: 1px;
    top: -2px;
    cursor: pointer;
  }
</style>