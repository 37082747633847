<template>
  <div
    v-if="display"
    class="position-relative pt-2 cart-row">
    <!-- Cart Item -->
    <div
      class="row bg-white"
      :class="!allowed && !loading ? 'unauth' : ''">
      <UnAuthorized
        v-if="!allowed && !loading"
        :allowed="allowed"
        @removeItem="removeItem"/>
      <Indicator
        :product="cartProduct.product_data"
        :virtualShow="cart.details.vshow_cart"/>
      <!-- Tools -->
      <div class="col-12">
        <!-- Remove Button -->
        <i
          v-if="!loading"
          class="fa fa-times text-danger float-end animate__animated animate__fadeIn animate__faster"
          style="cursor: pointer; padding: 6px;"
          @click="toggleRemove"/>
        <Comment
          class="float-end"
          :allowed="allowed"
          :cartProduct="cartProduct"
          :shippingCommentApplied="shippingCommentApplied"
          :loading="loading"
          @updateProduct="updateProduct"/>
        <Transfer
          class="float-end"
          v-if="!cart.details.vshow_cart"
          :loading="loading"
          :product="cartProduct"/>
        <Exclusion
          :product="cartProduct.product_data"
          class="float-end"/>
        <!-- Remove Confirm -->
        <div
          v-if="confirmRemove"
          class="text-danger shadow animate__animated animate__bounceIn animate__faster confirm-remove">
          <strong>Are you sure?</strong>
          <button
            class="btn btn-link btn-sm text-danger"
            @click="removeItem">
            Yes
          </button>
          <button
            class="btn btn-link btn-sm text-danger"
            @click="toggleRemove">
            No
          </button>
        </div>
      </div>
      <div class="col-lg-4 text-center d-flex justify-content-center align-items-center">
        <img
          data-bs-toggle="modal"
          data-bs-target="#productDetail"
          @click="setActiveProduct"
          class="img-fluid p-1"
          style="height:100px; object-fit:contain;cursor: pointer;"
          :src="cartProduct.product_data.image_url"
          @error="imgError">
      </div>
      <div class="col-lg-4">
        <div class="row text-center">
          <div
            v-if="allowed"
            style="cursor: pointer;"
            class="col-md-12">
            <b
              data-bs-toggle="modal"
              data-bs-target="#productDetail"
              @click="setActiveProduct">
              {{cartProduct.product_data.description}}
            </b>
            <!-- Data Points -->
            <div class="col-12 text-muted" style="font-size: 12px;">
              <div>
                <span>UPC: {{cartProduct.product_data.upc}}</span>
                
                <span v-if="cartProduct.customer_part_number">
                  <span class="mx-2">|</span>
                  Part Number: {{cartProduct.customer_part_number.part_number}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="cartProduct && cartProduct.product_data.eclipse_data"
          class="row mt-2">
          <div class="col-sm-3 col-4 text-center">
            <strong>Unit Price</strong>
            <br>
            <strong>{{numeral(cartProduct.pricing.unit_price).format('$0,0.00')}}</strong>
            <!-- SQFT indicator for unit price -->
            <span
              v-if="cartProduct.product_data.eclipse_data.price_uom == 'sf'"
              class="badge bg-secondary">
              per sqft
            </span>
            <!-- SQYD indicator for unit price -->
            <span
              v-if="cartProduct.product_data.eclipse_data.price_uom == 'sy'"
              class="badge bg-secondary">
              per sqyd
            </span>
            <!-- FT indicator for unit price -->
            <span
              v-if="cartProduct.product_data.eclipse_data.price_uom == 'ft'"
              class="badge bg-secondary">
              per ft
            </span>
          </div>
          <!-- Qty Form -->
          <div class="col-sm-6 col-4 text-center">
            <label
              :for="'qty' + cartProduct.eclipse_id"
              style="font-weight: bolder;">
              Qty ({{displayUom(cartProduct.uom)}})
            </label>
            <form
              name="qtyForm"
              @submit.prevent="updateQty"
              class="input-group position-relative"
              style="z-index: 0;">
              <button
                type="button"
                class="btn btn-sm btn-primary p-1 d-none d-sm-block qty"
                :disabled="loading || !allowed || nonStockOutOfStock"
                @click="qtyMod('down')">
                <div class="fa fa-minus"/>
              </button>
              <input
                :id="'qty' + cartProduct.eclipse_id"
                class="form-control qty-form"
                type="number"
                placeholder="Qty"
                :min="cartProduct.product_data.eclipse_data.price_uom == 'sf' ? 1 : cartProduct.product_data.eclipse_data.min_quantity"
                :step="cartProduct.product_data.eclipse_data.price_uom == 'sf' ? 1 : cartProduct.product_data.eclipse_data.min_quantity"
                v-model="cartProduct.quantity"
                :disabled="loading || !allowed || nonStockOutOfStock"
                style="padding: 0px 10px;"
                required>
              <button
                type="button"
                class="btn btn-sm btn-primary p-1 d-none d-sm-block qty"
                :disabled="loading || !allowed || nonStockOutOfStock"
                @click="qtyMod('up')">
                <div class="fa fa-plus"/>
              </button>
              <div class="text-muted overflow-hidden update-btn w-100">
                <button
                  v-if="showUpdate"
                  class="w-100 mt-1 btn btn-outline-primary qty-btn animate__animated animate__slideInDown animate__faster"
                  type="submit">
                  <i class="fa fa-sync"/>
                  Update
                </button>
              </div>
            </form>
            <!-- Min Qty -->
            <div v-if="minQty">
              <span>Min Qty: {{minQty.quantity}} {{ displayUom(minQty.name) }}</span>
            </div>
          </div>
          <div class="col-sm-3 col-4 text-center">
            <div>
              <strong>Ext. Price</strong>
              <div
                v-if="loading"
                class="text-center">
                <Spinner style="height: 15px; width: 15px;"/>
              </div>
              <div v-else>
                <strong>{{numeral(cartProduct.pricing.extended_price).format('$0,0.00')}}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <ShipFromBranch
          v-if="!cart.details.vshow_cart && cartProduct && allowed"
          :product="cartProduct"
          :shippingCommentApplied="shippingCommentApplied"
          :nonStockOutOfStock="nonStockOutOfStock"
          @updateProduct="updateProduct"
          @removeFromCart="removeItem"/>
        <VshowDropShipNote v-if="cart.details.vshow_cart && showDetail.dropship"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import Transfer from './Transfer.vue'
  import Exclusion from '../product/Exclusion.vue'
  import UnAuthorized from './UnAuthorized.vue'
  import ShipFromBranch from './ShipFromBranch.vue'
  import Comment from './Comment.vue'
  import Indicator from '../../views/product/Indicator.vue'
  import VshowDropShipNote from './VshowDropShipNote.vue'
  import {caseQuantity, palletQuantity, minQuantity, nonStockOutOfStock, displayUom } from '../../../../shared/ProductFunctions'
  import {showDetail} from '../../../../shared/showDetail'
  import comingSoon from '../../../../shared/comingSoon'

  export default {
    props:['cartProduct', 'showUnauth', 'loading'] ,
    inject: ['env'],
    data(){
      return{
        cartProductLoading: false,
        confirmRemove: false,
        showUpdate: false
      }
    },
    components:{
      Spinner,
      Transfer,
      Exclusion,
      UnAuthorized,
      ShipFromBranch,
      Comment,
      Indicator,
      VshowDropShipNote
    },
    watch:{
      qty(newVal){
        if(newVal) this.showUpdateBtn()
      }
    },
    computed:{
      cart(){return this.$store.getters.cart},
      cartAllowedProducts(){return this.$store.getters.cartAllowedProducts},
      allowed(){return this.cartAllowedProducts.includes(this.cartProduct.eclipse_id)},
      shippingCommentApplied(){
        if(this.cartProduct.comment?.includes("Ship item from another warehouse with added freight charge")){
          return true
        }else{
          return false
        }
      },
      minQty(){return minQuantity(this.cartProduct.product_data, null, this.env)},
      caseQty(){return caseQuantity(this.cartProduct.product_data)},
      palletQty(){return palletQuantity(this.cartProduct.product_data)},
      showDetail(){return showDetail(this.cartProduct.product_data)},
      display(){
        if(this.showUnauth){
          return this.allowed == false
        }
        return true
      },
      qty(){return this.cartProduct.quantity},
      nonStockOutOfStock(){
        return nonStockOutOfStock(this.env, this.cartProduct.product_data)
      },
    },
    methods:{
      showUpdateBtn(){
        this.showUpdate = true
      },
      updateQty(){
        this.showUpdate = false
        if(this.cartProduct.quantity && this.cartProduct.quantity > 0){
          this.updateProduct()
        }
      },
      toggleRemove(){
        this.confirmRemove = !this.confirmRemove
      },
      removeItem(){
        this.confirmRemove = false
        this.$store.dispatch('removeCartItem', this.cartProduct)
      },
      updateProduct(){
        this.$store.dispatch('updateProduct', this.cartProduct)
      },
      applyShippingComment(comment){
        this.cartProduct.comment = comment
      },
      imgError(e){
        comingSoon(e)
      },
      setActiveProduct(){
        this.$store.dispatch('setActiveProduct', this.cartProduct.product_data)
      },
      qtyMod(direction){
        switch(direction){
          case 'up':
            this.cartProduct.quantity += 1
          break
          case 'down':
            this.cartProduct.quantity -= 1
          break
        }
      },
      displayUom(uom){ return displayUom(uom) }
    }
  }
</script>

<style scoped>
  .disabled-dark{
    color:var(--bs-primary) !important;
    font-weight:bold;
  }
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    background: black;
    border-radius: 10px;
    z-index:2;
    opacity:0.02;
  }
  .confirm-remove{
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    background: white;
    padding: 0px 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
  }
  .qty{
    z-index: 1 !important;
  }
  .qty-btn{
    padding: 5px;
    font-size: .8em;
  }
  .unauth{
    background: var(--bs-danger-bg-subtle) !important;
    border-color: var(--bs-danger-bg-subtle) ;
  }
  .qty-form{
    max-width: 100px;
    text-align: center;
  }
  .inside{
    position: absolute;
    right: 75px;
    top: 8px;
  }
  .input-group{
    justify-content: center;
  }
  .input-group-text{
    padding: 0.375rem .3rem !important;
  }
  .update-btn{
    font-size: 14px;
    max-width: 10rem;
  }
  .cart-row{
    border-bottom: 1px solid lightgray;
  }
  /* remove number input inner up and down btns */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>