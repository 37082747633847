<template>
  <div>
    <div v-if="submitting" class="text-info">
      <i class="fa fa-spinner fa-spin"></i> Building your price list. Please do not refresh or close this window.
    </div>
    <div
      v-else
      :class="textColor"
      v-html="statusMessage">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Status',
  props: ['success','submitting','selected','sendTo','selected'],
  computed: {
    statusMessage(){
     let text = 'Price lists may take a few moments to process for large manufacturers.'
     if(this.success == true){
      text = '<i class="fa fa-check"></i> Success! Your ' + this.selected + ' Price List was sent to ' + this.sendTo
     }else if(this.success == false){
      text = '<i class="fa fa-times"></i> Something went wrong. If this issue persists, contact your account manager.'
     }
     return text
    },
    textColor(){
      let textColor = 'text-muted'
      if(this.success == true){
        textColor = 'text-success'
      }else if(this.success == false){
        textColor = 'text-danger'
      }
      return textColor
    }
  }
}
</script>