<template>
  <div v-if="showExclusion">
    <div class="row">
      <div class="col-md-12">
        <button
          v-if="exclusion && exclusion.message"
          data-bs-toggle="modal"
          data-bs-target="#prepaidExclude"
          class="btn btn-outline-danger btn-sm"
          @click="setExclusionMessage"
          style="font-size:12px;">
          <i class="fa fa-exclamation-triangle"/>
          {{exclusion.message.exclusion_type}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:['product'],
    methods:{
      setExclusionMessage(){this.$store.dispatch('setExclusionMessage', this.exclusion.message)}
    },
    computed:{
      exclusion(){return this.product.prepaid_freight_exclusion || null},
      company(){return this.$store.getters.sessionCompany},
      showExclusion(){
        if(this.exclusion){
          return this.company == this.exclusion.company
        }else{
          return false
        }
      }
    }
  }
</script>