<template>
  <div>
    <div class="row">
      <div
        class="col-md-6 d-none d-md-block"
        :style="saveTheDate">
      </div>
      <div
        class="col-md-6 col-12 bg-event text-white p-5 position-relative"
        style="min-height: 250px;">
        <div class="centered">
          <h2 style="font-weight: 400;">THERE IS ALWAYS SOMETHING HAPPENING AT LANCASTER</h2>
          <!-- <p>Be first to know about new products hitting the market to trade show buying events where you can find deep discounts.</p> -->
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center my-3">
      <button
        class="btn btn-outline-primary"
        @click="gotoLogin">
        LOGIN TO ACCESS EVENT DETAILS
      </button>
    </div>
  </div>
</template>

<script>
  export default {
   data(){
     return{
       saveTheDate: {
         backgroundImage: `url(${require('../../../../../../assets/images/lan/events/SaveTheDate.png')})`,
         ...this.bgProperties()
       }
     }
   },
   methods:{
      bgProperties(){
        return {
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '350px'
        }
      },
      gotoLogin(){
        this.$router.push({name: 'PublicHome'})
        let el = document.getElementById('router-view')
        el.scrollTo(0,0)
      }
    }
  }
</script>

<style scoped>
  .centered{
    position: absolute;
    top: 50%;
    padding:10px;
    width: 85%;
    transform: translateY(-50%);
  }
</style>