<template>
  <div class="container">
    <div class="row my-5">
      <div class="col-md-12 mb-5 text-center">
        <h2
          class="text-event"
          style="font-weight: 400;">
          EXPERIENCES FROM YOUR COLLEAGUES
        </h2>
      </div>
      <!-- Testimonial -->
      <div
        class="col-md-12 px-5"
        v-for="(t,i) in testimonials"
        :key="t.id">
        <div>
          <div>
            <p>{{t.comment}}</p>
            <strong>
              ► {{t.name}}
            </strong>
            <br>
            <small class="text-event">
              {{t.customer_name}}
              <br>
              {{t.state}}
            </small>
          </div>
        </div>
        <hr v-if="i+1 < testimonials.length">
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return{
        testimonials: null
      }
    },
    created(){
      this.getTestimonials()
    },
    methods:{
      getTestimonials(){
        axios.get('/api/v1/public/testimonials/get_events_testimonials').then(res =>{
          this.testimonials = res.data.testimonials
        })
      }
    }
  }
</script>
