<template>
  <div>
    <Dashboard
      v-if="view == 'dashboard'"
      :view="view"/>
    <ActiveTable
      v-else
      :view="view"/>
  </div>
</template>

<script>
  import Dashboard from './Dashboard.vue'
  import ActiveTable from './ActiveTable.vue'

  export default {
    components:{
      Dashboard,
      ActiveTable
    },
    created(){
      this.$store.dispatch('setAdminView', 'dashboard')
    },
    methods:{
      setView(view){
        this.view = view
      }
    },
    computed:{
      view(){return this.$store.getters.adminView}
    }
  }
</script>