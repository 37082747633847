<template>
  <div>
    <!-- Delete Confirm Message -->
    <div
      v-if="deleteMsg"
      class="row animate__animated animate__bounceIn animate__faster">
      <div class="col-md-12 text-center text-danger">
        <strong>
          <i class="fa fa-check-circle"/>
          OrderNotes Deleted
        </strong>
      </div>
    </div>
    <!-- Form -->
    <form
      v-if="showNotes"
      name="orderNotes"
      class="my-2"
      style="overflow: hidden;"
      @submit.prevent="submitOrderNotes">
      <div class="row animate__animated animate__fadeInDown animate__faster">
        <div class="col-md-12">
          <!-- Save Message -->
          <span
            v-if="showConfirm"
            class="float-end badge bg-success animate__animated animate__bounceIn animate__faster">
            <i class="fa fa-check-circle"/>
            Order Notes Saved!
          </span>
          <!-- Wait indicator -->
          <span
            v-if="showWait"
            class="float-end">
            <i class="fa fa-cog fa-spin"/>
          </span>
          <label for="orderNotes">Order Notes</label>
          <textarea
            class="form-control"
            placeholder="Enter Order Notes Here"
            v-model="orderNotes"
            @keydown="keyStroke"
            id="orderNotes"
            cols="30"
            rows="4"
            maxlength="250"/>
          <div class="float-end">
            <!-- Actions -->
            <div
              v-if="!confirmDelete"
              class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                @click="closeNotes">
                <i class="fa fa-times"/>
                Close
              </button>
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                :disabled="!orderNotes"
                @click="clear">
                <i class="fa fa-eraser"/>
                Clear
              </button>
              <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                :disabled="!orderNotes"
                @click="toggleDelete">
                <i class="fa fa-trash"/>
                Delete
              </button>
            </div>
            <!-- Delete confirm prompt -->
            <div
              v-else
              class="btn-group animate__animated animate__bounceIn animate__faster">
              <strong class="text-danger me-1">Are you sure?</strong>
              <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                @click="deleteOrderNotes">
                <i class="fa fa-trash"/>
                Yes
              </button>
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                @click="toggleDelete">
                <i class="fa fa-trash"/>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    props: ['showNotes'],
    data(){
      return{
        orderNotes: null,
        showConfirm: false,
        typeTimer: null,
        confirmDelete: false,
        deleteMsg: false,
        autosaved: false,
        showWait: false
      }
    },
    mounted(){
      this.orderNotes = this.cart.details.order_notes
    },
    watch:{
      showNotes(newVal){
        if(!newVal){
          this.showConfirm = false
        }
      }
    },
    methods:{
      submitOrderNotes(){
        this.showConfirm = true
        this.$store.dispatch('submitOrderNotes', this.orderNotes)
        setTimeout(function(){
          this.closeNotes()
          this.showConfirm = false
        }.bind(this), 3000)
      },
      closeNotes(){
        this.$emit('closeNotes')
      },
      clear(){
        this.orderNotes = null
        document.getElementById('orderNotes').focus()
      },
      autosave(){
        this.showConfirm = true
        this.$store.dispatch('submitOrderNotes', this.orderNotes)
      },
      deleteOrderNotes(){
        this.orderNotes = null
        this.showConfirm = false
        this.$store.dispatch('submitOrderNotes', this.orderNotes)
        this.confirmDelete = false
        this.deleteMsg = true
        setTimeout(function(){
          this.deleteMsg = false
          this.$emit('closeNotes')
        }.bind(this), 3000)
      },
      keyStroke(){
        this.showConfirm = false
        // reset timer on keystroke
        if(this.typeTimer){
          clearTimeout(this.typeTimer)
          this.showWait = true
        }
        this.typeTimer = setTimeout(function(){
          this.autosave()
          this.showWait = false
          this.autosaved = true
        }.bind(this), 3000)
      },
      toggleDelete(){
        this.confirmDelete = !this.confirmDelete
      }
    },
    computed:{
      cart(){return this.$store.getters.cart}
    }
  }
</script>

<style scope>
  .form-control{
    box-shadow: none !important;
  }
</style>