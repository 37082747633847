<template>
  <div class="my-4"> 
    <div
      v-if="slides && !shipToLoading"
      id="slides"
      class="carousel slide shadow"
      data-bs-ride="carousel">
      <ol class="carousel-indicators mt-2">
        <li
          v-for="(s, index) in slides"
          :key="s.id"
          data-bs-target="#slides"
          :data-bs-slide-to="index"
          :class="{active: index == 0}">
        </li>
      </ol>
      <div class="carousel-inner wrapper" role="listbox">
        <div
          v-for="(s, index) in slides"
          :key="s.id"
          class="carousel-item"
          :class="{active: index == 0}">
          <!-- Internal -->
          <a
            v-if="s.internal_link"
            :href="s.internal_link">
            <img
            :src="s.image_url"
            class="img-fluid slider">
          </a>
          <!-- External -->
          <a
            v-else-if="s.external_link"
            :href="s.external_link"
            target="_blank">
            <img
            :src="s.image_url"
            class="img-fluid slider">
          </a>
          <!-- Image -->
          <img
            v-else
            :src="s.image_url"
            class="img-fluid slider">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#slides" data-bs-slide="prev">
        <span class="fa fa-chevron-left chev-shadow" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#slides" data-bs-slide="next">
        <span class="fa fa-chevron-right chev-shadow" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div
      v-else
      class="position-relative bg-gray"
      style="max-height: 300px; height: 19.75vw; background: lightgray;">
      <!-- <Spinner class="spinner"/> -->
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Spinner from '../../../global/Spinner.vue'
  export default {
    data(){
      return{
        slides: null,
        show: false
      }
    },
    mounted(){
      this.getSlides()
    },
    watch:{
      shipToLoading(newVal){
        if(!newVal){
          this.getSlides()
        }
      }
    },
    methods:{
      getSlides(){
        axios.get('/api/v1/private/slides/get_slides').then(res=>{
          this.slides = res.data.slides
        })
      }
    },
    computed:{
      shipToLoading(){return this.$store.getters.shipToLoadStatus}
    },
    components:{
      Spinner
    }
  }
</script>

<style scoped>
  .carousel-indicators{
    bottom: -50px;
  }
  .carousel-indicators > li{
    height:10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--bs-primary);
    list-style-type: none;
  }
  .slider{
    max-height: 300px;
    width: 100%;
  }
  .wrapper:hover{
    transform: scale(1.02);
    transition: .2s ease-in-out;
    cursor: pointer;
  }
  .spinner{
    position: absolute;
    top: 38%;
    left: 50%;
  }
  li{
    height: 13px !important;
    width: 13px !important;
  }
  .chev-shadow{
    text-shadow: 2px 2px 7px black;
    font-size: 25px;
  }
  .carousel-control-prev, .carousel-control-next{
    width: 5% !important;
  }
</style>