<template>
  <div class="mx-1">
    <div class="dropdown">
      <div class="d-grid">
        <button
          class="btn btn-primary btn-sm dropdown-toggle"
          :class="mobile ? 'w-100' : ''"
          type="button"
          id="sortByFilter"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          :disabled="loading">
          Sort By
        </button>
        <div
          class="dropdown-menu"
          id="sortByDropdown"
          :class="{'w-100': fullWidthMenu}"
          aria-labelledby="sortByFilter">
          <a
            class="dropdown-item"
            :class="{active: activeSort == 'rel'}"
            href="javascript: void(0)"
            @click="sort('rel')"
            data-bs-dismiss="offcanvas">
            Relevence
          </a>
          <a
            class="dropdown-item"
            :class="{active: activeSort == 'desc-asc'}"
            href="javascript: void(0)"
            @click="sort('desc-asc')"
            data-bs-dismiss="offcanvas">
            Description
            <i class="fa fa-caret-up"/>
          </a>
          <a
            class="dropdown-item"
            :class="{active: activeSort == 'desc-desc'}"
            href="javascript: void(0)"
            @click="sort('desc-desc')"
            data-bs-dismiss="offcanvas">
            Description
            <i class="fa fa-caret-down"/>
          </a>
          <a
            class="dropdown-item"
            :class="{active: activeSort == 'mfg-asc'}"
            href="javascript: void(0)"
            @click="sort('mfg-asc')"
            data-bs-dismiss="offcanvas">
            Brand
            <i class="fa fa-caret-up"/>
          </a>
          <a
            class="dropdown-item"
            :class="{active: activeSort == 'mfg-desc'}"
            href="javascript: void(0)"
            @click="sort('mfg-desc')"
            data-bs-dismiss="offcanvas">
            Brand
            <i class="fa fa-caret-down"/>
          </a>
          <!-- <a
            v-if="reorderPad"
            class="dropdown-item"
            :class="{active: activeSort == 'last-ordered'}"
            href="javascript: void(0)"
            @click="sort('last-ordered')"
            data-bs-dismiss="offcanvas">
            Last Ordered
            <i class="fa fa-caret-down"/>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import hideDropdown from '../../../../../../shared/hideDropdown'
  
  export default {
    props: ['loading', 'filters', 'mobile', 'fullWidthMenu'],
    methods:{
      sort(view){
        hideDropdown('sortByDropdown')
        this.$store.dispatch('sortProducts', view)
      }
    },
    computed:{
      activeSort(){return this.$store.getters.productFilters.selected.sortBy},
      reorderPad(){return this.$router.currentRoute.value.query?.action == 'reorderPad'}
    }
  }
</script>