<template>
  <div class="main col-md-12 animate__animated animate__fadeIn animate__faster">
    <div
      v-if="loading"  
      class="col-md-12 text-center mt-5">
      <Spinner class="text-light"/>
    </div>
    <div
      v-else  
      class="p-3"
      style="border-radius: 10px;">
      <div
        v-if="!activeRecord"
        class="mb-3 px-5 text-center">
        <p v-if="data && data.options">{{data.options.description}}</p>
      </div>
      <Index
        v-if="activeRecord == null && !createNew && !activePromo"
        class="animate__animated animate__fadeIn animate__faster"
        :data="data"
        :view="view"
        @toggleCreateNew="toggleCreateNew"/>
      <Detail
        v-if="(activeRecord || createNew) && !activePromo"
        class="animate__animated animate__fadeIn animate__faster"
        :data="data"
        :view="view"
        :activeRecord="activeRecord"
        @cancelCreateNew="cancelCreateNew"/>
      <PromoData v-if="activePromo"/>
      </div>
    </div>
</template>

<script>
  import Index from './Index.vue'
  import Detail from  './Detail.vue'
  import Spinner from '../../../global/Spinner.vue'
  import PromoData from './promos/PromoData.vue'

  export default {
    props: ['data', 'view'],
    data(){
      return{
        createNew: false
      }
    },
    watch:{
      view(newVal, oldVal){
        if(newVal != oldVal){
          this.cancelCreateNew()
        }
      }
    },
    components:{
      Index,
      Detail,
      Spinner,
      PromoData
    },
    methods:{
      toggleCreateNew(){
        this.createNew = !this.createNew
      },
      cancelCreateNew(){
        this.createNew = false
      }
    },
    computed:{
      activeRecord(){return this.$store.getters.adminActiveRecord},
      activePromo(){return this.$store.getters.adminActivePromo},
      loading(){return this.$store.getters.adminLoading}
    }
  }
</script>

<style scoped>
  .main{
    overflow: hidden;
  }
</style>