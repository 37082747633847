<template>
  <div class="col-md-12 text-center p-2 bg-danger-subtle">
    <h5 class="text-danger">
      <i class="fa fa-exclamation-triangle"/>
      Item not authorized for this ship to location
    </h5>
    <button
      class="btn btn-outline-danger btn-sm"
      @click="removeItem">
      Remove item
      <i class="fa fa-times"/>
    </button>
  </div>
</template>

<script>
  export default {
    props: ['allowed'],
    methods:{
      removeItem(){
        this.$emit('removeItem')
      }
    }
  }
</script>