<template>
  <div
    class="col-md-4"
    style="border-right: 1px solid lightgray;">
    <h4 class="text-center">Label List</h4>
    <!-- Controls -->
    <div class="row p-2">
      <div class="col-md-6">
        <div class="d-grid">
          <button
            class="btn btn-primary btn-sm"
            :disabled="list && list.length == 0"
            @click="generate">
            Generate Labels
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-grid">
          <button
            class="btn btn-primary btn-sm"
            @click="clear">
            Clear List
          </button>
        </div>
      </div>
      <hr class="mt-2">
      <!-- Products in list -->
      <div
        v-if="list && list.length > 0"
        style="height: calc(100vh - 400px); overflow-y: scroll; overflow-x: hidden;">
        <div
          v-for="l in list.slice().reverse()"
          :key="l.product_id"
          class="animate__animated animate__fadeInRight animate__faster shadow-sm p-2 my-2"
          style="border-radius: 10px; border: 1px solid lightgray;">
          <i
            class="fa fa-times text-danger"
            style="position: absolute; top: 5px; right: 10px;cursor: pointer;"
            @click="remove(l.product_id)"/>
          <div class="row">
            <div class="col-md-4 text-center">
              <img
                :src="l.image_url"
                @error="imgError">
            </div>
            <div class="col-md-8 mt-1">
              {{l.description}}
            </div>
          </div>
        </div>
      </div>
      <!-- No Products -->
      <div
        v-else
        class="text-center animate__animated animate__fadeIn animate__faster"
        style="height: 300px;">
        <strong class="text-muted">No Products</strong>
      </div>
    </div>
  </div>
</template>

<script>
  import comingSoon from '../../../../shared/comingSoon'
  export default {
    methods:{
      clear(){
        this.$store.dispatch('clearBinLabelList')
      },
      remove(id){
        this.$store.dispatch('removeFromBinLabelList', id)
      },
      generate(){
        this.$store.dispatch('generateLabels')
      },
      imgError(e){
        comingSoon(e)
      }
    },
    computed:{
      list(){return this.$store.getters.binLabelList}
    }
  }
</script>

<style scoped>
  img{
    height:75px;
    width:75px;
    object-fit: contain;
  }
</style>