<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 mt-2 text-center">
        <h4 class="text-center">Terms & Details</h4>
        Lancaster regular freight policy applies and will be the minimum dollar amount to place a Road Show order.
      </div>
      <div class="col-md-12 mt-3">
        <ul>
          <li>
            August 12 through September 14, 2024
          </li>
          <li>
            Orders of $2,500 or more of Road Show ONLY open stock warehouse products, will receive payment terms of Net 60 Days.
          </li>
          <li>
            All advertised drop ships in the Road Show book will receive terms of Net 60 Days, unless otherwise noted and are subject to each drop ship vendor's prepaid and freight policies.
          </li>
          <li>
            All Road Show orders must ship no later than October 18, 2024.
          </li>
          <li>
            If inventory is not available in your Lancaster home distribution center by October 18, 2024 those products will be canceled from your order.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
  ul{
    line-height: 2em;
  }
</style>