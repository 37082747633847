<template>
  <div>
    <div v-if="!showDelete">
      <button
        class="btn btn-sm btn-link"
        @click="$emit('toggleEdit')">
        <i class="fa fa-pencil-alt"/>
        Edit
      </button>
      <button
        class="btn btn-sm btn-link"
        @click="toggleShowDelete">
        <i class="fa fa-trash"/>
        Delete
      </button>
    </div>
    <!-- Confirm Delete -->
    <div
      v-if="showDelete"
      class="animate__animated animate__bounceIn animate__faster">
      <strong class="text-danger">Are you sure?</strong>
      <div
        class="input-group"
        style="display: inherit;">
        <button
          class="btn btn-sm btn-link"
          @click="deleteRecord">
          <i class="fa fa-check"/>
          Yes
        </button>
        <button
          class="btn btn-sm btn-link"
          @click="toggleShowDelete">
          <i class="fa fa-times"/>
          No
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props: ['promoProduct'],
    data(){
      return{
        showDelete: false
      }
    },
    methods:{
      toggleShowDelete(){
        this.showDelete = !this.showDelete
      },
      deleteRecord(){
        this.$store.dispatch('deletePromoProduct', this.promoProduct)
      }
    }
  }
</script>