<template>
  <div>
    <div class="container-fluid mt-2">
      <!-- Account Details Dashboard-->
      <Dashboard
        v-if="!activeInvoice && !activeOrder"
        class="animate__animated animate__fadeIn animate__faster"/>
      <!-- Active Invoice -->
      <ActiveInvoice
        v-if="activeInvoice"
        :invoice="activeInvoice"
        @back="closeInvoice"/>
      <!-- Active Order -->
      <ActiveOrder
        v-if="activeOrder"
        :order="activeOrder"
        @back="closeOrder"/>
    </div>
  </div>
</template>

<script>
  import Dashboard from './Dashboard.vue'
  import ActiveInvoice from './ActiveInvoice.vue'
  import ActiveOrder from './ActiveOrder.vue'

  export default {
    components:{
      Dashboard,
      ActiveInvoice,
      ActiveOrder
    },
    created(){
      if(!this.settings.myAccount){
        this.$router.push({path: '/'})
      }else{
        this.$store.dispatch('getAccountDetails')
        this.$store.dispatch('setActiveTab', 'profile')
        this.$store.dispatch('setOrderStatusFilter', null)
      }
    },
    methods:{
      closeInvoice(){this.$store.dispatch('closeInvoice')},
      closeOrder(){this.$store.dispatch('closeOrder')}
    },
    computed:{
      activeInvoice(){return this.$store.getters.getActiveInvoice},
      activeOrder(){return this.$store.getters.getActiveOrder},
      settings(){return this.$store.getters.settings}
    }
  }
</script>

<style scoped>
</style>