<template>
  <div
    v-if="messages"
    class="bg-warning bar animate__animated animate__fadeInDown animate__faster">
    <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
      <div
        class="carousel-inner text-center" role="listbox"
        data-bs-toggle="modal"
        data-bs-target="#betaModal">
        <div
          v-for="(m, index) in messages"
          :key="m.id"
          class="carousel-item"
          :class="{active: index == 0}">
          {{m.title}} {{m.message}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data(){
      return{
        messages: null
      }
    },
    mounted(){
      this.getMessages()
    },
    methods:{
      getMessages(){
        axios.get('/api/v1/private/messages/get_messages').then(res =>{
          this.messages = res.data.messages
        })
      }
    }
  }
</script>

<style scoped>
  .carousel-inner{
    cursor:pointer;
  }
  .bar{
    border-bottom: 1px solid lightgray;
    height: 35px;
    overflow: hidden;
  }
  .bar:hover{
    font-weight: bold;
    transition: .2s ease-in-out;
  }
</style>