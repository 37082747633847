<template>
  <div class="card my-2 shadow-sm animate__animated animate__fadeIn animate__faster position-relative">
    <!-- Card Header -->
    <div
      class="card-header text-muted"
      :class="active ? 'active-header' : ''">
      <div class="row">
        <div class="col-md-12">
          <div class="float-start">
            <div>ID: {{customer.id}}</div>
            <div>
              <small class="me-1">
                Ship To:&nbsp;
                <template v-if="!active">
                  <i :class="customer.is_ship_to ? 'fa fa-check-circle text-success' : 'fa fa-times-circle text-muted'"/>
                </template>
                <template v-if="active">
                  <i :class="customer.is_ship_to ? 'fa fa-check-circle text-white' : 'fa fa-times-circle text-white'"/>
                </template>
              </small>
              <small class="ms-1">
                Bill To:&nbsp;
                <template v-if="!active">
                  <i :class="customer.is_bill_to ? 'fa fa-check-circle text-success' : 'fa fa-times-circle text-muted'"/>
                </template>
                <template v-if="active">
                  <i :class="customer.is_bill_to ? 'fa fa-check-circle text-white' : 'fa fa-times-circle text-white'"/>
                </template>
              </small>
            </div>
          </div>
          <!-- Make customer active -->
          <button
            class="float-end btn btn-link btn-sm"
            :disabled="loading"
            @click="unselect ? '' : activateCustomer(customer.id)">
            <span v-if="!active && customer.is_ship_to">
              <button
                class="btn btn-outline-primary"
                :disabled="unselect">
                Select
              </button>
            </span>
          </button>
          <!-- Display active status -->
          <div
            v-if="active"
            class="float-end badge bg-info shadow-sm"
            style="font-size:15px;">
            Active
          </div>
        </div>
        <div>
          <!-- Assigned To -->
          <div
            v-if="view == 'manager'"
            class="col-md-12">
            <small>
              Assigned To:&nbsp;
              <strong>{{customer.domain_name ? customer.domain_name.replace('.', ' ') : customer.user_id}}</strong>
            </small>
          </div>
          <!-- Status Flags -->
          <div class="col-md-12">
            <div v-if="customer.no_order_entry">
              <small class="float-start badge bg-danger sm-badge mx-1">
                <i class="fa fa-ban"/>
                No Order Entry
              </small>
            </div>
            <div v-if="customer.promo_receive_flag">
              <small class="float-start badge bg-success sm-badge mx-1">
                <i class="fa fa-tag"/>
                Promo Receive
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-body"
      :class="active ? 'active-body' : ''">
      <div v-if="error">
        <div class="row">
          <div class="col-md-12 text-center text-danger">
            <div class="alert alert-danger">
              <i class="fa fa-exclamation-triangle"/>
              {{error.msg}}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card-title">{{customer.name}}</div>
        <div class="card-text">
          <small>
            <div>{{customer.address1}}</div>
            <div>{{customer.address2}}</div>
            <div>{{customer.city}}, {{customer.state_code}}, {{customer.zip_code}}, {{customer.country_code}}</div>
          </small>
          <!-- Stats -->
          <ul
            class="p-0 my-2"
            :class="active ? 'text-white' : 'text-muted'"
            style="list-style-type: none; line-height:18px;">
            <li v-if="lastLogin">
              <small>Last Login: {{moment(lastLogin.updated_at).format('MM-DD-YYYY')}}</small>
            </li>
            <li v-if="lastOrder">
              <small >Last eStore Order: {{moment(lastOrder.completed_at).format('MM-DD-YYYY')}}</small>
              <span
                v-if="lastOrder.tm_cart"
                class="badge bg-success p-1 ms-2">TM Submitted</span>
            </li>
          </ul>
          <span v-if="!lastLogin" class="me-1 badge bg-muted sm-badge">No Login Data</span>
          <span v-if="!lastOrder" class="me-1 badge bg-muted sm-badge">No eStore Orders</span>
        </div>
      </div>
    </div>
    <!-- Loading Overlay -->
    <div
      v-if="loading && !error"
      class="loading-overlay">
      <div class="row">
        <div class="col-md-12 text-center">
          <Spinner class="light-spinner"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../../global/Spinner.vue'
  import {lastOrder, lastLogin} from '../../../../../shared/customerCard'
  
  export default {
    props: ['customer', 'tm', 'view', 'userStats', 'orderStats', 'unselect'],
    data(){
      return{
        loading: false
      }
    },
    components:{
      Spinner
    },
    methods:{
      // Make the active customer
      activateCustomer(id){
        if(!this.loading){
          this.loading = true
          const data = {
            customer_id: id
          }
          this.$store.dispatch('selectCustomer', data)
        }
      }
    },
    watch:{
      // Return to home screen when customer becomes active
      activeCustomer(newVal){
        if(newVal && newVal.entity_id != this.customer.id){
          document.activeElement.blur()
        }else{
          this.loading = false
          this.$store.dispatch('setRepDashboardView', false)
        }
      }
    },
    computed:{
      // active customer object
      activeCustomer(){return this.$store.getters.getEntity.activeShipTo},
      // Last order instance
      lastOrder(){
        return lastOrder(this.orderStats)
      },
      // last login instance
      lastLogin(){
        return lastLogin(this.userStats)
      },
      // flag the current card as active
      active(){
        if(this.activeCustomer){
          return this.customer.id == this.activeCustomer.entity_id
        }
        return false
      },
      error(){return this.$store.getters.tmError}
    }
  }
</script>

<style scoped>
  .card-body{
    min-height:120px;
    padding: 10px !important;
  }
  .sm-badge{
    font-size: 10px;
  }
  .btn-link{
    text-decoration: none;
  }
  .loading-overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: darkslategray;
    z-index: 2;
    opacity: 40%;
  }
  .light-spinner{
    background: white;
    position: absolute;
    top: 40%;
    left: 45%;
  }
  .card{
    height: 100%;
  }
  .cust-type{
    width: 100%;
    margin: 0 auto;
    max-width: 85px;
  }
  .active-header{
    background: var(--bs-success);
    color: white !important;
  }
  .active-body{
    background: var(--bs-primary);
    color:white;
  }
</style>