<template>
  <!--The purpose of this component is to inform the user they have an "Prepaid Freight Excluded item" in their cart.
  these items are heavy and carry a high freight charge.-->
  <div v-if="exclusionWarning">
    <table class="table table-bordered table-sm text-center mt-3">
      <thead>
        <tr>
          <th colspan="2">
            <span v-if="!exclusionConfirmed">
              ATTENTION
            </span>
            <span v-else>
              Freight Charges Accepted
            </span>
            <i
              v-if="exclusionConfirmed"
              class="fa fa-check-circle text-success"/>
            <i
              v-else
              class="fa fa-exclamation-triangle text-danger"/>
          </th>
        </tr>
      </thead>
      <tbody
        v-if="!exclusionConfirmed"
        class="text-danger"
        style="background: rgb(211,211,211,0.5)">
        <tr>
          <td>
            <span>
              Your Cart Contains:&nbsp;
              Prepaid Excluded Item(s):&nbsp;{{equipmentCount}}
            </span>
            <br>
            <span v-if="['EJ Welch', 'Cheney'].includes(company)">These products will incur freight charges that will be quoted prior to shipping.</span>
            <span v-else>If shipped alone or below prepaid, freight charges will be invoiced. Prepaid minimums can be achieved by ordering other products.</span>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="!exclusionConfirmed">
        <tr>
          <td>
            <div class="d-grid p-2">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="acknowledge">
                I Accept All Freight Charges
              </button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  export default {
    inject: ['env'],
    props:['exclusionWarning', 'exclusionConfirmed'],
    methods:{
      acknowledge(){
        this.$store.dispatch('equipmentFreightChargeAccept')
        this.$emit('acknowledged')
      }
    },
    computed:{
      cartProducts(){return this.$store.getters.cartProducts},
      equipmentCount(){
        let ids = _.map(this.cartProducts, cp => {
          if(cp.prepaid_freight_exclusion){
            return cp.prepaid_freight_exclusion.product_id
          }
        })
        return ids.filter(Number).length
      },
      company(){return this.$store.getters.sessionCompany}
    }
  }
</script>