<template>
  <div class="row main">
    <div class="col-md-6 col-12 content-wrap">
      <span class="text-danger">PROSUPPLY</span>
      <div class="my-4">
        <h2>Your Flooring Supply Headquarters</h2>
        <p>Stop into any of our ProSupply locations to pick up essential tools and supplies for your residential and commercial flooring projects. Consult with our expert staff on new flooring tools, sundry supplies or installation techniques and ask about our quarterly specials.</p>
        <ProSupplyBtn/>
      </div>
    </div>
    <div
      class="col-md-6 col-12 d-none d-md-block"
      :style="imgBackground"/>
  </div>
</template>

<script>
  import ProSupplyBtn from './ProSupplyBtn.vue'
  export default{
    data(){
      return{
        imgBackground:{
          backgroundImage: `url(${require('../../../../../assets/images/ejw/about/proSupply.jpg')})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }
      }
    },
    components:{
      ProSupplyBtn
    }
  }
</script>

<style scoped>
  @media(min-width: 992px){
    .main{
      height: 500px;
      overflow: hidden;
    }
  }
  .content-wrap{
    padding: 50px;
  }
</style>