<template>
  <div
    class="modal fade"
    id="prepaidExclude"
    tabindex="-1"
    role="dialog">
    <div
      class="modal-dialog"
      role="document">
      <div
        class="modal-content"
        style="border-radius: 20px;">
        <div class="modal-header">
          <h5 class="text-danger">
            <i class="fa fa-exclamation-triangle"/>
            Prepaid Freight Exclusions
          </h5>
        </div>
        <div class="modal-body" style="overflow:hidden;">
          <p class="text-danger">
            <span>{{ message }}</span>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    inject: ['env'],
    computed:{
      message(){return this.$store.getters.exclusionMessage}
    }
  }
</script>
