<template>
  <div>
    <div class="row m-5">
      <div class="col-md-12 text-center">
        <h2
          class="text-event"
          style="font-weight: 400;">
          REV UP SAVINGS WITH LANCASTER'S ROAD SHOW
        </h2>
      </div>
      <div class="row mt-5">
        <div class="col-lg-3 col-md-6 d-none d-lg-block">
          <img
            :src="book2018"
            class="img-fluid">
        </div>
        <div class="col-lg-3 col-md-6 d-none d-lg-block">
          <img
            :src="book2019"
            class="img-fluid">
        </div>
        <div class="col-lg-3 col-md-6 d-none d-md-block">
          <img
            :src="book2020"
            class="img-fluid">
        </div>
        <div class="col-lg-3 col-md-6 col-6">
          <img
            :src="book2021"
            class="img-fluid">
        </div>
        <div class="col-md-6 col-6 d-block d-md-none">
          <ul class="mobile-list">
            <li>100+ Brands On Sale</li>
            <li>August/September Savings</li>
            <li>Delayed Shipping & Extended Payment Terms</li>
            <li>Experienced Sales And Customer Service</li>
            <li>New Products</li>
            <li>Stock-Up For The Fall Season</li>
            <li>Unlimited Drop Ship Orders</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid d-none d-md-block">
      <div class="row m-5 px-5 text-center">
        <div class="col-md-4 col-sm-12 col-12">
          <strong>✓ 100+ Brands On Sale</strong>
          <br>
          <strong>✓ August/September Savings</strong>
          <br>
          <strong>✓ Available Online</strong>
        </div>
        <div class="col-md-4 col-sm-12 col-12">
          <strong>✓ Delayed Shipping & Extended Payment Terms</strong>
          <br>
          <strong>✓ Experienced Sales And Customer Service</strong>
          <br>
          <strong>✓ New Products</strong>
        </div>
        <div class="col-md-4 col-sm-12 col-12">
          <strong>✓ Stock-Up For The Fall Season</strong>
          <br>
          <strong>✓ Unlimited Drop Ship Orders</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        roadShow: require('../../../../../../assets/images/lan/events/RoadShowBanner.png'),
        book2018: require('../../../../../../assets/images/lan/events/RoadShow2018.png'),
        book2019: require('../../../../../../assets/images/lan/events/RoadShow2019.png'),
        book2020: require('../../../../../../assets/images/lan/events/RoadShow2020.png'),
        book2021: require('../../../../../../assets/images/lan/events/RoadShow2021.png')
      }
    }
  }
</script>

<style scoped>
  .center-x{
    line-height: 35px;
    left: 50%;
    position: relative;
    transform: translateX(-25%);
  }
  @media(max-width: 767pk){
    .mobile-center{
      text-align: center !important;
    }

  }
  .mobile-list{
    list-style-type: none;
  }

  .mobile-list li:before{
    content: '✓';
    margin:0 3px 0 -15px;
  }
</style>