import axios from 'axios'
import moment from 'moment'
import router from '../../../router'
import {internalRoles} from '../../../shared/roles'

const initialState = {
  checkout:{},
  confirmation:{},
  processing: false,
  error: null,
  freightAmounts: null
}

function setupShipVias(entity){
  let options = []
  entity.ship_via_list.forEach(s=>{
    let obj = {text: s.text, value: s.value}
    if(!_.map(options, 'value').includes(obj.value)) options.push(obj)
  })
  return options
}

// TM's can request a credit, they will submit a standard order but when 
// the credit request form element is set to true this function will flip
// all cart qty to negative values before submitting to eclipse
const processCreditRequest = function(cart){
  let adjustedCart = cart
  adjustedCart.cartProducts.forEach(cp => {
    cp.quantity = cp.quantity * -1
  })
  return adjustedCart
}

export const checkoutStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setCheckout(state, checkout){
      state.checkout = checkout
    }, 
    setConfirmation(state, data){
      state.confirmation.orderNumbers = data.order_numbers
      state.confirmation.completedAt = data.completed_at
      state.confirmation.completedDate = data.completed_date
      state.confirmation.orderDetails = state.checkout
      state.confirmation.orderDetails.cart = data.cart
      state.checkout = {}
      // let totals = getTotals(state)
      // state.confirmation.orderTotal = totals.dollarTotal
      // state.confirmation.orderQtyTotal = totals.qtyTotal
    },
    setProcessing(state, status){
      state.processing = status
    },
    setCheckoutError(state, error){
      state.error = error
    },
    freightAmounts(state, data){
      state.freightAmounts = data
    },
    equipmentFreightChargeAccept(state){
      state.checkout.form.equipmentFreightCharge = true
    }
  },
  actions:{
    checkoutResetState(context){
      context.commit('resetState')
    },
    populateCheckout(context){
      context.commit('setProcessing', false)
      let activeShipTo = this.state.entityStore.activeShipTo.entity_id
      let entity = this.state.entityStore.activeShipTo.entity
      let shipViaOptions = setupShipVias(entity)
      let availableShipTos = [this.state.entityStore.activeShipTo]
      let user = this.state.sessionStore.user
      let form = {
        shipVia: entity.ship_via || shipViaOptions[0],
        orderedBy: null,
        creditRequest: false,
        requiredDate: moment().format('YYYY-MM-DD'),
        equipmentFreightCharge: false,
        shipWithNextPrepaid: false
      }
      let checkout = {
        activeShipTo,
        availableShipTos,
        shipViaOptions,
        form,
        user
      }
      context.commit('setCheckout', checkout)
    },
    completeOrder(context){
      context.commit('setProcessing', true)
      const header = this.state.checkoutStore.checkout
      const cart = header.form.creditRequest ? processCreditRequest(this.state.cartStore.cart) : this.state.cartStore.cart
      const order = {
        header,
        cart
      }
      axios.post('/api/v1/private/order/create', {order}).then(res => {
        if(res.data.error){
          context.commit('setCheckoutError', res.data.error)
          context.commit('setProcessing', false)
        }else{
          context.commit('setProcessing', false)
          context.commit('setConfirmation', res.data)
          router.push({path: '/confirm'})
          this.dispatch('getCart')
        }
      })
    },
    clearError(context){
      context.commit('setCheckoutError', null)
    },
    freightAmounts(context){
      axios.get('/api/v1/private/freight/get_amounts').then(res=>{
        context.commit('freightAmounts',res.data.freight)
      })
    },
    equipmentFreightChargeAccept(context){
      context.commit('equipmentFreightChargeAccept')
    }
  },
  getters:{
    checkout: state => {return state.checkout},
    confirmation: state => {return state.confirmation},
    checkoutProcessing: state => {return state.processing},
    checkoutError: state => {return state.error},
    freightAmounts: state => {return state.freightAmounts}
  }
}