<template>
  <div class="text-light">
    <button
      class="btn btn-outline-light w-100 my-2"
      @click="toggleShow">
      <strong>Categories</strong>
      <i class="fa fa-caret-down ms-2"/>
    </button>
    <button
      v-if="show && categoryFilters && (categoryFilters.mains && categoryFilters.mains.length || categoryFilters.subs && categoryFilters.subs.length)"
      class="badge bg-light text-dark mx-1"
      style="cursor: pointer;"
      @click="clearCategories">
      Clear
      <i class="fa fa-times text-danger"/>
    </button>
    <ul
      v-show="show"
      class="p-0 mt-2 animate__animated animate__slideInLeft animate__faster">
      <li v-for="c in categoryData">
        <!-- Main Categories -->
        <LineItem
          lineType="main"
          :category="c"
          :clear="clear"
          @emitMain="handleMain"/>
        <!-- Sub Categories -->
        <!-- <ul>
          <li v-for="s in c.subs">
            <LineItem
              lineType="sub"
              :category="s"
              :clear="clear"
              @emitSub="handleSub"/>
          </li>
        </ul> -->
      </li>
    </ul>
  </div>
</template>

<script>
  import LineItem from './LineItem.vue'
  export default{
    props: ['loading', 'categories', 'categoryFilters'],
    data(){
      return{
        mains:[],
        subs:[],
        show: false,
        clear: false,
        expanded: false
      }
    },
    components:{
      LineItem
    },
    watch:{
      loading(newVal){
        if(!newVal) this.clear = false
      }
    },
    methods:{
      toggleShow(){
        this.show = !this.show
      },
      submit(){
        const categories = {
          mains: this.mains,
          subs: this.subs
        }
        this.$emit('setCategories', categories)
      },
      handleMain(data){
        if(!this.mains.includes(data.main)){
          this.mains.push(data.main)
        }else{
          this.mains = _.reject(this.mains, m => m == data.main)
        }
        this.submit()
      },
      handleSub(sub){
        if(!this.subs.includes(sub)){
          this.subs.push(sub)
        }else{
          this.subs = _.reject(this.subs, s => s == sub)
        }
        this.submit()
      },
      clearCategories(){
        this.clear = true
        this.mains = []
        this.subs = []
        this.submit()
      }
    },
    computed:{
      categoryData(){return _.reject(this.categories, {main: 'Uncategorized'})}
    }
  }
</script>

<style scoped>
  ul{
    list-style-type: none;
    font-size: 10px !important;
  }
  li{
    font-size: 10px;
    white-space: nowrap;
    padding: 0px 5px;
  }
</style>