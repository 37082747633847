<template>
  <div style="overflow: hidden;">
    <form
      name="promoBranch"
      @submit.prevent="save"
      class="mt-3 animate__animated animate__fadeIn animate__faster">
      <div class="btn-group  mx-2 float-end animate__animated animate__fadeInRight animate__faster">
        <button
          class="btn btn-sm btn-primary"
          type="button"
          @click="toggleBranches">
          <i class="fa fa-sync"/>
          Toggle All Branches
        </button>
        <button
          class="btn btn-sm btn-primary"
          type="submit">
          <i class="fa fa-save"/>
          Save
        </button>
      </div>
      <div
        v-if="message"
        class="badge bg-success mx-2 animate__animated animate__fadeInDown float-end">
        {{ message }}
      </div>
      <div class="clearfix"/>
      <table class="table table-light table-striped table-sm">
        <thead class="text-center">
          <th>Branch</th>
          <th>Off/On</th>
          <th>Price Branch</th>
        </thead>
        <tbody class="text-center">
          <PromoBranchRow
            v-for="b in branchListing"
            :key="b"
            :b="b"
            :form="form"
            :sellBranches="sellBranches"
            :priceBranches="priceBranches"
            :branchListing="branchListing"/>
        </tbody>
      </table>
    </form>
  </div>
</template>

<script>
  import PromoBranchRow from '../branch/PromoBranchRow.vue'
  import axios from 'axios'
  export default{
    inject: ['env'],
    props: ['branchData', 'promoHeader'],
    components:{
      PromoBranchRow
    },
    data(){
      return{
        branchListing: [],
        loaded:false,
        toggleAllBranches:false,
        form: {
          promo_id: this.promoHeader.id,
          allowedBranches:{},
          priceBranches:{}
        }
      }
    },
    mounted(){
      axios.get('/api/v1/public/branches/get_branches').then(function(res){
        const branches = res.data.branches
        let branchData = []
        branches.forEach(function(b){
          this.form.allowedBranches[b.branch_id] = false
          this.form.priceBranches[b.branch_id] = this.env.includes('EJW') ? (this.env == 'EJW-CHENEY' ? 'S69' : 'S01') : '1'
          branchData.push(b.branch_id)
        }.bind(this))
        this.branchListing = _.sortBy(_.reject(branchData, b => b == 'HQ'), b => {
          switch(b){
            case '1':
              return 1
            case '2':
              return 2
            case '7T':
              return 3
            case '8':
              return 4
            case '9':
              return 5
            case '14':
              return 6
            case '71':
              return 7
          }
        })
        this.loaded = true
      }.bind(this))
    },
    methods:{
      save(){
        this.$store.dispatch('savePromoBranch', this.form)
      },
      toggleBranches(){
        this.toggleAllBranches = !this.toggleAllBranches
        for(const [key,value] of Object.entries(this.form.allowedBranches)){
          this.form.allowedBranches[key] = this.toggleAllBranches
        }
        this.$store.dispatch('setAdminMessage', `All Branches ${this.toggleAllBranches}`)
      }
    },
    computed:{
      checkBoxes(){return this.form.allowedBranches},
      sellBranches(){return _.map(this.branchData, b => b.sell_branch)},
      priceBranches(){return _.map(this.branchData, b => {
        let branch = {}
        branch[b.sell_branch] = b.price_branch
        return branch
      })},
      message(){return this.$store.getters.adminMessage},
      allowedBranchesWatcher(){return _.values(this.form.allowedBranches)},
      priceBranchesWatcher(){return _.values(this.form.priceBranches)}
    }
  }
</script>